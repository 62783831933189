import {
    SET_APPINFO_ALL,
    SET_APPINFO_FIELD,
    SET_APPINFO_SAVED_ALL_CHANGES,
} from "../constants/actionTypes"

const initState = {
    frontPage: "",
    ridePage: "",
    endRidePage: "",
    appInfoLoaded: false,
    unsavedChanges: false,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_APPINFO_ALL:
            return {
                ...initState,
                ...state,
                ...action.payload,
            }
        case SET_APPINFO_FIELD:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                unsavedChanges: true,
            }
        case SET_APPINFO_SAVED_ALL_CHANGES:
            return {
                ...state,
                unsavedChanges: false,
            }
        default:
            return state
    }
}
