import React, { useEffect, useState } from "react"
import {
    Grid,
    Box,
    Typography,
    Button,
    TextField,
    Tooltip,
    IconButton,
} from "@mui/material"
import { useSelector } from "react-redux"
import store from "../../store"
import validateEmail from "../firebaseUtils/ValidateEmail"
import { functions } from "../../rootWrapper/firebase"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import {
    PERMISSIONSSHOULDUPDATESTATE,
    SET_PERMISSION_INSTALLER_EMAIL,
} from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"

const toolTip =
    "The submit adds the installers email. " +
    "The user will get an invite on email to register with password. Write the exact email address." +
    "After registering the user will have everything they need to install the SmartBox."

export default function AddInstallerInput(props) {
    const adminUser = useSelector((state) => state.firestore.data.user)
    const { courseDocId } = adminUser

    const initStatus = {
        error: false,
        success: false,
        loading: false,
    }

    const [status, setStatus] = useState(initStatus)
    const [error, setError] = useState("")
    const editing = useSelector((state) => state.permissionEdit)
    const handleChange = (e) => {
        e.preventDefault()
        const { value } = e.target
        store.dispatch({
            type: SET_PERMISSION_INSTALLER_EMAIL,
            payload: { email: value.toLowerCase() },
        })
    }
    const wait = () => new Promise((r) => setTimeout(r, 2000))

    const newUserInvite = httpsCallable(functions, "newUserInvite")
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus((state) => ({ ...state, loading: true }))
        const { installerEmail } = editing
        if (!validateEmail(installerEmail)) {
            setError("invalid email")
            setStatus((state) => ({ ...state, error: true, loading: false }))
            wait().then(() => {
                setError("")
                setStatus(initStatus)
            })
            return
        }
        newUserInvite({
            courseDocId,
            email: editing.installerEmail,
            isInstaller: true,
        })
            .then((r) => {
                setStatus({ ...initStatus, success: true })
                setError("")
                store.dispatch({ type: PERMISSIONSSHOULDUPDATESTATE })
                store.dispatch({
                    type: SET_PERMISSION_INSTALLER_EMAIL,
                    payload: { email: "" },
                })
                wait().then(() => {
                    setStatus(initStatus)
                })
            })
            .catch((e) => {
                setStatus((status) => ({
                    ...status,
                    error: true,
                    loading: false,
                }))
                setError(e.message)
                wait().then(() => {
                    setStatus(initStatus)
                })
            })
    }

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="center"
                alignContent="center"
                spacing={2}
            >
                <Grid item xs>
                    <TextField
                        color="secondary"
                        variant="filled"
                        label="Installer email"
                        placeholder="test@eagl.app"
                        name="cart-installer"
                        value={editing.installerEmail}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    title={<h3>{toolTip}</h3>}
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <Box sx={{ mt: 1 }}>
                        <SuccessLoader
                            status={status}
                            error={error}
                            contained
                            handleSubmit={handleSubmit}
                        >
                            Add Installer
                        </SuccessLoader>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
