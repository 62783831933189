import TableRow from "@mui/material/TableRow"
import { Checkbox, FormControlLabel, TableCell } from "@mui/material"
import React from "react"
import { accessPermissionsSchemaDesc } from "../../assets/applicationText/permissionSchemaDesc"

export const CheckboxRows = (prop) => {
    const { boxes, changePermissions } = prop
    const descSchema = accessPermissionsSchemaDesc
    return Object.keys(boxes)
        .sort((x, y) => boxes[y].index - boxes[x].index)
        .filter(
            (featureName) => Object.keys(descSchema).indexOf(featureName) > -1
        )
        .map((featureName, index) => (
            <TableRow hover role="checkbox" key={index}>
                <TableCell>{descSchema[featureName].name}</TableCell>
                <TableCell>{descSchema[featureName].desc}</TableCell>
                <TableCell align="right">
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="secondary"
                                checked={!!boxes[featureName].access}
                                onClick={(e) =>
                                    changePermissions(
                                        featureName,
                                        e.target.checked
                                    )
                                }
                                name="checkedA"
                            />
                        }
                        label=""
                    />
                </TableCell>
            </TableRow>
        ))
}
