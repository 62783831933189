import React from "react"
import Grid from "@mui/material/Grid"
import { useStyles } from "../assets/styles/views/statistics"
import { Box, Button, Divider, LinearProgress, Typography } from "@mui/material"
import StatsRowBookings from "../Components/vehicleStatsRow/statsRowAllCarts"
import { useSelector } from "react-redux"
import DataTimePicker from "../Components/vehicleStatsRow/timeDataPicker"
import store from "../store"
import { SET_BOOKING_QUERY_EXECUTE } from "../constants/actionTypes"
import IncomeSection from "./statistics/income"
import ReportSection from "./statistics/reportSection"
import UserRidesSection from "./statistics/userTripSection"

export default function Statistics() {
    const classes = useStyles()
    const courseName = useSelector(
        (state) => state.firestore?.data?.field?.name
    )
    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })
    const { queryComplete, edited } = query
    return (
        <Box className={classes.root}>
            <Box className={classes.gridContainer}>
                <Typography
                    className={classes.inlineText}
                    fontWeight={"bold"}
                    fontSize={"24pt"}
                >
                    Statistics {courseName}
                </Typography>
                <Box className={classes.inlineBoxRight}>
                    {queryComplete ? null : (
                        <LinearProgress color="secondary" />
                    )}
                    <Typography display={"inline-block"} sx={{ pr: 2 }}>
                        Period:
                    </Typography>
                    <DataTimePicker type="start" />
                    <Typography display={"inline-block"} sx={{ pr: 2 }}>
                        {" - "}
                    </Typography>
                    <DataTimePicker type="end" />
                    <Button
                        color="secondary"
                        sx={{
                            p: 0.5,
                            fontWeight: "bold",
                        }}
                        disabled={!edited}
                        variant="contained"
                        onClick={() => {
                            store.dispatch({
                                type: SET_BOOKING_QUERY_EXECUTE,
                            })
                        }}
                    >
                        Update
                    </Button>
                </Box>
            </Box>
            <Typography>
                To export a report,{" "}
                <Typography
                    color="secondary"
                    sx={{ textDecoration: "underline" }}
                    component={"a"}
                    href="#reports"
                >
                    click here.
                </Typography>
                To view payouts,{" "}
                <Typography
                    color="secondary"
                    sx={{ textDecoration: "underline" }}
                    component={"a"}
                    href="/settings/payouts"
                >
                    click here.
                </Typography>
            </Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Grid container direction="column" className={classes.grid}>
                <Grid
                    item
                    xs
                    style={{
                        maxWidth: "100%",
                        position: "relative",
                    }}
                >
                    <StatsRowBookings />
                </Grid>
                <Divider flexItem sx={{ mt: 1, mb: 1 }} />
                <Grid item xs style={{ maxWidth: "100%" }}>
                    <IncomeSection />
                </Grid>
                <Divider flexItem sx={{ mt: 1, mb: 1 }} />
                <Grid item xs style={{ maxWidth: "100%" }}>
                    <UserRidesSection />
                </Grid>
                <Divider flexItem sx={{ mt: 1, mb: 1 }} />
                <Grid item xs style={{ maxWidth: "100%" }}>
                    <ReportSection />
                </Grid>
            </Grid>
        </Box>
    )
}
