import React, { useEffect, useRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import { Tab, Tabs, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import styled from "@emotion/styled"
import { StatusBooker } from "./statusBooker"
import { DialogTitle } from "../DialogTitle"
import { DialogContent } from "../DialogTitle/dialogContent"
import { DialogActions } from "../DialogTitle/dialogAction"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import BookingOptions from "../bookingOptions"
import MaintenanceBooking from "../bookingMaintenance"
import { PrimaryAppbar } from "../settingsTabs/settingTab"
import store from "../../store"
import {
    CLEAR_CREATE_BOOKING,
    SET_BOOKING_FIELDS,
    UPDATE_BOOKING_VIEW,
} from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const getDefaultStart = (startDate) => {
    /* startDates date, but nows time and minute*/
    const now = new Date()
    now.setDate(new Date(startDate).getDate())
    now.setMonth(new Date(startDate).getMonth())
    const startMs = now.setFullYear(new Date(startDate).getFullYear())
    return startMs
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
    minWidth: "580px",
}))

export default function BookingDialog(props) {
    const style = useDialogStyles()
    const [pageIndex, setPageIndex] = useState(0)
    const [currentProgress, setCurrentProgress] = useState({
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    })
    const name = useRef("")
    const tlf = useRef("")
    const note = useRef("")
    const email = useRef("")

    const handleClose = () => {
        store.dispatch({ type: CLEAR_CREATE_BOOKING })
        props.handleClose()
    }

    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )

    const standardMinutes = useSelector(
        (state) => state.firestore?.data?.field?.standard ?? 5 * 60
    )
    const shortMinutes = useSelector(
        (state) => state.firestore?.data?.field?.short ?? 5 * 60
    )
    const standardTime = standardMinutes * 60 * 1000
    const shortTime = shortMinutes * 60 * 1000

    const {
        startTime,
        endTime,
        amountOfCarts,
        couponCode,
        isNineHoles,
        price,
        paid,
        userSegment,
        cartIdList,
        vehicleGroup,
        loading,
        reoccuring,
    } = useSelector((state) => state.createBooking)

    useEffect(() => {
        const defaultStartTime = getDefaultStart(props.startDate)
        if (!isNineHoles) {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    startTime: defaultStartTime,
                    endTime: defaultStartTime + standardTime,
                },
            })
        } else {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    startTime: defaultStartTime,
                    endTime: defaultStartTime + shortTime,
                },
            })
        }
    }, [props.startDate, isNineHoles])

    async function createBookingPressed() {
        setCurrentProgress({ ...currentProgress, inProgress: true })
        if (startTime > endTime) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "Start time is before end time",
            })
            return new Promise((r) => setTimeout(r, 2000)).then(() =>
                setCurrentProgress({
                    ...currentProgress,
                    error: false,
                    errorMessage: "",
                })
            )
        }
        if (pageIndex === 0) {
            console.log(
                name.current.value,
                note.current?.value,
                email.current?.value,
                tlf.current?.value,
                userSegment,
                price,
                amountOfCarts
            )
            if (
                !name.current ||
                name.current?.value === "" ||
                !tlf.current ||
                tlf.current?.value === "" ||
                price === "" ||
                price < 0 ||
                userSegment === "" ||
                isNaN(Number(amountOfCarts))
            ) {
                setCurrentProgress({
                    ...currentProgress,
                    error: true,
                    errorMessage: "Please fill out all the fields",
                })
                return new Promise((r) => setTimeout(r, 2000)).then(() =>
                    setCurrentProgress({
                        ...currentProgress,
                        error: false,
                        errorMessage: "",
                    })
                )
            }

            const booking = httpsCallable(functions, "createBookingMulti")

            const data = {
                startTime,
                endTime,
                amountOfCarts: Number(amountOfCarts),
                courseDocId,
                userSegment,
                name: name.current?.value || "",
                tlf: tlf.current?.value || "",
                couponCode,
                isNineHoles: isNineHoles ?? false,
                note: note.current?.value || "",
                email: email.current?.value || "",
                customPrice: price,
                hasPaid: paid,
                vehicleGroup,
                recurring: reoccuring ?? false,
            }
            console.log(data)
            await booking(data)
                .then((r) => {
                    if (r.data && r.data.status && r.data.status === "failed") {
                        setCurrentProgress({
                            inProgress: false,
                            success: false,
                            error: true,
                            errorMessage: r.data.msg,
                        })
                        return new Promise((resolve) =>
                            setTimeout(resolve, 3000)
                        )
                    }
                    setCurrentProgress({
                        ...currentProgress,
                        inProgress: false,
                        success: true,
                        error: false,
                        errorMessage: "",
                    })
                    store.dispatch({
                        type: UPDATE_BOOKING_VIEW,
                    })
                    return new Promise((resolve) =>
                        setTimeout(resolve, 3000)
                    ).then(() => handleClose())
                })
                .then(() => {
                    setCurrentProgress({
                        inProgress: false,
                        success: false,
                        error: false,
                        errorMessage: "",
                    })
                })
                .catch((e) => {
                    setCurrentProgress({
                        ...currentProgress,
                        error: true,
                        errorMessage: e.message,
                    })
                })
                .then(() => {
                    new Promise((resolve) => setTimeout(resolve, 3000)).then(
                        () => {
                            setCurrentProgress({
                                inProgress: false,
                                success: false,
                                error: false,
                                errorMessage: "",
                            })
                        }
                    )
                })
        } else {
            if (
                !note.current ||
                note.current?.value === "" ||
                !cartIdList ||
                cartIdList.length === 0
            ) {
                setCurrentProgress({
                    ...currentProgress,
                    error: true,
                    errorMessage: "Please fill out all the fields",
                })
                return new Promise((r) => setTimeout(r, 2000)).then(() =>
                    setCurrentProgress({
                        ...currentProgress,
                        error: false,
                        errorMessage: "",
                    })
                )
            }
            console.log("new booking", cartIdList, note?.current.value)

            const adminBookingCall = httpsCallable(
                functions,
                "createMaintenanceBookingMulti"
            )
            await adminBookingCall({
                startTime: startTime,
                endTime: endTime,
                courseDocId,
                cartDocIdList: cartIdList,
                note: note?.current.value,
            })
                .then(async (r) => {
                    if (r.data && r.data.status && r.data.status === "failed") {
                        setCurrentProgress({
                            inProgress: false,
                            success: false,
                            error: true,
                            errorMessage: r.data.msg,
                        })
                        return new Promise((resolve) =>
                            setTimeout(resolve, 3000)
                        )
                    }
                    setCurrentProgress({
                        ...currentProgress,
                        inProgress: false,
                        success: true,
                        error: false,
                        errorMessage: "",
                    })
                    store.dispatch({
                        type: UPDATE_BOOKING_VIEW,
                    })
                    await new Promise((resolve_1) =>
                        setTimeout(resolve_1, 3000)
                    )
                    return handleClose()
                })
                .then(() => {
                    setCurrentProgress({
                        inProgress: false,
                        success: false,
                        error: false,
                        errorMessage: "",
                    })
                })
                .catch(async (e) => {
                    console.error(e.message)
                    setCurrentProgress({
                        ...currentProgress,
                        error: true,
                        errorMessage: e.message,
                    })
                    await new Promise((resolve) => setTimeout(resolve, 3000))
                    return setCurrentProgress({
                        inProgress: false,
                        success: false,
                        error: false,
                        errorMessage: "",
                    })
                })
        }
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        }
    }

    const handleChangeIndex = (e, newValue) => {
        setPageIndex(newValue)
    }

    function TabSwitcher(pageIndex) {
        switch (pageIndex) {
            case 0:
                return (
                    <BookingOptions
                        {...{ name, tlf, note, email }}
                        setCurrentProgress={setCurrentProgress}
                    />
                )
            case 1:
                return (
                    <MaintenanceBooking
                        {...{
                            note,
                            setCurrentProgress,
                        }}
                    />
                )
            default:
                return <div>Please Refresh page</div>
        }
    }

    return (
        <div>
            <CustomDialog
                classes={{ paper: style.overlay }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <PrimaryAppbar position="static">
                        <Tabs
                            variant="fullWidth"
                            value={pageIndex}
                            onChange={handleChangeIndex}
                            aria-label="Booking tabs"
                        >
                            <Tab label="Booking" {...a11yProps(0)} />
                            <Tab label="Maintenance" {...a11yProps(1)} />
                        </Tabs>
                    </PrimaryAppbar>
                </DialogTitle>
                <DialogContent
                    sx={{
                        alignItems: "stretch",
                        flexDirection: "column",
                        display: "flex",
                    }}
                >
                    <form
                        style={{
                            flex: 1,
                            display: "block",
                        }}
                    >
                        {TabSwitcher(
                            pageIndex,
                            userSegment,
                            endTime,
                            cartIdList,
                            amountOfCarts,
                            price
                        )}
                    </form>
                    <DialogActions
                        style={{
                            marginTop: "auto",
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <StatusBooker
                            currentProgress={currentProgress}
                            defaultText="Make reservation"
                            disabled={loading}
                            doBooking={createBookingPressed}
                        />
                        <Typography variant="body2" color="#C4C4C4">
                            *The price listed for the booking will serve as a
                            base for the EAGL cut of the ride{" "}
                        </Typography>
                    </DialogActions>
                </DialogContent>
            </CustomDialog>
        </div>
    )
}
