import { Box, Link, TextField, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import { functions } from "../../../../rootWrapper/firebase"
import store from "../../../../store"

const desc = (
    <Box>Billing email, reieve important billing updates and receipts.</Box>
)
const title = <>Billing email</>

function CurrentValue() {
    const stripeSubEmail = useSelector(
        (state) => state.settingsForm.stripeSubEmail
    )
    return stripeSubEmail ?? ""
}

const getBillingAccount = httpsCallable(functions, "getBillingAccount")

const handleChange = (e) => {
    const value = e?.target?.value
    store.dispatch({
        type: SELECTSETTINGSFIELD,
        payload: { key: "stripeSubEmail", value: value },
    })
}

function StripeSubEmail() {
    const [email, setEmail] = useState(null)
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    const stripeSubEmail = useSelector(
        (state) => state.settingsForm.stripeSubEmail
    )

    useEffect(() => {
        if (courseDocId && !email) {
            getBillingAccount({ courseDocId, subscriptionAccount: true })
                .then((r) => {
                    const emailTmp = r?.data?.email || ""
                    setEmail(emailTmp)
                    store.dispatch({
                        type: SELECTSETTINGSFIELD,
                        payload: {
                            key: "stripeSubEmail",
                            value: emailTmp,
                        },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [courseDocId, email])

    if (!email) {
        return null
    }

    return (
        <Box>
            <TextField
                color="secondary"
                size="small"
                variant="standard"
                onChange={handleChange}
                value={stripeSubEmail || ""}
            />
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: StripeSubEmail,
}
