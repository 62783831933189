import React from "react"
import { useSelector } from "react-redux"
import BookingInfoCard from "./bookingInfoCard"

export default function NextBookingCard() {
    const { nextBooking, nextLoading } = useSelector(
        (state) => state.nextBooking
    )
    return (
        <BookingInfoCard
            bookingText={"Next booking"}
            booking={nextBooking}
            loading={nextLoading}
        />
    )
}
