import React from "react"
import { Input } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import store from "../../store"
import {
    SET_BOOKING_QUERY,
    SET_VEHICLE_QUERY,
} from "../../constants/actionTypes"
import enLocale from "date-fns/locale/en-GB"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useSelector } from "react-redux"

export const renderInput = (props) => (
    <Input
        type="text"
        color="secondary"
        inputRef={props.inputRef}
        inputProps={props.inputProps}
        value={props.value}
        onClick={props.onClick}
        onChange={props.onChange}
        endAdornment={props.InputProps?.endAdornment}
        style={{ marginLeft: "auto", marginRight: "20px", width: 125 }}
    />
)

const getDispatchPayload = (type, time) => {
    if (type === "start" || type === "startSearch" || type === "startVehicle") {
        const startTime = new Date(time)
        return {
            query: {
                [typeToValue[type]]: startTime.setHours(0, 0, 0),
            },
        }
    }
    if (type === "end" || type === "endSearch" || type === "endVehicle") {
        const endTime = new Date(time)
        const dayEnd = endTime.setHours(23, 59, 59)
        return {
            query: {
                [typeToValue[type]]: Math.min(Date.now(), dayEnd),
            },
        }
    }
}

const getDispatchAction = (type) => {
    if (type === "startVehicle" || type === "endVehicle") {
        return SET_VEHICLE_QUERY
    }
    if (type === "startSearch" || type === "endSearch") {
        return SET_BOOKING_QUERY
    }
    if (type === "start" || type === "end") {
        return SET_BOOKING_QUERY
    }
}
const validTypes = [
    "start",
    "end",
    "startSearch",
    "endSearch",
    "startVehicle",
    "endVehicle",
]

const typeToValue = {
    start: "queryStart",
    end: "queryEnd",
    startSearch: "startSearch",
    endSearch: "endSearch",
    startVehicle: "start",
    endVehicle: "end",
}
const vehicleQueryTypes = ["startVehicle", "endVehicle"]

export default function DataTimePicker(props) {
    const { type } = props

    const bookingQuery = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })
    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })

    const vehicleQuery = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })
    const cartDocIds = useSelector((state) => {
        return Object.keys(state?.firestore?.data?.carts || {})
    })

    if (validTypes.indexOf(type) === -1) {
        return "INVALID TYPE"
    }
    const value = typeToValue[type]
    const query =
        vehicleQueryTypes.indexOf(type) >= 0 ? vehicleQuery : bookingQuery

    const executeDispatch = (type, newTime) => {
        if (type === "startVehicle" || type === "endVehicle") {
            /** Update all carts, ensure all carts follow same time period on change of vehicle */
            cartDocIds.forEach((cartDocId) => {
                store.dispatch({
                    type: getDispatchAction(type),
                    payload: {
                        ...getDispatchPayload(type, newTime),
                        cartDocId,
                    },
                })
            })
        } else {
            store.dispatch({
                type: getDispatchAction(type),
                payload: getDispatchPayload(type, newTime),
            })
        }
    }

    const handleDateChange = (date, inputText) => {
        if (date instanceof Date && !isNaN(date)) {
            const newTime = date.getTime()
            if (inputText === undefined || inputText.length === 10) {
                executeDispatch(type, newTime)
            }
        }
    }
    console.log(type, query)
    return (
        <LocalizationProvider
            adapterLocale={enLocale}
            dateAdapter={AdapterDateFns}
        >
            <DatePicker
                autoOk
                emptyLabel
                disableToolbar
                variant="inline"
                id="date-picker-inline"
                inputFormat="dd/MM/yyyy"
                mask="__/__/____"
                value={query[value]}
                disableFuture={type === "end"}
                onChange={handleDateChange}
                renderInput={(props) => renderInput(props)}
            />
        </LocalizationProvider>
    )
}
