import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { users } from "../constants/dbPaths"
import db from "../rootWrapper/firebase"

export default function SigninStatus() {
    const initCourse = useSelector(
        (state) => state.firestore.data?.field?.courseDocId || false
    )
    const [courseDocId, setCourseDocId] = useState(initCourse)

    const authUid = useSelector((state) => state.firebase.auth.uid)
    const uid = getAuth()?.currentUser?.uid || authUid

    useEffect(() => {
        async function setCourse() {
            console.log("Uid", uid)
            if (!courseDocId && uid) {
                const userRef = await db.collection(users).doc(uid).get()
                const cId = userRef.data().courseDocId
                console.log("CourseDocId", cId)
                setCourseDocId(cId || "")
            }
        }
        setCourse()
    }, [uid])

    const authIsLoaded = useSelector((state) => state.firebase.auth.isLoaded)
    const authIsEmpty = useSelector((state) => state.firebase.auth.isEmpty)
    const uidExists = useSelector((state) => "uid" in state.firebase.auth)
    const authError = useSelector((state) => state.firebase.authError)
    const userIsLoaded = useSelector((state) => "user" in state.firestore.data)
    const permissionsOfUser = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            state.firestore.data.user &&
            Object.keys(state.firestore.data.user).indexOf(
                "accessPermissions"
            ) > -1
        ) {
            return state.firestore.data.user.accessPermissions
        }
        return false
    })
    const isAdmin = useSelector((state) => {
        if (
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("user") > -1 &&
            state.firestore.data.user
        ) {
            return state.firestore.data.user.admin
        }
        return false
    })
    const onboardingComplete = useSelector((state) => {
        if (
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("user") > -1 &&
            state.firestore.data.user
        ) {
            return (
                state.firestore.data.user.onboardingComplete ||
                state.firestore.data.user.academyComplete ||
                state.academyflow.academyComplete
            )
        }
        return true
    })

    const courseLoaded = useSelector(
        (state) =>
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("field") > -1 &&
            Object.keys(state.firestore.data).indexOf("carts") > -1 &&
            state.firestore.data.field &&
            Object.keys(state.firestore.data.field).length
    )
    const courseAddedCarts = useSelector((state) => {
        return state.firestore.data?.field?.cartsAdded
    })
    const courseData = useSelector((state) => {
        return state.firestore.data?.field
    })
    const courseIsConfigured = useSelector((state) => {
        if (
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("field") > -1
        ) {
            return (
                state.firestore.data?.field?.courseComplete ||
                state.setupflow.courseComplete
            )
        }
        return true
    })
    const userHasAnyViewPermissions = () =>
        Object.keys(permissionsOfUser)
            .map((key) => {
                if (key.startsWith("view")) {
                    return +permissionsOfUser[key].access
                }
                return 0
            })
            .reduce((x, y) => x + y, 0)
    const userHasPermission = (page) => {
        return (
            (Object.keys(permissionsOfUser).indexOf(page) > -1 &&
                permissionsOfUser[page].access) ||
            isAdmin
        )
    }
    return {
        courseDocId,
        authIsLoaded,
        authIsEmpty,
        authError,
        userHasAnyViewPermissions,
        userHasPermission,
        permissionsOfUser,
        onboardingComplete,
        courseIsConfigured,
        isAdmin,
        uidExists,
        authUid: uid,
        userIsLoaded,
        courseAddedCarts,
        courseLoaded,
        courseData,
    }
}
