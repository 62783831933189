import TableRow from "@mui/material/TableRow"
import { TableCell } from "@mui/material"
import Button from "@mui/material/Button"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import ConfirmDialog from "../Dialog"
import { functions } from "../../rootWrapper/firebase"
import store from "../../store"
import {
    CLEARUSERPERMISSIONEDIT,
    PERMISSIONSSHOULDUPDATESTATE,
    UNSETUSERSWPERMISSION,
} from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"

export const UserRows = (prop) => {
    const { users, handleUserClick } = prop
    const [open, setOpen] = useState(0)

    const userAdmin = useSelector((state) => state.firestore.data.user)

    const deleteUser = httpsCallable(functions, "deleteUserToPermissions")

    const handleClose = (success, uid) => {
        setOpen(false)
        if (success) {
            deleteUser({
                courseDocId: userAdmin.courseDocId,
                userDocId: uid,
            })
                .then(() => {
                    store.dispatch({ type: CLEARUSERPERMISSIONEDIT })
                    store.dispatch({ type: PERMISSIONSSHOULDUPDATESTATE })
                    store.dispatch({
                        type: UNSETUSERSWPERMISSION,
                        payload: { id: uid },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }

    return Object.values(users)
        .filter((user) => user)
        .filter((user) => user.id !== userAdmin.id)
        .sort((x, y) => y.tsCreated - x.tsCreated)
        .map((user, index) => (
            <TableRow hover role="checkbox" key={index}>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align="right">
                    <Button
                        style={{ marginRight: `${6}px` }}
                        variant="outlined"
                        color="secondary"
                        onClick={() => setOpen(index + 1)}
                    >
                        Delete
                    </Button>
                    <ConfirmDialog
                        style={{ minHeight: null }}
                        open={open === index + 1}
                        handleClose={handleClose}
                        val={user.id}
                        helperText={`Are you sure you want to delete user ${user.email}`}
                    />
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => handleUserClick(e, user.id)}
                    >
                        EDIT
                    </Button>
                </TableCell>
            </TableRow>
        ))
}
