import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import AppBar from "@mui/material/AppBar"
import styled from "@emotion/styled"

const PrimaryAppbar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none",
}))

export default function CreateHeader() {
    return (
        <PrimaryAppbar position="static">
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={0}
            >
                <Tab disabled key={0} label={"Create New Coupon"} />
            </Tabs>
        </PrimaryAppbar>
    )
}
