import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { TableCell } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import RebookableRow from "./rebookableRowCheck"
import CircleLoader from "../loader"
import { dateToHourMinuteSeconds, timeDate } from "../dateTools"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

export const bookingInProgress = (status) => status === "started"

export default function BookingAvList(props) {
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })
    const cartDocId = useSelector((state) => {
        if (state.avCartSelector.cartDocId) {
            return state.avCartSelector.cartDocId
        }
        return 0
    })
    const [bookings, setBookings] = useState([])
    const [loading, setLoading] = useState(true)

    const getBookings = httpsCallable(functions, "getBookingsAvailability")
    const nowTime = Date.now()
    const dayEndTime = nowTime + 7 * 24 * 60 * 60 * 1000
    const [rebookedBookings, setRebookedBookings] = useState([])
    const [deletedBookings, setDeletedBookings] = useState([])

    useEffect(() => {
        setLoading(true)
        getBookings({
            startTime: nowTime,
            endTime: dayEndTime,
            courseDocId,
            cartDocId,
        })
            .then((res) => {
                setBookings(res.data)
                setLoading(false)
            })
            .catch((e) => {
                console.error(e.message)
            })
    }, [courseDocId])

    useEffect(() => {
        if (!loading) {
            const bookingRefs = Object.values(bookings).map((x) => x.docRef)
            const filteredArgs = bookingRefs.filter(
                (x) =>
                    !Object.values(rebookedBookings).includes(x) &&
                    !Object.values(deletedBookings).includes(x)
            )
            if (filteredArgs.length === 0) {
                props.setRebookable(true)
            } else {
                props.setRebookable(false)
            }
        }
    }, [loading, cartDocId, bookings, rebookedBookings, deletedBookings])

    const Rows = (bookings, rebookedBookings, deletedBookings) =>
        bookings.map((book, i) => {
            if (Object.values(deletedBookings).indexOf(book.docRef) >= 0) {
                return (
                    <TableRow key={i}>
                        <TableCell>
                            {dateToHourMinuteSeconds(new Date(book.startTime))}
                        </TableCell>
                        <TableCell>Cancelled</TableCell>
                    </TableRow>
                )
            }
            return (
                <TableRow key={i}>
                    <TableCell>{timeDate(new Date(book.startTime))}</TableCell>
                    <TableCell>
                        <RebookableRow
                            key={i}
                            {...book}
                            timeString={dateToHourMinuteSeconds(
                                new Date(book.startTime)
                            )}
                            rebookedBookings={rebookedBookings}
                            setRebookedBookings={setRebookedBookings}
                            deletedBookings={deletedBookings}
                            setDeletedBookings={setDeletedBookings}
                        />
                    </TableCell>
                </TableRow>
            )
        })

    if (loading) {
        return <CircleLoader />
    }
    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell align="right">Action</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Rows(bookings, rebookedBookings, deletedBookings)}
            </TableBody>
        </Table>
    )
}
