import React, { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import makeStyles from "@mui/styles/makeStyles"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import validateEmail from "../Components/firebaseUtils/ValidateEmail"
import { formStyle } from "../assets/styles/views/formStyle"
import Copyright from "../Components/Copyright/copyright"
import { getAuth, sendPasswordResetEmail } from "firebase/auth"

const useStyles = makeStyles(formStyle)

export default function ForgotPass(props) {
    const [email, setEmail] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [invalidEmail, setinvalidEmail] = useState(false)
    const navigate = useNavigate()
    const styles = useStyles()

    const handleInputChange = (e) => {
        e.preventDefault()
        const { id } = e.target
        const { value } = e.target
        if (id === "email") {
            setEmail(value)
        }
    }
    const resetPass = (e) => {
        e.preventDefault()
        if (email && validateEmail(email)) {
            setinvalidEmail(false)
            const auth = getAuth()
            sendPasswordResetEmail(auth, email)
                .then((r) => {
                    setEmail("")
                    navigate("/login")
                })
                .catch((e) => {
                    console.log("Send email failed")
                    console.log(e.message)
                    setinvalidEmail(true)
                })
        } else {
            setinvalidEmail(true)
        }
    }
    const userIsLoaded = useSelector((state) => "uid" in state.firebase.auth)

    useEffect(() => {
        if (userIsLoaded) {
            navigate("/")
        }
    }, [userIsLoaded])

    const invalidEmailError = (
        <h3 className={styles.error}> Please enter a valid email</h3>
    )
    return (
        <div className={styles.root}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={styles.body}>
                    <Avatar className={styles.avatar}>
                        <AccountCircleIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                    </Typography>
                    <form className={styles.form} noValidate>
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleInputChange}
                        />

                        <Grid className={styles.errorGrid}>
                            {invalidEmail && !submit && invalidEmailError}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={styles.submit}
                            onClick={resetPass}
                        >
                            Reset Password
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link
                                    to="/login"
                                    style={{ color: "#c3c3c3" }}
                                    variant="body2"
                                >
                                    Login
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    to="/register"
                                    style={{ color: "#c3c3c3" }}
                                    variant="body2"
                                >
                                    Don&apos;t have an account? Sign Up
                                </Link>
                            </Grid>
                        </Grid>
                    </form>

                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </div>
            </Container>
        </div>
    )
}
