import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { prettyColorScale } from "../../assets/styles/muiTheme"
import { intToString } from "../stringUtils/numberFormater"
import ProgressBar from "./progressiveBar"

const getMatchingSegmentKey = (segments, name) => {
    const matchingSegments = segments.filter((x) => x.name === name)
    if (matchingSegments.length) {
        return matchingSegments[0].key
    }
    return 1000
}

const sortKeys = (segments) => (a, b) => {
    const aKey = getMatchingSegmentKey(segments, a)
    const bKey = getMatchingSegmentKey(segments, b)
    if (aKey !== bKey) {
        return aKey - bKey
    }
    return String(a).localeCompare(b)
}

export function CategoryUsage(props) {
    const { segmentSum } = props
    const maxCount = Object.values(segmentSum)
        .map((x) => x.count)
        .reduce((max, current) => Math.max(max, current), 0)
    const currency = useSelector(
        (state) => state.firestore.data?.field?.currency || ""
    )
    const segments = useSelector(
        (state) => state.firestore.data?.field?.segments || []
    )
    const sortSegments = sortKeys(segments)

    return (
        <>
            {Object.keys(segmentSum)
                .sort(sortSegments)
                .map((segment, index) => {
                    const { value, count } = segmentSum[segment]
                    const percentage = (count / maxCount) * 100
                    return (
                        <Box key={index} sx={{ width: "100%", height: "70px" }}>
                            <Grid
                                container
                                alignItems={"center"}
                                direction="row"
                            >
                                <Grid item xs={2} style={{ minWidth: "80px" }}>
                                    <Typography
                                        fontWeight={"Bold"}
                                        display={"inline-block"}
                                    >
                                        {segment}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} style={{ minWidth: "80px" }}>
                                    <Typography display={"inline-block"}>
                                        {intToString(value)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <ProgressBar
                                        lgcolor={
                                            prettyColorScale[
                                                index % prettyColorScale.length
                                            ]
                                        }
                                        bgcolor={
                                            prettyColorScale[
                                                index % prettyColorScale.length
                                            ]
                                        }
                                        completed={percentage}
                                        label={count}
                                        {...props}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                })}
        </>
    )
}
