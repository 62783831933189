import { Autocomplete, Box, Icon, IconButton, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import cartTypeToName from "../../assets/cartTypeToName"
import { SET_SELECTED_VEHICLE } from "../../constants/actionTypes"
import store from "../../store"
import theme from "../../assets/styles/muiTheme"
import CircleLoader from "../loader"
import { sortCarts } from "../timeline"

export default function VehicleSelector() {
    const Carts = useSelector((state) => {
        const cart = state.firestore.data.carts
        if (cart && Object.keys(cart).length) {
            return cart
        }
        return {}
    })

    const firstCart =
        Carts &&
        Object.keys(Carts).reduce((cart, currentCartKey) => {
            const currentCart = Carts[currentCartKey]
            if (Object.keys(cart).length === 0) {
                return currentCart
            }
            if (currentCart.id < cart.id) {
                return currentCart
            }
            return cart
        }, {})

    useEffect(() => {
        if (!selectedVehicle && firstCart) {
            store.dispatch({
                type: SET_SELECTED_VEHICLE,
                payload: {
                    selectedVehicle: firstCart,
                },
            })
        }
    }, [])

    const { selectedVehicle } = useSelector((state) => {
        return state.vehicleSelector
    })

    if (!firstCart || !selectedVehicle) {
        return <CircleLoader />
    }

    const alternatives = Object.values(Carts)
        .map((cart) => {
            return {
                ...cart,
                cartId: cart.id,
                type: cartTypeToName(cart.type, cart),
                label: cartTypeToName(cart.type, cart) + " " + cart.id,
                id: cart.cartDocId,
                sortKey: cart.sortKey === undefined ? cart.id : cart.sortKey,
            }
        })
        .sort(sortCarts)

    const handleChange = (event, values) => {
        if (values && values.id) {
            const cart = Carts[values.id]
            store.dispatch({
                type: SET_SELECTED_VEHICLE,
                payload: {
                    selectedVehicle: cart,
                },
            })
        }
    }

    const CartNumberIcon = ({ title }) => (
        <div
            style={{
                backgroundColor: theme.palette.secondary.main,
                width: "20px",
                color: theme.palette.primary.main,
                fontSize: "12px",
                fontWeight: "bold",
                padding: "2px",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
            }}
        >
            {title}
        </div>
    )
    const filteredAlternatives = alternatives.filter(
        (x) => x.id === selectedVehicle.cartDocId
    )
    const defaultValue =
        filteredAlternatives.length > 0 ? filteredAlternatives[0] : ""

    if (!defaultValue) {
        return <CircleLoader />
    }

    return (
        <Box
            sx={{
                width: "320px",
            }}
        >
            <Autocomplete
                options={alternatives}
                sx={{
                    width: 300,
                }}
                autoHighlight
                value={defaultValue || ""}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <div key={option.id}>
                        <Box
                            component="li"
                            sx={{ "& > div": { mr: 2, flexShrink: 0 } }}
                            {...props}
                        >
                            <CartNumberIcon title={option.cartId} />
                            {option.type}
                        </Box>
                    </div>
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                renderInput={(params) => (
                    <div>
                        <TextField
                            variant="filled"
                            {...params}
                            label="Vehicle"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                        />
                    </div>
                )}
            />
        </Box>
    )
}
