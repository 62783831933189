import {
    Grid,
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    Button,
    Icon,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import React, { useEffect } from "react"
import BookingSegment from "../bookingSegment"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import BookingDateInput from "../Booking/BookingDateInput"
import BookingTimeInput from "../Booking/BookingTimeInput"
import VehicleGroupSelector from "../Booking/vehicleTypeSelector/index.js"
import AdvancedOptions from "./advancedBooking.js"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_BOOKING_FIELDS } from "../../constants/actionTypes"

export default function BookingOptionsBase(props) {
    const {
        setCurrentProgress,
        userSegment,
        setUserSegment,
        setPrice,
        tlf,
        name,
        couponCodes,
        couponChange,
        coupons,
        amountOfCarts,
        isNineHoles,
        nineHolesEnabled,
        handleValueChange,
        note,
        percentageVat,
        taxAdd,
        price,
        tax,
        currency,
        payed,
        changePayed,
        email,
    } = props

    const advancedOptions = useSelector(
        (state) => state.createBooking?.advancedOptions ?? false
    )
    const handleAdvanced = () => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                advancedOptions: !advancedOptions,
            },
        })
    }
    const handleNineHoles = () => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                isNineHoles: !isNineHoles,
            },
        })
    }

    const style = useDialogStyles()

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <TextField
                            id="customer-name"
                            label="Name of customer (required)"
                            name="name"
                            inputRef={name}
                            fullWidth
                            inputProps={{ className: style.input }}
                            InputLabelProps={{
                                shrink: true,
                                className: style.input,
                            }}
                            style={{
                                color: "secondary",
                                backgroundColor: "primary",
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            id="customer-tlf"
                            label="Customer telephone number (required)"
                            name="tlf"
                            inputRef={tlf}
                            fullWidth
                            inputProps={{ className: style.input }}
                            InputLabelProps={{
                                shrink: true,
                                className: style.input,
                            }}
                            style={{
                                color: "secondary",
                                backgroundColor: "primary",
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <BookingSegment
                            userSegment={userSegment}
                            setUserSegment={setUserSegment}
                            setPrice={setPrice}
                        />
                    </Grid>
                    <Grid item xs>
                        <VehicleGroupSelector />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <BookingDateInput
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                    <Grid item xs>
                        <BookingTimeInput
                            isStartTime={true}
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <TextField
                            id="customer-email"
                            label="Customer email (optional)"
                            name="email"
                            inputRef={email}
                            fullWidth
                            inputProps={{ className: style.input }}
                            InputLabelProps={{
                                shrink: true,
                                className: style.input,
                            }}
                            style={{
                                color: "secondary",
                                backgroundColor: "primary",
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Box className={style.optionsBox}>
                    <Grid item xs>
                        <Box
                            className={style.expandHeaderBox}
                            style={
                                advancedOptions ? { paddingBottom: "5px" } : {}
                            }
                            onClick={() => handleAdvanced()}
                        >
                            <Typography
                                className={style.expandTypography}
                                display={"inline"}
                            >
                                Advanced
                            </Typography>
                            <Icon
                                className={[
                                    style.dropdown,
                                    style.marginLeft,
                                    advancedOptions
                                        ? style.dropdownClosed
                                        : style.dropdownOpen,
                                ]}
                            >
                                <ExpandMoreIcon />
                            </Icon>
                        </Box>
                    </Grid>
                    <Grid
                        container
                        padding={"8px"}
                        direction="column"
                        spacing={2}
                    >
                        {advancedOptions ? (
                            <AdvancedOptions
                                {...{
                                    setCurrentProgress,
                                    userSegment,
                                    setUserSegment,
                                    setPrice,
                                    tlf,
                                    name,
                                    couponCodes,
                                    couponChange,
                                    coupons,
                                    amountOfCarts,
                                    handleValueChange,
                                    note,
                                    percentageVat,
                                    taxAdd,
                                    price,
                                    tax,
                                    currency,
                                    payed,
                                    changePayed,
                                }}
                            />
                        ) : null}
                    </Grid>
                </Box>
            </Grid>

            <Grid item xs>
                <Grid
                    sx={{ height: "100%" }}
                    container
                    direction="row"
                    alignItems={"center"}
                    justifyContent="center"
                >
                    <Grid item xs>
                        <Typography>
                            Price {amountOfCarts > 1 ? "per cart" : ""}
                        </Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography color="secondary">
                            {taxAdd
                                ? Number(price - tax).toFixed(2)
                                : price.toFixed(2)}{" "}
                            {currency}*
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {taxAdd ? (
                <Grid container padding={"0"} item xs direction="row">
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignItems={"center"}
                            justifyContent="center"
                        >
                            <Grid item xs>
                                <Typography>
                                    Tax {Number(percentageVat * 100).toFixed(2)}{" "}
                                    % {amountOfCarts > 1 ? "per cart" : ""}
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography color="secondary">
                                    {Number(tax).toFixed(2)} {currency}*
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            {amountOfCarts > 1 || taxAdd ? (
                <Grid container padding={"0"} item xs direction="row">
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignItems={"center"}
                            padding={"0"}
                            justifyContent="center"
                        >
                            <Grid item xs>
                                <Typography> Total</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography color="secondary">
                                    {Number(price * amountOfCarts).toFixed(2)}{" "}
                                    {currency}*
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}

            <Grid item container direction={"row"} xs>
                <Grid item xs>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={payed}
                                onChange={changePayed}
                                name="paid"
                            />
                        }
                        label="Payment Received"
                    />
                </Grid>
                {nineHolesEnabled ? (
                    <Grid item xs>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isNineHoles}
                                    onChange={handleNineHoles}
                                    name="nineholes"
                                />
                            }
                            label="9 Holes"
                        />
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    )
}
