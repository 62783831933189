import React from "react"
import { useSelector } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

export const useStylesSegment = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
        width: `${100}%`,
    },
}))

export default function BookingSegment(props) {
    const classes = useStylesSegment()

    const segments = useSelector((state) => state.firestore.data.field.classes)
    const taxAddEnabled = useSelector(
        (state) =>
            state.firestore.data.field?.costStructure?.taxAddEnabled ?? false
    )

    const vat = useSelector(
        (state) =>
            state.firestore.data.field?.costStructure?.percentageVat ?? false
    )

    const handleChange = (event) => {
        props.setUserSegment(event.target.value)
        const price = segments[event.target.value][0]
        if (taxAddEnabled) {
            const tax = price * vat
            props.setPrice(price + tax, tax)
        } else {
            props.setPrice(price, 0)
        }
    }

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-label">User Category</InputLabel>
                <Select
                    fullWidth
                    labelId="select-label"
                    id="simple-select"
                    value={props.userSegment}
                    onChange={handleChange}
                >
                    {Object.keys(segments).length
                        ? Object.keys(segments).map((key, index) => (
                              <MenuItem value={key} key={index}>
                                  {key}
                              </MenuItem>
                          ))
                        : null}
                </Select>
            </FormControl>
        </div>
    )
}
