import React from "react"
import { Grid } from "@mui/material"
import { useSelector } from "react-redux"
import CartDialog from "../Carts/cartPopupComponents"

export function groupRenderer({ group, isRightSidebar }) {
    const cartTrips = useSelector((state) => state.bookingView.cartTrips)
    const tripsOfCart =
        Object.keys(cartTrips).indexOf(group.id) > -1 ? cartTrips[group.id] : 0
    if (isRightSidebar) {
        return (
            <div style={{ borderLeft: "1px solid rgba(81, 81, 81, 1)" }}>
                <Grid
                    container
                    justifyContent="center"
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                >
                    <Grid item xs>
                        <div
                            style={{
                                width: `${100}%`,
                            }}
                        >
                            <b>
                                {`${tripsOfCart}/${
                                    group.av ? group.bookingDailyLimit : 0
                                }`}
                            </b>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
    return (
        <Grid
            container
            justifyContent="center"
            direction="row"
            alignContent="center"
            alignItems="center"
            height={"inherit"}
        >
            <Grid item xs height="inherit">
                <CartDialog
                    style={{
                        position: null,
                        top: null,
                        left: null,
                        cursor: "auto",
                        width: `${100}%`,
                        color: "white",
                    }}
                    {...{
                        id: group.visualId,
                        av: group.av,
                        engine: group.engine,
                        keyless: group.keyless,
                        connected: group.connected,
                        groupIcon: group.groupIcon,
                    }}
                />
            </Grid>
        </Grid>
    )
}
