import makeStyles from "@mui/styles/makeStyles"

const useLockTableStyles = makeStyles((theme) => ({
    table: {
        backgroundColor: theme.palette.primary.main,
        outline: "none",
        margin: 0,
        padding: 0,
    },
    textStyle: {
        fontSize: 16,
    },
    bottomBar: {
        backgroundColor: theme.palette.primary.main,
    },
    rootCell: {
        width: `${100}%`,
        paddingBottom: `${0}%`,
        paddingTop: `${0}%`,
    },
    tableBody: {},
    tableRow: {
        margin: 0,
        padding: 0,
    },
    tableCell: {
        padding: 0,
        margin: 0,
    },
    rightCell: {
        textAlign: "right",
    },
}))

export { useLockTableStyles }
