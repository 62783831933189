import React, { useEffect, useRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import { TextField, Tooltip } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useSelector } from "react-redux"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { StatusBooker } from "./statusBooker"
import { DialogTitle } from "../DialogTitle"
import { DialogContent } from "../DialogTitle/dialogContent"
import { DialogActions } from "../DialogTitle/dialogAction"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import { dateToHourMinute } from "../dateTools"
import {
    CHANGERAINTIME,
    COLLECTBOOKINGDONE,
    COLLECTBOOKINGLOADING,
    UPDATE_BOOKING_VIEW,
} from "../../constants/actionTypes"
import store from "../../store"
import enLocale from "date-fns/locale/en-GB"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

export default function RainPopup(props) {
    const style = useDialogStyles()

    const initProgress = {
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    }
    const [currentProgress, setCurrentProgress] = useState(initProgress)
    const message = useRef("")

    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })
    const rainDayState = useSelector((state) => state.rainDay)
    const { startTime, endTime } = rainDayState

    const getAllBookings = httpsCallable(functions, "getAllBookingsToCancel")
    useEffect(() => {
        if (rainDayState.selected) {
            store.dispatch({ type: COLLECTBOOKINGLOADING })
            getAllBookings({
                startTime,
                endTime,
                courseDocId,
            })
                .then((r) => {
                    store.dispatch({
                        type: COLLECTBOOKINGDONE,
                        payload: {
                            bookingIds: Object.values(r.data.bookings).map(
                                (booking) => booking.bookingDocId
                            ),
                        },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                    setCurrentProgress({
                        ...currentProgress,
                        error: true,
                        errorMessage: e.message,
                    })
                })
        }
    }, [startTime, endTime])

    const handleClose = (e) => {
        props.handleClose()
        setCurrentProgress(initProgress)
    }

    const handleTimeChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        const values = value.split(":")
        const hours = parseInt(values[0])
        const minutes = parseInt(values[1])
        if (
            hours !== parseInt(hours, 10) ||
            minutes !== parseInt(minutes, 10)
        ) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid Time",
            })
            return
        }
        let toUpdate = {
            startTime,
            endTime,
        }
        if (name === "rain-start") {
            const newTime = new Date(startTime).setHours(hours, minutes, 0, 0)
            toUpdate.startTime = newTime
        } else if (name === "rain-end") {
            let newTime = new Date(endTime).setHours(hours, minutes, 0, 0)
            if (newTime - 24 * 60 * 60 * 1000 > startTime) {
                newTime -= 24 * 60 * 60 * 1000
            }
            toUpdate.endTime = newTime
        }
        if (toUpdate.startTime == toUpdate.endTime) {
            toUpdate.endTime += 24 * 60 * 60 * 1000
        }
        store.dispatch({
            type: CHANGERAINTIME,
            payload: toUpdate,
        })
        setCurrentProgress({
            ...currentProgress,
            error: false,
            errorMessage: "",
        })
    }

    const handleDateChange = (date) => {
        console.log(date)
        if (date instanceof Date && !isNaN(date)) {
            const dateOfMonth = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()
            const newStart = new Date(startTime).setFullYear(
                year,
                month,
                dateOfMonth
            ) // setFullYear returns ms since 1970
            let newEnd = new Date(endTime).setFullYear(year, month, dateOfMonth)
            if (newEnd == newStart) {
                newEnd += 24 * 60 * 60 * 1000
            }
            store.dispatch({
                type: CHANGERAINTIME,
                payload: {
                    startTime: newStart,
                    endTime: newEnd,
                },
            })
            setCurrentProgress({
                ...currentProgress,
                error: false,
                errorMessage: "",
            })
            return
        }
        setCurrentProgress({
            ...currentProgress,
            error: true,
            errorMessage: "Invalid Date format",
        })
    }
    const deleteMultipleBookings = httpsCallable(
        functions,
        "deleteMultipleBookings"
    )

    const handleSubmit = (e) => {
        e.preventDefault()
        const messageValue = message.current.value
        if (messageValue.length < 10) {
            setCurrentProgress((state) => ({
                ...state,
                error: true,
                errorMessage:
                    "Please input a descriptive message to the customers.",
            }))
            new Promise((r) => setTimeout(r, 3000)).then(() => {
                setCurrentProgress(initProgress)
            })
            return
        }
        setCurrentProgress((state) => ({ ...state, inProgress: true }))
        deleteMultipleBookings({
            courseDocId,
            startTime,
            endTime,
            message: messageValue,
        })
            .then((r) => {
                setCurrentProgress((state) => ({
                    ...state,
                    inProgress: false,
                    success: true,
                    error: false,
                    errorMessage: "",
                }))
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
                new Promise((r) => setTimeout(r, 3000)).then(() => {
                    setCurrentProgress(initProgress)
                    handleClose()
                })
            })
            .catch((e) => {
                setCurrentProgress((state) => ({
                    ...state,
                    inProgress: false,
                    success: false,
                    error: true,
                    errorMessage: e.message,
                }))
                new Promise((r) => setTimeout(r, 3000)).then(() => {
                    setCurrentProgress(initProgress)
                })
            })
    }
    return (
        <div>
            <Dialog
                classes={{ paper: style.rainOverlay }}
                className={style.rainOverlay}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Fleet Override
                </DialogTitle>
                <DialogContent component="div">
                    <Grid container direction="column">
                        <Grid item xs>
                            <LocalizationProvider
                                adapterLocale={enLocale}
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    disableToolbar
                                    variant="inline"
                                    color="secondary"
                                    inputFormat="dd/MM/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Choose date"
                                    value={rainDayState.startTime}
                                    onChange={handleDateChange}
                                    inputProps={{ className: style.input }}
                                    InputLabelProps={{ className: style.input }}
                                    renderInput={(props) => (
                                        <TextField {...props} />
                                    )}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs>
                                    <TextField
                                        id="start-time"
                                        label="Rain start time"
                                        type="time"
                                        color="secondary"
                                        value={dateToHourMinute(
                                            new Date(rainDayState.startTime)
                                        )}
                                        onChange={handleTimeChange}
                                        name="rain-start"
                                        fullWidth
                                        inputProps={{ className: style.input }}
                                        InputLabelProps={{
                                            shrink: true,
                                            className: style.input,
                                        }}
                                        style={{
                                            color: "secondary",
                                            backgroundColor: "primary",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        id="end-time"
                                        label="Rain end time"
                                        type="time"
                                        color="secondary"
                                        value={dateToHourMinute(
                                            new Date(rainDayState.endTime)
                                        )}
                                        onChange={handleTimeChange}
                                        name="rain-end"
                                        fullWidth
                                        inputProps={{ className: style.input }}
                                        InputLabelProps={{
                                            shrink: true,
                                            className: style.input,
                                        }}
                                        style={{
                                            color: "secondary",
                                            backgroundColor: "primary",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                style={{ marginTop: "20px" }}
                                required
                                multiline
                                id="filled-required"
                                label="Cancellation message"
                                placeholder="Dear customer, there is going to be too much rain today. Because of that, we needed to cancel your booking for a golf cart. Sorry for the inconvenience."
                                rows={4}
                                inputRef={message}
                                fullWidth
                                name="message"
                                color="secondary"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <Tooltip
                                            title="The app bookings users will be informed by email if they have entered a valid email address in the app. The admin booking users will not be informed about the cancellation (from us) as the booking was created on the web dashboard."
                                            placement="right"
                                        >
                                            <IconButton size="small">
                                                <HelpOutlineIcon />
                                            </IconButton>
                                        </Tooltip>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            {!rainDayState.bookingCollectLoading ? (
                                <div style={{ marginTop: `${10}px` }}>
                                    <Typography color="error" variant="h6">
                                        {" "}
                                        This will cancel{" "}
                                        {rainDayState.bookingIds.length} rides.
                                    </Typography>
                                </div>
                            ) : null}
                        </Grid>
                    </Grid>

                    <DialogActions
                        style={{ justifyContent: "center", display: "flex" }}
                    >
                        <StatusBooker
                            currentProgress={currentProgress}
                            disabled={rainDayState.bookingCollectLoading}
                            defaultText="Cancel all Bookings & make the carts reserved"
                            doBooking={handleSubmit}
                        />
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
