import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import "./style.css"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import path from "path"
import { SuccessLoader } from "../loader/successLoader"
import db from "../../../rootWrapper/firebase"
import { golfCourses, storageImagesBasePath } from "../../../constants/dbPaths"
import { getStorage, ref, uploadBytes } from "firebase/storage"

const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
}

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
}

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
}

const img = {
    display: "block",
    width: "auto",
    height: "100%",
}

const useStyles = makeStyles((theme) => ({
    secondaryColor: {
        color: theme.palette.secondary.light,
    },
}))

export default function DropBox(props) {
    const storage = getStorage()
    const classes = useStyles()
    const [files, setFiles] = useState([])
    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }

    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)

    async function sha256(message) {
        const msgBuffer = new TextEncoder().encode(message)
        const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer)
        const hashArray = Array.from(new Uint8Array(hashBuffer))
        return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
    }

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!settingsAccess) {
            setStatus({ ...status, error: true })
            return
        }
        setStatus({ ...status, loading: true })
        if (files.length !== 0) {
            await Promise.all(
                files.map(async (file) => {
                    const metadata = {
                        cacheControl: "public,max-age=31536000",
                    }
                    const fileNameList = file.name.split(".")
                    const extension = fileNameList[fileNameList.length - 1]
                    const newFileName = `${await sha256(
                        file.name
                    )}.${extension}`

                    const storageRef = ref(
                        storage,
                        `${storageImagesBasePath}/${golfCourses}/assets/${courseDocId}/${newFileName}`
                    )
                    const uploadTask = uploadBytes(storageRef, file, metadata)
                    return uploadTask
                })
            )
                .then((r) => {
                    const base = `https://storage.googleapis.com/${process.env.REACT_APP_PROJECT_ID}.appspot.com/`
                    console.log(r)
                    r.map((d) => {
                        const pathName = d.metadata.fullPath
                        const uploadPath =
                            base +
                            pathName.replace(
                                path.extname(pathName),
                                "_700x400.jpeg"
                            )
                        const courseRef = db
                            .collection(golfCourses)
                            .doc(courseDocId)
                        return db.runTransaction((transaction) =>
                            transaction.get(courseRef).then((courseDoc) => {
                                let { images } = courseDoc.data()
                                if (!images) {
                                    images = []
                                }
                                images = [...images, uploadPath]
                                transaction.update(courseRef, {
                                    images,
                                })
                            })
                        )
                    })
                })
                .then(() => {
                    setStatus({ ...status, success: true, loading: false })
                    setError("")
                    new Promise((r) => setTimeout(r, 5000)).then(() => {
                        setStatus(initStatus)
                        setError("")
                    })
                })
                .catch((e) => {
                    setStatus({ ...status, error: true })
                    setError(e.message)
                    new Promise((r) => setTimeout(r, 5000)).then(() => {
                        setStatus(initStatus)
                        setError("")
                    })
                })
        } else {
            setError("You must upload a file")
            setStatus({ ...status, error: true, loading: false })
            new Promise((r) => setTimeout(r, 2000)).then(() => {
                setStatus(initStatus)
                setError("")
            })
        }
    }

    const thumbs = files.map((file) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.preview}
                    style={img}
                    alt="image upload thumbnail"
                />
            </div>
        </div>
    ))
    const dropboxText =
        "Drag 'n' drop some files here, or click to select files"

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview))
        },
        [files]
    )

    return (
        <section className="container">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p className={classes.secondaryColor}>{dropboxText}</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
            <SuccessLoader
                disabled={!settingsAccess}
                status={status}
                error={error}
                noAccess={!settingsAccess}
                handleSubmit={handleSubmit}
            />
        </section>
    )
}
