import React, { useEffect } from "react"
import { Autocomplete, TextField, InputAdornment, Box } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { NotInstalledIcon, InstalledIcon } from "../installationMain"
import { sortCarts } from "../../timeline"

const GetInstalledIcon = (props) => {
    if (props) {
        if (props.installed) {
            return InstalledIcon
        }
        return NotInstalledIcon
    }
}

export default function CartSelectorInstallerHeader(props) {
    const params = useParams()
    const navigate = useNavigate()
    const { cartId } = params
    const carts = useSelector((state) => state.firestore.data.carts)
    const vehicleGroups = useSelector(
        (state) => state.firestore.data.field?.vehicleGroups
    )
    const installation = useSelector((state) => state.installation)
    const idToCartMap = Object.keys(carts).reduce((obj, key) => {
        const vehicleGroup = vehicleGroups[carts[key].vehicleGroup].name
        return {
            ...obj,
            [carts[key].boardId]: {
                ...carts[key],
                label: `${vehicleGroup} ${carts[key].id}`,
                docId: key,
                id: carts[key].id,
                boardId: carts[key].boardId,
                installed: installation.cartsComplete[key],
            },
        }
    }, {})

    const options = Object.keys(carts).length
        ? Object.keys(carts)
              .sort((x, y) => sortCarts(carts[x], carts[y]))
              .map((key) => ({
                  label: `${vehicleGroups[carts[key].vehicleGroup].name} ${
                      carts[key].id
                  }`,
                  docId: key,
                  boardId: carts[key].boardId,
                  id: carts[key].id,
                  installed: installation.cartsComplete[key],
              }))
        : []
    const setHeader = (value) => {
        if (value) {
            navigate("../cart/" + String(value.boardId))
        }
    }

    return (
        <Autocomplete
            value={idToCartMap[cartId]}
            onChange={(event, newValue) => {
                setHeader(newValue)
            }}
            options={options}
            isOptionEqualToValue={(option, value) =>
                option.boardId === value.boardId
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Cart Id"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <Box
                                    component={GetInstalledIcon(
                                        idToCartMap[cartId]
                                    )}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Box
                        component={GetInstalledIcon(option)}
                        sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
                    />
                    <Box
                        component="span"
                        sx={{
                            width: 14,
                            height: 14,
                            flexShrink: 0,
                            borderRadius: "3px",
                            mr: 1,
                            mt: "2px",
                        }}
                    />
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        {option.label}
                    </Box>
                </li>
            )}
        ></Autocomplete>
    )
}
