import {
    SET_SELECTED_VEHICLE,
    SET_VEHICLE_NOTE,
    SET_VEHICLE_NOTE_RELOAD,
    SET_VEHICLE_RATING,
    SET_VEHICLE_QUERY,
    SET_VEHICLE_BOOKINGS,
    SET_VEHICLE_STATS,
    SET_NEW_SMARTBOXID,
} from "../constants/actionTypes"

const initialQuery = {
    lastUpdated: Date.now(),
    reloadCount: 0,
    queryComplete: false,
    start: Date.now() - 30 * 24 * 60 * 60 * 1000,
    queryStart: 0,
    end: Date.now(),
    queryEnd: 0,
    limit: 10,
    queryLimit: 0,
}

const initStats = {
    totalBookings: 0,
    totalRentalTime: 0,
    incomeTotal: 0,
    starsTotal: 0,
    starBookings: 0,
}

const initialState = {
    selectedVehicle: "",
    vehicleBooking: {},
    vehicleStats: {},
    vehicleBookingQuery: {},
    vehicleNotes: {},
    vehicleRatings: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload.selectedVehicle,
            }
        case SET_NEW_SMARTBOXID:
            return {
                ...state,
                selectedVehicle: {
                    ...state.selectedVehicle,
                    boardId: action.payload.boardId,
                },
            }
        case SET_VEHICLE_NOTE:
            return {
                ...state,
                vehicleNotes: {
                    ...state.vehicleNotes,
                    [action.payload.cartDocId]: {
                        requestedAmount: action.payload.requestedAmount,
                        notes: action.payload.vehicleNotes,
                    },
                },
            }
        case SET_VEHICLE_NOTE_RELOAD:
            return {
                ...state,
                vehicleNotes: {
                    ...state.vehicleNotes,
                    [action.payload.cartDocId]: {
                        ...state.vehicleNotes[action.payload.cartDocId],
                        requestedAmount: 0,
                    },
                },
            }
        case SET_VEHICLE_RATING:
            return {
                ...state,
                vehicleRatings: {
                    ...state.vehicleRatings,
                    [action.payload.cartDocId]: {
                        requestedAmount: action.payload.requestedAmount,
                        bookingsWithRating: action.payload.bookingsWithRating,
                    },
                },
            }

        case SET_VEHICLE_QUERY:
            return {
                ...state,
                vehicleBookingQuery: {
                    ...state.vehicleBookingQuery,
                    [action.payload.cartDocId]: {
                        ...initialQuery,
                        ...state.vehicleBookingQuery[action.payload.cartDocId],
                        ...action.payload.query,
                        queryComplete: false,
                        reloadCount: state.vehicleBookingQuery[
                            action.payload.cartDocId
                        ]
                            ? state.vehicleBookingQuery[
                                  action.payload.cartDocId
                              ].reloadCount + 1
                            : 0,
                    },
                },
            }
        case SET_VEHICLE_BOOKINGS:
            return {
                ...state,
                vehicleBooking: {
                    ...state.vehicleBooking,
                    [action.payload.cartDocId]: action.payload.bookings,
                },
                vehicleBookingQuery: {
                    ...state.vehicleBookingQuery,
                    [action.payload.cartDocId]: {
                        ...initialQuery,
                        ...state.vehicleBookingQuery[action.payload.cartDocId],
                        ...action.payload.query,
                        queryComplete: true,
                    },
                },
            }
        case SET_VEHICLE_STATS:
            return {
                ...state,
                vehicleStats: {
                    ...state.vehicleStats,
                    [action.payload.cartDocId]: {
                        ...initStats,
                        ...action.payload.stats,
                    },
                },
            }
        default:
            return state
    }
}
