import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
    Box,
    Grid,
    IconButton,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material"
import StatsItem from "./statsElement"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import StarIcon from "@mui/icons-material/Star"
import CircleIcon from "@mui/icons-material/Circle"
import { ReactComponent as GolfCart } from "../../assets/img/golfcart-icon.svg"
import theme from "../../assets/styles/muiTheme"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import CircleLoader from "../loader"
import StatsCounter from "./countStats"

const CurrencyIcon = ({ currency }) => (
    <p
        style={{
            margin: 0,
            color: theme.palette.secondary.main,
            fontSize: "18px",
            fontWeight: "bold",
            display: "inline-block",
        }}
    >
        {currency}
    </p>
)

const CartIcon = () => <SvgIcon inheritViewBox component={GolfCart} />

export default function StatsRowBookings() {
    const [totalDays, setTotalDays] = useState(7)
    StatsCounter()

    const {
        totalBookings,
        totalRentalTime,
        incomeTotal,
        starsTotal,
        starBookings,
    } = useSelector((state) => {
        return state.bookingHistory.stats || {}
    })
    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })
    const { start, end } = query

    useEffect(() => {
        const diff = end - start
        const days = Math.round(diff / (24 * 60 * 60 * 1000))
        setTotalDays(days)
    }, [start, end])

    const averageRentalTime = Math.round(totalRentalTime / totalBookings)
    const hourFloat = averageRentalTime / (60 * 60 * 1000)
    const minutesFloat = (averageRentalTime % (60 * 60 * 1000)) / (60 * 1000)
    const currency = useSelector(
        (state) => state.firestore.data?.field?.currency || ""
    )
    const CurrentCurrencyIcon = () => <CurrencyIcon currency={currency} />
    const avgIncomePerDay = Number(incomeTotal / totalDays).toFixed(2)
    const avgBookingsPerDay = Number(totalBookings / totalDays).toFixed(2)
    const avgStarRating = Number(starsTotal / starBookings).toFixed(1)
    const starRating = isNaN(avgStarRating) ? "-" : avgStarRating
    const bookingsPerDay = isNaN(avgBookingsPerDay) ? "-" : avgBookingsPerDay
    const incomePerDay = isNaN(avgIncomePerDay) ? "-" : avgIncomePerDay
    const hours = isNaN(hourFloat) ? " " : hourFloat.toFixed()
    const minutes = isNaN(minutesFloat) ? " " : minutesFloat.toFixed()
    const totalBookingsToDisplay = isNaN(totalBookings) ? "-" : totalBookings

    const { queryComplete } = query
    console.log("Complete", queryComplete)

    if (!query || !queryComplete) {
        return (
            <Box height={200 + "px"}>
                <CircleLoader />
            </Box>
        )
    }

    return (
        <Box display={"block"} height="100%" position="relative">
            <Typography color="#DCDCDCC9">Key statistics fleet</Typography>
            <Box position="absolute" right={0} top={0}>
                <Tooltip
                    title={
                        <Typography fontSize="14pt">
                            Fleet statistics over {totalDays} days, showing the
                            selected time period.
                        </Typography>
                    }
                    placement="top"
                >
                    <IconButton size="small">
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Grid
                container
                alignItems={"center"}
                direction="row"
                width="100%"
                minHeight={200 + "px"}
                sx={{ height: "100%" }}
            >
                <Grid item xs>
                    <StatsItem
                        StatsIcon={CartIcon}
                        headerText={`${bookingsPerDay}`}
                        subheaderText={"Avg. bookings per day"}
                    />
                </Grid>
                <Grid item xs>
                    <StatsItem
                        StatsIcon={AccessTimeIcon}
                        headerText={`${hours}h ${minutes}m`}
                        subheaderText={"Avg. time per rental"}
                    />
                </Grid>
                <Grid item xs>
                    <StatsItem
                        StatsIcon={CircleIcon}
                        headerText={`${totalBookingsToDisplay}`}
                        subheaderText={`Total bookings`}
                    />
                </Grid>
                <Grid item xs>
                    <StatsItem
                        StatsIcon={CurrentCurrencyIcon}
                        headerText={`${incomePerDay}`}
                        subheaderText={`Avg. income per day`}
                    />
                </Grid>
                <Grid item xs>
                    <StatsItem
                        StatsIcon={StarIcon}
                        headerText={`${starRating}`}
                        subheaderText={"Avg. rating"}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
