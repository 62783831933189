import React, { useEffect, useState } from "react"
import TableContainer from "@mui/material/TableContainer"
import {
    Collapse,
    LinearProgress,
    Paper,
    Tooltip,
    Typography,
} from "@mui/material"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableCell from "@mui/material/TableCell"
import TableBody from "@mui/material/TableBody"
import TablePagination from "@mui/material/TablePagination"
import Grid from "@mui/material/Grid"
import Switch from "@mui/material/Switch"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import IconButton from "@mui/material/IconButton"
import KeyIcon from "@mui/icons-material/Key"
import { useSelector } from "react-redux"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { useLockTableStyles } from "../../assets/styles/components/lockTable"
import {
    textTableHeaderMapTable,
    textTrueSwitchMapTable,
} from "../../assets/applicationText/mapTable"
import CartDialog from "../Carts/cartPopupComponents"
import getLockAccess from "../../constants/accessPermissionsChecks"
import PowerOnSwitch from "../PowerOnSwitch"

export default function MapTable(props) {
    const { rows } = props
    const getRowsPerPage = (height) => {
        const amountOfCartsOnScreen = Math.floor(height / 45)
        const perTable = Math.floor(((amountOfCartsOnScreen - 5) * 2) / 3)
        return Math.max(perTable, 1)
    }

    const vehicleGroups = useSelector((state) => {
        const course = state.firestore.data.field
        return course.vehicleGroups
    })

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(
        getRowsPerPage(window.innerHeight)
    )
    const lockAccess = getLockAccess()
    const [open, setOpen] = useState(true)

    useEffect(() => {
        setRowsPerPage(getRowsPerPage(window.innerHeight))
    }, [window.innerHeight, window.outerHeight, rows.length])

    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading

    const avLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("avLoading") > -1 &&
        cartLoader[cartDocId].avLoading
    const textVariant = props.lockTable ? 0 : 1

    const handleChangePage = (_, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }
    const cartReading = useSelector((state) => state.cartReadings || {})

    const classes = useLockTableStyles()
    function TopRow(props) {
        if (props.lockTable) {
            return (
                <TableRow>
                    <TableCell className={classes.tableCell}>
                        <Typography
                            sx={{ width: "100%" }}
                            paddingLeft={2}
                            color={"#DCDCDCDC"}
                            fontWeight={"500"}
                        >
                            All vehicles
                        </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {props.topDisabled ? (
                            <LinearProgress color="secondary" />
                        ) : null}
                        <Grid
                            component="label"
                            container
                            justifyContent="center"
                            alignItems={"center"}
                        >
                            <Grid item>
                                <Typography
                                    sx={{ width: "100%" }}
                                    fontWeight={"300"}
                                >
                                    {textTrueSwitchMapTable(textVariant, 0)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Switch
                                    checked={props.lockAll.switchStatus}
                                    onChange={props.handleLockAll}
                                    disabled={props.topDisabled || !lockAccess}
                                    color="secondary"
                                    name="lockStatus"
                                    id="all"
                                    align="right"
                                    inputProps={{
                                        "aria-label": "primary checkbox",
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{ width: "100%" }}
                                    fontWeight={"300"}
                                >
                                    {textTrueSwitchMapTable(textVariant, 1)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
            )
        }
        return <></>
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.root}>
                <Table
                    size="small"
                    className={classes.table}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Vehicle
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? (
                                        <KeyboardArrowUpIcon />
                                    ) : (
                                        <KeyboardArrowDownIcon />
                                    )}
                                </IconButton>
                            </TableCell>
                            <TableCell align="right">
                                <Typography
                                    variant="subtitle"
                                    fontWeight={800}
                                    fontSize={16}
                                >
                                    {props.lockTable
                                        ? "Key (power)"
                                        : "Available for Booking"}
                                </Typography>
                                <Tooltip
                                    title={textTableHeaderMapTable(textVariant)}
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        {props.lockTable ? <KeyIcon /> : null}
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <Collapse in={open} unmountOnExit>
                    <Table className={classes.table} size="small" stickyHeader>
                        <TableBody className={classes.tableBody}>
                            {TopRow(props)}
                            {Object.keys(rows).length
                                ? Object.keys(rows)
                                      .slice(
                                          page * rowsPerPage,
                                          page * rowsPerPage + rowsPerPage
                                      )
                                      .map((key, i) => {
                                          const cart = props.rows[key]
                                          if (!cart) {
                                              return null
                                          }
                                          const keyless = cart.keyless
                                          const currentBooking =
                                              cartReading?.[cart.cartDocId]
                                                  ?.booking || {}

                                          const icon =
                                              vehicleGroups[cart.vehicleGroup]
                                                  .vehicleIcon
                                          const readingTs =
                                              cart?.reading?.timestampReceived
                                          const connected =
                                              readingTs &&
                                              readingTs?.toMillis() +
                                                  60 * 60 * 1000 >
                                                  Date.now()

                                          return (
                                              <TableRow
                                                  key={i}
                                                  className={classes.TableRow}
                                                  onClick={() => {
                                                      props.handleGetVehiclePosition(
                                                          cart,
                                                          cart?.LOC
                                                      )
                                                  }}
                                              >
                                                  <TableCell
                                                      scope="row"
                                                      className={
                                                          classes.tableCell
                                                      }
                                                  >
                                                      <CartDialog
                                                          {...{
                                                              ...cart,
                                                              groupIcon: icon,
                                                              av: cart.available,
                                                              setSelected: () =>
                                                                  null,
                                                              connected,
                                                          }}
                                                          style={{
                                                              position: null,
                                                              top: null,
                                                              left: null,
                                                              cursor: "auto",
                                                              width: `120px`,
                                                              color: "white",
                                                          }}
                                                      />
                                                  </TableCell>
                                                  <TableCell
                                                      className={
                                                          classes.rightCell
                                                      }
                                                  >
                                                      {keyless ? (
                                                          props.lockTable ? (
                                                              <PowerOnSwitch
                                                                  vehicle={
                                                                      props
                                                                          .rows[
                                                                          key
                                                                      ]
                                                                  }
                                                                  currentBooking={
                                                                      currentBooking
                                                                  }
                                                              />
                                                          ) : (
                                                              <Switch
                                                                  checked={
                                                                      rows[key]
                                                                          .available
                                                                  }
                                                                  onChange={
                                                                      props.handleChange
                                                                  }
                                                                  color="secondary"
                                                                  id={key}
                                                                  disabled={avLoading(
                                                                      rows[key]
                                                                          .cartDocId
                                                                  )}
                                                                  name={props.rows[
                                                                      key
                                                                  ].id.toString()}
                                                                  align="right"
                                                                  inputProps={{
                                                                      "aria-label":
                                                                          "primary checkbox",
                                                                  }}
                                                              />
                                                          )
                                                      ) : (
                                                          <Typography
                                                              textAlign={
                                                                  "center"
                                                              }
                                                          >
                                                              Not Keyless
                                                          </Typography>
                                                      )}
                                                  </TableCell>
                                              </TableRow>
                                          )
                                      })
                                : null}
                        </TableBody>
                    </Table>
                </Collapse>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={Object.keys(props.rows).length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    className={classes.bottomBar}
                />
            </TableContainer>
        </Paper>
    )
}
