import React, { useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import { Box, Divider, Icon } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from "../../AvailabilityRebooking/popupAvailability"
import { bookingInProgress } from "../../AvailabilityRebooking/bookingAvList"
import DeleteBookingButton from "../bookingDeleteButton"
import RebookButton from "../bookingRebookCart"
import MoveBookingButton from "../bookingMoveButton"
import RefundBookingButton from "../bookingRefundButton"
import {
    statusAllUpcomingOngoing,
    statusPaid,
} from "../../../constants/statusTypes"
import BookingControlPanel from "./bookingStartPayEndControll"
import { dateToHourMinute } from "../../dateTools"
import PaymentSection from "./paymentSection"
import { getPrice, getRefundAmount, hasPayed } from "../bookingUtils"
import { CheckCircle } from "@mui/icons-material"
import { getFullStatus } from "../bookingTable"

const useDialogStyles = makeStyles((theme) => ({
    overlay: {
        top: "44px",
        opacity: 0.96,
        maxWidth: "100vw",
        minWidth: "40vw",
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        padding: "10px",
    },
}))

const getBookingType = (platform) => {
    if (platform === "app") {
        return "app"
    }
    if (platform === "admin") {
        return "dashboard"
    }
    if (platform === "maintenance") {
        return "dashboard"
    }
    if (platform === "webApp") {
        return "Web App"
    }
    return " - "
}

export default function ControlBooking(props) {
    const [inProgress, setInProgress] = useState(false)
    const {
        selected,
        cartDocId,
        startTime,
        endTime,
        tlf,
        dashStatus,
        status,
        name,
        ridepass,
        userSegment,
        couponValid,
        note,
        verificationCode,
        bookingType,
        keyless,
        capturedAmount,
        rpValid,
        refunded,
        refundedRidepass,
        skipPayment,
        couponCode,
    } = useSelector((state) => state.bookingSelector)
    const bookingDoc = useSelector((state) => state.bookingSelector)
    const upcomingOngoing = statusAllUpcomingOngoing.indexOf(status) > -1
    useEffect(() => {
        setInProgress(bookingInProgress(status))
    }, [status])
    const style = useDialogStyles()
    const { currency } = useSelector((state) => state.firestore.data.field)
    const cartId = useSelector((state) => {
        if (
            state.firestore.data.carts &&
            Object.keys(state.firestore.data.carts).indexOf(cartDocId) > -1
        ) {
            return state.firestore.data.carts[cartDocId].id
        }
        return null
    })

    if (!selected || !bookingDoc) {
        return <></>
    }
    return (
        <div>
            <Dialog
                classes={{ paper: style.overlay }}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.handleClose}
                >
                    <Grid
                        container
                        alignItems={"center"}
                        justifyContent="center"
                        direction="row"
                    >
                        <Grid item xs>
                            <Typography variant="inherit" align="left">
                                {`BOOKING OVERVIEW`}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="inherit"
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                            >
                                {`${getBookingType(bookingType)} Booking`}
                            </Typography>
                        </Grid>
                        {keyless === false ? (
                            <Grid item xs>
                                <Typography variant="inherit" align="left">
                                    {`Booking Ref: ${verificationCode}`}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid
                            item
                            xs
                            container
                            alignItems={"center"}
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item xs>
                                {hasPayed(bookingDoc) && (
                                    <Grid container direction="row">
                                        <Typography
                                            variant="inherit"
                                            align="center"
                                        >
                                            {`Paid:`}
                                        </Typography>
                                        &nbsp;
                                        <CheckCircle color="success" />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs>
                                {getRefundAmount(bookingDoc) ? (
                                    <Typography variant="h6" fontSize="14pt">
                                        (Refunded: {getRefundAmount(bookingDoc)}{" "}
                                        {currency})
                                    </Typography>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent
                    style={{ width: `${100}%`, maxWidth: `${1000}px` }}
                >
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        marginBottom={2}
                        marginTop={1}
                    >
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                <Typography variant="h5" align="center">
                                    {name}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    {tlf}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                >
                                    <Typography variant="h5" align="center">
                                        {getPrice(bookingDoc)} {currency}
                                    </Typography>
                                </Grid>
                                <Typography
                                    variant="caption"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    {userSegment}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Divider orientation="vertical" flexItem />
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                <Typography variant="h5" align="center">
                                    {dateToHourMinute(new Date(startTime))}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    {dateToHourMinute(new Date(endTime))}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider orientation="horizontal" flexItem />
                    <Grid
                        container
                        direction="row"
                        spacing={2}
                        marginY={2}
                        paddingBottom={2}
                    >
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                <Typography variant="h5" align="center">
                                    {cartId}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    Vehicle No.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                <Typography variant="h5" align="center">
                                    {getFullStatus(bookingDoc)}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    STATUS
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs>
                            <Grid container direction="column" spacing={2}>
                                {rpValid && (
                                    <Typography variant="h5" align="center">
                                        {ridepass.name}
                                    </Typography>
                                )}
                                {couponValid && (
                                    <Typography variant="h5" align="center">
                                        {couponCode}
                                    </Typography>
                                )}
                                {!rpValid && !couponValid && (
                                    <Typography variant="h5" align="center">
                                        -
                                    </Typography>
                                )}

                                <Typography
                                    variant="caption"
                                    align="center"
                                    color="#c3c3c3"
                                >
                                    DISCOUNT
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {note ? (
                        <Box>
                            <Typography fontSize={"16pt"}>
                                Note: {note}
                            </Typography>
                        </Box>
                    ) : null}

                    <DialogActions
                        style={{ justifyContent: "center", display: "flex" }}
                    >
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                            marginTop={1}
                        >
                            <Grid container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        height="90px"
                                    >
                                        <Grid item xs>
                                            <BookingControlPanel
                                                handleClose={props.handleClose}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <PaymentSection
                                                handleClose={props.handleClose}
                                            />
                                        </Grid>

                                        <Grid item xs>
                                            <MoveBookingButton
                                                disabled={inProgress}
                                                handleClose={props.handleClose}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs>
                                    <Grid
                                        container
                                        direction="row"
                                        spacing={2}
                                        height="90px"
                                    >
                                        <Grid item xs>
                                            <RebookButton />
                                        </Grid>
                                        <Grid item xs>
                                            <RefundBookingButton
                                                disabled={
                                                    statusPaid.indexOf(
                                                        status
                                                    ) === -1 ||
                                                    ((capturedAmount === 0 ||
                                                        skipPayment) &&
                                                        !rpValid) ||
                                                    (refunded &&
                                                        refunded >=
                                                            capturedAmount) ||
                                                    refundedRidepass
                                                }
                                                handleClose={props.handleClose}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <DeleteBookingButton
                                                disabled={!upcomingOngoing}
                                                handleClose={props.handleClose}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
