import React from "react"
import {
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Table,
    Tooltip,
} from "@mui/material"
import { iconOptions, iconUnavailableOptions } from "../cartIcon"
import { ReactComponent as KeyIcon } from "../../../assets/img/KeyIcon.svg"
import theme from "../../../assets/styles/muiTheme"
import { styled } from "@mui/styles"
import { DotSwitcher } from "./ReadingRow"

const tooltipWrap = (obj, av) => {
    if (av) {
        return obj
    }
    return (
        <Tooltip
            title={
                <Typography fontWeight={"500"} margin="0">
                    Unavailable for booking
                </Typography>
            }
            placement="bottom-start"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -20],
                        },
                    },
                ],
            }}
        >
            {obj}
        </Tooltip>
    )
}

const vehicleIcon = (groupIcon, av, engine) => {
    if (groupIcon === undefined) {
        return null
    }
    const groupIconString = String(groupIcon)
    if (iconOptions.filter((x) => x.value === groupIconString).length === 0) {
        console.error("Bad group icon", groupIconString)
        return null
    }
    let Icon

    if (av) {
        Icon = iconOptions[groupIconString].icon
    } else {
        Icon = iconUnavailableOptions[groupIconString].icon
    }
    return tooltipWrap(
        <Box
            style={{
                height: "100%",
                display: "block",
                position: "relative",
            }}
        >
            <Icon
                fill={getColorFromVehicleLockStatus(engine)}
                style={{
                    position: "absolute",
                    left: "-12.5px",
                    top: 7,
                    width: "25px",
                    height: "25px",
                }}
            />
        </Box>,
        av
    )
}

const getKeyText = (engine, keyless) => {
    if (engine && keyless) {
        return "ON"
    }
    if (!engine && keyless) {
        return "OFF"
    }
    if (engine && !keyless) {
        return "OUT"
    }
    if (!engine && !keyless) {
        return "IN"
    }
    return ""
}

const keyIcon = (engine, keyless) => {
    if (keyless) {
        return (
            <Typography
                display={"block"}
                fontSize={"11pt"}
                fontWeight={"500"}
                color={getColorFromVehicleLockStatus(engine)}
            >
                {getKeyText(engine, keyless)}
            </Typography>
        )
    }
    return (
        <Box
            sx={{
                display: "block",
                width: "100%",
                height: "30px",
                position: "relative",
                margin: "auto",
            }}
        >
            <KeyIcon
                fill={getColorFromVehicleLockStatus(engine)}
                style={{ width: "16px" }}
            />
            <Typography
                lineHeight={"0"}
                display={"block"}
                fontSize={"11pt"}
                fontWeight={"500"}
                color={getColorFromVehicleLockStatus(engine)}
            >
                {getKeyText(engine, keyless)}
            </Typography>
        </Box>
    )
}

const getColorFromVehicleLockStatus = (engine) => {
    if (engine) {
        return theme.palette.secondary.main
    }
    return theme.palette.gray.light
}
const TableStyled = styled(Table)(({ theme }) => ({
    height: "40px",
    marginTop: "-4px",
    cursor: "pointer",
}))
const TableBodyStyled = styled(TableBody)(({ theme }) => ({
    width: "100%",
    height: "40px",
    borderCollapse: "collapse",
    borderSpacing: 0,
    display: "table",
    tableLayout: "fixed",
}))
const TableRowStyled = styled(TableRow)(({ theme }) => ({
    padding: 0,
    border: "none",
}))
const TableCellStyle = styled(TableCell)(({ theme }) => ({
    border: "none",
    padding: 0,
    width: "35px",
    height: "100%",
    textAlign: "center",
    verticalAlign: "center",
}))
const TableCellContent = styled("div")(() => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

const TableCellStyled = ({ children }) => (
    <TableCellStyle>
        <TableCellContent>{children}</TableCellContent>
    </TableCellStyle>
)

const ConnectedDot = ({ keyless, connected }) =>
    keyless ? (
        <Tooltip
            title={
                <Typography fontWeight={"500"} margin="0">
                    {connected
                        ? "Smartbox has been online in the last hour"
                        : "Smartbox not responding in the last hour"}
                </Typography>
            }
            placement="right"
            PopperProps={{
                sx: {
                    "& .MuiTooltip-tooltip": {
                        padding: "0 5px",
                    },
                },
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, -10],
                        },
                    },
                ],
            }}
        >
            <Box top="-1px" right="-4px" position={"absolute"}>
                <DotSwitcher isAvailable={connected} size={9} />
            </Box>
        </Tooltip>
    ) : null

export default function CartIconLockRow(props) {
    const {
        style,
        av,
        engine,
        selected,
        setSelected,
        bookingStatus,
        groupIcon,
        id,
        keyless,
        connected,
    } = props
    return (
        <Box position="relative">
            <TableStyled
                onClick={() => {
                    if (setSelected) setSelected(!selected)
                }}
                style={{ ...style }}
            >
                <TableBodyStyled>
                    <TableRowStyled>
                        <TableCellStyled>
                            <Typography
                                variant="h6"
                                color={theme.palette.gray.main}
                            >
                                {id}
                            </Typography>
                        </TableCellStyled>
                        <TableCellStyled>
                            {vehicleIcon(groupIcon, av, engine)}
                        </TableCellStyled>
                        <TableCellStyled>
                            {keyIcon(engine, keyless)}
                        </TableCellStyled>
                    </TableRowStyled>
                </TableBodyStyled>
            </TableStyled>
            <ConnectedDot connected={connected} keyless={keyless} />
        </Box>
    )
}
