import {
    CLEARCREATEFORM,
    COUPONADDSEGMENT,
    COUPONADDPARTNERCOURSE,
    COUPONREMOVEPARTNERCOURSE,
    COUPONCHANGE,
    COUPONDELETE,
    COUPONREMOVESEGMENT,
    SETACTIVECOUPONS,
    SETACTIVECOUPONSLOADED,
    SETACTIVECOUPONSNOTLOADED,
    SETINACTIVECOUPONS,
    SETINACTIVECOUPONSLOADED,
    SETINACTIVECOUPONSNOTLOADED,
    UPDATECREATEFORM,
    SET_EDIT_COUPON,
} from "../constants/actionTypes"

const initState = {
    active: true,
    createState: true,
    activeCouponsLoaded: false,
    inactiveCouponsLoaded: false,
    activeCoupons: [],

    inactiveCoupons: [],
    createCouponForm: {
        code: "",
        name: "",
        validFrom: Date.now(),
        validTo: Date.now() + 6 * 30 * 24 * 60 * 60 * 1000,
        segments: [],
        isPercentage: true,
        discountPercentage: 0,
        discountFixed: 0,
        usageLimited: false,
        usageLimit: 0,
        perUserLimited: false,
        perUserLimit: 0,
        partnerCourses: [],
    },
}

export default (state = initState, action) => {
    switch (action.type) {
        case SETACTIVECOUPONS:
            return {
                ...state,
                active: true,
            }
        case SETINACTIVECOUPONS:
            return {
                ...state,
                active: false,
            }
        case UPDATECREATEFORM:
            return {
                ...state,
                createCouponForm: {
                    ...state.createCouponForm,
                    ...action.payload,
                },
            }
        case CLEARCREATEFORM:
            return {
                ...state,
                createState: true,
                createCouponForm: initState.createCouponForm,
            }
        case SET_EDIT_COUPON:
            return {
                ...state,
                createState: false,
                createCouponForm: {
                    ...state.createCouponForm,
                    ...action.payload,
                },
            }
        case COUPONADDSEGMENT:
            return {
                ...state,
                createCouponForm: {
                    ...state.createCouponForm,
                    segments: [
                        ...state.createCouponForm.segments,
                        action.payload.segment,
                    ],
                },
            }

        case COUPONADDPARTNERCOURSE:
            return {
                ...state,
                createCouponForm: {
                    ...state.createCouponForm,
                    partnerCourses: [
                        ...state.createCouponForm.partnerCourses,
                        action.payload.partnerCourse,
                    ],
                },
            }
        case COUPONREMOVEPARTNERCOURSE:
            return {
                ...state,
                createCouponForm: {
                    ...state.createCouponForm,
                    partnerCourses:
                        state.createCouponForm.partnerCourses.filter(
                            (x) => x !== action.payload.partnerCourse
                        ),
                },
            }
        case COUPONREMOVESEGMENT:
            return {
                ...state,
                createCouponForm: {
                    ...state.createCouponForm,
                    segments: state.createCouponForm.segments.filter(
                        (x) => x !== action.payload.segment
                    ),
                },
            }
        case SETACTIVECOUPONSLOADED:
            return {
                ...state,
                activeCouponsLoaded: true,
                activeCoupons: action.payload.activeCoupons,
            }
        case SETINACTIVECOUPONSLOADED:
            return {
                ...state,
                inactiveCouponsLoaded: true,
                inactiveCoupons: action.payload.inactiveCoupons,
            }
        case SETACTIVECOUPONSNOTLOADED:
            return {
                ...state,
                activeCouponsLoaded: false,
            }
        case SETINACTIVECOUPONSNOTLOADED:
            return {
                ...state,
                inactiveCouponsLoaded: false,
            }
        case COUPONCHANGE:
            if (action.payload.active) {
                return {
                    ...state,
                    activeCoupons: [
                        ...state.activeCoupons,
                        ...state.inactiveCoupons
                            .filter(
                                (x) =>
                                    x.couponDocId === action.payload.couponDocId
                            )
                            .map((x) => {
                                x.active = true
                                return x
                            }),
                    ],
                    inactiveCoupons: state.inactiveCoupons.filter(
                        (x) => x.couponDocId !== action.payload.couponDocId
                    ),
                }
            }
            return {
                ...state,
                activeCoupons: state.activeCoupons.filter(
                    (x) => x.couponDocId !== action.payload.couponDocId
                ),
                inactiveCoupons: [
                    ...state.inactiveCoupons,
                    ...state.activeCoupons
                        .filter(
                            (x) => x.couponDocId === action.payload.couponDocId
                        )
                        .map((x) => {
                            x.active = false
                            return x
                        }),
                ],
            }

        case COUPONDELETE:
            return {
                ...state,
                activeCoupons: state.activeCoupons,
                inactiveCoupons: state.inactiveCoupons.filter(
                    (x) => x.couponDocId !== action.payload.couponDocId
                ),
            }

        default:
            return state
    }
}
