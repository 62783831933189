import {
    SETUSERACCESSPERMISSION,
    SETUSERSWPERMISSION,
    UNSETUSERSWPERMISSION,
} from "../constants/actionTypes"

export default (state = {}, action) => {
    switch (action.type) {
        case SETUSERSWPERMISSION:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    ...action.payload,
                },
            }
        case SETUSERACCESSPERMISSION:
            return {
                ...state,
                [action.payload.id]: {
                    ...state[action.payload.id],
                    accessPermissions: {
                        ...state[action.payload.id].accessPermissions,
                        ...action.payload.accessPermissions,
                    },
                },
            }
        case UNSETUSERSWPERMISSION:
            return {
                ...state,
                [action.payload.id]: null,
            }
        default:
            return state
    }
}
