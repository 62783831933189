import * as React from "react"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"
import IconButton from "@mui/material/IconButton"
import CommentIcon from "@mui/icons-material/Comment"
import PropTypes from "prop-types"

export const outrowListItems = {
    "Golf Cart Stickers":
        "We have applied stickers on the windshield on our golf carts, visible for all member guest.",
    "How To EAGL Prints":
        "How To EAGL table stands and posters (order only) is on display and visible to guests and members.",
    Website:
        "We have updated the information on our website about golf cart rental. See example in media bank",
    "Newsletter and emails":
        "We have informed our members about golf cart rental and EAGL through our newsletter and players will receive information on email when they book a tee time.",
    "Social media (Facebook, Instagram etc.)":
        "We have published information/video about golf cart rental and EAGL on our channels. Please tag EAGL social media accounts, so EAGL can share it with all the EAGL users nearby! Facebook: @EAGLapp . Instagram: @eagl.app ",
    "How To EAGL monitor":
        "“How to EAGL digital poster» is displayed on a monitor / TV in Proshop / Club house etc, visible for members and guest.",
}

export default function CheckboxList(props) {
    const { checked, setChecked } = props
    const handleToggle = (key) => () => {
        const currentIndex = checked.indexOf(key)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(key)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    return (
        <List sx={{ width: "100%", bgcolor: "inherit" }}>
            {Object.entries(outrowListItems).map(([object, key]) => {
                const labelId = `checkbox-list-label-${key}`
                const labelText = `checkbox-list-label-${object}`

                return (
                    <ListItem key={key} disablePadding>
                        <ListItemButton
                            role={undefined}
                            onClick={handleToggle(key)}
                            dense
                        >
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(key) === -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={`${object}`}
                                secondary={`${key}`}
                            />
                        </ListItemButton>
                    </ListItem>
                )
            })}
        </List>
    )
}

CheckboxList.propTypes = {
    checked: PropTypes.array.isRequired,
    setChecked: PropTypes.func.isRequired,
}
