import React, { memo, useState } from "react"
import { Typography, Box } from "@mui/material"
import { getColorStyle } from "./getCartStyles"
import { cartStyle } from "../../assets/styles/components/carts"
import { styled } from "@mui/styles"
import CartDialog from "./cartPopupComponents"

const StyledBox = styled(Box)(({ selected }) => ({
    ...cartStyle.openCloseButton(selected),
    "&:hover": {
        zIndex: 10,
    },
}))

const StyledDot = styled("div")(({ bookingstatus }) => ({
    ...cartStyle.customPointer(getColorStyle(bookingstatus)),
}))

const StyledDotGetter = ({ bookingstatus }) => {
    return <StyledDot bookingstatus={bookingstatus} />
}
const StyledDotActual = memo(StyledDotGetter)

const zIndexTen = {
    zIndex: 10,
}

const hasReadingValid = (time) => {
    if (!time) {
        return false
    }
    const timeIsMoreRecentLastHour = time && time + 60 * 60 * 1000 > Date.now()
    return timeIsMoreRecentLastHour
}

export default function CartStyleOnMap(props) {
    const { bookingStatus } = props
    const [selected, setSelected] = useState(false)

    const extraStyle = selected ? zIndexTen : {}
    const hasReading = hasReadingValid(
        props?.cart?.reading?.timestampReceived?.toMillis()
    )

    return (
        <div style={{ height: "58px", width: "120px", position: "relative" }}>
            <StyledDotActual bookingstatus={bookingStatus} />
            <CartDialog
                {...props}
                keyless={props?.cart?.keyless ?? false}
                selected={selected}
                setSelected={setSelected}
                connected={hasReading}
            />
            <StyledBox
                selected={selected}
                onClick={() => {
                    setSelected(!selected)
                }}
                style={extraStyle}
            >
                <Typography
                    style={{
                        ...cartStyle.openCloseButtonText(),
                        ...extraStyle,
                    }}
                    fontWeight={"600"}
                    fontSize={"10px"}
                >
                    {selected ? "Close" : "Open"}
                </Typography>
            </StyledBox>
        </div>
    )
}
