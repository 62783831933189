import React, { forwardRef, useEffect, useRef, useState } from "react"
import store from "../../../store"
import { SET_REGISTER_STEP } from "../../../constants/actionTypes"
import {
    Box,
    Button,
    Divider,
    Grid,
    Icon,
    Switch,
    Typography,
} from "@mui/material"
import { ToolTipYellow } from "../../../Components/CreateCartTable"
import CheckoutForm from "../../../Components/CheckoutForm"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import db, { functions } from "../../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import { getAuth } from "firebase/auth"
import { cartsPath, golfCourses, users } from "../../../constants/dbPaths"
import theme from "../../../assets/styles/muiTheme"
import StarRoundedIcon from "@mui/icons-material/StarRounded"
import CircleLoader from "../../../Components/loader"
import { useSelector } from "react-redux"
import SubTrialPromoBox from "../../../Components/SubTrialPromoBox"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const getUnitPrice = (subscription) => {
    const subscriptionItems = subscription?.items?.data
    if (subscriptionItems && subscriptionItems.length) {
        const subscriptionItem = subscriptionItems[0]
        const tiers = subscriptionItem?.price?.tiers
        if (tiers && tiers.length) {
            const firstTier = tiers[0]
            const unitPrice = firstTier.unit_amount
            const flatAmount = firstTier.flat_amount
            return {
                ...firstTier,
                unitPrice,
                flatAmount,
            }
        }
    }
    return {}
}

const getCurrency = (subscription) => {
    return String(subscription.currency).toUpperCase()
}

const options = (secret) => ({
    clientSecret: secret,
    appearance: {
        theme: "night",
    },
})

const PremiumQualiComp = (_, ref) => {
    return (
        <Box
            width={"100%"}
            sx={{
                pr: 8,
                pl: 8,
                pb: 2,
                pt: 2,
                borderRadius: "4px",
                backgroundColor: theme.palette.primary.main,
            }}
            position="relative"
        >
            <Grid container direction={"column"}>
                <Grid item xs>
                    <Typography fontSize={"18pt"}>
                        You might qualify for EAGL{" "}
                        <Typography
                            component="span"
                            fontSize="inherit"
                            color="secondary"
                            fontWeight={"600"}
                        >
                            Connect Keyless
                        </Typography>
                        !
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Switch
                        color="secondary"
                        defaultChecked={true}
                        inputRef={ref}
                    />
                    <Typography display="inline-block">
                        I want to be contacted with prices and more information.
                    </Typography>
                </Grid>
            </Grid>
            <Icon
                color="secondary"
                sx={{
                    "& svg": {
                        fontSize: "30pt",
                    },
                    height: "auto",
                    width: "auto",
                    position: "absolute",
                    top: 8,
                    right: 8,
                }}
            >
                <ToolTipYellow
                    toolTip="Lorem ipsum"
                    input={<StarRoundedIcon />}
                ></ToolTipYellow>
            </Icon>
        </Box>
    )
}

const getTrialText = (trialLength) => {
    if (trialLength) {
        return `You are now signing up to a ${trialLength} day free trial. After the trial period you will be billed`
    }
    return `You are now signing up to be billed`
}

const confirmationText = (
    cartsCount,
    subscriptionAnual,
    subscriptionMonth,
    trialLength,
    payMonthly
) => {
    if (
        !(
            cartsCount &&
            Object.keys(subscriptionAnual).length &&
            Object.keys(subscriptionMonth).length
        )
    ) {
        return <CircleLoader />
    }
    if (payMonthly) {
        return (
            <Typography sx={{ pt: 1 }} fontSize={"10pt"} fontWeight="100">
                {getTrialText(trialLength)} {getCurrency(subscriptionMonth)}{" "}
                {Number(
                    getUnitPrice(subscriptionMonth).unitPrice *
                        0.01 *
                        cartsCount
                ).toFixed(2)}
                ,- /month of EAGL Connect access
                {trialLength ? " if you do not cancel." : "."}
            </Typography>
        )
    }
    return (
        <Typography sx={{ pt: 1 }} fontSize={"10pt"} fontWeight="100">
            {getTrialText(trialLength)} {getCurrency(subscriptionAnual)}{" "}
            {Number(
                getUnitPrice(subscriptionAnual).unitPrice * 0.01 * cartsCount
            ).toFixed(2)}
            ,- for 12 months of EAGL Connect access{" "}
            {trialLength ? " if you do not cancel." : "."}
        </Typography>
    )
}

const PremiumQualiPromo = forwardRef(PremiumQualiComp)

export default function CreatePayment() {
    const requestPremiumRef = useRef(true)
    const [stripeOptionsAnual, setStripeOptionsAnual] = useState(null)
    const [stripeOptionsMonthly, setStripeOptionsMonthly] = useState(null)
    const [cartsCount, setCartsCount] = useState()
    const [payMonthly, setPayMonthly] = useState(false)
    const [subscriptionAnual, setSubscriptionAnual] = useState({})
    const [subscriptionMonth, setSubscriptionMonth] = useState({})
    const [trialLength, setTrialLength] = useState(0)
    const [pmIntent, setPmIntent] = useState(false)

    useEffect(() => {
        store.dispatch({ type: SET_REGISTER_STEP, payload: { step: 3 } })
    }, [])
    const uid = getAuth().currentUser?.uid

    const code = useSelector((state) => state.register?.code)

    const requestContacted = requestPremiumRef.current.checked

    const createSubscription = httpsCallable(functions, "createSubscription")
    useEffect(() => {
        async function getSecret() {
            if (!stripeOptionsMonthly && uid && functions) {
                const userRef = await db.collection(users).doc(uid).get()
                const courseDocId = userRef.data().courseDocId
                console.log(courseDocId)
                const carts = await db
                    .collection(golfCourses)
                    .doc(courseDocId)
                    .collection(cartsPath)
                    .get()
                setCartsCount(carts.docs.length)
                console.log("carts, ", carts.docs.length)
                const course = await db
                    .collection(golfCourses)
                    .doc(courseDocId)
                    .get()
                const courseData = course.data()

                const result = await createSubscription({
                    courseDocId,
                    monthly: true,
                    quantity: carts.docs.length,
                    currency: courseData.currency,
                    code,
                }).catch((e) => {
                    console.error(e.message)
                })
                if (result && result?.data) {
                    console.log(result.data)
                    const optionsMonth = options(result.data.clientSecret)
                    setStripeOptionsMonthly(optionsMonth)
                    setSubscriptionMonth(result.data.subscription)
                    setTrialLength(result.data.priceModel.trialDays)
                    setPmIntent(result.data.paymentIntent)
                }
            }
        }
        getSecret()
    }, [stripeOptionsMonthly, functions, uid])

    useEffect(() => {
        const setupClientSecret = new URLSearchParams(
            window.location.search
        ).get("setup_intent_client_secret")
        const pmIntentClientSecret = new URLSearchParams(
            window.location.search
        ).get("payment_intent_client_secret")
        const clientSecret = setupClientSecret || pmIntentClientSecret

        if (!clientSecret) {
            return
        }
        const optionsAnual = options(clientSecret)
        setStripeOptionsAnual(optionsAnual)
        const optionsMonth = options(clientSecret)
        setStripeOptionsMonthly(optionsMonth)
    }, [])

    useEffect(() => {
        async function getSecret() {
            if (!stripeOptionsAnual && uid && functions) {
                const userRef = await db.collection(users).doc(uid).get()
                const courseDocId = userRef.data().courseDocId
                const carts = await db
                    .collection(golfCourses)
                    .doc(courseDocId)
                    .collection(cartsPath)
                    .get()
                setCartsCount(carts.docs.length)
                const course = await db
                    .collection(golfCourses)
                    .doc(courseDocId)
                    .get()
                const courseData = course.data()
                const result = await createSubscription({
                    courseDocId,
                    monthly: false,
                    quantity: carts.docs.length,
                    currency: courseData.currency,
                    code,
                }).catch((e) => {
                    console.error(e.message)
                    return
                })
                if (result && result?.data) {
                    console.log(result.data)
                    const optionsAnual = options(result.data.clientSecret)
                    setStripeOptionsAnual(optionsAnual)
                    setSubscriptionAnual(result.data.subscription)
                    setTrialLength(result.data.priceModel.trialDays)
                    setPmIntent(result.data.paymentIntent)
                }
            }
        }
        getSecret()
    }, [stripeOptionsAnual, uid, functions])

    return (
        <Box
            sx={{
                width: "80%",
                maxWidth: "900px",
                margin: "auto",
                paddingBottom: 4,
            }}
        >
            <SubTrialPromoBox
                code={code}
                payMonthly={payMonthly}
                setPayMonthly={setPayMonthly}
            />
            <Box height="30px" />
            <PremiumQualiPromo ref={requestPremiumRef} />
            <Box width="100%">
                <Divider sx={{ mt: 2, mb: 2 }} />
                {payMonthly ? (
                    stripeOptionsMonthly && stripePromise ? (
                        <Elements
                            stripe={stripePromise}
                            options={stripeOptionsMonthly}
                        >
                            <CheckoutForm
                                pmIntent={pmIntent}
                                requestContacted={requestContacted}
                            />
                        </Elements>
                    ) : null
                ) : stripeOptionsAnual && stripePromise ? (
                    <Elements
                        stripe={stripePromise}
                        options={stripeOptionsAnual}
                    >
                        <CheckoutForm
                            pmIntent={pmIntent}
                            requestContacted={requestContacted}
                        />
                    </Elements>
                ) : null}
                {confirmationText(
                    cartsCount,
                    subscriptionAnual,
                    subscriptionMonth,
                    trialLength,
                    payMonthly
                )}
            </Box>
            <Box mt={8}>
                <Button
                    sx={{
                        color: "#DCDCDC",
                        display: "block",
                        margin: "auto",
                        p: 2,
                    }}
                    variant="contained"
                >
                    need help?
                </Button>
            </Box>
        </Box>
    )
}
