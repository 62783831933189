import React, { useState } from "react"
import { Dialog, TableCell, Tooltip, Typography } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import makeStyles from "@mui/styles/makeStyles"
import TableRow from "@mui/material/TableRow"
import TableHead from "@mui/material/TableHead"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import Grid from "@mui/material/Grid"
import { getBorderStyle, getCartStyle } from "../Carts/getCartStyles"
import { cartStyle, K_SIZE } from "../../assets/styles/components/carts"
import {
    statusAdmin,
    statusMaintenance,
    statusStarted,
} from "../../constants/statusTypes"
import {
    DialogContent,
    DialogTitle,
} from "../AvailabilityRebooking/popupAvailability"
import theme from "../../assets/styles/muiTheme"
import CartDialog from "../Carts/cartPopupComponents"

const useMapHelpStyles = makeStyles((theme) => ({
    overlay: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
    },
    questionOverlay: {
        top: "12px",
        left: "12px",
        opacity: 0.97,
        width: "20%",
        minWidth: 20,
        zIndex: 1,
        position: "absolute",
        maxHeight: "80vh",
    },
    body: {
        color: theme.palette.primary.main,
    },
}))
const dialogTitleStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        marginLeft: theme.spacing(2),
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}))

export default function MapHelpDialog(props) {
    const styles = useMapHelpStyles()
    const dialogStyle = dialogTitleStyles()
    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <div className={styles.questionOverlay}>
                <Tooltip
                    title="Click to show color explanations"
                    placement="top"
                >
                    <IconButton onClick={() => setOpen(true)} size="large">
                        <HelpOutlineIcon
                            fontSize="large"
                            color="secondary"
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "50%",
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                classes={{ paper: styles.overlay }}
                color="primary"
                aria-labelledby="helper-dialog-map"
            >
                <DialogTitle
                    onClose={handleClose}
                    classes={dialogStyle}
                    id="helper-dialog-map-title"
                >
                    Map color explanations
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignContent="center"
                    >
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Cart State
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={styles.body}>
                                            Symbol
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Explanation
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <CartDialog
                                                {...{
                                                    av: true,
                                                    engine: false,
                                                    bookingStatus: "",
                                                    groupIcon: "0",
                                                    id: "X",
                                                    keyless: true,
                                                    cartDocId: "",
                                                    selected: false,
                                                    setSelected: () => true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Available and off
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <CartDialog
                                                {...{
                                                    av: true,
                                                    engine: true,
                                                    keyless: true,
                                                    bookingStatus: "",
                                                    groupIcon: "0",
                                                    id: "X",
                                                    cartDocId: "",
                                                    selected: false,
                                                    setSelected: () => true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Available and on
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <CartDialog
                                                {...{
                                                    av: false,
                                                    engine: false,
                                                    keyless: true,
                                                    bookingStatus: "",
                                                    groupIcon: "0",
                                                    id: "X",
                                                    cartDocId: "",
                                                    selected: false,
                                                    setSelected: () => true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Not available
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <CartDialog
                                                {...{
                                                    av: false,
                                                    engine: true,
                                                    keyless: true,
                                                    bookingStatus: "",
                                                    groupIcon: "0",
                                                    id: "X",
                                                    cartDocId: "",
                                                    selected: false,
                                                    setSelected: () => true,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Not available and on
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                                Booking State
                            </Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={styles.body}>
                                            Symbol
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Explanation
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                style={{
                                                    ...cartStyle.root(),
                                                    ...getBorderStyle(""),
                                                    ...{
                                                        position: null,
                                                        borderWidth: "6px",
                                                    },
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            No current booking
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                style={{
                                                    ...cartStyle.root(K_SIZE),
                                                    ...getBorderStyle(
                                                        statusStarted
                                                    ),
                                                    ...{
                                                        position: null,
                                                        borderWidth: "6px",
                                                    },
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            App booking
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                style={{
                                                    ...cartStyle.root(K_SIZE),
                                                    ...getBorderStyle(
                                                        statusAdmin
                                                    ),
                                                    ...{
                                                        position: null,
                                                        borderWidth: "6px",
                                                    },
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Dashboard user booking
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            <div
                                                style={{
                                                    ...cartStyle.root(K_SIZE),
                                                    ...getBorderStyle(
                                                        statusMaintenance
                                                    ),
                                                    ...{
                                                        position: null,
                                                        borderWidth: "6px",
                                                    },
                                                }}
                                            >
                                                <Typography variant="h6">
                                                    {}
                                                </Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell className={styles.body}>
                                            Maintenance booking
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
