import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import AppBar from "@mui/material/AppBar"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import styled from "@emotion/styled"
import theme from "../../assets/styles/muiTheme"
import { Box, Tooltip } from "@mui/material"

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1,
        position: "relative",
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
}))

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    }
}
function CloneProps(props) {
    const { children, ...other } = props
    return children(other)
}
export const PrimaryAppbar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none",
}))

export default function SettingTopbar(props) {
    const classes = useStyles()

    const handleChange = (event, newValue) => {
        props.handleChange(event, newValue)
    }
    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index
    }
    const uniqueSettings = props.allSettings.filter(onlyUnique)

    return (
        <div className={classes.root}>
            <PrimaryAppbar position="static">
                <Tabs
                    value={props.settingFocused}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant={props.variant ? props.variant : "scrollable"}
                    scrollButtons="auto"
                    aria-label="scrollable tabs of fields"
                >
                    {uniqueSettings.map((setting, index) => {
                        const noAccess = props.settings.indexOf(setting) === -1
                        if (noAccess) {
                            return (
                                <CloneProps key={index}>
                                    {(tabProps) => (
                                        <Tooltip
                                            title={
                                                "Limited permissions - Ask admin for info"
                                            }
                                            arrow
                                        >
                                            <div>
                                                <Tab
                                                    {...tabProps}
                                                    disabled={true}
                                                    key={index}
                                                    label={setting}
                                                    {...a11yProps(index)}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                                </CloneProps>
                            )
                        }

                        return (
                            <Tab
                                key={index}
                                position="relative"
                                label={setting}
                                {...a11yProps(index)}
                            />
                        )
                    })}
                </Tabs>
            </PrimaryAppbar>
        </div>
    )
}
