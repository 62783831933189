import React, { useState } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import PropTypes from "prop-types"
import { PAGE_CHANGE_SETTING_FLOW } from "../../constants/actionTypes"
import { settingsSteps } from "./getSettingsSteps"
import { Box } from "@mui/system"
import {
    Button,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material"
import SettingsSubmit from "./handleSubmit"

export const getNumbersFromString = (input) => {
    return Number(String(input).replace(/\D/g, ""))
}

export default function SettingsFormStepper(props) {
    const { handleComplete } = props

    const settingsController = SettingsSubmit()
    const [submitted, setSubmitted] = useState(false)
    const { error, status } = settingsController
    const handleSubmit = async (e) => {
        await settingsController.handleSubmit(e)
    }

    const activeStep = useSelector((state) => state.setupflow.activeSettingPage)
    const handleStepPress = (step) => {
        store.dispatch({
            type: PAGE_CHANGE_SETTING_FLOW,
            payload: { page: step },
        })
    }
    const handleNext = async (e) => {
        if (activeStep === steps.length - 1) {
            await handleSubmit(e)
            setSubmitted(true)
            handleComplete()
        }
        await handleSubmit(e)
            .then(() => {
                setSubmitted(true)
            })
            .catch((e) => {
                console.error(e)
            })

        store.dispatch({
            type: PAGE_CHANGE_SETTING_FLOW,
            payload: { page: activeStep + 1 },
        })
    }

    const handleBack = () => {
        store.dispatch({
            type: PAGE_CHANGE_SETTING_FLOW,
            payload: { page: activeStep - 1 },
        })
    }
    const steps = settingsSteps()

    return (
        <Box sx={{ pl: "20px" }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => {
                    const Element = step.Element
                    const CurrentValue = step.CurrentValue
                    return (
                        <Step key={index}>
                            <StepLabel
                                onClick={() => handleStepPress(index)}
                                optional={
                                    activeStep > index ? (
                                        <Typography variant="caption">
                                            <CurrentValue />
                                        </Typography>
                                    ) : null
                                }
                            >
                                {step.title}
                            </StepLabel>
                            <StepContent sx={{ maxWidth: "500px" }}>
                                <Typography>{step.desc}</Typography>
                                <Box sx={{ mt: 10 + "px" }}>
                                    <Element />
                                </Box>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1, ml: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleNext}
                                            disabled={
                                                String(step.desc).length === 0
                                            }
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1
                                                ? "Finish"
                                                : "Continue"}
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    )
                })}
            </Stepper>
            {submitted && error ? (
                <Typography color="error">{error}</Typography>
            ) : null}
            {activeStep === steps.length - 1 &&
                (!submitted ? (
                    <Typography color="error">
                        {"Fill out all fields to continue"}
                    </Typography>
                ) : null)}
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Back
                    </Button>
                </Paper>
            )}
        </Box>
    )
}

SettingsFormStepper.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
