import {
    SET_REGISTER_STEP,
    SET_PRICE_MODEL,
    SET_PAYMENT_MODEL,
} from "../constants/actionTypes"

const initialState = {
    courseComplete: false,
    registerStep: 0,
    code: "",
    priceModel: {},
    subscription: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REGISTER_STEP:
            return {
                ...state,
                registerStep: action.payload.step,
            }
        case SET_PAYMENT_MODEL:
            return {
                ...state,
                code: action.payload.code,
            }
        case SET_PRICE_MODEL:
            return {
                ...state,
                priceModel: action.payload.priceModel,
            }
        default:
            return state
    }
}
