import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Typography } from "@mui/material"
import { loadStripe } from "@stripe/stripe-js"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { getAuth } from "firebase/auth"
import SigninStatus from "../../models/signinStatus"
import { useSelector } from "react-redux"
import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js"
import CircleLoader from "../../Components/loader"
import { Route, Routes, useNavigate } from "react-router-dom"
import SubTrialPromoBox from "../../Components/SubTrialPromoBox"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
const createSubscription = httpsCallable(functions, "createSubscription")
const options = (secret) => ({
    clientSecret: secret,
    appearance: {
        theme: "night",
    },
})

function PaymentCheckout(props) {
    const { pmIntent, clientSecret, subscription, conf } = props
    const stripe = useStripe()
    const elements = useElements()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault()

        if (elements === null) {
            return
        }
        setLoading(true)

        console.log("pmIntent", pmIntent)
        let confirmSetup = pmIntent
            ? stripe.confirmPayment
            : stripe.confirmSetup

        const result = await confirmSetup({
            elements,
            confirmParams: {
                return_url: `${location.href}/confirm`,
            },
        })

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message)
            setError(result.error.message)
        }
        setLoading(false)
    }
    return (
        <Grid container spacing={2} direction={"column"} alignItems="center">
            <Grid item xs style={{ maxWidth: "1200px", minWidth: "80%" }}>
                <SubTrialPromoBox
                    payMonthly={conf.payMonthly}
                    setPayMonthly={() => true}
                    code={conf.code}
                    disabled
                />
            </Grid>
            <Grid item xs style={{ maxWidth: "900px", minWidth: "60%" }}>
                <Typography>
                    You are now signing on to 12 months of payment after a{" "}
                    {conf.trialLength} trial period.
                </Typography>
                <Typography>
                    Please confirm you have read the contract.
                </Typography>
            </Grid>
            <Grid item xs style={{ maxWidth: "900px", minWidth: "60%" }}>
                <form onSubmit={handleSubmit}>
                    <PaymentElement type="payment" />
                    <Button
                        variant="contained"
                        fullWidth
                        color="secondary"
                        type="submit"
                        sx={{ fontWeight: "600" }}
                        disabled={!stripe || !elements}
                    >
                        Start trial
                    </Button>
                    {loading ? <CircleLoader /> : null}
                    {error ? (
                        <Typography
                            sx={{ p: 2, textAlign: "center" }}
                            color="error"
                            fontSize="14pt"
                        >
                            {error}
                        </Typography>
                    ) : null}
                </form>
            </Grid>
        </Grid>
    )
}

function ConfirmPayment() {
    const stripe = useStripe()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const { courseDocId } = SigninStatus()
    const navigate = useNavigate()

    useEffect(() => {
        if (!stripe) {
            return
        }

        const setupClientSecret = new URLSearchParams(
            window.location.search
        ).get("setup_intent_client_secret")
        const pmIntentClientSecret = new URLSearchParams(
            window.location.search
        ).get("payment_intent_client_secret")
        const clientSecret = setupClientSecret || pmIntentClientSecret

        if (!clientSecret) {
            return
        }
        const pmIntentParam = Boolean(pmIntentClientSecret)
        console.log("PmIntentParam", pmIntentParam)
        setLoading(true)
        const checkSubscriptionStatus = httpsCallable(
            functions,
            "checkSubscriptionStatus"
        )
        const uid = getAuth().currentUser?.uid
        if (!uid) {
            console.error("Uid not defined", uid)
            return
        }
        const retrieveFunction = pmIntentParam
            ? stripe.retrievePaymentIntent
            : stripe.retrieveSetupIntent

        retrieveFunction(clientSecret).then((res) => {
            const intent = pmIntentParam ? res.paymentIntent : res.setupIntent
            if (intent.status === "requires_payment_method") {
                setError("Something went wrong.")
            } else if (
                intent.status === "succeeded" ||
                intent.status === "processing"
            ) {
                if (intent.status === "succeeded") {
                    setMessage("Successful payment")
                } else {
                    setMessage("Processing payment")
                }
                checkSubscriptionStatus({ courseDocId, upgrade: true })
                    .then((res) => {
                        console.log(res)
                        navigate("/")
                        setLoading(false)
                    })
                    .catch((e) => {
                        console.log(e.message)
                        setError(e.message)
                        setLoading(false)
                        navigate("/upgrade")
                    })
            } else {
                console.error(intent.status)
            }
        })
    }, [stripe, getAuth().currentUser?.uid])

    return (
        <>
            <Typography>Confirming payment</Typography>
            {loading ? <CircleLoader /> : null}
            {error ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="error"
                    fontSize="14pt"
                >
                    {error}
                </Typography>
            ) : null}
            {message ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="secondary"
                    fontSize="14pt"
                >
                    {message}
                </Typography>
            ) : null}
        </>
    )
}
const getClientSecret = (window) => {
    const setupClientSecret = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret"
    )
    const pmIntentClientSecret = new URLSearchParams(
        window.location.search
    ).get("payment_intent_client_secret")
    const clientSecret = setupClientSecret || pmIntentClientSecret
    return clientSecret
}

export default function PayScreen(props) {
    const { payMonthly, code } = props
    const [stripeOptions, setStripeOptions] = useState()
    const { courseDocId, courseData } = SigninStatus()
    const [pmIntent, setPmIntent] = useState(false)
    const [trialLength, setTrialLength] = useState(0)
    const [subscription, setSubscription] = useState({})

    const uid = getAuth().currentUser?.uid
    const cartsCount = useSelector(
        (state) => Object.keys(state.firestore?.data?.carts || {}).length || 0
    )

    useEffect(() => {
        async function getSecret() {
            const secret = getClientSecret(window)
            if (secret) {
                const optionsObject = options(secret)
                setStripeOptions(optionsObject)
                return
            }
            if (!stripeOptions && uid && functions && cartsCount) {
                console.log("carts, ", cartsCount)
                const result = await createSubscription({
                    courseDocId,
                    monthly: payMonthly,
                    quantity: cartsCount,
                    currency: courseData.currency,
                    code,
                }).catch((e) => {
                    console.error(e.message)
                })
                if (result && result?.data) {
                    console.log(result.data)
                    const optionsObject = options(result.data.clientSecret)
                    setStripeOptions(optionsObject)
                    setSubscription(result.data.subscription)
                    setTrialLength(result.data.priceModel.trialDays)
                    setPmIntent(result.data.paymentIntent)
                }
            }
        }
        getSecret()
    }, [stripeOptions?.clientSecret, cartsCount, functions, uid])
    if (!stripeOptions) {
        return <CircleLoader fillScreen />
    }

    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Elements stripe={stripePromise} options={stripeOptions}>
                <Routes>
                    <Route
                        index
                        element={
                            <PaymentCheckout
                                clientSecret={options.clientSecret}
                                pmIntent={pmIntent}
                                subscription={subscription}
                                conf={{
                                    payMonthly,
                                    cartsCount,
                                    trialLength,
                                    code,
                                }}
                            />
                        }
                    />
                    <Route path="confirm" element={<ConfirmPayment />} />
                </Routes>
            </Elements>
        </Box>
    )
}
