import { Divider, Grid } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import CurrentBookingCard from "./currentBookingCard"
import CurrentBookingLoading from "./currentBookingLoader"
import NextBookingCard from "./nextBookingCard"
import NextBookingLoading from "./nextBookingLoader"

export default function CurrentNextBookingSection() {
    const { nextBooking, currentBooking } = useSelector(
        (state) => state.nextBooking
    )
    CurrentBookingLoading()
    NextBookingLoading()
    const nextBookingExists = nextBooking !== null
    const currentBookingExists = currentBooking !== null
    const bothExist = nextBookingExists && currentBookingExists
    if (bothExist) {
        return (
            <Grid item xs sx={{ mb: 1 }}>
                <Grid container direction="row">
                    <Grid item xs>
                        <CurrentBookingCard />
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs sx={{ marginLeft: 4 }}>
                        <NextBookingCard />
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    if (currentBookingExists) {
        return (
            <Grid item xs>
                <CurrentBookingCard />
            </Grid>
        )
    }
    if (nextBookingExists) {
        return (
            <Grid item xs>
                <NextBookingCard />
            </Grid>
        )
    }
    return null
}
