import { Box, TextField } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"

const desc = (
    <>
        The amount of days in advance booking of vehicles becomes available. The
        maximum is 6 days (6 * 24 hours) and the minimum is 0 days. If you want
        to disable pre-booking and only allow &quot;start ride now&quot;, you
        can set this to 0 days in advance.
    </>
)

const title = <>Booking days in advance</>

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const reservationDays = currentForm.reservationDays ?? 6
    return `Booking ${reservationDays} days in advance `
}
const getValue = (currentvalue) => {
    if (currentvalue === "") {
        return ""
    }
    const numericValue = parseInt(currentvalue)
    if (isNaN(numericValue)) {
        return ""
    }
    if (numericValue <= 6 && numericValue >= 0) {
        return Math.round(numericValue)
    }
    if (numericValue < 0) {
        return 0
    }
    return 6
}

function DaysInAdvance() {
    const currentForm = useSelector((state) => state.settingsForm)

    const handleInputChange = (e) => {
        const inputValue = e?.target?.value ?? ""
        console.log(inputValue)
        const value = getValue(inputValue)

        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "reservationDays",
                value: value,
            },
        })
    }

    return (
        <Box>
            <TextField
                id="days-in-advance"
                label="Days in advance"
                type="number"
                variant="standard"
                name="open"
                value={currentForm.reservationDays ?? 6}
                onChange={handleInputChange}
                fullWidth
                size="small"
                inputProps={{
                    min: 0,
                    max: 6,
                    step: 1,
                }}
            />
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: DaysInAdvance,
}
