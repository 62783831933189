import {
    SET_NEXT_BOOKING,
    SET_CURRENT_BOOKING,
    SET_LOADING_NEXT_BOOKING,
    SET_LOADING_CURRENT_BOOKING,
} from "../constants/actionTypes"

const initialState = {
    nextBooking: null,
    currentBooking: null,
    nextLoading: false,
    currentLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_NEXT_BOOKING:
            return {
                ...state,
                nextLoading: false,
                nextBooking: action.payload.nextBooking,
            }
        case SET_CURRENT_BOOKING:
            return {
                ...state,
                currentLoading: false,
                currentBooking: action.payload.currentBooking,
            }
        case SET_LOADING_CURRENT_BOOKING:
            return {
                ...state,
                currentLoading: true,
            }
        case SET_LOADING_NEXT_BOOKING:
            return {
                ...state,
                nextLoading: true,
            }
        default:
            return state
    }
}
