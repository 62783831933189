import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { bookingPath, golfCourses } from "../constants/dbPaths"
import Booking from "../views/booking"
import db from "./firebase"
import CircleLoader from "../Components/loader"
import store from "../store"
import {
    SET_BOOKINGS_BOOKING_VIEW,
    SET_BOOKINGS_LOADING_BOOKING_VIEW,
} from "../constants/actionTypes"
import { Timestamp } from "firebase/firestore"

export default function BookingWrap() {
    const { startTime, endTime } = useSelector((state) => state.bookingView)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })

    const bookingsInStore = useSelector((state) => state.bookingView.bookings)
    const updateNumber = useSelector((state) => state.bookingView.updateNumber)

    useEffect(() => {
        store.dispatch({
            type: SET_BOOKINGS_LOADING_BOOKING_VIEW,
        })
        db.collection(golfCourses)
            .doc(courseDocId)
            .collection(bookingPath)
            .where(
                "startTime",
                ">=",
                Timestamp.fromMillis(startTime - 1.5 * 60 * 60 * 24 * 1000)
            )
            .where(
                "startTime",
                "<=",
                Timestamp.fromMillis(endTime + 1.5 * 60 * 60 * 24 * 1000)
            )
            .get()
            .then((snapshot) => {
                const bookings = snapshot.docs.map((x) => ({
                    ...x.data(),
                    id: x.id,
                }))
                const bookingsToRemove = snapshot.docs.map((x) => x.id)
                const bookingsToKeep = bookingsInStore.filter(
                    (x) => bookingsToRemove.indexOf(x.id) === -1
                )
                const newBookings = [...bookingsToKeep, ...bookings]
                store.dispatch({
                    type: SET_BOOKINGS_BOOKING_VIEW,
                    payload: {
                        bookings: newBookings,
                    },
                })
            })
    }, [startTime, endTime, updateNumber])

    const bookings = useSelector((state) => state.bookingView.bookings)

    if (typeof bookings !== "undefined") {
        return <Booking />
    }
    return <CircleLoader />
}
