import React, { useState } from "react"
import Button from "@mui/material/Button"
import RefundDialog from "./bookingRefundPopup"

export default function RefundBookingButton(props) {
    const [refundOpen, setRefundOpen] = useState(false)

    const handleClose = () => {
        setRefundOpen(false)
        props.handleClose()
    }
    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                style={{
                    marginTop: "10px",
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                    height: "calc(100% - 10px)",
                }}
                onClick={() => setRefundOpen(true)}
                disabled={props.disabled}
            >
                Refund Booking
            </Button>
            <RefundDialog handleClose={handleClose} open={refundOpen} />
        </>
    )
}
