import React, { useEffect, useState } from "react"
import withStyles from "@mui/styles/withStyles"
import Dialog from "@mui/material/Dialog"
import MuiDialogTitle from "@mui/material/DialogTitle"
import MuiDialogContent from "@mui/material/DialogContent"
import MuiDialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Typography from "@mui/material/Typography"
import { makeStyles } from "@mui/styles"
import Switch from "@mui/material/Switch"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import store from "../../store"
import {
    CARTAVLOADING,
    CARTAVNOTLOADING,
    UNSELECTAVCART,
} from "../../constants/actionTypes"
import BookingAvList from "./bookingAvList"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
})

const useStyles = makeStyles((theme) => ({
    overlay: {
        top: "44px",
        opacity: 0.96,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        padding: "2%",
    },
}))

export const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    )
})

export const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent)

export const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions)

const wait = async (ms) => {
    return new Promise((res) => {
        setTimeout(res, ms)
    })
}

export default function PopupAvailability() {
    const style = useStyles()
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const [rebookable, setRebookable] = useState(false)

    const open = useSelector((state) => state.avCartSelector.selected)

    const handleClose = () => {
        store.dispatch({ type: UNSELECTAVCART })
    }

    const cartDocId = useSelector((state) => {
        if (state.avCartSelector.cartDocId) {
            return state.avCartSelector.cartDocId
        }
        return 0
    })
    const cart = useSelector((state) => state.avCartSelector)

    const switchAvailability = httpsCallable(functions, "switchAvailability")

    const switchAv = async ({ courseDocId, cartDocId, av }) => {
        store.dispatch({
            type: CARTAVLOADING,
            payload: { cart: cart.cartDocId, boardId: cart.boardId },
        })
        await switchAvailability({
            courseDocId,
            cartDocId,
            av,
        })
            .then(() => {
                wait(1000).then(() => {
                    store.dispatch({
                        type: CARTAVNOTLOADING,
                        payload: {
                            cart: cart.cartDocId,
                            boardId: cart.boardId,
                        },
                    })
                })

                handleClose()
            })
            .catch((e) => {
                store.dispatch({
                    type: CARTAVNOTLOADING,
                    payload: { cart: cart.cartDocId, boardId: cart.boardId },
                })
                console.error(e.message)
            })
    }
    const handleChange = async (e) => {
        e.preventDefault()
        const availability = e.target.checked
        if (!(cart.availability === availability)) {
            await switchAv({
                courseDocId,
                cartDocId: cart.cartDocId,
                av: availability,
            })
        }
    }

    useEffect(() => {
        // Auto switch to true if false
        if (cart.cartDocId && !cart.availability) {
            switchAv({
                courseDocId: cart.courseDocId,
                cartDocId: cart.cartDocId,
                av: true,
            })
        }
    }, [cart.cartDocId, cart.availability])

    const cartLoader = useSelector((state) => state.cartLoading)
    const avLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("avLoading") > -1 &&
        cartLoader[cartDocId].avLoading
    if (!cart || !cartDocId) {
        return <></>
    }
    return (
        <div>
            <Dialog
                classes={{ paper: style.overlay }}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {`Bookings the next week of cart ${cart.id.toString()} `}
                </DialogTitle>
                <DialogContent dividers>
                    <BookingAvList setRebookable={setRebookable} />
                </DialogContent>
                <DialogActions>
                    <Grid
                        component="label"
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Grid item>
                            <Typography>Unavailable</Typography>
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={cart.availability}
                                onChange={handleChange}
                                color="secondary"
                                id={cart.id.toString()}
                                disabled={
                                    !rebookable || avLoading(cart.cartDocId)
                                }
                                name={cart.id.toString()}
                                align="right"
                                inputProps={{
                                    "aria-label": "primary checkbox",
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography>Available</Typography>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}
