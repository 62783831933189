import React from "react"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import CouponGrid from "../CouponGrid"
import RatingCard from "./RatingCard"

export default function RatingGrid(gridSpacing) {
    const { selectedVehicle, vehicleRatings } = useSelector((state) => {
        return state.vehicleSelector
    })
    const { cartDocId } = selectedVehicle
    const currentRatingsObject = vehicleRatings[cartDocId]
    const currentBookingsWithRating = currentRatingsObject?.bookingsWithRating

    return (
        <Box sx={{ overflowY: "auto", width: "100%" }}>
            <CouponGrid
                couponList={currentBookingsWithRating}
                Component={RatingCard}
                cardWidth={500}
                gridSpacing={gridSpacing}
            />
        </Box>
    )
}
