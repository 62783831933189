import React, { useEffect } from "react"
import { useFirestoreConnect } from "react-redux-firebase"
import { useSelector } from "react-redux"
import { golfCourses } from "../constants/dbPaths"
import store from "../store"
import { SETENGINE, SETREADING } from "../constants/actionTypes"
import CircleLoader from "../Components/loader"

export default function MapWrap(props) {
    const user = useSelector((state) => state.firestore?.data?.user)
    const courseDocId = user?.courseDocId

    const courseLoaded = useSelector((state) => {
        const field = state.firestore.data.field
        return field !== undefined
    })

    const carts = useSelector((state) => {
        const cartsInField = state.firestore.data.carts
        if (
            typeof cartsInField !== "undefined" &&
            cartsInField &&
            Object.keys(cartsInField).length
        ) {
            return cartsInField
        } else if (
            typeof cartsInField !== "undefined" &&
            cartsInField === null
        ) {
            return []
        }
        return false
    })

    if (courseDocId) {
        useFirestoreConnect([
            {
                collection: golfCourses,
                doc: courseDocId || null,
                storeAs: "field",
                limit: 0,
            },
            {
                collection: golfCourses,
                doc: courseDocId || null,
                subcollections: [{ collection: "carts" }],
                storeAs: "carts",
                limit: 0,
            },
        ])
    } else {
        useEffect(() => {}, [])
        useEffect(() => {}, [])
    }

    useEffect(() => {
        if (courseDocId && carts) {
            console.log("Reload readings")
            Object.keys(carts).forEach((cartDocId, index) => {
                const cartData = carts[cartDocId]
                if (!cartData || !cartData.reading) {
                    return
                }
                store.dispatch({
                    type: SETREADING,
                    payload: {
                        ...cartData.reading,
                        cartDocId,
                    },
                })
                store.dispatch({
                    type: SETENGINE,
                    payload: {
                        cartDocId,
                        boardId: cartData.boardId,
                        engine: cartData.engine,
                        ts: cartData.reading.timestampReceived
                            .toDate()
                            .getTime(),
                    },
                })
            })
        }
    }, [carts])

    const loadedCourse = !courseDocId || courseLoaded

    if (!loadedCourse) {
        return <CircleLoader fillScreen />
    }

    return props.children
}
