import {
    CHANGECART,
    SELECT_BOOKING,
    UNSELECT_BOOKING,
} from "../constants/actionTypes"

const initialState = {
    selected: false,
    cartDocId: "",
    bookingId: null,
    startTime: null,
    endTime: null,
    status: "",
    name: "",
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SELECT_BOOKING:
            return {
                ...state,
                ...action.payload,
                selected: true,
            }
        case CHANGECART:
            return {
                ...state,
                cartDocId: action.payload.cartDocId,
            }
        case UNSELECT_BOOKING:
            return {
                selected: false,
                cartDocId: "",
                bookingId: null,
                startTime: null,
                endTime: null,
                status: "",
                name: "",
                hourMinString: "",
            }
        default:
            return state
    }
}
