import React from "react"
import { Table, TableBody } from "@mui/material"
import TableItem from "./tableItems/index.js"
import AveragePlayingTimeItem from "./tableItems/averagePlayingTime.js"
import ReservedPlayingTimeItem from "./tableItems/reservedTime.js"
import ReservedPlayingTimeNineHolesItem from "./tableItems/reservedTimeNineHoles.js"
import unlockDurationItem from "./tableItems/unlockTimeItem.js"
import PickUpBuffer from "./tableItems/beforeTeeTime.js"
import OpenTime from "./tableItems/openTime.js"
import CloseTime from "./tableItems/closeTime.js"
import nightLock from "./tableItems/nightLock.js"
import customerFacingVat from "./tableItems/customerFacingVat.js"
import appBookingItem from "./tableItems/appBookingItem.js"
import billingEmail from "./tableItems/billingEmail.js"
import getSettingsRowPermissions from "./settingsPermissions.js"
import stripeSubEmail from "./tableItems/stripeSubEmail.js"
import autoLockItem from "./tableItems/autoLockItem.js"
import ppmEnabled from "./tableItems/ppmEnabledItem.js"
import daysInAdvance from "./tableItems/bookingDaysAdvance.js"
import IntegrationUnlockDurationItem from "./tableItems/integrationUnlockTimeItem.js"
import nineHolesEnabledItem from "./tableItems/nineHolesEnabledItem.js"

export const settingsTableRowItems = [
    {
        component: appBookingItem,
        requires: [],
    },

    {
        component: ppmEnabled,
        requires: [],
    },
    {
        component: nineHolesEnabledItem,
        requires: [],
    },
    {
        component: daysInAdvance,
        requires: [],
    },
    {
        component: PickUpBuffer,
        requires: [1],
    },
    {
        component: unlockDurationItem,
        requires: [2],
    },
    {
        component: IntegrationUnlockDurationItem,
        requires: [1],
    },
    {
        component: AveragePlayingTimeItem,
        requires: [],
    },
    {
        component: ReservedPlayingTimeItem,
        requires: [],
    },
    {
        component: ReservedPlayingTimeNineHolesItem,
        requires: [4],
    },
    {
        component: autoLockItem,
        requires: [],
    },
    {
        component: OpenTime,
        requires: [],
    },
    {
        component: CloseTime,
        requires: [],
    },
    {
        component: nightLock,
        requires: [],
    },
    {
        component: customerFacingVat,
        requires: [],
    },
    {
        component: billingEmail,
        requires: [3],
    },
    {
        component: stripeSubEmail,
        requires: [0, 3],
    },
]

function AccessToSetting(route, security) {
    for (let i = 0; i < route.requires.length; i++) {
        const securityRule = route.requires[i]
        if (i >= security.length || !security[securityRule]) {
            return false
        }
    }
    return true
}

export default function SettingsTable() {
    const permissions = getSettingsRowPermissions()

    const rowsToDisplay = settingsTableRowItems.filter((x) =>
        AccessToSetting(x, permissions)
    )

    return (
        <Table
            size="small"
            padding="none"
            sx={{
                margin: "auto",
                maxWidth: "600px",
                border: "solid rgba(81, 81, 81, 1)",
                borderWidth: "3px 0px 3px 0px",
            }}
        >
            <TableBody>
                {rowsToDisplay
                    .map((x) => x.component)
                    .map((Item, index) => (
                        <TableItem
                            sx={{ mb: 1 }}
                            key={index}
                            title={Item.title}
                            desc={Item.desc}
                        >
                            <Item.Element />
                        </TableItem>
                    ))}
            </TableBody>
        </Table>
    )
}
