export const getLocale = () => {
    if (navigator.languages != undefined) return navigator.languages[0]
    return navigator.language
}

export const dateToHourMinute = (date) => {
    let hour = String(date.getHours())
    let min = String(date.getMinutes())
    if (hour.length === 1) {
        hour = `0${hour}`
    }
    if (min.length === 1) {
        min = `0${min}`
    }
    return `${hour}:${min}`
}
export const dateToHourMinuteSeconds = (timestamp) =>
    `${timestamp.getHours() < 10 ? "0" : ""}${timestamp.getHours()}:${
        timestamp.getMinutes() < 10 ? "0" : ""
    }${timestamp.getMinutes()}:${
        timestamp.getSeconds() < 10 ? "0" : ""
    }${timestamp.getSeconds()}`

export const getHourMinTimerString = (time) => {
    const hours = Math.floor(time / (60 * 60))
    const minutes = Math.floor((time - hours * 60 * 60) / 60)
    const seconds = Math.floor(time - (hours * 60 + minutes) * 60)
    return `${String(hours)}:${intTwoDigitString(minutes)}:${intTwoDigitString(
        seconds
    )}`
}
export const dayMonthYear = (timestamp) =>
    `${timestamp.getDate() < 10 ? "0" : ""}${timestamp.getDate()}/${
        timestamp.getMonth() + 1 < 10 ? "0" : ""
    }${timestamp.getMonth() + 1}/${timestamp.getFullYear()}`

export const timeDate = (timestamp) =>
    `${timestamp.getHours() < 10 ? "0" : ""}${timestamp.getHours()}:${
        timestamp.getMinutes() < 10 ? "0" : ""
    }${timestamp.getMinutes()}:${
        timestamp.getSeconds() < 10 ? "0" : ""
    }${timestamp.getSeconds()} @ ${
        timestamp.getDate() < 10 ? "0" : ""
    }${timestamp.getDate()}/${timestamp.getMonth() + 1 < 10 ? "0" : ""}${
        timestamp.getMonth() + 1
    }/${timestamp.getFullYear()}`

export const dateShort = (timestamp) => {
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    }
    var date = timestamp.toLocaleDateString("en-us", options)
    return date
}

export const timeShort = (timestamp) => {
    if (process.env.REACT_APP_PROJECT_ID == "gsw-db") {
        return `${
            timestamp.getHours() < 10 ? "0" : ""
        }${timestamp.getHours()}:${
            timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()}`
    } else {
        var hours = timestamp.getHours()
        var minutes = timestamp.getMinutes()
        var ampm = hours >= 12 ? "PM" : "AM"
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes
        var strTime = hours + ":" + minutes + " " + ampm + " "
    }
    return strTime
}

export const timeDateShort = (timestamp) => {
    if (process.env.REACT_APP_PROJECT_ID == "gsw-db") {
        return `${
            timestamp.getHours() < 10 ? "0" : ""
        }${timestamp.getHours()}:${
            timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()} - ${
            timestamp.getDate() < 10 ? "0" : ""
        }${timestamp.getDate()}.${timestamp.getMonth() + 1 < 10 ? "0" : ""}${
            timestamp.getMonth() + 1
        }.${timestamp.getFullYear() % 100}`
    } else {
        const options = {
            year: "numeric",
            month: "short",
            day: "numeric",
        }
        var date = timestamp.toLocaleDateString("en-us", options)
        var hours = timestamp.getHours()
        var minutes = timestamp.getMinutes()
        var ampm = hours >= 12 ? "PM" : "AM"
        hours = hours % 12
        hours = hours ? hours : 12 // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes
        var strTime = hours + ":" + minutes + " " + ampm + " " + date
        return strTime
    }
}
export const intTwoDigitString = (time) => String(time < 10 ? `0${time}` : time)

export const getYearMonth = (date) =>
    `${date.getDate() < 10 ? "0" : ""}${date.getDate()}.${
        date.getMonth() < 10 ? "0" : ""
    }${date.getMonth()}.${date.getFullYear()}`

export const getRegionFormatTime = (timestamp) => {
    if (process.env.REACT_APP_PROJECT_ID == "gsw-db") {
        return `${
            timestamp.getHours() < 10 ? "0" : ""
        }${timestamp.getHours()}:${
            timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()} - ${timestamp.getDate() < 10 ? "0" : ""}`
    } else {
        return `${
            timestamp.getHours() < 10 ? "0" : ""
        }${timestamp.getHours()}:${
            timestamp.getMinutes() < 10 ? "0" : ""
        }${timestamp.getMinutes()} - ${timestamp.getDate() < 10 ? "0" : ""}`
    }
}
