import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { Checkbox, Divider, FormControlLabel, Grid } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from "../AvailabilityRebooking/popupAvailability"
import { StatusDelete } from "./statusDeleter"
import {
    statusNotStarted,
    statusStarted,
    statusAdmin,
} from "../../constants/statusTypes"
import store from "../../store"
import {
    UNSELECT_BOOKING,
    UPDATE_BOOKING_VIEW,
} from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const useDialogStyles = makeStyles((theme) => ({
    overlay: {
        top: "20px",
        opacity: 1,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        padding: "2%",
    },
}))

export default function DeleteDialog(props) {
    const [message, setMessage] = useState("")
    const [multiDel, setMultiDel] = useState(false)
    const [recDel, setRecDel] = useState(false)
    const {
        selected,
        cartDocId,
        bookingId,
        startTime,
        endTime,
        status,
        name,
        tlf,
        email,
        hourMinString,
        multiBookingId,
        multiRecurringBookingId,
    } = useSelector((state) => state.bookingSelector)
    const isMultiBooking = multiBookingId && multiBookingId.length
    const isRecurringBooking =
        multiRecurringBookingId && multiRecurringBookingId.length
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })
    const cartId = useSelector((state) => {
        if (Object.keys(state.firestore.data.carts).indexOf(cartDocId) > -1) {
            return state.firestore.data.carts[cartDocId].id
        }
        return null
    })
    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setMessage(value)
    }

    const deleteMultiBookingCall = httpsCallable(
        functions,
        "deleteMultiBooking"
    )
    const callAbleDel = deleteMultiBookingCall

    const initialProgress = {
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    }
    const [currentProgress, setCurrentProgress] = useState(initialProgress)
    const style = useDialogStyles()
    const deleteBooking = () => {
        if (
            Object.values([statusStarted, statusNotStarted]).indexOf(status) >
                -1 &&
            email != null &&
            Object.values(
                [statusAdmin].indexOf(status) > -1 && email.length < 0
            )
        ) {
            if (email.length < 0 && message.length < 10) {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    error: true,
                    errorMessage:
                        "Please enter a descriptive message to the user why the booking was cancelled.",
                })
                new Promise((r) => setTimeout(r, 3000)).then(() => {
                    setCurrentProgress({
                        ...initialProgress,
                    })
                })
                return
            }
            setCurrentProgress({
                ...initialProgress,
            })
        }
        setCurrentProgress({
            ...currentProgress,
            inProgress: true,
        })
        callAbleDel({
            courseDocId,
            bookingDocId: bookingId,
            multi: multiDel,
            recurring: recDel,
            message,
        })
            .then(() => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: true,
                })
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
            })
            .catch((e) => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    error: true,
                    errorMessage: e.message,
                })
            })
        new Promise((r) => setTimeout(r, 2000)).then(() => {
            if (props.deletedBookings) {
                props.setDeletedBookings([...props.deletedBookings, bookingId])
            }
            setCurrentProgress(initialProgress)
            store.dispatch({ type: UNSELECT_BOOKING })
            props.handleClose()
        })
    }
    function CheckboxMultiBookingDelete() {
        if (isMultiBooking) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={multiDel}
                            onChange={() => setMultiDel(!multiDel)}
                            name="multi-delete"
                        />
                    }
                    label="Cancel all bookings in group"
                />
            )
        }
        return null
    }
    function CheckboxRecBookingDelete() {
        if (isRecurringBooking) {
            return (
                <FormControlLabel
                    disabled={!multiDel}
                    control={
                        <Checkbox
                            checked={recDel}
                            onChange={() => setRecDel((del) => !del)}
                            name="rec-delete"
                        />
                    }
                    label="Cancel all recurring bookings"
                />
            )
        }
        return null
    }

    function InformAboutEmail(message) {
        if (
            Object.values([statusStarted, statusNotStarted]).indexOf(status) >
            -1
        ) {
            return (
                <>
                    <p>
                        The user will be informed by email if they have entered
                        a valid email address in the app.
                    </p>
                    <p>The user will not be charged for the booking.</p>
                    <TextField
                        style={{ marginTop: "20px" }}
                        required
                        multiline
                        id="filled-required"
                        label="Cancellation message"
                        value={message}
                        rows={3}
                        name="message"
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        onChange={handleInputChange}
                    />
                </>
            )
        }
        if (
            email != null &&
            Object.values([statusAdmin].indexOf(status) > -1) &&
            email.length > 0
        ) {
            return (
                <>
                    <p>
                        The user will be informed by email if a valid email has
                        been used when creating the booking.
                    </p>

                    <TextField
                        style={{ marginTop: "20px" }}
                        required
                        multiline
                        id="filled-required"
                        label="Cancellation message"
                        value={message}
                        rows={3}
                        name="message"
                        color="secondary"
                        variant="outlined"
                        fullWidth
                        onChange={handleInputChange}
                    />
                </>
            )
        }
        return (
            <p>
                The user will not be informed about the cancellation as a valid
                email has not been added.
            </p>
        )
    }

    return (
        <div>
            <Dialog
                classes={{ paper: style.overlay }}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.handleClose}
                >
                    <Typography variant="inherit" align="center">
                        CANCEL BOOKING
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to manually cancel the booking?{" "}
                        <br />
                        <br />
                        Name: {name} <br />
                        Tlf: {tlf} <br />
                        Email: {email} <br />
                        Vehicle No.: {cartId} <br />
                        Starting Time: {hourMinString}
                        <br />
                        Status: {status} <br />
                    </Typography>
                    {InformAboutEmail(message)}
                    <DialogActions>
                        <Grid
                            container
                            direction="column"
                            alignContent="center"
                        >
                            <Grid item xs>
                                {CheckboxMultiBookingDelete()}
                            </Grid>
                            <Grid item xs>
                                {CheckboxRecBookingDelete()}
                            </Grid>
                            <Grid
                                item
                                style={{ display: "block", width: "100%" }}
                            >
                                <StatusDelete
                                    fullWidth
                                    deleteBooking={deleteBooking}
                                    currentProgress={currentProgress}
                                />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
