import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"

function TabPanel(props) {
    const { children, value, index, horizontal, noPad, ...other } = props
    let padding = 3
    if (noPad) padding = 0
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={
                horizontal
                    ? `scrollable-auto-tabpanel-${index}`
                    : `vertical-tabpanel-${index}`
            }
            aria-labelledby={
                horizontal
                    ? `scrollable-auto-tab-${index}`
                    : `vertical-tab-${index}`
            }
            {...other}
        >
            {value === index && (
                <Box paddingTop={padding} paddingBottom={padding}>
                    {children}
                </Box>
            )}
        </Typography>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    horizontal: PropTypes.any,
    noPad: PropTypes.any,
}

export default TabPanel
