import React, { useEffect, useState } from "react"
import { Alert, AlertTitle } from "@mui/material"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import { useFirebase } from "react-redux-firebase"
import { Box } from "@mui/system"
import { useSettingsFormStyle } from "../../assets/styles/components/settingsForm"
import { golfCourses } from "../../constants/dbPaths"
import { SuccessLoader } from "./loader/successLoader"
import theme from "../../assets/styles/muiTheme"
import {
    SETTALLSETTINGS,
    SET_SETTINGS_SAVED,
} from "../../constants/actionTypes"
import store from "../../store"
import CourseLinkingIdDisplay from "../Permissions/courseLinkingId"
import SegmentSection from "./segmentPrices"
import { wait } from "../TimeUtils"

export default function CoursePricing() {
    const classes = useSettingsFormStyle(theme)
    const settings = useSelector((state) => state.firestore.data.field)
    const fire = useFirebase()
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    const priceAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featPrice?.access ||
            state.firestore.data?.user?.admin
    )

    const initState = {
        ...settings,
    }
    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }
    const form = useSelector((state) => state.settingsForm)

    useEffect(() => {
        if (!form.settingsLoaded) {
            store.dispatch({ type: SETTALLSETTINGS, payload: initState })
        }
    }, [])

    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)

    const handleSubmit = async (e) => {
        e.preventDefault()
        let error = false
        if (!priceAccess) {
            setError("Limited permissions - talk to admin")
            setStatus({ ...status, error: true })
            return
        }
        const currentForm = { ...form }
        Object.values(currentForm.segments).forEach((segment) => {
            Object.values(segment.vehicleGroups).forEach((group) => {
                const fixed = parseFloat(group[0])
                const ppm = parseFloat(group[1])
                if (isNaN(fixed) || isNaN(ppm)) {
                    error = true
                    setError("All prices must be numbers")
                    setStatus({ ...status, loading: false, error: true })
                    wait().then(() => {
                        setError("")
                        setStatus(initStatus)
                    })
                }
            })
        })

        if (error || currentForm.error) {
            return
        }
        // Add for temporary backward compatability
        const classes = Object.values(currentForm.segments).reduce(
            (classes, segment) => {
                return {
                    ...classes,
                    [segment.name]: segment.vehicleGroups["0"],
                }
            },
            {}
        )
        const toSave = {
            segments: currentForm.segments,
            classes,
        }
        delete toSave["savedChanges"]
        delete toSave["settingsLoaded"]
        delete toSave["selectedSegment"]
        setStatus({ ...status, loading: true })

        fire.firestore()
            .collection(golfCourses)
            .doc(courseDocId)
            .update(toSave)
            .then(() => {
                setStatus({ ...status, loading: false, success: true })
                store.dispatch({ type: SET_SETTINGS_SAVED, payload: initState })
            })
            .catch((e) => {
                setStatus({ ...status, error: true, loading: false })
                setError(e.message)
            })
            .then(() => {
                new Promise((r) => setTimeout(r, 3000)).then(() => {
                    setStatus(initStatus)
                    setError("")
                })
            })
    }

    return (
        <div className={classes.root}>
            <Box right={0} sx={{ pr: 2 }} zIndex={1} position="absolute">
                <CourseLinkingIdDisplay />
            </Box>

            <Grid
                container
                sx={{ pt: 3 }}
                direction="column"
                alignItems={"center"}
            >
                <Grid item xs minWidth={"300px"}>
                    {form.savedChanges ? null : (
                        <Alert severity="error">
                            <AlertTitle>Unsaved Changes</AlertTitle>
                            Remember to save!
                        </Alert>
                    )}
                </Grid>
                <Grid item xs sx={{ pt: 8 }} style={{ maxWidth: "100%" }}>
                    <SegmentSection />
                </Grid>
                <Grid item xs minWidth={"300px"} position={"relative"}>
                    <SuccessLoader
                        status={status}
                        noAccess={!priceAccess}
                        error={error}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
