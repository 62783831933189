import React from "react"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import { Grid, Typography } from "@mui/material"
import { Box } from "@mui/system"
import StepperSidebar from "../Components/StepperSidebar"
import SetupFlowController from "../models/setupflowController"
import SetupIntro from "../Components/StepperIntro"
import DetailsFormStepper from "../Components/detailsEditor/detailStepper"
import PropTypes from "prop-types"
import SettingsFormStepper from "../Components/settingsForm/settingStepper"
import StripeOnboarding from "../Components/Stripe/stripeGetOnboarding"
import addInstallerOnboardingView from "../Components/Installer/addInstallerFlowView"
import SetupOutro from "../Components/StepperOutro"
import getSubscriptionType from "../Components/GetSubscriptionType"
import CoursePricing from "../Components/settingsForm/settingsPricePage"

const allSteps = {
    Intro: SetupIntro,
    "Payment Setup": StripeOnboarding,
    Details: DetailsFormStepper,
    Configuration: SettingsFormStepper,
    Pricing: CoursePricing,
    Installation: addInstallerOnboardingView,
    "Before Launch": SetupOutro,
}
const stepsOnlyInFull = ["Installation"]
const allStepsKeys = Object.keys(allSteps)

const getStepComponents = (isBasic) => {
    if (isBasic) {
        return Object.keys(allSteps)
            .filter((x) => stepsOnlyInFull.indexOf(x) === -1)
            .reduce((steps, key) => {
                return {
                    ...steps,
                    [key]: allSteps[key],
                }
            }, {})
    }
    return allSteps
}

function ActiveComponent(props) {
    const { activePage, handleComplete, isBasic } = props
    const stepComponents = getStepComponents(isBasic)
    const steps = Object.keys(stepComponents)
    if (!(activePage in steps)) {
        const Component = stepComponents[steps[0]]
        return <Component handleComplete={handleComplete} />
    }
    const Component = stepComponents[steps[activePage]]
    return <Component handleComplete={handleComplete} />
}

ActiveComponent.propTypes = {
    handleComplete: PropTypes.func.isRequired,
    activePage: PropTypes.number.isRequired,
}

export default function SetupFlow() {
    const navigate = useNavigate()
    const { keyless } = getSubscriptionType()
    console.log("keyless", keyless)
    const stepComponents = getStepComponents(!keyless)
    const steps = Object.keys(stepComponents)
    const setupFlowController = SetupFlowController({ steps })
    const {
        completeCourseSetup,
        handleReset,
        handleComplete,
        handleBack,
        handleNext,
        allStepsCompleted,
        isLastStep,
        completedSteps,
        totalSteps,
        activePage,
        completed,
        setCompleted,
        handleStepPress,
        handleStep,
    } = setupFlowController

    const CompleteAllStep = (prop) => {
        const { completeCourseSetup } = prop

        return (
            <Grid
                container
                direction="column"
                spacing={6}
                alignItems="center"
                justifyContent="center"
                sx={{ heigth: "100%", m: 4 }}
            >
                <Grid item xs>
                    <Typography variant="h5">
                        Congratulations on finishing the onboarding setup!
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">
                        Take some time getting familiar with the site! Please
                        contact us at <strong> clubsupport@eagl.app</strong> if
                        you have any questions.
                    </Typography>
                </Grid>
                <Grid item xs>
                    <CompleteButton completeCourseSetup={completeCourseSetup} />
                </Grid>
            </Grid>
        )
    }
    const CompleteButton = (prop) => {
        const { completeCourseSetup } = prop
        return (
            <Button
                color="secondary"
                label="submit"
                maring="normal"
                variant="contained"
                onClick={completeCourseSetup}
            >
                Continue
            </Button>
        )
    }
    return (
        <Box sx={{ padding: "24px", height: "100vh" }}>
            <Typography sx={{ mt: 2, mb: 1 }} variant="h3">
                Onboarding Setup
            </Typography>
            <Typography sx={{ mt: 2, mb: 1 }} variant="h5">
                {activePage >= steps.length
                    ? "Step: Finished onboarding!"
                    : `Step ${activePage + 1} : ${steps[activePage]}`}
            </Typography>
            <Grid container direction={"row"} sx={{ height: "80%" }}>
                <Grid item xs>
                    {allStepsCompleted && activePage >= steps.length ? (
                        <CompleteAllStep
                            completeCourseSetup={completeCourseSetup}
                        />
                    ) : (
                        <>
                            <ActiveComponent
                                isBasic={!keyless}
                                activePage={activePage}
                                handleComplete={handleComplete}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                }}
                            >
                                <Button
                                    color="primary"
                                    disabled={activePage === 0}
                                    variant="contained"
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                >
                                    Back
                                </Button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button
                                    onClick={handleNext}
                                    variant="contained"
                                    color="secondary"
                                    sx={{ mr: 1 }}
                                >
                                    Next
                                </Button>
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item xs={3} style={{ maxWidth: "200px" }}>
                    <StepperSidebar
                        {...{
                            activePage,
                            steps,
                            completed,
                            handleStepPress,
                            handleBack,
                            handleNext,
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}
