import React, { useEffect, useState } from "react"
import {
    Box,
    Grid,
    LinearProgress,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import store from "../../store"
import { SELECTAVCART } from "../../constants/actionTypes"
import PopupAvailability from "../AvailabilityRebooking/popupAvailability"
import { getAvailabilityAccess } from "../../constants/accessPermissionsChecks"

export default function AvailabilitySwitch(props) {
    const { vehicle } = props
    const [available, setAvailable] = useState(true)
    const { cartDocId, boardId, id } = vehicle

    const cart = useSelector(
        (state) => state.firestore.data?.carts?.[cartDocId]
    )
    useEffect(() => {
        if (cart) {
            setAvailable(cart.available)
        }
    }, [cartDocId, cart])

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const canChangeAv = getAvailabilityAccess()

    const cartLoader = useSelector((state) => state.cartLoading)
    const avLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        cartLoader[cartDocId].avLoading

    const handleChange = (event) => {
        event.preventDefault()
        if (!canChangeAv) {
            return
        }
        store.dispatch({
            type: SELECTAVCART,
            payload: {
                boardId,
                cartDocId,
                id,
                availability: available,
                courseDocId,
            },
        })
    }

    return (
        <Box sx={{ margin: 1, position: "relative" }}>
            <PopupAvailability />
            {avLoading(cartDocId) && <LinearProgress color="secondary" />}
            {canChangeAv ? null : (
                <Tooltip
                    placement="right"
                    title={"Limited permissions - talk to admin"}
                >
                    <Box
                        sx={{
                            // border: "1px solid", // Use to check where the helper box is
                            position: "absolute",
                            width: "100%",
                            top: "-5px",
                            height: "50px",
                        }}
                    ></Box>
                </Tooltip>
            )}
            <Grid
                container
                component="label"
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{ width: "140px" }}
                textAlign={"center"}
            >
                <Grid item xs sx={{ margin: 0 }}>
                    <Typography fontWeight={"300"}>{"NO"}</Typography>
                </Grid>
                <Grid item xs sx={{ margin: 0 }}>
                    <Switch
                        checked={available || false}
                        onChange={handleChange}
                        color="secondary"
                        disabled={avLoading(cartDocId) || !canChangeAv || false}
                        name={String(id)}
                        align="right"
                        inputProps={{
                            "aria-label": "primary checkbox",
                        }}
                    />
                </Grid>
                <Grid item xs sx={{ margin: 0 }}>
                    <Typography fontWeight={"300"}>{"YES"}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
