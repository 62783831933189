import React, { useEffect } from "react"
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { useSelector } from "react-redux"
import store from "../../store"
import {
    SET_ALL_CARTS_TO_INSTALL,
    SET_ALL_CARTS_INSTALL_STATUS,
    SET_CARTID_TO_DOC_ID,
} from "../../constants/actionTypes"
import { Route, Routes } from "react-router-dom"
import InstallCartView from "./installCartView"
import InstallIntroPage from "./introPage"
import InstallOverview from "./installOverview"
import InstallFinishedPage from "./finishPage"

export const NotInstalledIcon = () => <CancelIcon color="error" />
export const InstalledIcon = () => <CheckCircleIcon color="success" />

export default function InstallationMenu(props) {
    const cartsFromCourse = useSelector(
        (state) =>
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("carts") > -1 &&
            state.firestore.data.carts
    )
    const cartIds = useSelector((state) => {
        const cartsDocIds =
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("carts") > -1 &&
            state.firestore.data.carts
        if (cartsDocIds) {
            return Object.keys(cartsDocIds).reduce(
                (obj, cartDocId) => ({
                    ...obj,
                    [state.firestore.data.carts[cartDocId].boardId]: cartDocId,
                }),
                {}
            )
        }
        return []
    })
    useEffect(() => {
        if (cartsFromCourse) {
            const cartDocIds = Object.keys(cartsFromCourse)
            const cartsCompleted = cartDocIds.reduce((obj, key) => {
                const isOnline =
                    "lockStatus" in cartsFromCourse[key] &&
                    cartsFromCourse[key].lockStatus === 201
                obj[key] = isOnline
                return obj
            }, {})
            store.dispatch({
                type: SET_ALL_CARTS_TO_INSTALL,
                payload: { cartsToComplete: cartsFromCourse },
            })
            store.dispatch({
                type: SET_ALL_CARTS_INSTALL_STATUS,
                payload: { cartsComplete: cartsCompleted },
            })
            store.dispatch({
                type: SET_CARTID_TO_DOC_ID,
                payload: { cartIdToDocId: cartIds },
            })
        }
    }, [])

    return (
        <Routes>
            <Route path="/" element={<InstallIntroPage />} />
            <Route path="cart/:cartId" element={<InstallCartView />} />
            <Route path="overview" element={<InstallOverview />} />
            <Route path="finished" element={<InstallFinishedPage />} />
        </Routes>
    )
}

InstallationMenu.propTypes = {}
