import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import CircleLoader from "../loader"

export function StatusBooker(props) {
    if (props.currentProgress.error) {
        return (
            <Typography variant="h6" align="center" color="error">
                {props.currentProgress.errorMessage}
            </Typography>
        )
    }
    if (props.currentProgress.success) {
        return (
            <div height="calc(100% - 10px)">
                <Typography variant="h6" align="center" marginTop="10px">
                    Success!
                </Typography>
            </div>
        )
    }
    if (props.currentProgress.inProgress) {
        return (
            <div height="calc(100% - 10px)">
                <CircleLoader />
            </div>
        )
    }
    return (
        <Button
            variant="contained"
            color="secondary"
            disabled={props.disabled}
            style={{
                marginTop: "10px",
                alignSelf: "center",
                display: "flex",
                width: "100%",
                height: "calc(100% - 10px)",
            }}
            onClick={props.doBooking}
        >
            {props.defaultText}
        </Button>
    )
}
