import React from "react"
import {
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Table,
    Grid,
    Switch,
} from "@mui/material"
import { styled } from "@mui/styles"
import { useSelector } from "react-redux"
import PowerOnSwitch from "../../PowerOnSwitch"
import { dateShort, timeShort } from "../../dateTools/index.js"
import PingFunction from "../../PowerOnSwitch/ping"
import { ReactComponent as KeylessIcon } from "../../../assets/img/keylessIcon.svg"
import { ReactComponent as BatteryIcon } from "../../../assets/img/batteryIcon.svg"
import theme from "../../../assets/styles/muiTheme"

const TableStyled = styled(Table)(({ theme }) => ({
    height: "40px",
    marginBottom: theme.spacing(1),
}))
const TableBodyStyled = styled(TableBody)(({ theme }) => ({
    width: "100%",
    height: "40px",
    borderCollapse: "collapse",
    borderSpacing: 0,
    display: "table",
    tableLayout: "fixed",
}))
const TableRowStyled = styled(TableRow)(({ theme }) => ({
    border: "none",
}))
const TableCellStyle = styled(TableCell)(({ theme }) => ({
    border: "none",
    padding: 0,
    height: "100%",
    textAlign: "center",
    verticalAlign: "center",
}))
const TableCellContent = styled("div")(() => ({
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    wordWrap: "anywhere",
}))

const TableCellStyled = ({ colSpan, children }) => (
    <TableCellStyle colSpan={colSpan}>
        <TableCellContent>{children}</TableCellContent>
    </TableCellStyle>
)

const defaultDot = (size) => ({
    height: size + "px",
    width: size + "px",
    borderRadius: "10px",
    margin: "4px",
    border: "1px",
    borderStyle: "solid",
    borderColor: "black",
})

const RedDot = (prop) => {
    const { size = 12 } = prop
    const Dot = styled("div")(({ theme }) => ({
        ...defaultDot(size),
        backgroundColor: theme.palette.red.light,
    }))
    return <Dot />
}

const GreenDot = (prop) => {
    const { size = 12 } = prop
    const Dot = styled("div")(({ theme }) => ({
        ...defaultDot(size),
        backgroundColor: theme.palette.green.main,
    }))
    return <Dot />
}

export const DotSwitcher = (prop) => {
    const { isAvailable, size } = prop
    const sizeProp = size ?? 12
    if (isAvailable) {
        return <GreenDot size={sizeProp} />
    }
    return <RedDot size={sizeProp} />
}

export const ReadingChecker = (props) => {
    const { readingTs } = props
    const hasReadingLastHour =
        readingTs && readingTs.getTime() + 60 * 60 * 1000 > Date.now()
    return (
        <Box display={"flex"} width="100%" alignItems={"center"}>
            <DotSwitcher isAvailable={hasReadingLastHour} />
            <Grid container direction="column" alignItems="flex-end">
                <Grid>{readingTs ? timeShort(readingTs) : ""}</Grid>
                <Grid item xs>
                    {readingTs ? dateShort(readingTs) : "never"}
                </Grid>
            </Grid>
        </Box>
    )
}

const DisabledTypography = (props) => {
    const { children } = props

    return (
        <Typography display={"inline"} fontSize={"9pt"} color="#DCDCDCE0">
            {children}
        </Typography>
    )
}
const UpgradeFont = (props) => {
    const { children, href } = props
    return (
        <Typography
            color="secondary"
            href={href}
            component="a"
            display={"inline"}
            fontSize={"9pt"}
            sx={{ textDecoration: "underline" }}
        >
            {children}
        </Typography>
    )
}

const NoBoxRow = (props) => {
    const { keylessCourse } = props
    return (
        <TableStyled>
            <TableBodyStyled>
                <TableRowStyled>
                    <TableCellStyled colSpan={2}>
                        <Grid
                            container
                            direction={"column"}
                            alignItems="flex-start"
                        >
                            <Grid
                                item
                                xs
                                width={"100%"}
                                style={{ display: "flex" }}
                            >
                                <KeylessIcon
                                    width={"12px"}
                                    height={"10px"}
                                    style={{ margin: "4px" }}
                                    fill={theme.palette.red.light}
                                />
                                <DisabledTypography>
                                    Keyless: No signal
                                </DisabledTypography>
                            </Grid>
                            <Grid
                                item
                                xs
                                width={"100%"}
                                style={{ display: "flex" }}
                            >
                                <RedDot />
                                <DisabledTypography>
                                    GPS: No signal
                                </DisabledTypography>
                            </Grid>
                            <Grid
                                item
                                xs
                                width={"100%"}
                                style={{ display: "flex" }}
                            >
                                <BatteryIcon
                                    width={"12px"}
                                    height={"10px"}
                                    style={{ margin: "4px" }}
                                    fill={theme.palette.red.light}
                                />
                                <DisabledTypography>
                                    Battery: No signal
                                </DisabledTypography>
                            </Grid>
                        </Grid>
                    </TableCellStyled>
                    <TableCellStyled>
                        <Box>
                            <Switch disabled />
                            {keylessCourse ? (
                                <UpgradeFont href="/">Add Smartbox</UpgradeFont>
                            ) : (
                                <UpgradeFont href="/upgrade">
                                    Upgrade plan
                                </UpgradeFont>
                            )}
                        </Box>
                    </TableCellStyled>
                </TableRowStyled>
            </TableBodyStyled>
        </TableStyled>
    )
}

export default function ReadingRow(props) {
    const { cartDocId } = props

    const bid = useSelector(
        (state) => state.firestore?.data?.carts?.[cartDocId]?.boardId
    )

    const keyless = useSelector(
        (state) => state.firestore?.data?.carts?.[cartDocId]?.keyless
    )

    const keylessCourse = useSelector(
        (state) => state.firestore?.data?.field?.keyless
    )

    const reading = useSelector(
        (state) => state.firestore?.data?.carts?.[cartDocId]?.reading
    )

    if (!keyless) {
        return <NoBoxRow keylessCourse={keylessCourse} />
    }
    const currentBooking = useSelector(
        (state) => state.cartReadings?.[cartDocId]?.booking || {}
    )

    return (
        <TableStyled>
            <TableBodyStyled>
                <TableRowStyled>
                    <TableCellStyled colSpan={2}>
                        <PowerOnSwitch
                            vehicle={{
                                boardId: bid,
                                cartDocId,
                            }}
                            width="120px"
                            fontSize="10pt"
                            fontWeight="400"
                            currentBooking={currentBooking}
                        />
                    </TableCellStyled>
                    <TableCellStyled>
                        <Typography fontSize={"14pt"} fontWeight={1000}>
                            {reading?.VLT ? reading?.VLT : "- "}V
                        </Typography>
                    </TableCellStyled>
                </TableRowStyled>
                <TableRowStyled>
                    <TableCellStyled colSpan={2}>
                        <ReadingChecker
                            readingTs={
                                reading?.timestampReceived?.toDate() || 0
                            }
                        />
                    </TableCellStyled>
                    <TableCellStyled>
                        <PingFunction vehicle={{ cartDocId, boardId: bid }} />
                    </TableCellStyled>
                </TableRowStyled>
            </TableBodyStyled>
        </TableStyled>
    )
}
