import React, { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import Button from "@mui/material/Button"
import { useSelector } from "react-redux"
import { httpsCallable } from "firebase/functions"
import { bookingInProgress } from "../AvailabilityRebooking/bookingAvList"
import store from "../../store"
import { CHANGECART, UPDATE_BOOKING_VIEW } from "../../constants/actionTypes"
import BookingCartDropdown from "../bookingCartsSelector"
import useAppStyle from "../../assets/styles/App"
import CircleLoader from "../loader"
import { functions } from "../../rootWrapper/firebase"

export default function RebookButton(props) {
    const [loading, setLoading] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [isRebooked, setIsRebooked] = useState(false)
    const [error, setError] = useState("")
    const [isPressed, setIsPressed] = useState(false)
    const [cartDocIdSelected, setCartDocIdSelected] = useState("")

    const { selected, cartDocId, bookingId, startTime, endTime, status } =
        useSelector((state) => state.bookingSelector)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })

    const rebookCartCallable = httpsCallable(functions, "rebookCartOfBooking")
    const rebookCart = () => {
        setLoading(true)
        rebookCartCallable({
            bookingDocId: bookingId,
            courseDocId,
            cartDocId: cartDocIdSelected,
        })
            .then((res) => {
                const rebookedCart = res.data.cartDocId
                store.dispatch({
                    type: CHANGECART,
                    payload: { cartDocId: rebookedCart },
                })
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
                setIsRebooked(true)
                setLoading(false)
            })
            .catch((e) => {
                console.error(e.message)
                setError(e.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (bookingInProgress(status)) {
            setInProgress(true)
            setLoading(false)
        }
    }, [status])

    const cartId = useSelector((state) => {
        if (
            Object.keys(state.firestore.data.carts).indexOf(
                state.bookingSelector.cartDocId
            ) > -1
        ) {
            return state.firestore.data.carts[state.bookingSelector.cartDocId]
                .id
        }
        return null
    })

    if (loading) {
        return <CircleLoader />
    }
    if (inProgress) {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                style={{
                    marginTop: 10,
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                }}
            >
                Booking in progress
            </Button>
        )
    }
    if (error !== "") {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                style={{
                    marginTop: 10,
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                }}
            >
                {error}
            </Button>
        )
    }
    if (isRebooked) {
        return (
            <Button
                variant="contained"
                color="secondary"
                disabled
                style={{
                    marginTop: 10,
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                }}
                onClick={rebookCart}
            >
                {`Rebooked to cart ${cartId}`}
            </Button>
        )
    }
    if (isPressed) {
        return (
            <div>
                <BookingCartDropdown
                    selectMulti={false}
                    dropdown={cartDocIdSelected}
                    setDropdown={setCartDocIdSelected}
                />
                <Button
                    variant="contained"
                    color="secondary"
                    style={{
                        marginTop: 10,
                        alignSelf: "center",
                        display: "flex",
                        width: "100%",
                    }}
                    onClick={rebookCart}
                >
                    Confirm Cart
                </Button>
            </div>
        )
    }
    return (
        <Button
            variant="contained"
            color="secondary"
            style={{
                marginTop: 10,
                alignSelf: "center",
                display: "flex",
                width: "100%",
                height: "calc(100% - 10px)",
            }}
            onClick={() => setIsPressed(true)}
        >
            CHANGE VEHICLE
        </Button>
    )
}
