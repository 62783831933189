import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import getSubscriptionStatus from "../../Components/SubscriptionStatus"
import SubPayouts from "./subPayouts"
import StripeAccessLink from "../../Components/StripeAccessLink"

export default function NoSubBilling() {
    const { subscriptionStatus, subData, subscription } =
        getSubscriptionStatus()

    useEffect(() => {
        console.log(subscriptionStatus)
        console.log(subData)
    }, [subscription, subData])

    return (
        <Box sx={{ p: 4 }}>
            <Grid container direction="column" spacing={4}>
                <Grid item xs>
                    <SubPayouts subData={subData} />
                </Grid>
                <Grid item xs>
                    <StripeAccessLink />
                </Grid>
            </Grid>
        </Box>
    )
}
