import { InputAdornment, TextField } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SET_PERCENTAGE_VAT } from "../../../../constants/actionTypes"
import store from "../../../../store"

const desc = "The customer facing VAT, to be used for app customers"
const title = "Customer VAT"

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const vat =
        isNaN(currentForm?.costStructure?.percentageVat) ||
        currentForm?.costStructure?.percentageVat === ""
            ? ""
            : Number(
                  parseFloat(
                      currentForm?.costStructure?.percentageVat * 100
                  ).toFixed(2)
              )
    return vat + " %"
}
function CustomerFacingVATItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    const handleInputChange = (e) => {
        const value = e?.target?.value
        if (!isNaN(value)) {
            if (Number.isInteger(value) || Number(value)) {
                let valueToSend = Number(value)
                if (valueToSend >= 1) {
                    valueToSend = valueToSend / 100
                }
                valueToSend = Number(valueToSend.toFixed(4))
                store.dispatch({
                    type: SET_PERCENTAGE_VAT,
                    payload: {
                        key: "percentageVat",
                        value: Math.min(valueToSend, 1),
                    },
                })
            } else {
                store.dispatch({
                    type: SET_PERCENTAGE_VAT,
                    payload: { key: "percentageVat", value },
                })
            }
        }
    }

    return (
        <TextField
            id="standard"
            label="Percentage VAT"
            value={
                isNaN(currentForm?.costStructure?.percentageVat) ||
                currentForm?.costStructure?.percentageVat === ""
                    ? ""
                    : Number(
                          parseFloat(
                              currentForm?.costStructure?.percentageVat * 100
                          ).toFixed(2)
                      )
            }
            name="standard"
            size="small"
            variant="standard"
            type="number"
            onChange={handleInputChange}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            fullWidth
            margin="normal"
            inputProps={{
                min: 1,
                max: 100,
                step: 0.1,
            }}
            InputLabelProps={{
                shrink: true,
            }}
        />
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: CustomerFacingVATItem,
}
