import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import { Alert, AlertTitle, Typography } from "@mui/material"
import { useSettingsFormStyle } from "../../assets/styles/components/settingsForm"
import store from "../../store"
import { SET_APPINFO_ALL, SET_APPINFO_FIELD } from "../../constants/actionTypes"
import AppInfoCard from "./appInfoCard"
import frontpage from "../../assets/img/frontpage.jpg"
import endRidePage from "../../assets/img/endRidePage.jpg"
import bookingPage from "../../assets/img/bookingPage.jpg"
import ridePage from "../../assets/img/ridePage.jpg"
import termsOfService from "../../assets/img/termsOfService.jpg"

export default function AppInfoForm() {
    const classes = useSettingsFormStyle()
    const currentForm = useSelector((state) => state.appInfo)

    const appInfo = useSelector((state) => {
        const { field } = state.firestore.data
        return {
            ...field.appInfo,
            frontPage: currentForm.frontPage || field.appInfo?.frontPage || "",
            ridePage: currentForm.ridePage || field.appInfo?.ridePage || "",
            bookingPage:
                currentForm.bookingPage || field.appInfo?.bookingPage || "",
            endRidePage:
                currentForm.endRidePage || field.appInfo?.endRidePage || "",
            termsOfService:
                currentForm.termsOfService ||
                field.appInfo?.termsOfService ||
                "",
        }
    })
    const initState = {
        frontPage: appInfo.frontPage,
        ridePage: appInfo.ridePage,
        bookingPage: appInfo.bookingPage,
        endRidePage: appInfo.endRidePage,
        termsOfService: appInfo.termsOfService,
    }
    useEffect(() => {
        store.dispatch({ type: SET_APPINFO_ALL, payload: initState })
    }, [])

    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        store.dispatch({
            type: SET_APPINFO_FIELD,
            payload: { key: name, value },
        })
    }

    return (
        <div className={classes.root} style={{ margin: "8px" }}>
            <Grid container alignItems="center" direction="column" spacing={4}>
                <Grid item xs>
                    <Typography variant="h4">
                        Write custom messages to the users in the app
                    </Typography>
                    {!currentForm.unsavedChanges ? null : (
                        <Alert severity="error">
                            <AlertTitle>Unsaved Changes</AlertTitle>
                            Remember to save!
                        </Alert>
                    )}
                </Grid>
                <Grid item xs>
                    <AppInfoCard
                        infoText={"Front Page"}
                        label={""}
                        placeholder={
                            "Information displayed to players prior to their booking. Example: The golf carts are parked in the garage under the proshop."
                        }
                        name="frontPage"
                        text={currentForm.frontPage}
                        setText={handleInputChange}
                        image={frontpage}
                    />
                </Grid>
                <Grid item xs>
                    <AppInfoCard
                        infoText={"Booked ride page"}
                        label={""}
                        placeholder={
                            "The carts can be found in the garage behind the pro-shop. Make sure the cart has sufficient battery before starting the ride."
                        }
                        name="bookingPage"
                        text={currentForm.bookingPage}
                        setText={handleInputChange}
                        image={bookingPage}
                    />
                </Grid>
                <Grid item xs>
                    <AppInfoCard
                        infoText={"During Ride"}
                        label={""}
                        placeholder={
                            "Information displayed to players during the ride. Example: maintenance work on hole 16 - Keep the carts on path."
                        }
                        name="ridePage"
                        text={currentForm.ridePage}
                        setText={handleInputChange}
                        image={ridePage}
                    />
                </Grid>
                <Grid item xs>
                    <AppInfoCard
                        infoText={"Ending Ride"}
                        label={""}
                        placeholder={
                            "Information displayed to players after they have ended their ride. Example: Thank you for the ride. Visit the café for the best waffles in the world"
                        }
                        name="endRidePage"
                        text={currentForm.endRidePage}
                        setText={handleInputChange}
                        image={endRidePage}
                    />
                </Grid>
                <Grid item xs>
                    <AppInfoCard
                        infoText={"Terms of Service"}
                        label={""}
                        placeholder={
                            "Information displayed to players which they need to accept prior to booking a vehicle. Will be hidden to users if left empty. Example: All drivers needs to be over 18 and carry a valid drivers license"
                        }
                        name="termsOfService"
                        text={currentForm.termsOfService}
                        setText={handleInputChange}
                        image={termsOfService}
                        explination="The text you enter in this field activates a pop-up with a checkbox that appears before payment step in EAGL app.
                                With no text, the 'Terms of service' pop-up will not appear."
                    />
                </Grid>
            </Grid>
        </div>
    )
}
