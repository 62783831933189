import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up("md")]: {
            width: "calc(100vw - 270px)",
        },
        height: "100vh",
        display: "inherit",
        paddingTop: `${20}px`,
        paddingRight: `${10}px`,
        overflowX: "hidden",
        backgroundColor: theme.palette.primary.dark,
    },

    grid: {
        margin: 0,
        width: "inherit",
    },
    cal: {
        padding: theme.spacing(2),
        height: "100%",
        width: "100%",
    },

    sidebarheader: {
        textAlign: "center",
    },
    topbarheader: {
        textAlign: "center",
        marginTop: "10%",
        marginBottom: "10%",
    },
    item: {
        color: theme.palette.primary.main,
        borderStyle: "solid",
        borderWidth: 0,
        fontWeight: "bold",
    },
}))
