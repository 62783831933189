import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Button, Typography } from "@mui/material"
import CircleLoader from "../../loader/index"
import {
    SELECT_BOOKING,
    UPDATE_BOOKING_VIEW,
} from "../../../constants/actionTypes"
import store from "../../../store"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../../rootWrapper/firebase"
import theme from "../../../assets/styles/muiTheme"

export default function BookingStartEndControll() {
    const {
        cartDocId,
        bookingId,
        bookingType,
        startTime,
        endTime,
        dashStatus,
        status,
    } = useSelector((state) => state.bookingSelector)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const engine = useSelector((state) =>
        Boolean(state.firestore?.data?.carts?.[cartDocId]?.engine)
    )
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })

    const startAdminBooking = httpsCallable(functions, "startAdminBooking")
    const endAdminBooking = httpsCallable(functions, "endAdminBooking")

    useEffect(() => {
        if (error !== "") {
            new Promise((res) => {
                setTimeout(res, 3000)
            }).then(() => {
                setError("")
            })
        }
        return () => clearTimeout()
    }, [error !== ""])

    const startBooking = async () => {
        setLoading(true)
        console.log(true, courseDocId, cartDocId, bookingId)
        try {
            await startAdminBooking({
                courseDocId,
                bookingDocId: bookingId,
            })
            store.dispatch({
                type: SELECT_BOOKING,
                payload: { dashStatus: "started" },
            })
            store.dispatch({
                type: UPDATE_BOOKING_VIEW,
            })
        } catch (e) {
            console.error(e.message)
            setError(e.message)
        } finally {
            setLoading(false)
        }
    }
    const endBooking = async () => {
        setLoading(true)
        try {
            await endAdminBooking({
                courseDocId,
                bookingDocId: bookingId,
            })
            store.dispatch({
                type: SELECT_BOOKING,
                payload: { dashStatus: "ended" },
            })
            store.dispatch({
                type: UPDATE_BOOKING_VIEW,
            })
        } catch (e) {
            console.error(e.message)
        } finally {
            setLoading(false)
        }
    }

    if (loading) {
        return <CircleLoader />
    }
    if (error !== "") {
        return (
            <div>
                <Typography
                    sx={{
                        color: theme.palette.error.main,
                        textAlign: "center",
                    }}
                >
                    {error}
                </Typography>
            </div>
        )
    }

    const ActionButtons = () => {
        if (
            status === "not_started" ||
            (bookingType === "admin" &&
                ((dashStatus !== "ended" && dashStatus !== "started") ||
                    dashStatus === undefined) &&
                !engine)
        ) {
            return (
                <Button
                    onClick={startBooking}
                    disabled={
                        startTime > Date.now() ||
                        dashStatus === "ended" ||
                        endTime < Date.now()
                    }
                    style={{
                        marginTop: "10px",
                        alignSelf: "center",
                        display: "flex",
                        width: "100%",
                        height: "calc(100% - 10px)",
                    }}
                    variant="contained"
                    color="secondary"
                >
                    Start Booking
                </Button>
            )
        } else {
            return (
                <Button
                    onClick={endBooking}
                    disabled={
                        endTime < Date.now() ||
                        dashStatus === "ended" ||
                        status === "ended"
                    }
                    style={{
                        marginTop: "10px",
                        alignSelf: "center",
                        display: "flex",
                        width: "100%",
                        height: "calc(100% - 10px)",
                    }}
                    variant="contained"
                    color="secondary"
                >
                    End Booking
                </Button>
            )
        }
    }
    return (
        <>
            <ActionButtons />
        </>
    )
}
