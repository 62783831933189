import makeStyles from "@mui/styles/makeStyles"

export const progressStyle = makeStyles((theme) => ({
    container: {
        height: `${30}px`,
        width: "100%",
        borderRadius: `${50}px`,
    },
    filler: {
        height: "100%",
        width: (props) =>
            `calc(${props.completed >= 100 ? 100 : props.completed}% - 60px)`,
        background: (props) =>
            `linear-gradient(to right,${props.bgcolor},${props.lgcolor})`,
        textAlign: "right",
        borderRadius: "inherit",
        display: "inline-block",
    },

    label: {
        paddingLeft: "16px",
        display: "inline-block",
        padding: `${5}px`,
        color: "white",
        fontWeight: "bold",
        fontSize: `${16}px`,
        margin: "auto",
        verticalAlign: "top",
    },
}))
