import React from "react"
import Button from "@mui/material/Button"
import { Dialog, DialogContentText } from "@mui/material"
import { DialogTitle } from "../AvailabilityRebooking/popupAvailability"
import { DialogActions } from "../DialogTitle/dialogAction"
import { DialogContent } from "../DialogTitle/dialogContent"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"

export default function ConfirmDialog(props) {
    const styles = useDialogStyles()
    const { open, handleClose, helperText, val } = props
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={styles.overlay}
            classes={{ paper: styles.overlay }}
        >
            <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {helperText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => handleClose(false)}>
                    No
                </Button>
                <Button
                    onClick={() => handleClose(true, val)}
                    color="secondary"
                    autoFocus
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}
