import React, { useCallback, useEffect, useState } from "react"

import Timeline, {
    CustomMarker,
    DateHeader,
    SidebarHeader,
    TimelineHeaders,
    defaultHeaderLabelFormats,
    defaultSubHeaderLabelFormats,
} from "react-calendar-timeline"
import theme from "../../assets/styles/muiTheme"
import {
    SELECT_BOOKING,
    SET_BOOKING_VIEW_START,
    SET_CART_TRIPS_BOOKING,
    UPDATE_BOOKING_VIEW,
} from "../../constants/actionTypes"
import store from "../../store"
import { useStyles } from "../../assets/styles/views/booking"
import HeaderText from "./headerText"
import { oneDayInMs } from "../../views/booking"
import { useSelector } from "react-redux"
import { groupRenderer } from "./groupRender"
import {
    statusAdmin,
    statusBookingTrips,
    statusMaintenance,
    validStatus,
} from "../../constants/statusTypes"
import {
    getCouponCode,
    getEndTime,
    getIsOnOvertime,
    getPrice,
    hasPayed,
} from "../Booking/bookingUtils"
import { dateToHourMinute } from "../dateTools"
import getTlfFromBooking from "../Booking/helperfunctions/getTlfFromBooking"
import getNameFromBooking from "../Booking/helperfunctions/getNameFromBooking"
const overTimeWariningText = () => (
    <span style={{ color: "red" }}>OVERTIME</span>
)

export const sortCarts = (a, b) => {
    if (a.vehicleGroup !== b.vehicleGroup) {
        return a.vehicleGroup - b.vehicleGroup
    }
    const aSortKey = a.sortKey === undefined ? a.id : a.sortKey
    const bSortKey = b.sortKey === undefined ? b.id : b.sortKey
    return String(aSortKey).localeCompare(String(bSortKey), "en", {
        numeric: true,
    })
}

function BookingTimeline(props) {
    const { handleClickOpenNewBooking, handleOpenRain } = props
    const classes = useStyles(theme)
    const [nowTime, setTime] = useState(Date.now())
    const { startTime, endTime, updateNumber, bookingsLoading } = useSelector(
        (state) => state.bookingView
    )

    useEffect(() => {
        const timeout = setTimeout(() => {
            setTime(nowTime + 60000)
        }, 60000)
        store.dispatch({
            type: UPDATE_BOOKING_VIEW,
        })
        return () => clearTimeout(timeout)
    }, [nowTime])

    const addOneDay = () => {
        store.dispatch({
            type: SET_BOOKING_VIEW_START,
            payload: { startTime: startTime + oneDayInMs },
        })
    }
    const removeOneDay = () => {
        store.dispatch({
            type: SET_BOOKING_VIEW_START,
            payload: { startTime: startTime - oneDayInMs },
        })
    }
    const cartState = useSelector((state) => state.cartReadings)
    const engineValue = (cartDocId) => {
        if (Object.keys(cartState).indexOf(cartDocId) > -1) {
            return cartState[cartDocId].engineComp
        }
        return false
    }
    const getColor = (doc) => {
        if (doc.status === statusAdmin) {
            return "#00aaff"
        }
        if (doc.status === statusMaintenance) {
            return "#99999b"
        }
        return theme.palette.secondary.main
    }

    const itemsSelector = useSelector((state) => {
        if (state.bookingView.bookings.length) {
            return state.bookingView.bookings
                .filter(
                    (x) =>
                        x.status === null || validStatus.indexOf(x.status) > -1
                )
                .filter((x) => getEndTime(x).toMillis() > startTime) // + 60 * 60 * 24 * 1000 - 6 * 60 * 60 * 1000
                .filter((x) => x.startTime.toMillis() < endTime)
                .map((doc) => {
                    const name = getNameFromBooking(doc)
                    const price = getPrice(doc)
                    const hourMinString = dateToHourMinute(
                        doc.startTime.toDate()
                    )
                    const endTime = getEndTime(doc)
                    const color = getColor(doc)
                    const tlf = getTlfFromBooking(doc)
                    const couponCode = getCouponCode(doc)
                    const isOnOvertime = getIsOnOvertime(doc)
                    const title = `${hourMinString} - ${name}`
                    return {
                        ...doc,
                        id: doc.id,
                        title,
                        name,
                        tlf,
                        couponCode,
                        isOnOvertime,
                        hasPayed: hasPayed(doc),
                        hourMinString,
                        group: doc.cartDocId,
                        status: doc.status,
                        price,
                        start_time: doc.startTime.toDate(),
                        end_time: endTime.toDate(),
                        endTime,
                        canMove: false,
                        canResize: false,
                        color,
                        selectedBgColor: color,
                        bgColor: color,
                    }
                })
        }
        return [{}]
    })
    const [items, setItems] = useState(itemsSelector)

    useEffect(() => {
        setItems(itemsSelector)
        console.log(itemsSelector)
        console.log(
            "New bookings in booking timeline",
            startTime,
            itemsSelector.length,
            updateNumber
        )
    }, [startTime, itemsSelector.length, updateNumber, bookingsLoading])

    useEffect(() => {
        if (itemsSelector.length) {
            const bookingCount = itemsSelector
                .filter((x) => statusBookingTrips.indexOf(x.status) > -1)
                .filter((x) => x.startTime.toMillis() >= startTime) // + 60 * 60 * 24 * 1000 - 6 * 60 * 60 * 1000
                .filter((x) => x.startTime.toMillis() <= endTime)
                .map((x) => x.cartDocId)
                .reduce((dict, key) => {
                    if (Object.keys(dict).indexOf(key) > -1) {
                        dict[key] += 1
                    } else {
                        dict[key] = 1
                    }
                    return dict
                }, {})
            store.dispatch({
                type: SET_CART_TRIPS_BOOKING,
                payload: { cartTrips: bookingCount },
            })
        }
    }, [itemsSelector.length, nowTime, updateNumber, bookingsLoading])
    const groups = useSelector((state) => {
        const { carts } = state.firestore.data
        const course = state.firestore.data.field
        const vehicleGroups = course.vehicleGroups
        if (course && carts && Object.keys(carts).length) {
            const group = Object.keys(carts).map((cartDocId) => {
                const cart = carts[cartDocId]
                const group = vehicleGroups[cart.vehicleGroup]
                const groupIcon = group.vehicleIcon
                const engine = engineValue(cartDocId)
                const av = carts[cartDocId].available
                const bookingDailyLimit = carts[cartDocId].bookingDailyLimit
                    ? carts[cartDocId].bookingDailyLimit
                    : 2
                const readingTs = cart?.reading?.timestampReceived
                const connected =
                    readingTs &&
                    readingTs?.toMillis() + 60 * 60 * 1000 > Date.now()
                return {
                    id: cartDocId,
                    sortKey: cart.sortKey ?? cart.id,
                    groupIcon,
                    visualId: cart.id,
                    engine,
                    av,
                    bookingDailyLimit,
                    key: carts.id,
                    keyless: cart.keyless,
                    connected,
                    vehilceGroupName: group.name,
                    vehicleGroup: cart.vehicleGroup,
                }
            })
            return group.sort(sortCarts)
        }
        return []
    })

    const itemRender = ({
        item,
        timelineContext,
        itemContext,
        getItemProps,
        getResizeProps,
    }) => {
        const { left: leftResizeProps, right: rightResizeProps } =
            getResizeProps()
        return (
            <div
                {...getItemProps({
                    ...item,
                    style: {
                        background: itemContext.selected
                            ? item.selectedBgColor
                            : item.bgColor,
                        color: item.color,
                        border: `1px solid ${item.color}`,
                        borderRadius: "8px",
                        zIndex: 0,
                    },
                    onMouseDown: () => {
                        const bookingInfo = {
                            ...item,
                            ...item.doc,
                            selected: true,
                            cartDocId: item.group,
                            bookingId: item.id,
                            startTime: item.startTime.toDate().valueOf(),
                            endTime: item.endTime.toDate().valueOf(),
                            name: item.name,
                            hourMinString: item.hourMinString,
                        }
                        store.dispatch({
                            type: SELECT_BOOKING,
                            payload: bookingInfo,
                        })
                        console.log("on item click", item)
                    },
                })}
            >
                {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                ) : null}

                <div
                    className={classes.item}
                    style={{
                        height: itemContext.dimensions.height,
                        overflow: "hidden",
                        textAlign: "center",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                    }}
                >
                    {itemContext.title}{" "}
                    {!item.isOnOvertime ? null : overTimeWariningText()}
                </div>

                {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                ) : null}
            </div>
        )
    }
    const NowMarker = useCallback(() => {
        return <CustomMarker date={Date.now()} />
    }, [nowTime, updateNumber])

    const HourHeader = useCallback(() => {
        return (
            <DateHeader
                unit="hour"
                labelFormat={
                    process.env.REACT_APP_PROJECT_ID == "gsw-db"
                        ? "HH:mm"
                        : "h A"
                }
                intervalRenderer={({ getIntervalProps, intervalContext }) => (
                    <div
                        key={getIntervalProps().key}
                        style={getIntervalProps().style}
                        className="rct-dateHeader"
                    >
                        {intervalContext.intervalText}
                    </div>
                )}
            />
        )
    }, [startTime])

    return (
        <Timeline
            groups={groups}
            items={items}
            visibleTimeStart={startTime}
            visibleTimeEnd={endTime}
            rightSidebarWidth={120}
            sidebarWidth={120}
            itemRenderer={itemRender}
            stackItems
            canMove={false}
            canResize={false}
            lineHeight={40}
            groupRenderer={(obj) => groupRenderer({ ...obj })}
        >
            <TimelineHeaders>
                <SidebarHeader>
                    {({ getRootProps }) => {
                        return (
                            <div {...getRootProps()}>
                                <div className={classes.sidebarheader}>
                                    Carts
                                </div>
                            </div>
                        )
                    }}
                </SidebarHeader>
                <NowMarker />
                <SidebarHeader variant="right">
                    {({ getRootProps }) => (
                        <div
                            {...getRootProps()}
                            style={{
                                ...getRootProps().style,
                                borderLeft: "1px solid rgba(81, 81, 81, 1)",
                                alignSelf: "stretch",
                                display: "flex",
                            }}
                        >
                            <div style={{ margin: "auto" }}> Daily Limit</div>
                        </div>
                    )}
                </SidebarHeader>
                <DateHeader
                    height={50}
                    unit="day"
                    intervalRenderer={({
                        getIntervalProps,
                        intervalContext,
                    }) => (
                        <div
                            {...getIntervalProps()}
                            key={getIntervalProps().key}
                            style={getIntervalProps().style}
                            className="rct-dateHeader"
                        >
                            <HeaderText
                                text={intervalContext.intervalText}
                                handleClickOpenNewBooking={
                                    handleClickOpenNewBooking
                                }
                                handleOpenRain={handleOpenRain}
                                addOneDay={addOneDay}
                                removeOneDay={removeOneDay}
                            />
                        </div>
                    )}
                />
                <HourHeader />
            </TimelineHeaders>
        </Timeline>
    )
}

export default BookingTimeline
