import React, { useState } from "react"
import {
    Card,
    CardActionArea,
    CardActions,
    Typography,
    Box,
} from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { useSelector } from "react-redux"
import theme from "../../assets/styles/muiTheme"
import { functions } from "../../rootWrapper/firebase"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import {
    COUPONCHANGE,
    COUPONDELETE,
    SET_EDIT_COUPON,
} from "../../constants/actionTypes"
import store from "../../store"
import { httpsCallable } from "firebase/functions"

export const COUPONCARDWIDTH = 200
export const COUPONCARDHEIGHT = 200

const getDateFromTs = (ts) => {
    const d = new Date(ts)
    return `${`0${d.getDate()}`.slice(-2)}-${`0${d.getMonth() + 1}`.slice(
        -2
    )}-${d.getFullYear()}`
}

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: `${COUPONCARDWIDTH}px`,
        height: props.height ? null : `${COUPONCARDHEIGHT}px`,
        backgroundColor: theme.palette.primary.main,
    }),
    cardAction: (props) => ({
        height: props.height ? null : `${COUPONCARDHEIGHT * 0.75}px`,
        overflowY: "hidden",
    }),
    cardContentAction: (props) => ({
        height: props.height ? null : `${COUPONCARDHEIGHT * 0.75}px`,
        overflowY: "hidden",
    }),
    cardGrid: (props) => ({}),
}))

const getDiscount = (
    isPercentage,
    discountPercentage,
    discountFixed,
    currency
) => {
    if (isPercentage) {
        return `${discountPercentage}%`
    }
    return discountFixed + currency
}

export default function CouponCard(props) {
    const {
        courseDocId,
        couponDocId,
        code,
        name,
        active,
        validFrom,
        validTo,
        segments,
        isPercentage,
        discountPercentage,
        discountFixed,
        timesUsed,
        usageLimited,
        usageLimit,
        perUserLimited,
        perUserLimit,
        isSharedCoupon,
        partnerCourses,
    } = props

    const currency = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("currency") > -1) {
            return field.currency.toUpperCase()
        }
        return "Nan"
    })

    const getPartnerCourses = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("partnerCourses") > -1) {
            return field.partnerCourses
        }
        return "Nan"
    })
    const [heightExt, setHeight] = useState(false)
    const [deletePress, setDeletePress] = useState(false)
    const handleChangeHeight = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setHeight(!heightExt)
        setDeletePress(false)
    }
    const handleEditCoupon = (e) => {
        e.preventDefault()
        store.dispatch({ type: SET_EDIT_COUPON, payload: props })
    }
    const handleButtonClick = (active, changeActive) => {
        const updateCoupon = httpsCallable(functions, "updateCoupon")
        updateCoupon({
            active: changeActive ? active : false,
            deleted: !changeActive,
            couponDocId,
            courseDocId,
        })
            .then((r) => {
                if (changeActive) {
                    store.dispatch({
                        type: COUPONCHANGE,
                        payload: { couponDocId, active },
                    })
                } else {
                    store.dispatch({
                        type: COUPONDELETE,
                        payload: { couponDocId },
                    })
                }
            })
            .catch((e) => console.error(e.message))
    }
    const classes = useStyles({ ...theme, height: heightExt })

    const GetSharedDiscountsCourseName = (course) => {
        const courses = getPartnerCourses[course.course]?.name ?? ""

        return <Typography variant="body2">{courses}</Typography>
    }

    return (
        <Card className={classes.root} variant="outlined">
            <CardActionArea
                onClick={handleChangeHeight}
                className={classes.cardContentAction}
            >
                <CardContent className={classes.cardAction}>
                    {isSharedCoupon ? (
                        <Grid container direction="column">
                            <Grid item xs>
                                <Typography
                                    gutterBottom
                                    variant="body1"
                                    component="h4"
                                    color="secondary"
                                >
                                    SHARED DISCOUNT
                                </Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <div />
                    )}

                    <Typography gutterBottom variant="body1" component="h4">
                        {name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {code}
                    </Typography>
                    <Grid
                        container
                        direction="column"
                        spacing={1}
                        className={classes.cardGrid}
                    >
                        <Grid item xs>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs>
                                    Valid From {getDateFromTs(validFrom)}
                                </Grid>
                                <Grid item xs>
                                    Valid To {getDateFromTs(validTo)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs>
                                    Discount:{" "}
                                    {getDiscount(
                                        isPercentage,
                                        discountPercentage,
                                        discountFixed,
                                        currency
                                    )}
                                </Grid>
                                <Grid item xs>
                                    Times Used: {timesUsed}
                                    {usageLimited
                                        ? String(`/${usageLimit}`)
                                        : null}
                                </Grid>
                            </Grid>
                        </Grid>
                        {perUserLimited ? (
                            <Grid item xs>
                                <Typography variant="body2">
                                    {" "}
                                    User Limit: {perUserLimit}{" "}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid item xs>
                            <Grid container direction="column">
                                <Grid item xs>
                                    <Typography variant="body2">
                                        {" "}
                                        Valid Categories:{" "}
                                    </Typography>
                                </Grid>
                                {segments.map((x, index) => (
                                    <Grid key={index} item xs>
                                        <Typography variant="body2">
                                            {x}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {isSharedCoupon ? (
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography variant="body2">
                                        {" "}
                                        Valid for courses:{" "}
                                    </Typography>
                                </Grid>

                                <Grid item xs color="secondary">
                                    <List color="secondary">
                                        {Object.values(partnerCourses).map(
                                            (course, index) => (
                                                <ListItem
                                                    key={index}
                                                    disablePadding
                                                >
                                                    <GetSharedDiscountsCourseName
                                                        course={course}
                                                    />
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                </Grid>
                            </Grid>
                        ) : (
                            <div />
                        )}
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ bottom: 0 }}>
                {!active && deletePress ? (
                    <Button
                        size="small"
                        color="secondary"
                        disabled={active}
                        fullWidth
                        onClick={() => handleButtonClick(false, false)}
                    >
                        Confirm Delete
                    </Button>
                ) : (
                    <>
                        <Button
                            size="small"
                            color="secondary"
                            fullWidth
                            onClick={() => handleButtonClick(!active, true)}
                        >
                            {active ? "Deactivate" : "Activate"}
                        </Button>
                        {active ? (
                            <Button
                                size="small"
                                color="secondary"
                                fullWidth
                                onClick={handleEditCoupon}
                            >
                                Edit
                            </Button>
                        ) : (
                            <Button
                                size="small"
                                color="secondary"
                                disabled={active}
                                fullWidth
                                onClick={() => setDeletePress(true)}
                            >
                                Delete
                            </Button>
                        )}
                    </>
                )}
            </CardActions>
        </Card>
    )
}
