import { React, useState, useRef } from "react"
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Switch,
    Tooltip,
    Typography,
    TextField,
} from "@mui/material"
import { useSelector } from "react-redux"
import {
    SET_NEW_SMARTBOXID,
    SET_CART_STATUS,
    SET_COMPLETE_CART,
} from "../../constants/actionTypes"

import store from "../../store"
import PingCart from "../lock/PingCart"
import getLockAccess from "../../constants/accessPermissionsChecks"
import Dialog from "@mui/material/Dialog"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import { DialogTitle } from "../DialogTitle"
import { DialogContent } from "../DialogTitle/dialogContent"
import { DialogActions } from "../DialogTitle/dialogAction"
import styled from "@emotion/styled"
import { PrimaryAppbar } from "../settingsTabs/settingTab"
import { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"

import { SuccessLoader } from "../settingsForm/loader/successLoader"

export default function ChangeSmartboxId(props) {
    const { vehicle, width, fontSize, fontWeight } = props
    const [changeIdOpen, setChangeIdOpen] = useState(false)
    const initStatus = {
        success: false,
        loading: false,
        error: false,
    }
    const [status, setStatus] = useState(initStatus)
    const [error, setError] = useState("")

    const smartboxRef = useRef()

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const style = useDialogStyles()
    const lockAccess = getLockAccess()
    const { cartDocId } = vehicle
    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading

    const CustomDialog = styled(Dialog)(({ theme }) => ({
        minWidth: "300px",
    }))

    const wait = async (time) =>
        new Promise((resolve) => setTimeout(resolve, time))

    const handleClick = async (e) => {
        e.preventDefault()
        setStatus((state) => ({ ...state, loading: true }))
        const newSmartBoxId = parseInt(smartboxRef.current.value)
        if (checkIfValidSmartboxId(smartboxRef.current.value)) {
            const updateSmartBoxId = httpsCallable(
                functions,
                "changeSmartboxId"
            )

            await updateSmartBoxId({
                cartDocId: cartDocId,
                courseDocId: courseDocId,
                newSmartboxId: newSmartBoxId,
            })
                .then(() => {
                    store.dispatch({
                        type: SET_NEW_SMARTBOXID,
                        payload: { boardId: newSmartBoxId },
                    })
                    setStatus((state) => ({
                        error: false,
                        success: true,
                        loading: false,
                    }))

                    wait(3000).then(() => setChangeIdOpen(false))
                })
                .catch((e) => {
                    setStatus((state) => ({
                        ...state,
                        loading: false,
                        error: true,
                    }))
                    setError(e.message)
                })
                .then(
                    () =>
                        new Promise(() =>
                            setTimeout(() => setStatus(initStatus), 5000)
                        )
                )
        } else {
            new Promise(() => setTimeout(() => setStatus(initStatus), 2000))
            setStatus((state) => ({
                ...state,
                loading: false,
                error: true,
            }))
            setError("Not a valid smartbox ID")
        }
    }

    const handleClickOpen = async (openValue) => {
        setChangeIdOpen(openValue)
    }

    const checkIfValidSmartboxId = (smartboxId) => {
        const idToCheck = parseInt(smartboxId)
        const re = new RegExp("^[0-9]{4}$")
        return re.test(idToCheck)
    }

    return (
        <Grid container direction="column">
            <Grid item xs></Grid>
            <Box
                sx={{
                    margin: 1,
                    width,
                    textAlign: "center",
                    position: "relative",
                    ...style,
                }}
            >
                {cartDocId && lockLoading(cartDocId) && (
                    <LinearProgress color="secondary" />
                )}
                {lockAccess ? null : (
                    <Tooltip
                        placement="right"
                        title={"Limited permissions - talk to admin"}
                    >
                        <Box
                            sx={{
                                // border: "1px solid", // Use to check where the helper box is
                                position: "absolute",
                                width: "100%",
                                height: "37px",
                            }}
                        ></Box>
                    </Tooltip>
                )}
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={lockLoading(cartDocId) || !lockAccess}
                    fullWidth
                >
                    <Typography
                        fontSize={"10pt"}
                        fontWeight="bold"
                        color={"gray"}
                        onClick={() => {
                            {
                                handleClickOpen(true)
                            }
                        }}
                    >
                        Change ID
                    </Typography>
                </Button>
            </Box>

            {changeIdOpen ? (
                <div>
                    <CustomDialog
                        classes={{ paper: style.changeSmartboxOverlay }}
                        onClose={() => {
                            {
                                handleClickOpen(false)
                            }
                        }}
                        aria-labelledby="customized-dialog-title"
                        open={changeIdOpen}
                        variant="outlined"
                    >
                        <DialogTitle
                            id="customized-dialog-title"
                            onClose={() => {
                                {
                                    handleClickOpen(false)
                                }
                            }}
                        >
                            Change Smartbox ID
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                flexDirection: "column",
                                display: "flex",
                            }}
                        >
                            <DialogActions
                                style={{
                                    marginTop: "auto",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <Grid container direction="column" spacing={2}>
                                    <Grid item xs>
                                        <TextField
                                            fullWidth
                                            required
                                            id="filled-required"
                                            label="New Smartbox ID"
                                            placeholder="1234"
                                            name="smartboxID"
                                            color="secondary"
                                            variant="outlined"
                                            inputRef={smartboxRef}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <SuccessLoader
                                            handleSubmit={handleClick}
                                            status={status}
                                            error={error}
                                        >
                                            Update ID
                                        </SuccessLoader>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </DialogContent>
                    </CustomDialog>
                </div>
            ) : (
                <div />
            )}
        </Grid>
    )
}
