import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { ErrorMessage } from "../CheckoutForm/submitSetupIntent"

const cancelSubscriptionAtPeriodEnd = httpsCallable(
    functions,
    "cancelSubscriptionAtPeriodEnd"
)
export default function CancelSubButton(props) {
    const { subData } = props
    const hasCancelled = Boolean(
        subData?.stripeSubscription?.cancel_at_period_end
    )
    console.log("cancelled", hasCancelled)
    const [cancelWarning, setCancelWarning] = useState(false)
    const [error, setError] = useState("")

    const cancelSubscription = async (e) => {
        e.preventDefault()
        const courseDocId = subData.courseDocId
        cancelSubscriptionAtPeriodEnd({ courseDocId, disable: true })
            .then(() => {})
            .catch((e) => {
                setError(e.message)
            })
    }
    const uncancelSubscription = async (e) => {
        e.preventDefault()
        const courseDocId = subData.courseDocId
        cancelSubscriptionAtPeriodEnd({ courseDocId, disable: false })
            .then(() => {})
            .catch((e) => {
                setError(e.message)
            })
    }
    useEffect(() => {
        let timeout
        if (error) {
            setTimeout(() => {
                setError("")
            }, 2000)
        }
        return timeout
    }, [error])

    return (
        <>
            {hasCancelled ? (
                <Box>
                    <Typography
                        onClick={uncancelSubscription}
                        sx={{
                            fontSize: "16pt",
                            textDecoration: "underline",
                            cursor: "pointer",
                        }}
                    >
                        Reactivate!
                    </Typography>
                    <Typography
                        onClick={uncancelSubscription}
                        sx={{ cursor: "pointer" }}
                    >
                        Don&apos;t miss out on the complete overview, increased
                        revenue and make your customers sad.
                    </Typography>
                </Box>
            ) : cancelWarning ? (
                <Box sx={{ width: 400 }}>
                    <Typography
                        onClick={cancelSubscription}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        Confirm cancel Subscription.
                    </Typography>
                    <Typography
                        onClick={cancelSubscription}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        You will then loose all access at the end of the current
                        billing period. Are you sure you want this?
                    </Typography>
                </Box>
            ) : (
                <Box sx={{ width: 200 }}>
                    <Typography
                        onClick={() => setCancelWarning(true)}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                        Cancel Subscription?
                    </Typography>
                </Box>
            )}

            <ErrorMessage error={error} />
        </>
    )
}
