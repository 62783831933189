import Grid from "@mui/material/Grid"
import { TextField } from "@mui/material"
import React, { useState } from "react"
import BookingCartDropdown from "../bookingCartsSelector"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import store from "../../store"
import { SET_BOOKING_FIELDS } from "../../constants/actionTypes"
import { useSelector } from "react-redux"
import BookingDateInput from "../Booking/BookingDateInput"
import BookingTimeInput from "../Booking/BookingTimeInput"
import TransferList from "./transferlist"
import { sortCarts } from "../timeline"

export default function MaintenanceBooking(props) {
    const { note, setCurrentProgress } = props
    const { cartIdList } = useSelector((state) => state.createBooking)
    const style = useDialogStyles()
    const carts = useSelector((state) => state.firestore.data.carts)
    const sortedCarts = Object.keys(carts).sort((x, y) =>
        sortCarts(carts[x], carts[y])
    )
    const [cartsToSelect, setCartsToSelect] = useState(sortedCarts)

    const setCartIdList = (cartIdList) => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                cartIdList,
            },
        })
    }
    return (
        <Grid container direction="column" spacing={2}>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <BookingDateInput
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <BookingTimeInput
                            isStartTime={true}
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                    <Grid item xs>
                        <BookingTimeInput
                            isStartTime={false}
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <TransferList
                    cartsToSelect={cartsToSelect}
                    setCartsToSelect={setCartsToSelect}
                    cartIdList={cartIdList}
                    setCartIdList={setCartIdList}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    id="admin-note"
                    label="Reason for the booking"
                    inputRef={note}
                    defaultValue={note?.current?.value ?? ""}
                    name="cnote"
                    fullWidth
                    margin="normal"
                    inputProps={{ className: style.input }}
                    InputLabelProps={{
                        shrink: true,
                        className: style.input,
                    }}
                    style={{ color: "secondary", backgroundColor: "primary" }}
                />
            </Grid>
        </Grid>
    )
}
