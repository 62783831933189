import {
    Box,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const desc = (
    <>
        Automaticly locks the vehicle at the end of the booking (at the end of
        the reserved time).
        <br />
        <br /> <b>Note:</b> To prevent vehicles from stoping on the course set
        the reserved time long enough and remember to press &quot;End
        booking&quot; a player has delivered the vehicle.
    </>
)

const title = <>Auto lock after booking end</>

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const appAutoEnd = currentForm.appAutoEnd ? "On" : "Off"
    const adminAutoEnd = currentForm.adminAutoEnd ? "On" : "Off"
    return `Auto lock: App ${appAutoEnd} | Admin ${adminAutoEnd}`
}

function AutoLockItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    const handleInputChangeAdmin = () => {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "adminAutoEnd",
                value: Boolean(!currentForm.adminAutoEnd),
            },
        })
    }
    const handleInputChangeApp = () => {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "appAutoEnd",
                value: Boolean(!currentForm.appAutoEnd),
            },
        })
    }

    return (
        <Box>
            <Table size="small">
                <TableBody
                    sx={{
                        "& .MuiTableCell-root": {
                            borderBottom: "none",
                            paddingLeft: "0",
                        },
                        textAlign: "left",
                    }}
                >
                    <TableRow>
                        <TableCell>App bookings</TableCell>
                        <TableCell>
                            <Switch
                                sx={{ margin: "auto" }}
                                onClick={handleInputChangeApp}
                                checked={currentForm.appAutoEnd || false}
                                color="secondary"
                                align="right"
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Admin bookings</TableCell>
                        <TableCell>
                            <Switch
                                sx={{ margin: "auto" }}
                                onClick={handleInputChangeAdmin}
                                checked={currentForm.adminAutoEnd || false}
                                color="secondary"
                                align="right"
                            />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: AutoLockItem,
}
