import { Typography, Box } from "@mui/material"
import { useSelector } from "react-redux"
import React from "react"

export default function CourseLinkingIdDisplay() {
    const courseLinkingId = useSelector(
        (state) => state.firestore.data.field.courseLinkingId
    )

    return (
        <Box>
            <Typography variant="subtitle2">
                Course Linking Id: {courseLinkingId}
            </Typography>
        </Box>
    )
}
