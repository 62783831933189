import React, { useState } from "react"
import { Alert, Box, Collapse, IconButton } from "@mui/material"
import getSubscriptionStatus from "../SubscriptionStatus"
import CloseIcon from "@mui/icons-material/Close"
import { PropTypes } from "prop-types"

const DUE_TEXT =
    "Your last invoice is past due, please renew you payment method in settings"

function AlertTopBar(props) {
    const { text } = props
    const [open, setOpen] = useState(true)

    return (
        <Box
            sx={{
                left: 0,
                zIndex: 1400,
                position: "absolute",
                width: "100%",
                textAlign: "center",
            }}
        >
            <Collapse in={open}>
                <Alert
                    severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="medium"
                            onClick={() => {
                                setOpen(false)
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{
                        mb: 2,
                        ".MuiAlert-message": {
                            pt: 1,
                        },
                    }}
                >
                    {text}
                </Alert>
            </Collapse>
        </Box>
    )
}
AlertTopBar.propTypes = {
    text: PropTypes.string.isRequired,
}

export default function SubTopbar() {
    const { init, subscriptionStatus, stripeBasedSub } = getSubscriptionStatus()
    if (init && stripeBasedSub) {
        if (subscriptionStatus === "past_due") {
            return <AlertTopBar text={DUE_TEXT} />
        }
    }

    return null
}
