import Grid from "@mui/material/Grid"
import React, { useRef } from "react"
import theme from "../../assets/styles/muiTheme"
import PropTypes from "prop-types"

export default function CouponGrid(props) {
    const { couponList, Component, cardWidth, spread, gridSpacing } = props
    const spacing = gridSpacing ?? "space-around"
    const sizeRef = useRef()
    const DEFAULT_COLS = 2
    const getAmountOfRows = (ref) => {
        return Math.max(
            Math.ceil(couponList.length / getAmountOfColumns(ref)),
            1
        )
    }
    const getAmountOfColumns = (ref) => {
        if (ref.current === undefined) {
            return DEFAULT_COLS
        }
        const maxWidthAvailable = parseInt(ref.current.clientWidth)
        return Math.max(Math.floor(maxWidthAvailable / cardWidth + 20), 2)
    }

    return (
        <div
            ref={sizeRef}
            style={{ width: "100%", height: "auto", padding: theme.spacing(1) }}
        >
            <Grid container direction="column" alignItems={spacing} spacing={2}>
                {[...Array(getAmountOfRows(sizeRef))].map((x, rowIndex) => (
                    <Grid key={rowIndex} item xs>
                        <Grid
                            container
                            direction="row"
                            alignItems={spacing}
                            spacing={2}
                            justifyContent={spacing}
                        >
                            {[...Array(getAmountOfColumns(sizeRef))].map(
                                (x, colIndex) => (
                                    <Grid item key={colIndex}>
                                        {rowIndex *
                                            getAmountOfColumns(sizeRef) +
                                            colIndex <
                                        couponList.length ? (
                                            <Component
                                                {...couponList[
                                                    rowIndex *
                                                        getAmountOfColumns(
                                                            sizeRef
                                                        ) +
                                                        colIndex
                                                ]}
                                            />
                                        ) : null}
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

CouponGrid.protoTypes = {
    couponList: PropTypes.array.isRequired,
    Component: PropTypes.element.isRequired,
    cardWidth: PropTypes.number.isRequired,
}
