import React, { useEffect, useState } from "react"
import {
    Box,
    Grid,
    LinearProgress,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import { SET_CART_STATUS, SET_COMPLETE_CART } from "../../constants/actionTypes"
import store from "../../store"
import LockCart from "../lock/unlocking-cart"
import { textTrueSwitchMapTable } from "../../assets/applicationText/mapTable"
import getLockAccess from "../../constants/accessPermissionsChecks"
import theme from "../../assets/styles/muiTheme"

const CurrentBookingActive = ({ active, fontWeight, fontSize }) => {
    if (active) {
        return (
            <Box>
                <Typography
                    color={theme.palette.warning.main}
                    fontSize={fontSize}
                    fontWeight={600}
                >
                    Active Booking
                </Typography>
            </Box>
        )
    }
    return null
}

export default function PowerOnSwitch(props) {
    const { vehicle, width, fontSize, fontWeight, currentBooking } = props
    const { cartDocId } = vehicle

    const [requiresConfirmation, setRequiresConfirmed] = useState(false)

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const engineValue = useSelector(
        (state) => state.cartReadings?.[cartDocId]?.engineComp || false
    )

    useEffect(() => {
        if (cartDocId && courseDocId) {
            const hasBooking = Boolean(currentBooking?.bookingDocId)
            const requiresConfirm = hasBooking
            setRequiresConfirmed(requiresConfirm)
        } else {
            setRequiresConfirmed(false)
        }
    }, [courseDocId, cartDocId, currentBooking?.bookingDocId])

    const lockAccess = getLockAccess()

    const handleClick = async (cartDocId) => {
        const engine = engineValue
        if (!lockAccess) {
            return
        }
        const result = await LockCart(!engine, courseDocId, cartDocId)
        store.dispatch({
            type: SET_CART_STATUS,
            payload: {
                cartsStatus: {
                    [cartDocId]: result,
                },
            },
        })
        if (result && result.status === 201) {
            store.dispatch({
                type: SET_COMPLETE_CART,
                payload: {
                    cartsComplete: { [cartDocId]: true },
                },
            })
        }
    }
    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading
    const error = (cartDocId) => cartLoader?.[cartDocId]?.status?.error || false
    const errorMessage = (cartDocId) =>
        JSON.stringify(cartLoader?.[cartDocId]?.status?.errorMessage) || ""

    return (
        <Box
            sx={{ margin: 1, width, textAlign: "center" }}
            position={"relative"}
        >
            {cartDocId && lockLoading(cartDocId) && (
                <LinearProgress color="secondary" />
            )}
            {lockAccess ? null : (
                <Tooltip
                    placement="right"
                    title={"Limited permissions - talk to admin"}
                >
                    <Box
                        sx={{
                            // border: "1px solid", // Use to check where the helper box is
                            position: "absolute",
                            width: "100%",
                            top: "-5px",
                            height: "50px",
                        }}
                    ></Box>
                </Tooltip>
            )}
            <CurrentBookingActive
                fontSize={fontSize}
                fontWeight={fontWeight}
                active={requiresConfirmation}
            />
            <Grid
                container
                component="label"
                alignItems="center"
                direction="row"
                justifyContent="center"
                style={{ cursor: lockAccess ? "pointer" : null }}
            >
                <Grid item xs sx={{ margin: 0 }}>
                    <Typography
                        sx={{ width: "100%" }}
                        fontSize={fontSize}
                        fontWeight={fontWeight || "300"}
                    >
                        {textTrueSwitchMapTable(0, 0)}
                    </Typography>
                </Grid>
                <Grid item xs sx={{ margin: 0 }}>
                    <Switch
                        sx={{
                            m: "-16px",
                            cursor: lockAccess ? "default" : null,
                        }}
                        onClick={async () => handleClick(cartDocId)}
                        checked={(cartDocId && engineValue) || false}
                        disabled={
                            (cartDocId && lockLoading(cartDocId)) ||
                            // (requiresConfirmation && !confirmed) ||
                            !lockAccess ||
                            false
                        }
                        color="secondary"
                        align="right"
                    >
                        Press to turn on cart
                    </Switch>
                </Grid>
                <Grid item xs sx={{ margin: 0 }}>
                    <Typography
                        sx={{ width: "100%" }}
                        fontSize={fontSize}
                        fontWeight={fontWeight || "300"}
                    >
                        {textTrueSwitchMapTable(0, 1)}
                    </Typography>
                </Grid>
            </Grid>
            {error(cartDocId) ? (
                <Typography fontSize={"10pt"} color="error">
                    Error: {errorMessage(cartDocId)}
                </Typography>
            ) : null}
        </Box>
    )
}
