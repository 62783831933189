import React, { useState } from "react"
import Button from "@mui/material/Button"
import { useSelector } from "react-redux"
import DeleteDialog from "./bookingDeletePopup"

export default function DeleteBookingButton(props) {
    const [deleteOpen, setDeleteOpen] = useState(false)
    const { selected, cartDocId, bookingId, startTime, endTime } = useSelector(
        (state) => state.bookingSelector
    )
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })

    const handleClose = () => {
        setDeleteOpen(false)
        //props.handleClose()
    }
    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                disabled={props.disabled}
                style={{
                    marginTop: 10,
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                    height: "calc(100% - 10px)",
                }}
                onClick={() => setDeleteOpen(true)}
            >
                Cancel Booking
            </Button>
            <DeleteDialog
                docRef={bookingId}
                startTime={startTime}
                endTime={endTime}
                courseDocId={courseDocId}
                open={deleteOpen}
                handleClose={handleClose}
            />
        </>
    )
}
