import {
    PAGE_CHANGE_SETUP_FLOW,
    COMPLETE_SETUP,
    COMPLETE_STEP,
    PAGE_CHANGE_DETAIL_FLOW,
    PAGE_CHANGE_SETTING_FLOW,
    ONBOARDING_SET_STRIPE_URL,
    ONBOARDING_SET_STRIPE_COMPLETED,
} from "../constants/actionTypes"

const initialState = {
    courseComplete: false,
    activePage: 0,
    completed: { 0: true },
    activeDetailPage: 0,
    activeSettingPage: 0,
    stripeUrl: "",
    stripeCompleted: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPLETE_SETUP:
            return {
                ...state,
                courseComplete: true,
            }
        case COMPLETE_STEP: {
            return {
                ...state,
                completed: {
                    ...state.completed,
                    ...action.payload.completed,
                },
            }
        }
        case PAGE_CHANGE_SETUP_FLOW:
            return {
                ...state,
                activePage: action.payload.page,
            }
        case PAGE_CHANGE_DETAIL_FLOW:
            return {
                ...state,
                activeDetailPage: action.payload.page,
            }
        case PAGE_CHANGE_SETTING_FLOW:
            return {
                ...state,
                activeSettingPage: action.payload.page,
            }
        case ONBOARDING_SET_STRIPE_URL:
            return {
                ...state,
                stripeUrl: action.payload.stripeUrl,
            }
        case ONBOARDING_SET_STRIPE_COMPLETED:
            return {
                ...state,
                stripeCompleted: true,
            }
        default:
            return state
    }
}
