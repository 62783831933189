import { getAuth } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { USER_LOG_OUT } from "../../constants/actionTypes"
import store from "../../store"

export default function SignOutController() {
    const navigate = useNavigate()
    const auth = getAuth()
    const signOut = () => {
        auth.signOut()
            .then(() => {
                console.log("signed out")
                store.dispatch({ type: USER_LOG_OUT })
                navigate("/login")
            })
            .catch((error) => {
                console.error(error.message)
            })
    }
    return { signOut }
}
