import {
    Box,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Tooltip,
    Typography,
} from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React, { useState } from "react"
import ReportTable from "../../Components/ReportTable"
import ReportGeneratorBox from "../../Components/ReportTable/generateReport"

export const HelpQuestion = ({ tooltip, fontSize = "9pt" }) => (
    <Tooltip
        title={<Typography fontSize={fontSize}>{tooltip}</Typography>}
        placement="top"
    >
        <Typography
            color="secondary"
            fontSize={fontSize}
            display={"inline-block"}
        >
            {"(?)"}
        </Typography>
    </Tooltip>
)

export default function ReportSection() {
    return (
        <Box minHeight={450 + "px"} position="relative">
            <Typography id="reports" component="h1" color="#DCDCDCC9">
                Reports
            </Typography>
            <Box position="absolute" right={0} top={0}>
                <Tooltip
                    title={
                        <Typography fontSize="14pt">
                            Generate reports with all bookings in a time period
                        </Typography>
                    }
                    placement="top"
                >
                    <IconButton size="small">
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    p: 4,
                }}
            >
                <Grid container direction={"row"}>
                    <Grid item xs={5} style={{ minWidth: "300px" }}>
                        <ReportGeneratorBox />
                        <Typography fontSize={"9pt"} maxWidth="400px">
                            Reports are generated in Microsoft Excel documents.
                            Tip: Use the{" "}
                            <Link
                                fontSize={"inherit"}
                                color="secondary"
                                target="_blank"
                                component="a"
                                href="https://support.microsoft.com/en-us/office/filter-data-in-a-range-or-table-01832226-31b5-4568-8806-38c37dcc180e"
                            >
                                filter-function in Microsoft Excel
                            </Link>{" "}
                            to filter data.
                        </Typography>
                    </Grid>
                    <Grid item xs={7} style={{ minWidth: "300px" }}>
                        <ReportTable />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
