import React from "react"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { dayMonthYear } from "../dateTools"

const getLink = (id, payoutLinks) => {
    const link = Object.keys(payoutLinks).filter((x) => x.includes(id))
    if (link.length) {
        return payoutLinks[link[0]]
    }
    return null
}

export default function PayoutTable(props) {
    const { payoutLinks, payouts } = props

    return (
        <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: "100px" }}>
                            Created Date
                        </TableCell>
                        <TableCell style={{ minWidth: "100px" }}>
                            Arrival Date
                        </TableCell>
                        <TableCell style={{ minWidth: "80px" }}>
                            Status
                        </TableCell>
                        <TableCell style={{ minWidth: "100px" }}>
                            Description
                        </TableCell>
                        <TableCell style={{ minWidth: "100px" }}>
                            Amount
                        </TableCell>
                        <TableCell>Download Itemized report</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payouts
                        ? payouts.map((x, index) => {
                              const currency = x.currency
                              const link = getLink(x.id, payoutLinks)
                              return (
                                  <TableRow key={index}>
                                      <TableCell>
                                          {dayMonthYear(
                                              new Date(x.created * 1000)
                                          )}
                                      </TableCell>
                                      <TableCell>
                                          {dayMonthYear(
                                              new Date(x.arrival_date * 1000)
                                          )}
                                      </TableCell>
                                      <TableCell>{x.status}</TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                          {x.statement_descriptor}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                          {Number(x.amount / 100).toFixed(2)}{" "}
                                          {currency.toUpperCase()}
                                      </TableCell>
                                      <TableCell>
                                          {link ? (
                                              <Typography
                                                  component="a"
                                                  color="secondary"
                                                  target="_blank"
                                                  download={`payout-${x.id}.xlsx`}
                                                  href={getLink(
                                                      x.id,
                                                      payoutLinks
                                                  )}
                                              >
                                                  Link
                                              </Typography>
                                          ) : (
                                              <Typography
                                                  component="a"
                                                  color="secondary"
                                              >
                                                  Link
                                              </Typography>
                                          )}
                                      </TableCell>
                                  </TableRow>
                              )
                          })
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
