import { Box, Stepper, Step, StepLabel } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"

const allSteps = [
    "Create a user",
    "Register your golf course",
    "Add your vehicle fleet",
    "Start free trial",
]

const steps = (skipPay) => {
    if (skipPay) {
        return allSteps.slice(0, 3)
    }
    return allSteps
}

export default function RegisterStepper() {
    const step = useSelector((state) => state.register?.registerStep)
    const priceModel = useSelector((state) => state.register.priceModel)
    const skipPay = priceModel.skipPay
    const stepsToUse = steps(skipPay)

    return (
        <Box height={100} width="100%">
            <Stepper activeStep={step || 0} alternativeLabel>
                {stepsToUse.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}
