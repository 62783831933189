import React from "react"
import { useSelector } from "react-redux"
import makeStyles from "@mui/styles/makeStyles"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import store from "../../../store"
import { SET_BOOKING_FIELDS } from "../../../constants/actionTypes"

export const useStylesSegment = makeStyles(() => ({
    formControl: {
        minWidth: 120,
        width: `${100}%`,
    },
}))

export default function VehicleGroupSelector() {
    const classes = useStylesSegment()

    const allGroups = useSelector(
        (state) => state.firestore.data.field.vehicleGroups
    )
    const groups = Object.keys(allGroups)
        .filter((group) => allGroups[group].bookable)
        .reduce(
            (currentGroups, currentGroup) => ({
                ...currentGroups,
                [currentGroup]: allGroups[currentGroup],
            }),
            {}
        )
    const { vehicleGroup } = useSelector((state) => state.createBooking)

    const setVehicleGroup = (event) => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                vehicleGroup: event.target.value,
            },
        })
    }

    if (Object.keys(groups).length <= 1) {
        return null
    }

    return (
        <div>
            <FormControl className={classes.formControl}>
                <InputLabel id="select-label">Vehicle Type</InputLabel>
                <Select
                    fullWidth
                    labelId="select-label"
                    id="simple-select"
                    value={vehicleGroup}
                    onChange={setVehicleGroup}
                >
                    {Object.keys(groups).length
                        ? Object.keys(groups).map((key, index) => (
                              <MenuItem value={key} key={index}>
                                  {groups[key]?.name}
                              </MenuItem>
                          ))
                        : null}
                </Select>
            </FormControl>
        </div>
    )
}
