import {
    SET_ALL_CARTS_TO_INSTALL,
    SET_ALL_CARTS_INSTALL_STATUS,
    SET_COMPLETE_CART,
    SET_CART_STATUS,
    SET_INSTALL_PAGE,
    SET_CARTID_TO_DOC_ID,
    SET_CURRENT_CART_INSTALL,
    SET_ALL_CARTS_INSTALLED,
} from "../constants/actionTypes"

const initialState = {
    allCartsComplete: false,
    activePage: 0,
    cartsComplete: {},
    cartsStatus: {},
    cartIdToDocId: {},
    cartsToComplete: {},
    currentCartDocId: "",
    currentCart: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_CARTS_TO_INSTALL:
            return {
                ...state,
                cartsToComplete: action.payload.cartsToComplete,
            }
        case SET_CARTID_TO_DOC_ID:
            return {
                ...state,
                cartIdToDocId: action.payload.cartIdToDocId,
            }
        case SET_CURRENT_CART_INSTALL:
            return {
                ...state,
                currentCartDocId: action.payload.currentCartDocId,
                currentCart: action.payload.currentCart,
            }
        case SET_ALL_CARTS_INSTALL_STATUS: {
            return {
                ...state,
                cartsComplete: {
                    ...state.cartsComplete,
                    ...action.payload.cartsComplete,
                },
            }
        }
        case SET_INSTALL_PAGE:
            return {
                ...state,
                activePage: action.payload.page,
            }
        case SET_COMPLETE_CART:
            return {
                ...state,
                cartsComplete: {
                    ...state.cartsComplete,
                    ...action.payload.cartsComplete,
                },
            }
        case SET_CART_STATUS:
            return {
                ...state,
                cartsStatus: {
                    ...state.cartsStatus,
                    ...action.payload.cartsStatus,
                },
            }
        case SET_ALL_CARTS_INSTALLED:
            return {
                ...state,
                allCartsComplete: true,
            }
        default:
            return state
    }
}
