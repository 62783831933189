import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { useSettingsFormStyle } from "../../assets/styles/components/settingsForm"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { golfCourses } from "../../constants/dbPaths"
import store from "../../store"
import PropTypes from "prop-types"
import {
    PAGE_CHANGE_DETAIL_FLOW,
    SELECT_DETAIL_FIELD,
    SET_DETAILS_ALL_SETTINGS,
} from "../../constants/actionTypes"
import { detailSteps } from "./getDetailsSteps"
import { Box } from "@mui/system"
import {
    Button,
    Paper,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material"

export const getNumbersFromString = (input) => {
    return Number(String(input).replace(/\D/g, ""))
}

export default function DetailsFormStepper(props) {
    const { handleComplete } = props
    const classes = useSettingsFormStyle()
    const details = useSelector((state) => {
        const { field } = state.firestore.data
        const details = state.DetailsForm
        if (details.detailsLoaded) {
            return {
                ...details,
            }
        } else {
            if (Object.keys(field).indexOf("details") > -1) {
                return {
                    ...field.details,
                    about: field.about,
                    totalHoles: getNumbersFromString(field.details.totalHoles),
                }
            }
            return {
                about: "",
                address: "",
                county: "",
                phoneNumber: "",
                totalHoles: 72,
            }
        }
    })
    const initState = {
        about: details.about,
        address: details.address,
        county: details.county,
        phoneNumber: details.phoneNumber,
        totalHoles: details.totalHoles,
    }
    useEffect(() => {
        store.dispatch({ type: SET_DETAILS_ALL_SETTINGS, payload: initState })
    }, [])
    const currentForm = useSelector((state) => state.DetailsForm)

    const fire = useFirebase()
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })

    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }
    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)
    const [steps, setSteps] = useState([])

    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        store.dispatch({
            type: SELECT_DETAIL_FIELD,
            payload: { key: name, value },
        })
    }
    const handleInputNumber = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        console.log(value)
        if (value === "") {
            store.dispatch({
                type: SELECT_DETAIL_FIELD,
                payload: { key: name, value: "" },
            })
        } else {
            store.dispatch({
                type: SELECT_DETAIL_FIELD,
                payload: { key: name, value: getNumbersFromString(value) },
            })
        }
    }
    const handleSubmit = async () => {
        const detailsUpdate = {
            address: currentForm.address,
            county: currentForm.county,
            phoneNumber: currentForm.phoneNumber,
            totalHoles: currentForm.totalHoles,
        }
        console.log(detailsUpdate)
        setStatus({ ...status, loading: true })
        const courseRef = fire
            .firestore()
            .collection(golfCourses)
            .doc(courseDocId)
        fire.firestore()
            .runTransaction(async (transaction) => {
                const course = await transaction.get(courseRef)
                const courseData = course.data()
                const courseDetails = courseData?.details || {}
                const newDetails = {
                    ...courseDetails,
                    ...detailsUpdate,
                }
                transaction.update(courseRef, {
                    details: newDetails,
                    about: currentForm.about,
                })
            })
            .then(() => {
                setStatus({ ...status, loading: false, success: true })
            })
            .catch((e) => {
                setStatus({ ...status, error: true, loading: false })
                setError(e.message)
            })
        await new Promise((r) => setTimeout(r, 2000)).then(() => {
            setStatus(initStatus)
            setError("")
        })
    }
    useEffect(() => {
        const stepsToSet = detailSteps({
            handleInputChange,
            handleInputNumber,
            classes,
        })
        setSteps(stepsToSet)
    }, [])
    const activeStep = useSelector((state) => state.setupflow.activeDetailPage)

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            await handleSubmit()
            handleComplete()
        }
        store.dispatch({
            type: PAGE_CHANGE_DETAIL_FLOW,
            payload: { page: activeStep + 1 },
        })
    }

    const handleBack = () => {
        store.dispatch({
            type: PAGE_CHANGE_DETAIL_FLOW,
            payload: { page: activeStep - 1 },
        })
    }

    return (
        <Box sx={{ pl: "20px" }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel
                            optional={
                                activeStep > index ? (
                                    <Typography variant="caption">
                                        {step.value(currentForm)}
                                    </Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography>{step.description}</Typography>
                            <Box sx={{ maxWidth: 800 + "px", mt: 10 + "px" }}>
                                {step.component(currentForm)}
                            </Box>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1, ml: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleNext}
                                        disabled={
                                            String(step.value(currentForm))
                                                .length === 0
                                        }
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1
                                            ? "Finish"
                                            : "Continue"}
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Back
                    </Button>
                </Paper>
            )}
        </Box>
    )
}

DetailsFormStepper.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
