import React, { useRef, useState } from "react"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { Divider, Switch } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from "../AvailabilityRebooking/popupAvailability"
import { StatusBooker } from "./statusBooker"
import { httpsCallable } from "firebase/functions"
import store from "../../store"
import { UPDATE_BOOKING_VIEW } from "../../constants/actionTypes"
import { functions } from "../../rootWrapper/firebase"

const useDialogStyles = makeStyles((theme) => ({
    overlay: {
        top: "44px",
        opacity: 1,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        padding: "2%",
    },
}))

const styles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "25ch",
    },
    gridRow: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function RefundDialog(props) {
    const { bookingId, name, price, refunded, currency, courseDocId, rpValid } =
        useSelector((state) => state.bookingSelector)
    const refundRef = useRef()
    const [rpSwitched, setSwitched] = useState(false)
    const maxAmount = refunded ? price - Number(refunded) : price

    const refundBooking = httpsCallable(functions, "refundBooking")
    const initialProgress = {
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    }
    const [currentProgress, setCurrentProgress] = useState(initialProgress)
    const style = useDialogStyles()
    const classes = styles()

    const checkAmountHasError = async (amountToRefund) => {
        if (amountToRefund === "") {
            setCurrentProgress({
                ...currentProgress,
                inProgress: false,
                success: false,
                error: true,
                errorMessage: "Please input a valid amount",
            })
            await new Promise((resolve) => setTimeout(resolve, 2000)).then(
                () => {
                    setCurrentProgress(initialProgress)
                }
            )
            return true
        }
        if (Number(amountToRefund) <= 0) {
            setCurrentProgress({
                ...currentProgress,
                inProgress: false,
                success: false,
                error: true,
                errorMessage: "Can't refund negative amount",
            })
            await new Promise((resolve) => setTimeout(resolve, 2000)).then(
                () => {
                    setCurrentProgress(initialProgress)
                }
            )
            return true
        } else if (isNaN(amountToRefund)) {
            setCurrentProgress({
                ...currentProgress,
                inProgress: false,
                success: false,
                error: true,
                errorMessage: "Invalid amount",
            })
            await new Promise((resolve) => setTimeout(resolve, 2000)).then(
                () => {
                    setCurrentProgress(initialProgress)
                }
            )
            return true
        } else if (
            Number(amountToRefund) > price ||
            Number(amountToRefund) > maxAmount
        ) {
            setCurrentProgress({
                ...currentProgress,
                inProgress: false,
                success: false,
                error: true,
                errorMessage: `Can't refund more than the price, max ${maxAmount}`,
            })
            await new Promise((resolve) => setTimeout(resolve, 2000)).then(
                () => {
                    setCurrentProgress(initialProgress)
                }
            )
            return true
        }
        return false
    }

    const submitRefundBooking = async () => {
        setCurrentProgress({
            ...currentProgress,
            inProgress: true,
        })
        let amountToRefund = 0
        if (rpValid) {
            if (!rpSwitched) {
                setCurrentProgress(initialProgress)
                return
            }
        } else {
            amountToRefund = refundRef.current.value
            const hasError = await checkAmountHasError(amountToRefund)
            if (hasError) {
                return
            }
        }

        await refundBooking({
            bookingDocId: bookingId,
            courseDocId: courseDocId,
            amountToRefund: Number(amountToRefund) || 0,
            refundRidepass: rpValid && rpSwitched,
        })
            .then(() => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: true,
                })
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
            })
            .catch((e) => {
                console.log(e)
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: false,
                    error: true,
                    errorMessage: e.message,
                })
            })
        await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
            props.handleClose()
        })
    }
    const contentSwitcher = (rpValid) => {
        if (rpValid) {
            return (
                <>
                    <Typography variant="inherit" align="center">
                        {`This action will refund the coupon usage and is not reversible`}
                    </Typography>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs style={{ maxWidth: "fit-content" }}>
                            No
                            <Switch
                                id="refund-amount"
                                label="Refund Amount"
                                name="refund"
                                color="secondary"
                                checked={rpSwitched}
                                onChange={() => setSwitched((val) => !val)}
                                style={{
                                    color: "secondary",
                                    backgroundColor: "primary",
                                }}
                            />
                            Yes
                        </Grid>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    <Typography variant="inherit" align="center">
                        {`This action will refund the amount below to the customer in 5-10 business days and is not reversible`}
                    </Typography>

                    <TextField
                        id="refund-amount"
                        label="Refund Amount"
                        type="number"
                        defaultValue={maxAmount}
                        name="open"
                        className={classes.textField}
                        color="secondary"
                        inputRef={refundRef}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{
                            color: "secondary",
                            backgroundColor: "secondary",
                        }}
                    />
                </>
            )
        }
    }

    return (
        <div>
            <Dialog
                classes={{ paper: style.overlay }}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.handleClose}
                >
                    <Typography variant="inherit" align="center">
                        {`Refund payment for user: ${name}, ${price} ${currency}`}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {contentSwitcher(rpValid)}
                    <DialogActions
                        style={{ justifyContent: "center", display: "flex" }}
                    >
                        <Grid container direction="column">
                            <Grid item xs>
                                <StatusBooker
                                    currentProgress={currentProgress}
                                    defaultText="Confirm"
                                    doBooking={() => submitRefundBooking()}
                                />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
