import {
    CLEARUSERPERMISSIONEDIT,
    PERMISSIONSSHOULDUPDATESTATE,
    SETPERMISSION,
    SETPERMISSIONEMAIL,
    SETUSERFORPERMISSION,
    SET_PERMISSION_INSTALLER_EMAIL,
} from "../constants/actionTypes"
import { accessPermissionsSchema } from "../constants/accessPermissionSchema"

const initState = {
    userId: "",
    permission: accessPermissionsSchema,
    email: "",
    installerEmail: "",
    version: false,
    shouldUpdate: 1,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SETUSERFORPERMISSION:
            return {
                ...state,
                userId: action.payload.uid,
                permission: {
                    ...initState.permission,
                    ...action.payload.permission,
                },
            }
        case PERMISSIONSSHOULDUPDATESTATE:
            return {
                ...state,
                shouldUpdate: state.shouldUpdate + 1,
            }
        case SETPERMISSION:
            return {
                ...state,
                permission: {
                    ...state.permission,
                    [action.payload.key]: {
                        ...state.permission[action.payload.key],
                        access: action.payload.value,
                    },
                },
            }
        case SETPERMISSIONEMAIL:
            return {
                ...state,
                email: action.payload.email,
            }
        case SET_PERMISSION_INSTALLER_EMAIL:
            return {
                ...state,
                installerEmail: action.payload.email,
            }
        case CLEARUSERPERMISSIONEDIT:
            return initState
        default:
            return state
    }
}
