import React, { useEffect, useState } from "react"
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_VEHICLE_NOTE } from "../../constants/actionTypes"
import CircleLoader from "../loader"
import { getLocale } from "../dateTools"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { getAvailabilityAccess } from "../../constants/accessPermissionsChecks"

const BASE_AMOUNT = 4

export default function NoteList() {
    const { selectedVehicle, vehicleNotes } = useSelector((state) => {
        return state.vehicleSelector
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const canChangeAv = getAvailabilityAccess()

    const [requestAmount, setRequestAmount] = useState(BASE_AMOUNT)
    const [loading, setLoading] = useState(false)
    const { cartDocId } = selectedVehicle
    const currentNotesObject = vehicleNotes[cartDocId]
    const requestedAmount = currentNotesObject?.requestedAmount
    const currentVehicleNotes = currentNotesObject?.notes

    const getCartNotes = httpsCallable(functions, "getCartNotes")

    useEffect(() => {
        if (!requestedAmount || requestAmount > requestedAmount) {
            setLoading(true)
            getCartNotes({
                courseDocId,
                cartDocId,
                amount: requestAmount,
            })
                .then((res) => {
                    const notesToUpload = res.data
                    console.log("notes", notesToUpload)
                    store.dispatch({
                        type: SET_VEHICLE_NOTE,
                        payload: {
                            cartDocId,
                            requestedAmount: requestAmount,
                            vehicleNotes: notesToUpload,
                        },
                    })
                    setLoading(false)
                })
                .catch((e) => {
                    console.error(e.message)
                    setLoading(false)
                })
        }
    }, [cartDocId, requestedAmount, requestAmount])

    if (loading && !currentVehicleNotes) {
        return <CircleLoader />
    }
    if (!canChangeAv) {
        return (
            <Grid
                container
                sx={{ height: "100%" }}
                alignItems={"center"}
                textAlign="center"
            >
                <Grid item xs>
                    <Typography>No access to availability notes</Typography>
                </Grid>
            </Grid>
        )
    }
    if (
        !loading &&
        (!currentVehicleNotes || currentVehicleNotes.length === 0)
    ) {
        return (
            <Grid
                container
                sx={{ height: "100%" }}
                alignItems={"center"}
                textAlign="center"
            >
                <Grid item xs>
                    <Typography>
                        No notes on this vehicle yet, write one in the box on
                        the left to try it out!
                    </Typography>
                </Grid>
            </Grid>
        )
    }
    const handleViewMore = () => {
        setRequestAmount((value) => value + BASE_AMOUNT)
    }

    return (
        <Box>
            <TableContainer sx={{ maxHeight: 204 }} component={Paper}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: "140px" }}>
                                Time
                            </TableCell>
                            <TableCell style={{ minWidth: "120px" }}>
                                Name
                            </TableCell>
                            <TableCell>Description</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentVehicleNotes.map((x, index) => {
                            const date = new Date(x.tsCreated)
                            const yeardateOptions = {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                            }
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        {date.toLocaleDateString(
                                            getLocale(),
                                            yeardateOptions
                                        )}
                                    </TableCell>
                                    <TableCell>{x.name}</TableCell>
                                    <TableCell>{x.note}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {currentVehicleNotes.length === requestedAmount && (
                <Typography
                    fontSize={"8"}
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    fontWeight="300"
                    textAlign="right"
                    onClick={handleViewMore}
                >
                    View More
                </Typography>
            )}
        </Box>
    )
}
