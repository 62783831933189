import React, { useEffect, useState } from "react"
import CircularProgress from "@mui/material/CircularProgress"
import { useSelector } from "react-redux"
import loadingStyle from "../../assets/styles/App"
import { functions } from "../../rootWrapper/firebase"
import DeleteBookingMessageIcon from "./deleteBooking"
import { RebookableMessage } from "./rebookableMessage"
import { bookingInProgress } from "./bookingAvList"
import RowMessage from "./inProgressBooking"
import store from "../../store"
import { SETBOOKING, UNSETBOOKING } from "../../constants/actionTypes"
import CircleLoader from "../loader"
import { httpsCallable } from "firebase/functions"

export default function RebookableRow(props) {
    const [loading, setLoading] = useState(false)
    const [isRebooked, setIsRebooked] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [error, setError] = useState("")
    const [noCarts, setNoCarts] = useState(false)
    const [newCart, setNewCart] = useState("")
    const { courseDocId, rebookedBookings, docRef, status } = props

    const rebookCartCallable = httpsCallable(functions, "rebookCartOfBooking")

    const cartsToBIds = useSelector((state) => state.firestore.data.carts)
    const cartId = useSelector((state) => {
        if (Object.keys(state.firestore.data.carts).indexOf(newCart) > -1) {
            return state.firestore.data.carts[newCart].id
        }
        return null
    })

    const rebookCart = () => {
        setLoading(true)
        rebookCartCallable({
            bookingDocId: docRef,
            courseDocId,
        })
            .then((res) => {
                props.setRebookedBookings([...props.rebookedBookings, docRef])
                setIsRebooked(true)
                const rebookedCart = res.data.cartDocId
                const oldCartBoardId = cartsToBIds[props.cartDocId].boardId
                const newCartBoardId = cartsToBIds[rebookedCart].boardId
                setNewCart(rebookedCart)
                store.dispatch({
                    type: UNSETBOOKING,
                    payload: { boardId: oldCartBoardId },
                })
                store.dispatch({
                    type: SETBOOKING,
                    payload: { ...props, boardId: newCartBoardId },
                })
                setLoading(false)
            })
            .catch((e) => {
                console.error(e.message)
                setError(e.message)
                setLoading(false)
                return new Promise((r) => setTimeout(r, 2000)).then(() => {
                    setError("")
                    setNoCarts(true)
                })
            })
    }

    useEffect(() => {
        if (bookingInProgress(status)) {
            setInProgress(true)
            setLoading(false)
        }
        if (Object.keys(rebookedBookings).indexOf(docRef) > -1) {
            setIsRebooked(true)
        }
    }, [docRef, rebookedBookings, status])

    if (loading) {
        return <CircleLoader />
    }
    if (inProgress) {
        return <RowMessage text="In Progress" />
    }
    if (isRebooked) {
        return <RowMessage text={`Rebooked to cart ${cartId}`} />
    }
    if (error !== "") {
        return <RowMessage text={error} />
    }
    if (noCarts) {
        return (
            <DeleteBookingMessageIcon
                {...props}
                timeString={props.timeString}
            />
        )
    }
    return <RebookableMessage rebookCart={rebookCart} />
}
