import {
    RIDEPASSFORM_CLEAR,
    RIDEPASS_ADD_SEGMENT,
    RIDEPASS_REMOVE_SEGMENT,
    RIDEPASS_CHANGE,
    SET_ACTIVE_RIDEPASS,
    SET_ACTIVE_RIDEPASS_LOADED,
    SET_ACTIVE_RIDEPASS_NOT_LOADED,
    SET_INACTIVE_RIDEPASS,
    SET_INACTIVE_RIDEPASS_LOADED,
    SET_INACTIVE_RIDEPASS_NOT_LOADED,
    UPDATECREATE_RIDEPASS_FORM,
} from "../constants/actionTypes"

const initState = {
    active: true,
    activePassesLoaded: false,
    inactivePassesLoaded: false,
    activePasses: [],
    inactivePasses: [],
    createPassForm: {
        name: "",
        price: "",
        validFrom: Date.now(),
        validTo: Date.now() + 6 * 30 * 24 * 60 * 60 * 1000,
        segments: [],
        usageLimited: true,
        usageLimit: 10,
    },
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_ACTIVE_RIDEPASS:
            return {
                ...state,
                active: true,
            }
        case SET_INACTIVE_RIDEPASS:
            return {
                ...state,
                active: false,
            }
        case UPDATECREATE_RIDEPASS_FORM:
            return {
                ...state,
                createPassForm: {
                    ...state.createPassForm,
                    ...action.payload,
                },
            }
        case RIDEPASSFORM_CLEAR:
            return {
                ...state,
                createPassForm: initState.createPassForm,
            }
        case RIDEPASS_ADD_SEGMENT:
            return {
                ...state,
                createPassForm: {
                    ...state.createPassForm,
                    segments: [
                        ...state.createPassForm.segments,
                        action.payload.segment,
                    ],
                },
            }
        case RIDEPASS_REMOVE_SEGMENT:
            return {
                ...state,
                createPassForm: {
                    ...state.createPassForm,
                    segments: state.createPassForm.segments.filter(
                        (x) => x !== action.payload.segment
                    ),
                },
            }
        case SET_ACTIVE_RIDEPASS_LOADED:
            return {
                ...state,
                activePassesLoaded: true,
                activePasses: action.payload.activePasses,
            }
        case SET_INACTIVE_RIDEPASS_LOADED:
            return {
                ...state,
                inactivePassesLoaded: true,
                inactivePasses: action.payload.inactivePasses,
            }
        case SET_ACTIVE_RIDEPASS_NOT_LOADED:
            return {
                ...state,
                activePassesLoaded: false,
            }
        case SET_INACTIVE_RIDEPASS_NOT_LOADED:
            return {
                ...state,
                inactivePassesLoaded: false,
            }
        case RIDEPASS_CHANGE:
            if (action.payload.active) {
                return {
                    ...state,
                    activePasses: [
                        ...state.activePasses,
                        ...state.inactivePasses
                            .filter((x) => x.rpDocId === action.payload.rpDocId)
                            .map((x) => {
                                x.active = true
                                return x
                            }),
                    ],
                    inactivePasses: state.inactivePasses.filter(
                        (x) => x.rpDocId !== action.payload.rpDocId
                    ),
                }
            }
            return {
                ...state,
                activePasses: state.activePasses.filter(
                    (x) => x.rpDocId !== action.payload.rpDocId
                ),
                inactivePasses: [
                    ...state.inactivePasses,
                    ...state.activePasses
                        .filter((x) => x.rpDocId === action.payload.rpDocId)
                        .map((x) => {
                            x.active = false
                            return x
                        }),
                ],
            }

        default:
            return state
    }
}
