import getSettingsRowPermissions from "./settingsTable/settingsPermissions"
import { settingsTableRowItems } from "./settingsTable"

function AccessToSetting(route, security) {
    for (let i = 0; i < route.requires.length; i++) {
        const securityRule = route.requires[i]
        if (i >= security.length || !security[securityRule]) {
            return false
        }
    }
    return true
}
export const settingsSteps = () => {
    const permissions = getSettingsRowPermissions()

    const rowsToDisplay = settingsTableRowItems
        .filter((x) => AccessToSetting(x, permissions))
        .map((x) => x.component)
    return rowsToDisplay
}
