import {
    SETBOOKING,
    SETENGINE,
    SETREADING,
    UNSETBOOKING,
    SET_NEXT_BOOKING_OF_CART,
    UNSET_NEXT_BOOKING_OF_CART,
} from "../constants/actionTypes"

export default (state = {}, action) => {
    switch (action.type) {
        case SETREADING:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    status: action.payload.status,
                    boardId: action.payload.boardId,
                    cartDocId: action.payload.cartDocId,
                    engine: action.payload.engine,
                },
            }
        case SETENGINE:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    ...action.payload,
                    boardId: action.payload.boardId,
                    cartDocId: action.payload.cartDocId,
                    engineComp: action.payload.engine,
                    engineTs: action.payload.ts,
                },
            }
        case SETBOOKING:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    booking: { ...action.payload },
                },
            }
        case UNSETBOOKING:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    booking: {},
                },
            }
        case SET_NEXT_BOOKING_OF_CART:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    nextBooking: { ...action.payload },
                },
            }
        case UNSET_NEXT_BOOKING_OF_CART:
            return {
                ...state,
                [action.payload.cartDocId]: {
                    ...state[action.payload.cartDocId],
                    nextBooking: {},
                },
            }
        default:
            return state
    }
}
