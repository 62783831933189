import {
    SET_CART_TRIPS_BOOKING,
    SET_BOOKING_VIEW_START,
    UPDATE_BOOKING_VIEW,
    SET_BOOKINGS_BOOKING_VIEW,
    SET_BOOKINGS_LOADING_BOOKING_VIEW,
    ADD_BOOKINGS_BOOKING_VIEW,
    EDIT_BOOKINGS_BOOKING_VIEW,
    REMOVE_BOOKINGS_BOOKING_VIEW,
} from "../constants/actionTypes"

const startOfDay = () => {
    const now = new Date()
    const StartOfDay = now.setHours(0, 0, 0, 0)
    return StartOfDay
}

const initState = {
    cartTrips: {},
    startTime: startOfDay(),
    endTime: startOfDay() + 24 * 60 * 60 * 1000,
    updateNumber: 0,
    bookings: [],
    bookingsLoading: false,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_CART_TRIPS_BOOKING:
            return {
                ...state,
                cartTrips: action.payload.cartTrips,
            }
        case SET_BOOKING_VIEW_START:
            return {
                ...state,
                cartTrips: {},
                startTime: action.payload.startTime,
                endTime: action.payload.startTime + 24 * 60 * 60 * 1000,
            }
        case UPDATE_BOOKING_VIEW:
            return {
                ...state,
                updateNumber: state.updateNumber + 1,
            }
        case SET_BOOKINGS_BOOKING_VIEW:
            return {
                ...state,
                bookings: action.payload.bookings,
                bookingsLoading: false,
            }
        case SET_BOOKINGS_LOADING_BOOKING_VIEW:
            return {
                ...state,
                bookingsLoading: true,
            }
        case ADD_BOOKINGS_BOOKING_VIEW:
            return {
                ...state,
                bookings: [
                    ...state.bookings.filter(
                        (x) => x.id !== action.payload.booking.id
                    ),
                    action.payload.booking,
                ],
            }
        case EDIT_BOOKINGS_BOOKING_VIEW:
            return {
                ...state,
                bookings: [
                    ...state.bookings.filter(
                        (x) => x.id !== action.payload.booking.id
                    ),
                    action.payload.booking,
                ],
            }
        case REMOVE_BOOKINGS_BOOKING_VIEW:
            return {
                ...state,
                bookings: [
                    ...state.bookings.filter(
                        (x) => x.id !== action.payload.booking.id
                    ),
                ],
            }
        default:
            return state
    }
}
