import makeStyles from "@mui/styles/makeStyles"

export const useSettingsFormStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
        paddingLeft: 16,
        paddingRight: 32,
        paddingBottom: theme.spacing(4),
    },
    textField: {
        margin: theme.spacing(1),
        minWidth: "600px",
    },
    whiteTextBox: {},
    yellowTextBox: {
        margin: theme.spacing(1),
        color: theme.palette.secondary,
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.secondary.main,
            },
            "&:hover fieldset": {
                borderColor: theme.palette.secondary.light,
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    gridRow: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    gridRowCentered: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: "flex",
        justifyContent: "center",
    },
    input: {
        color: "white",
        borderColor: "white",
    },
    timeField: {
        width: "25ch",
    },
}))
