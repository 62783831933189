import { Typography } from "@mui/material"
import React from "react"
import { progressStyle } from "../../assets/styles/components/statistics/progressBar"

function ProgressBar(props) {
    const { bgcolor, lgcolor, completed, label } = props
    const prop = { bgcolor, lgcolor, completed }
    const style = progressStyle(prop)

    return (
        <div className={style.container}>
            <div className={style.filler} />
            <Typography className={style.label}>{`${label}`}</Typography>
        </div>
    )
}

export default ProgressBar
