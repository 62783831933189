import { Box } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import getSubscriptionStatus from "../../Components/SubscriptionStatus"
import { functions } from "../../rootWrapper/firebase"
import PayScreen from "./payScreen"
import RequestUpgrade from "./requestUpgrade"
import UpgradePay from "./upgradePay"

const getSubModel = httpsCallable(functions, "getSubModel")

export default function UpgradeView() {
    const { subData } = getSubscriptionStatus()
    const [upgradeModel, setUpgradeModel] = useState()
    const [payMonthly, setPayMonthly] = useState(false)

    useEffect(() => {
        async function getModel(code) {
            console.log(code)
            const res = await getSubModel({
                code: code,
                courseDocId: subData.courseDocId,
            }).catch((e) => console.error(e.message))
            console.log(res.data)
            setUpgradeModel(res.data.model)
        }
        const code = subData?.upgrade?.to
        if (code) {
            getModel(code)
        }
    }, [subData?.upgrade?.to])

    const ToRender = subData?.upgrade?.approved ? (
        <UpgradePay
            payMonthly={payMonthly}
            setPayMonthly={setPayMonthly}
            subData={subData}
            upgradeModel={upgradeModel}
        />
    ) : (
        <RequestUpgrade subData={subData} upgradeModel={upgradeModel} />
    )

    return (
        <Routes>
            <Route
                path="pay/*"
                element={
                    <PayScreen
                        code={subData?.upgrade?.to}
                        payMonthly={payMonthly}
                    />
                }
            />
            <Route index element={ToRender} />
        </Routes>
    )
}
