import { statusMaintenance } from "../../../constants/statusTypes"

const getNameFromBooking = (booking) => {
    if (booking.status === statusMaintenance) {
        return "Maintenance"
    }
    if (booking.name === undefined || booking.name === "") {
        return "NaN"
    }
    return booking.name
}

export default getNameFromBooking
