import React from "react"
import { Route, Routes } from "react-router-dom"
import ConfirmEmail from "./confirmEmail"
import CreateCarts from "./createCarts"
import CreateCourse from "./createCourse"
import RegisterHeader from "./registerHeader"
import RegisterUser from "./registerNewUser"
import RegisterStepper from "./registerStepper"
import CreatePayment from "./payment"
import PriceSetter from "../../../models/priceSetter"
import UserWrap from "../../../rootWrapper/userWrap"
import MapWrap from "../../../rootWrapper/mapWrap"

export default function RegisterCourse() {
    PriceSetter()
    return (
        <>
            <RegisterHeader />
            <RegisterStepper />
            <Routes>
                <Route index element={<RegisterUser />} />
                <Route
                    path="*"
                    element={
                        <UserWrap>
                            <Routes>
                                <Route
                                    path="confirmEmail"
                                    element={<ConfirmEmail />}
                                />
                                <Route
                                    path="create"
                                    element={<CreateCourse />}
                                />
                                <Route
                                    path="*"
                                    element={
                                        <MapWrap fieldLoaded={true}>
                                            <Routes>
                                                <Route
                                                    path="carts"
                                                    element={<CreateCarts />}
                                                />
                                                <Route
                                                    path="payment"
                                                    element={<CreatePayment />}
                                                />
                                            </Routes>
                                        </MapWrap>
                                    }
                                />
                            </Routes>
                        </UserWrap>
                    }
                />
            </Routes>
        </>
    )
}
