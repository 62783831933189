import { SET_IMAGE, UNSET_IMAGE } from "../constants/actionTypes"

const initialState = {
    imageLoaded: false,
    image: null,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_IMAGE:
            return {
                imageLoaded: true,
                image: action.payload.image,
            }
        case UNSET_IMAGE:
            return {
                ...initialState,
            }

        default:
            return state
    }
}
