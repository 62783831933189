import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import Grid from "@mui/material/Grid"
import { InputAdornment, TextField } from "@mui/material"
import { useSettingsFormStyle } from "../../assets/styles/components/settingsForm"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { golfCourses } from "../../constants/dbPaths"
import store from "../../store"
import {
    SELECT_DETAIL_FIELD,
    SET_DETAILS_ALL_SETTINGS,
} from "../../constants/actionTypes"

export const getNumbersFromString = (input) => {
    return Number(String(input).replace(/\D/g, ""))
}

export default function DetailsForm(props) {
    const classes = useSettingsFormStyle()
    const details = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("details") > -1) {
            return {
                ...field.details,
                about: field.about,
                totalHoles: getNumbersFromString(field.details.totalHoles),
            }
        }
        return {
            about: "",
            address: "",
            county: "",
            totalHoles: 18,
            phoneNumber: "",
        }
    })
    const initState = {
        about: details.about,
        address: details.address,
        county: details.county,
        phoneNumber: details.phoneNumber,
        totalHoles: details.totalHoles,
    }
    useEffect(() => {
        store.dispatch({ type: SET_DETAILS_ALL_SETTINGS, payload: initState })
    }, [])
    const currentForm = useSelector((state) => state.DetailsForm)

    const fire = useFirebase()
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }
    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)

    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        store.dispatch({
            type: SELECT_DETAIL_FIELD,
            payload: { key: name, value },
        })
    }
    const handleInputNumber = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        if (value === "") {
            return
        }
        store.dispatch({
            type: SELECT_DETAIL_FIELD,
            payload: { key: name, value: getNumbersFromString(value) },
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!settingsAccess) {
            setError("Limited permissions - talk to admin")
            setStatus({ ...status, error: true })
            return
        }
        const detailsUpdate = {
            address: currentForm.address,
            county: currentForm.county,
            phoneNumber: currentForm.phoneNumber,
            totalHoles: currentForm.totalHoles,
        }
        console.log(detailsUpdate)
        setStatus({ ...status, loading: true })
        const courseRef = fire
            .firestore()
            .collection(golfCourses)
            .doc(courseDocId)
        fire.firestore()
            .runTransaction(async (transaction) => {
                const course = await transaction.get(courseRef)
                const courseData = course.data()
                const courseDetails = courseData?.details || {}
                const newDetails = {
                    ...courseDetails,
                    ...detailsUpdate,
                }
                transaction.update(courseRef, {
                    details: newDetails,
                    about: currentForm.about,
                })
            })
            .then(() => {
                setStatus({ ...status, loading: false, success: true })
            })
            .catch((e) => {
                setStatus({ ...status, error: true, loading: false })
                setError(e.message)
            })
        new Promise((r) => setTimeout(r, 2000)).then(() => {
            setStatus(initStatus)
            setError("")
        })
    }

    return (
        <div className={classes.root}>
            <Grid
                paddingTop={4}
                container
                direction="column"
                alignItems={"center"}
                spacing={4}
                minWidth={"800px"}
            >
                <Grid item xs>
                    <TextField
                        id="outlined-multiline-static"
                        label="About the course"
                        onChange={handleInputChange}
                        name="about"
                        multiline
                        className={classes.textField}
                        minRows={4}
                        value={currentForm.about}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        id="standard-full-width"
                        label="Address"
                        value={currentForm.address}
                        name="address"
                        className={classes.textField}
                        helperText="Address of the course"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        id="standard-full-width"
                        label="Postal code"
                        value={currentForm.county}
                        name="county"
                        className={classes.textField}
                        helperText="City and Postal Code"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        id="standard-full-width"
                        label="Phone number"
                        value={currentForm.phoneNumber}
                        name="phoneNumber"
                        className={classes.textField}
                        helperText="Phone number to contact the course"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        id="standard-full-width"
                        label="Total holes"
                        value={currentForm.totalHoles}
                        name="totalHoles"
                        type="number"
                        className={classes.textField}
                        helperText="Base hole amount per rental (18 hole course = 18, 9 hole course = 9)"
                        onChange={handleInputNumber}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs minWidth={"600px"}>
                    <SuccessLoader
                        disabled={!settingsAccess}
                        noAccess={!settingsAccess}
                        status={status}
                        error={error}
                        handleSubmit={handleSubmit}
                    />
                </Grid>
            </Grid>
        </div>
    )
}
