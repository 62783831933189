import { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import db from "../../rootWrapper/firebase"
import { golfCourses, ridepassPurchasedPath } from "../../constants/dbPaths"
import { SET_COUPON_HISTORY } from "../../constants/actionTypes"
import { Timestamp } from "firebase/firestore"

export const updateFrequency = 5 * 60 * 1000

const couponLoader = async (courseDocId, query) => {
    const { startCoupon, endCoupon, queryStart, queryEnd } = query

    let queryRes = null
    let currentQuery = {}
    if (
        queryStart &&
        queryEnd &&
        (startCoupon !== queryStart || endCoupon !== queryEnd)
    ) {
        console.log("startQuery coupon", new Date(queryStart))
        queryRes = await db
            .collection(golfCourses)
            .doc(courseDocId)
            .collection(ridepassPurchasedPath)
            .where("purchasedTs", ">=", Timestamp.fromMillis(queryStart))
            .where("purchasedTs", "<=", Timestamp.fromMillis(queryEnd))
            .orderBy("purchasedTs", "desc")
            .get()
        currentQuery.startCoupon = queryStart
        currentQuery.endCoupon = queryEnd
        currentQuery.lastUpdatedCoupon = Date.now()
        return {
            currentQuery,
            queryRes,
            completeCoupon: true,
        }
    }
    return {
        completeCoupon: false,
    }
}

export default function CouponHistoryLoader() {
    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })

    const {
        lastUpdated,
        reloadCount,
        completeCoupon,
        start,
        end,
        queryStart,
        queryEnd,
    } = query

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )

    useEffect(() => {
        async function handleTimeQuery() {
            if (courseDocId) {
                if (!completeCoupon) {
                    console.log(start, queryStart, end, queryEnd)
                    const { completeCoupon, currentQuery, queryRes } =
                        await couponLoader(courseDocId, query)

                    if (completeCoupon) {
                        store.dispatch({
                            type: SET_COUPON_HISTORY,
                            payload: {
                                coupons: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeQuery()
    }, [courseDocId, completeCoupon])

    useEffect(() => {
        async function handleTimeUpdate() {
            if (courseDocId) {
                if (
                    !completeCoupon &&
                    lastUpdated + updateFrequency < Date.now()
                ) {
                    const { completeCoupon, currentQuery, queryRes } =
                        await couponLoader(courseDocId, query)

                    if (completeCoupon) {
                        store.dispatch({
                            type: SET_COUPON_HISTORY,
                            payload: {
                                coupons: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeUpdate()
    }, [courseDocId, completeCoupon, lastUpdated, reloadCount])
}
