import React from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import CircleLoader from "../loader"
import PropTypes from "prop-types"

const render = (status, children) => {
    if (status === Status.LOADING) {
        return <CircleLoader />
    }
    if (status === Status.FAILURE) {
        console.error("Faled to load google maps")
        return children
    }
    if (status === Status.SUCCESS) {
        return children
    }
}

export default function GoogleMapWrap(props) {
    const { children } = props

    return (
        <Wrapper
            libraries={["places", "geometry"]}
            apiKey={process.env.REACT_APP_API_KEY}
            render={(status) => render(status, children)}
        />
    )
}

GoogleMapWrap.propTypes = {
    children: PropTypes.object.isRequired,
}
