import React from "react"
import { Box, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"

export default function InstallOverviewSuccessText() {
    const navigator = useNavigate()

    const handleFinish = () => {
        navigator("../finished")
    }
    return (
        <Box>
            <Typography variant="body1">
                All carts are tested and the carts are installed.
            </Typography>
            <br />
            <Typography variant="body1">
                Congratulations on finishing. You may now press finish and exit
                the page.
            </Typography>
            <Button
                onClick={handleFinish}
                color="secondary"
                variant="contained"
                fullWidth
            >
                Finish
            </Button>
        </Box>
    )
}
