import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import PremiumTypography from "../../../Components/premiumTypography"

const headerGetter = (headerGetter) => (
    <>
        <Typography display="inline-block"> EAGL</Typography>{" "}
        <Typography display="inline-block" fontWeight={"bold"}>
            {headerGetter}
        </Typography>
    </>
)

export default function RegisterHeader() {
    const headerText = useSelector(
        (state) => state.register?.priceModel?.headerText
    )
    const hasUpgrade = useSelector(
        (state) => state.register?.priceModel?.upgrade?.exists
    )

    return (
        <Box height={100} margin="16px">
            <Grid direction="row" container sx={{ textAlign: "center" }}>
                <Grid item xs></Grid>
                <Grid item xs>
                    {headerGetter(headerText)}
                </Grid>
                <Grid item xs>
                    {hasUpgrade ? (
                        <Typography
                            fontStyle="italic"
                            fontWeight={300}
                            display="inline-block"
                        >
                            You can upgrade your plan to <PremiumTypography />{" "}
                            after signup.
                        </Typography>
                    ) : (
                        <Grid />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}
