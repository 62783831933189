const formatNum = (num) => {
    const positiveInt = Math.max(num, 0)
    const rounded = Number(positiveInt.toFixed(2))
    return rounded
}

const baseReturn = {
    courseShare: formatNum(0),
    courseGross: formatNum(0),
    vatAmount: formatNum(0),
    eaglTotal: formatNum(0),
    stripeFees: formatNum(0),
}
const getAmountDistribution = (amountToCapture, costStructure) => {
    const {
        percentStripeFee,
        fixedStripeFee,
        fixedEaglFee,
        eaglCut,
        percentageVat,
        taxAddEnabled,
    } = costStructure
    if (amountToCapture === 0) {
        return baseReturn
    }

    const taxToSubtract = taxAddEnabled ? percentageVat : 0
    const priceWithoutTax = Number(
        Number(amountToCapture / (1 + taxToSubtract)).toFixed(2)
    )
    const vatAmount = Number(
        Number(amountToCapture - priceWithoutTax).toFixed(2)
    )
    const stripeFees = amountToCapture * percentStripeFee + fixedStripeFee
    if (amountToCapture <= stripeFees) {
        return {
            ...baseReturn,
            vatAmount: formatNum(vatAmount),
            stripeFees: formatNum(stripeFees),
        }
    }
    if (amountToCapture <= fixedEaglFee + stripeFees + vatAmount) {
        /** Assuming fixedEaglFee is the biggest variable */
        const toEagl = priceWithoutTax - stripeFees
        return {
            ...baseReturn,
            courseShare: formatNum(vatAmount),
            eaglTotal: formatNum(toEagl),
            stripeFees: formatNum(stripeFees),
        }
    }
    const amountToShare = Math.max(priceWithoutTax - fixedEaglFee, 0)

    const courseShareOfAmountToShare = amountToShare * (1 - eaglCut)
    const courseNet = courseShareOfAmountToShare + vatAmount - stripeFees
    console.log(courseShareOfAmountToShare, vatAmount, stripeFees, courseNet)
    const eaglTotal = fixedEaglFee + amountToShare * eaglCut
    return {
        courseShare: formatNum(courseNet),
        courseGross: formatNum(courseShareOfAmountToShare),
        vatAmount: formatNum(vatAmount),
        eaglTotal: formatNum(eaglTotal),
        stripeFees: formatNum(stripeFees),
    }
}

const getEaglShare = (amountToCapture, costStructure) => {
    const { courseShare, eaglTotal, stripeFees } = getAmountDistribution(
        amountToCapture,
        costStructure
    )
    return eaglTotal
}

const getCourseShare = (amountToCapture, costStructure) => {
    const { courseShare, eaglTotal, stripeFees } = getAmountDistribution(
        amountToCapture,
        costStructure
    )
    return courseShare
}

const getStripeFee = (amountToCapture, costStructure) => {
    const { stripeFees } = getAmountDistribution(amountToCapture, costStructure)
    return stripeFees
}

export { getCourseShare, getStripeFee }

export default getAmountDistribution
