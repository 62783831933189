import React, { useEffect, useState } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { Button, Typography } from "@mui/material"
import CircleLoader from "../loader"
import db, { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import { getAuth } from "firebase/auth"
import { golfCourses, users } from "../../constants/dbPaths"
import { logger } from "workbox-core/_private"
import { useNavigate } from "react-router-dom"

export default function CheckoutForm(props) {
    const navigate = useNavigate()
    const { requestContacted, pmIntent } = props
    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!stripe) {
            return
        }

        const setupClientSecret = new URLSearchParams(
            window.location.search
        ).get("setup_intent_client_secret")
        const pmIntentClientSecret = new URLSearchParams(
            window.location.search
        ).get("payment_intent_client_secret")
        const clientSecret = setupClientSecret || pmIntentClientSecret

        if (!clientSecret) {
            return
        }
        const pmIntentParam = Boolean(pmIntentClientSecret)
        console.log("PmIntentParam", pmIntentParam)
        setLoading(true)
        const checkSubscriptionStatus = httpsCallable(
            functions,
            "checkSubscriptionStatus"
        )
        const uid = getAuth().currentUser?.uid
        if (!uid) {
            console.error("Uid not defined", uid)
            return
        }
        const retrieveFunction = pmIntentParam
            ? stripe.retrievePaymentIntent
            : stripe.retrieveSetupIntent

        retrieveFunction(clientSecret).then((res) => {
            const intent = pmIntentParam ? res.paymentIntent : res.setupIntent
            if (intent.status === "requires_payment_method") {
                setError("Something went wrong.")
            } else if (
                intent.status === "succeeded" ||
                intent.status === "processing"
            ) {
                if (intent.status === "succeeded") {
                    setMessage("Successful payment")
                } else {
                    setMessage("Processing payment")
                }

                db.collection(users)
                    .doc(uid)
                    .get()
                    .then((userRef) => {
                        const courseDocId = userRef.data().courseDocId
                        checkSubscriptionStatus({ courseDocId, upgrade: false })
                            .then((res) => {
                                logger.log(res)
                                navigate("/")
                                setLoading(false)
                            })
                            .catch((e) => {
                                console.log(e.message)
                                setError(e.message)
                                setLoading(false)
                            })
                    })
                    .catch((e) => {
                        console.log(e.message)
                        setError(e.message)
                    })
            } else {
                logger.error(intent.status)
            }
        })
    }, [stripe, getAuth().currentUser?.uid])
    const handleSubmit = async (event) => {
        event.preventDefault()

        if (elements === null) {
            return
        }
        console.log("requested contact", requestContacted)
        setLoading(true)
        const uid = getAuth().currentUser?.uid
        const user = await db.collection(users).doc(uid).get()
        const courseDocId = user.data().courseDocId
        await db
            .collection(golfCourses)
            .doc(courseDocId)
            .update({
                requestContacted,
            })
            .catch((e) => {
                console.error(e.message)
            })

        console.log("pmIntent", pmIntent)
        let confirmSetup = pmIntent
            ? stripe.confirmPayment
            : stripe.confirmSetup

        const result = await confirmSetup({
            elements,
            confirmParams: {
                return_url: `${location.href}`,
            },
        })

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message)
            setError(result.error.message)
        }
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement type="payment" />
            <Button
                variant="contained"
                fullWidth
                color="secondary"
                type="submit"
                sx={{ fontWeight: "600" }}
                disabled={!stripe || !elements}
            >
                Start trial
            </Button>
            {loading ? <CircleLoader /> : null}
            {error ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="error"
                    fontSize="14pt"
                >
                    {error}
                </Typography>
            ) : null}
            {message ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="secondary"
                    fontSize="14pt"
                >
                    {message}
                </Typography>
            ) : null}
        </form>
    )
}
