import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import {
    SETBOOKING,
    SET_NEXT_BOOKING_OF_CART,
    UNSETBOOKING,
    UNSET_NEXT_BOOKING_OF_CART,
} from "../../constants/actionTypes"
import db from "../../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../../constants/dbPaths"
import {
    statusAllUpcoming,
    statusAllUpcomingOngoing,
} from "../../constants/statusTypes"
import { Timestamp } from "firebase/firestore"
import CartStyleOnMap from "./cartStyleOnMap"

function CartOnMap(props) {
    const { cartDocId } = props
    const course = useSelector((state) => state.firestore.data?.field)

    const bookingStatus = useSelector(
        (state) => state.cartReadings?.[cartDocId]?.booking?.status || ""
    )

    const cartState = useSelector((state) => state.cartReadings)
    const engineValue = (cartDocId) => {
        if (Object.keys(cartState).indexOf(cartDocId) > -1) {
            return cartState[cartDocId].engineComp
        }
        return false
    }
    const getVehicleIconFromGroupKey = (groupKey) =>
        course.vehicleGroups[groupKey].vehicleIcon

    return (
        <CartStyleOnMap
            {...{
                cartDocId,
                av: props.cart.available,
                engine: engineValue(cartDocId),
                bookingStatus,
                hover: props.$hover,
                id: props.cart.id,
                cart: props.cart,
                groupIcon: getVehicleIconFromGroupKey(props.cart.vehicleGroup),
            }}
        />
    )
}

export default CartOnMap
