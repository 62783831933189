import {
    Card,
    Box,
    CardMedia,
    TextField,
    CardHeader,
    Typography,
} from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { getYearMonth } from "../dateTools"
import { golfCourses } from "../../constants/dbPaths"
import store from "../../store"
import { SET_APPINFO_SAVED_ALL_CHANGES } from "../../constants/actionTypes"
import { SuccessLoader } from "../settingsForm/loader/successLoader"

export default function AppInfoCard(props) {
    const {
        text,
        setText,
        infoText,
        label,
        placeholder,
        name,
        image,
        explination,
    } = props

    const fire = useFirebase()
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })

    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }
    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)
    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const appInfo = useSelector((state) => {
        const { field } = state.firestore.data
        return {
            ...field.appInfo,
            frontPage: field.appInfo?.frontPage || "",
            ridePage: field.appInfo?.ridePage || "",
            bookingPage: field.appInfo?.bookingPage || "",
            endRidePage: field.appInfo?.endRidePage || "",
            termsOfService: field.appInfo?.termsOfService || "",
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault()
        const detailsUpdate = {
            ...appInfo,
            [name]: text,
        }
        console.log(detailsUpdate)
        setStatus({ ...status, loading: true })
        const currentDate = getYearMonth(new Date())
        fire.firestore()
            .collection(golfCourses)
            .doc(courseDocId)
            .update({ appInfo: { ...detailsUpdate, lastUpdated: currentDate } })
            .then(() => {
                setStatus({ ...status, loading: false, success: true })
                store.dispatch({
                    type: SET_APPINFO_SAVED_ALL_CHANGES,
                })
            })
            .catch((e) => {
                setStatus({ ...status, error: true, loading: false })
                setError(e.message)
            })
        new Promise((r) => setTimeout(r, 2000)).then(() => {
            setStatus(initStatus)
            setError("")
        })
    }

    return (
        <Card
            variant="outlined"
            sx={{
                margin: "10px",
                padding: "10px",
                width: "fit-content",
                maxWidth: "800px",
            }}
        >
            <CardHeader title={`${infoText}`} />
            <Box sx={{ display: "flex" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box
                        sx={{
                            minWidth: "300px",
                            maxWidth: "500px",
                            paddingRight: "10px",
                            paddingLeft: "10px",
                        }}
                    >
                        <TextField
                            id="outlined-multiline-static"
                            label={label}
                            onChange={setText}
                            name={name}
                            rows={20}
                            placeholder={placeholder}
                            multiline
                            value={text}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                        />
                        {explination ? (
                            <Typography
                                color="#DCDCDC"
                                width="100%"
                                display={"inline-block"}
                            >
                                {explination}
                            </Typography>
                        ) : null}
                        <SuccessLoader
                            disabled={!settingsAccess}
                            status={status}
                            error={error}
                            noAccess={!settingsAccess}
                            handleSubmit={handleSubmit}
                        >
                            Save
                        </SuccessLoader>
                    </Box>
                </Box>
                <CardMedia
                    component="img"
                    sx={{
                        maxWidth: "350px",
                        minWidth: "180px",
                        height: "100%",
                        borderRadius: "10px",
                    }}
                    image={image}
                    alt="Image of where the text is placed in the app"
                />
            </Box>
        </Card>
    )
}
