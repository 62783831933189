import React from "react"
import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"

export default function BoardIdDisplay() {
    const boardId = useSelector((state) => {
        const { currentCart } = state.installation
        console.log(currentCart)

        if ("boardId" in currentCart) {
            return currentCart.boardId
        }
        return ""
    })

    return (
        <Box>
            <Typography variant="h6">Board Id: {boardId}</Typography>
        </Box>
    )
}
