import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    Select,
    InputLabel,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    IconButton,
} from "@mui/material"
import React from "react"
import { vehicleSwitch } from "../../assets/cartTypeToName"
import CartIconSelector from "../../assets/vehicleIconType"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const textStyle = {
    backgroundColor: "#3B3B3B",
    borderRadius: "4px",
    marginRight: "4px",
    marginLeft: "4px",
}

export const ToolTipYellow = (props) => {
    const { toolTip, input = "(?)" } = props
    return (
        <Tooltip
            title={<Typography fontSize="14pt">{toolTip}</Typography>}
            placement="top"
        >
            <Typography
                component="span"
                display={"inline-block"}
                color="secondary"
                fontSize="inherit"
            >
                {input}
            </Typography>
        </Tooltip>
    )
}

export default function VehicleTable(props) {
    const {
        amount,
        vehicles,
        handleChange,
        namePlaceholder,
        changeAmount,
        setGroupName,
        setGroupIcon,
        rentable,
    } = props
    return (
        <Box
            sx={{
                borderWidth: 1,
                borderStyle: "solid",
                width: "880px",
                margin: "auto",
                mt: 2,
                mb: 2,
                borderRadius: "8px",
                padding: 2,
            }}
        >
            <Grid
                container
                alignItems={"center"}
                direction="row"
                sx={{ padding: 2 }}
            >
                <Grid item xs container direction="column">
                    <Box sx={{ width: 250, display: "inline-block" }}>
                        <Grid item xs>
                            <Typography
                                sx={{ pt: "16px", mr: "10px" }}
                                display={"inline-block"}
                            >
                                Vehicle Group Name:
                            </Typography>
                            <Tooltip
                                title={
                                    <h3>
                                        The name to describe the vehicles in the
                                        group. For example golf cart or scooter.
                                        If you only have golf carts, you only
                                        need one group. Max 12 characters.
                                    </h3>
                                }
                                placement="right"
                            >
                                <IconButton size="small">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                required
                                id="groupName"
                                placeholder={
                                    namePlaceholder || "Ex: Golf carts"
                                }
                                name="groupName"
                                autoComplete="off"
                                onChange={setGroupName}
                                variant="outlined"
                                inputProps={{ maxLength: 12 }}
                                sx={textStyle}
                            />
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs container direction="column">
                    <Box
                        sx={{
                            width: 250,
                            display: "inline-block",
                        }}
                    >
                        <Grid item xs>
                            <Typography
                                sx={{
                                    pt: "16px",
                                    mr: "10px",
                                }}
                                display={"inline-block"}
                            >
                                Icon:
                            </Typography>
                            <Tooltip
                                title={
                                    <h3>
                                        The icon to visualize this type of
                                        vehicle. Will be displayed in the app
                                        together with the id to identify a
                                        vehicles.
                                    </h3>
                                }
                                placement="right"
                            >
                                <IconButton size="small">
                                    <HelpOutlineIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs sx={{ height: "48px" }}>
                            <CartIconSelector
                                textStyle={textStyle}
                                onChange={setGroupIcon}
                            />
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs container direction="column">
                    <Grid item xs>
                        <Box
                            sx={{
                                width: 150,
                                ml: "auto",
                            }}
                        >
                            <Grid item xs>
                                <Typography
                                    sx={{
                                        display: "inline-block",
                                        pt: "16px",
                                        pr: "8px",
                                    }}
                                >
                                    {"Amount: "}
                                </Typography>
                                <Tooltip
                                    title={
                                        <h3>
                                            The amount of vehicles in this
                                            group. You can also add and remove
                                            vehicles with the buttons &quot;-
                                            Remove Vehicle`&quot; and &quot;+
                                            Add Vehilce&quot;
                                        </h3>
                                    }
                                    placement="right"
                                >
                                    <IconButton size="small">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    id="name"
                                    type="tel"
                                    variant="outlined"
                                    name="name"
                                    value={amount}
                                    required
                                    size="small"
                                    autoFocus
                                    sx={{
                                        width: "70px",
                                        display: "inline-block",
                                        ...textStyle,
                                    }}
                                    onChange={(e) =>
                                        changeAmount(e?.target?.value)
                                    }
                                />
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

            {amount === 60 ? (
                <>
                    <Typography
                        color="error"
                        sx={{
                            textAlign: "right",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        {"If you have more than 60 carts, please contact "}
                        <Typography
                            component="a"
                            style={{
                                display: "inline-block",
                                color: "red",
                            }}
                            href="mailto:contact@eagl.app?subject=More than 60 carts"
                        >
                            sales.
                        </Typography>
                    </Typography>
                </>
            ) : null}
            <Divider />
            <Box sx={{ pr: 4 }}>
                <Table
                    sx={{
                        width: "830px",
                        margin: "auto",
                        marginTop: 2,
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& .MuiTableCell-root": {
                                    borderBottom: "0",
                                    p: "0px",
                                    pt: "2px",
                                    pb: "2px",
                                    pl: "24px",
                                },
                            }}
                        >
                            <TableCell>Nr.</TableCell>
                            <TableCell>
                                Model <ToolTipYellow toolTip={"Lorem"} />
                            </TableCell>
                            <TableCell>
                                Seats <ToolTipYellow toolTip={"Lorem"} />
                            </TableCell>
                            <TableCell>
                                Internal description{" "}
                                <ToolTipYellow toolTip={"Lorem"} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {amount
                            ? [...Array(amount)].map((x, i) => {
                                  const currentVehicle = vehicles[i]
                                  return (
                                      <TableRow
                                          sx={{
                                              "& .MuiTableCell-root": {
                                                  borderBottom: 0,
                                                  p: "12px",
                                                  pt: "2px",
                                                  pb: "2px",
                                              },
                                          }}
                                          key={i}
                                      >
                                          <TableCell width={"20px"}>
                                              <TextField
                                                  size="small"
                                                  name="vehicle"
                                                  required
                                                  variant="outlined"
                                                  placeholder="Id"
                                                  value={currentVehicle?.name}
                                                  sx={{
                                                      width: "100%",
                                                      ...textStyle,
                                                  }}
                                                  inputProps={{ maxLength: 3 }}
                                                  onChange={(e) =>
                                                      handleChange(e, i, "name")
                                                  }
                                              />
                                          </TableCell>
                                          <TableCell
                                              width={
                                                  currentVehicle?.brand === "OT"
                                                      ? "200px"
                                                      : "120px"
                                              }
                                          >
                                              <FormControl
                                                  sx={{
                                                      width:
                                                          currentVehicle?.brand ===
                                                          "OT"
                                                              ? "140px"
                                                              : "100%",
                                                      display: "inline-block",
                                                  }}
                                              >
                                                  <InputLabel shrink={false}>
                                                      {currentVehicle?.brand
                                                          ? ""
                                                          : "Choose Model"}
                                                  </InputLabel>
                                                  <Select
                                                      fullWidth
                                                      size="small"
                                                      variant="outlined"
                                                      required
                                                      value={
                                                          currentVehicle?.brand ||
                                                          ""
                                                      }
                                                      placeholder="Model"
                                                      onChange={(e) =>
                                                          handleChange(
                                                              e,
                                                              i,
                                                              "brand",
                                                              vehicles
                                                          )
                                                      }
                                                      color="secondary"
                                                      sx={{
                                                          ...textStyle,
                                                      }}
                                                  >
                                                      {Object.keys(
                                                          vehicleSwitch
                                                      ).map((code) => (
                                                          <MenuItem
                                                              key={code}
                                                              value={code}
                                                          >
                                                              {
                                                                  vehicleSwitch[
                                                                      code
                                                                  ]
                                                              }
                                                          </MenuItem>
                                                      ))}
                                                  </Select>
                                              </FormControl>
                                              {currentVehicle?.brand ===
                                              "OT" ? (
                                                  <TextField
                                                      size="small"
                                                      placeholder="Other brand"
                                                      name="brand"
                                                      variant="outlined"
                                                      value={
                                                          currentVehicle?.other ||
                                                          ""
                                                      }
                                                      sx={{
                                                          width: "150px",
                                                          display:
                                                              "inline-block",
                                                          ...textStyle,
                                                      }}
                                                      onChange={(e) =>
                                                          handleChange(
                                                              e,
                                                              i,
                                                              "other",
                                                              vehicles
                                                          )
                                                      }
                                                  />
                                              ) : null}
                                          </TableCell>
                                          <TableCell width={"80px"}>
                                              <TextField
                                                  variant="outlined"
                                                  id="seats"
                                                  size="small"
                                                  value={currentVehicle?.seats}
                                                  type="tlf"
                                                  placeholder="Seats"
                                                  required
                                                  autoComplete="off"
                                                  name="name"
                                                  sx={{
                                                      width: "100%",
                                                      ...textStyle,
                                                  }}
                                                  onChange={(e) =>
                                                      handleChange(
                                                          e,
                                                          i,
                                                          "seats",
                                                          vehicles
                                                      )
                                                  }
                                              />
                                          </TableCell>
                                          <TableCell width={"140px"}>
                                              <TextField
                                                  variant="outlined"
                                                  id="desd"
                                                  size="small"
                                                  value={currentVehicle?.desc}
                                                  placeholder="Description"
                                                  autoComplete="off"
                                                  name="seats"
                                                  sx={{
                                                      width: "100%",
                                                      ...textStyle,
                                                  }}
                                                  onChange={(e) =>
                                                      handleChange(
                                                          e,
                                                          i,
                                                          "desc",
                                                          vehicles
                                                      )
                                                  }
                                              />
                                          </TableCell>
                                      </TableRow>
                                  )
                              })
                            : null}
                    </TableBody>
                </Table>
            </Box>
            <Grid container direction="row" sx={{ p: 2 }}>
                <Grid item xs={3}>
                    <Button
                        sx={{ fontWeight: 600 }}
                        color="secondary"
                        variant="contained"
                        onClick={() => changeAmount(Math.max(amount - 1, 0))}
                    >
                        - Remove vehicle
                    </Button>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        sx={{ fontWeight: 600 }}
                        color="secondary"
                        variant="contained"
                        onClick={() => changeAmount(amount + 1)}
                    >
                        + Add vehicle
                    </Button>
                </Grid>
                <Grid item xs>
                    <Typography fontSize={12}>
                        Vehicle groups and rental availability, prices etc. will
                        be configured at later stage. You can also add/delete
                        vehicles at any time in EAGL Dashboard.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}
