import makeStyles from "@mui/styles/makeStyles"

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.up("md")]: {
            width: "calc(100vw - 270px)",
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
        height: "100vh",
        width: "100%",
        display: "inherit",
        paddingRight: `${30}px`,
        paddingTop: `${10}px`,
        paddingLeft: `${0}px`,
        overflowX: "hidden",
    },
    grid: {
        margin: 0,
    },
    inlineText: {
        display: "inline-block",
    },
    gridContainer: {
        width: "100%",
        display: "flex",
    },
    inlineBox: {
        width: "fit-content",
        display: "inline",
    },
    inlineBoxRight: {
        pt: 1,
        marginLeft: "auto",
    },
    paper: {
        textAlign: "center",
    },
    cardspacing: {
        padding: "20px",
    },
}))
