import React from "react"
import Statistics from "../views/statistics"
import BookingHistoryLoader from "../Components/bookingHistory/bookingHistoryLoader"
import CouponHistoryLoader from "../Components/CouponPurchaseHistory/couponHistoryLoader"

export default function StatisticsWrap(props) {
    BookingHistoryLoader()
    CouponHistoryLoader()

    return <Statistics {...props} />
}
