import React from "react"
import Grid from "@mui/material/Grid"
import { Divider } from "@mui/material"
import CreateCoupon from "../Components/CouponsCreate"
import CouponsList from "../Components/CouponsList"

export default function Coupons(props) {
    return (
        <Grid container direction="row" style={{ height: `${100}%` }}>
            <Grid item xs={12} md={8}>
                <CouponsList />
            </Grid>
            <Divider flexItem orientation="vertical" />
            <Grid item xs>
                <CreateCoupon />
            </Grid>
        </Grid>
    )
}
