import React from "react"
import { Box } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
    imageIcon: {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
})

export default function CartInstallationCheck() {
    const style = useStyles()

    return (
        <Box>
            <ol>
                <li>
                    Make sure SmartBox is installed and cart has power (run mode
                    etc.)
                </li>
                <li>Double-check the Board ID and Cart ID with the SmartBox</li>
                <li>
                    Press the button below to test the keyless access
                    functionality
                </li>
            </ol>
        </Box>
    )
}
