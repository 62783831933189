import React, { useEffect } from "react"
import { getCartStyle } from "../getCartStyles"
import { styled } from "@mui/styles"
import { useSelector } from "react-redux"
import CartIconLockRow from "./CartIconRow"
import CartPopupBody from "./CartRowBody"
import db from "../../../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../../../constants/dbPaths"
import { Timestamp } from "firebase/firestore"
import store from "../../../store"
import {
    SETBOOKING,
    SET_NEXT_BOOKING_OF_CART,
    UNSETBOOKING,
    UNSET_NEXT_BOOKING_OF_CART,
} from "../../../constants/actionTypes"
import {
    statusAllUpcoming,
    statusAllUpcomingOngoing,
} from "../../../constants/statusTypes"

const StyledDiv = styled("div")(() => ({
    "&:hover": {
        zIndex: 10,
    },
}))

const zIndexTen = {
    zIndex: 10,
}

export default function CartDialog(props) {
    const { style, av, engine, selected, bookingStatus, cartDocId } = props
    const extraStyle = selected ? zIndexTen : {}

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    useEffect(() => {
        if (cartDocId && courseDocId) {
            console.log("Get current cart booking", cartDocId)
            const unsubscribe = db
                .collection(golfCourses)
                .doc(courseDocId)
                .collection(bookingPath)
                .where("cartDocId", "==", cartDocId)
                .where("status", "in", statusAllUpcomingOngoing)
                .where("startTime", "<=", Timestamp.now())
                .orderBy("startTime", "desc")
                .limit(1)
                .onSnapshot(
                    (r) => {
                        if (r.empty) {
                            store.dispatch({
                                type: UNSETBOOKING,
                                payload: { cartDocId },
                            })
                            return
                        }
                        const booking = r.docs[0].data()
                        if (
                            booking.endTime.toDate().valueOf() <= Date.now() ||
                            statusAllUpcomingOngoing.indexOf(booking.status) ===
                                -1
                        ) {
                            store.dispatch({
                                type: UNSETBOOKING,
                                payload: { cartDocId },
                            })
                            return
                        }
                        store.dispatch({
                            type: SETBOOKING,
                            payload: { cartDocId, ...booking },
                        })
                    },
                    (error) => {
                        console.error(error)
                        store.dispatch({
                            type: UNSETBOOKING,
                            payload: {
                                payload: { cartDocId },
                            },
                        })
                    }
                )
            return unsubscribe
        }
    }, [cartDocId, courseDocId])

    useEffect(() => {
        if (cartDocId && courseDocId) {
            console.log("Get next cart booking", cartDocId)
            const unsubscribe = db
                .collection(golfCourses)
                .doc(courseDocId)
                .collection(bookingPath)
                .where("cartDocId", "==", cartDocId)
                .where("status", "in", statusAllUpcoming)
                .where("startTime", ">", Timestamp.now())
                .orderBy("startTime", "asc")
                .limit(1)
                .onSnapshot(
                    (r) => {
                        if (!r.empty) {
                            const booking = r.docs[0].data()
                            store.dispatch({
                                type: SET_NEXT_BOOKING_OF_CART,
                                payload: { cartDocId, ...booking },
                            })
                        } else {
                            store.dispatch({
                                type: UNSET_NEXT_BOOKING_OF_CART,
                                payload: { cartDocId },
                            })
                        }
                    },
                    (error) => {
                        console.error(error)
                        store.dispatch({
                            type: UNSET_NEXT_BOOKING_OF_CART,
                            payload: { cartDocId },
                        })
                    }
                )
            return unsubscribe
        }
    }, [cartDocId, courseDocId])

    return (
        <StyledDiv
            style={{
                ...getCartStyle(av, engine, bookingStatus, selected),
                ...extraStyle,
                ...style,
            }}
        >
            <CartIconLockRow {...props} />
            {selected ? <CartPopupBody {...props} /> : null}
        </StyledDiv>
    )
}
