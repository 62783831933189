import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import { IconButton, Button } from "@mui/material"
import RemoveIcon from "@mui/icons-material/Remove"
import ListItemText from "@mui/material/ListItemText"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Divider,
    TextField,
    Typography,
    Box,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import {
    CLEARCREATEFORM,
    COUPONADDSEGMENT,
    COUPONREMOVESEGMENT,
    SETACTIVECOUPONSNOTLOADED,
    UPDATECREATEFORM,
    COUPONADDPARTNERCOURSE,
    COUPONREMOVEPARTNERCOURSE,
} from "../../constants/actionTypes"
import store from "../../store"
import TabPanel from "../TabPannels/TabPannel"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { httpsCallable } from "firebase/functions"
import theme from "../../assets/styles/muiTheme"
import enLocale from "date-fns/locale/en-GB"
import CreateHeader from "./createHeader"
import { functions } from "../../rootWrapper/firebase"
import { array } from "prop-types"

const textFieldSize = "small"

const useCouponListStyles = makeStyles((theme) => ({
    root: {
        height: `${100}%`,
        minHeight: `${100}vh`,
        paddingRight: `${20}px`,
        backgroundColor: theme.palette.primary.main,
    },
    formStyle: {
        paddingRight: `${10}px`,
        paddingLeft: `${10}px`,
    },
    yellowTextBox: {
        margin: theme.spacing(1),
        color: theme.palette.secondary,
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.secondary.main,
            },
            "&:hover fieldset": {
                borderColor: theme.palette.secondary.light,
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
}))

const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))

export default function CreateCoupon() {
    const classes = useCouponListStyles(theme)
    const { createState } = useSelector((state) => state.coupons)

    const couponForm = useSelector((state) => state.coupons.createCouponForm)
    const [emailList, setEmailList] = useState([])
    const [emailRecipientEmail, setEmailRecipientEmail] = useState("")
    const [emailRecipientName, setemailRecipientName] = useState("")
    const [sharedDiscountCourses, setSharedDiscountCourses] = useState([])
    const initStatus = {
        success: false,
        loading: false,
        error: false,
        errorMessage: "",
    }
    const [status, setStatus] = useState(initStatus)
    const [emailStatus, setEmailStatus] = useState(initStatus)
    const [openEmail, setOpenEmail] = useState(false)

    const handleAddEmail = (e) => {
        e.preventDefault()
        setEmailStatus({ ...initStatus, loading: true })
        let regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i
        if (!regex.test(emailRecipientEmail.replace(/\s/g, ""))) {
            setEmailStatus((emailStatus) => ({
                ...emailStatus,
                loading: false,
                error: true,
                errorMessage: "Please enter a valid email",
            }))
            wait(500).then(() => setEmailStatus(initStatus))
        } else {
            var newArray = emailList
            newArray.push({
                email: emailRecipientEmail,
                emailName: emailRecipientName,
            })
            setEmailList(newArray)
            setEmailRecipientEmail("")
            setemailRecipientName("")
            wait(500).then(() => setEmailStatus(initStatus))
        }
    }

    const handleRemoveEmail = (value) => () => {
        setEmailStatus({ ...initStatus, loading: true })

        var newArray = emailList
        newArray.pop(value)
        setEmailList(newArray)
        wait(500).then(() => setEmailStatus(initStatus))
    }

    const handlePartnerCourseChange = (e) => {
        e.preventDefault()
        const { name, checked } = e.target

        if (checked) {
            store.dispatch({
                type: COUPONADDPARTNERCOURSE,
                payload: { partnerCourse: name },
            })
        } else {
            store.dispatch({
                type: COUPONREMOVEPARTNERCOURSE,
                payload: { partnerCourse: name },
            })
        }
    }

    const sendDiscountToUser = httpsCallable(functions, "sendDiscountCode")

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === "code") {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { [name]: value.toUpperCase() },
            })
        } else {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { [name]: value },
            })
        }
    }
    const handleDateChange = (value, name) => {
        if (value === null) {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { [name]: "" },
            })
        } else {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { [name]: value.getTime() },
            })
        }
    }

    const handleDiscountChange = (e) => {
        const { value } = e.target
        if (couponForm.isPercentage) {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { discountPercentage: value, discountFixed: 0 },
            })
        } else {
            store.dispatch({
                type: UPDATECREATEFORM,
                payload: { discountPercentage: 0, discountFixed: value },
            })
        }
    }
    const handleLimitChange = (e) => {
        const { name, value } = e.target
        if (name === "usage-limit") {
            if (couponForm.usageLimited) {
                store.dispatch({
                    type: UPDATECREATEFORM,
                    payload: { usageLimit: value },
                })
            }
        }
        if (name === "per-user-limit") {
            if (couponForm.perUserLimited) {
                store.dispatch({
                    type: UPDATECREATEFORM,
                    payload: { perUserLimit: value },
                })
            }
        }
    }
    const handleUsageLimit = (e) => {
        const { checked } = e.target
        store.dispatch({
            type: UPDATECREATEFORM,
            payload: { usageLimited: checked },
        })
    }
    const handlePerUserLimit = (e) => {
        const { checked } = e.target
        store.dispatch({
            type: UPDATECREATEFORM,
            payload: { perUserLimited: checked },
        })
    }

    const priceOptions = ["Percentage Discount", "Fixed Discount"]
    const usageLimitLabels = ["Total Usage Limited", "Unlimited Usage"]
    const perUserLimitLabels = ["Per User Limited", "Unlimited Usage"]
    const currency = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("currency") > -1) {
            return field.currency.toUpperCase()
        }
        return "Nan"
    })

    const partnerCourses = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("partnerCourses") > -1) {
            return field.partnerCourses
        }
        return "Nan"
    })

    const user = useSelector((state) => {
        return state.firestore.data.user
    })

    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const segments = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("classes") > -1) {
            return Object.keys(field.classes).sort((x, y) => x.localeCompare(y))
        }
        return []
    })
    const handleDiscountTypeChange = (e) => {
        const { checked } = e.target
        store.dispatch({
            type: UPDATECREATEFORM,
            payload: { isPercentage: !checked },
        })
    }
    const handleSegChange = (e) => {
        const { name, checked } = e.target
        if (checked) {
            store.dispatch({
                type: COUPONADDSEGMENT,
                payload: { segment: name },
            })
        } else {
            store.dispatch({
                type: COUPONREMOVESEGMENT,
                payload: { segment: name },
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus({ ...initStatus, loading: true })
        if (
            (couponForm.isPercentage &&
                !parseFloat(couponForm.discountPercentage)) ||
            (!couponForm.isPercentage && !parseFloat(couponForm.discountFixed))
        ) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Discount must be a positive number",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (
            couponForm.isPercentage &&
            parseFloat(couponForm.discountPercentage) > 100
        ) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Percentage discount can't be higher than 100%",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.segments.length === 0) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "No categories selected",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.validFrom > couponForm.validTo) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Valid to must be larger than valid from",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.validTo < Date.now()) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Valid to must be a date in the future",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.code.length < 5) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Discount Code must be at least 5 characters",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.name.length < 5) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Discount Name must be at least 5 characters",
            }))
            wait(3000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.usageLimited && !parseInt(couponForm.usageLimit)) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "The usage Limit must be >0 if activated",
            }))
            wait(3000).then(() => setStatus(initStatus))
            return
        }
        if (couponForm.perUserLimited && !parseInt(couponForm.perUserLimit)) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "The per user Limit must be >0 if activated",
            }))
            wait(3000).then(() => setStatus(initStatus))
            return
        }

        if (emailList.length) {
            var discountAmountString = ""
            var validityString = ""
            var perUserLimitString = ""
            var usageLimitString = ""
            if (couponForm.isPercentage) {
                discountAmountString = `${couponForm.discountPercentage} %`
            } else {
                discountAmountString = `${couponForm.discountFixed} ${currency}`
            }
            const segmentString = couponForm.segments.join(", ")

            const validFromDate = new Date(couponForm.validFrom).toDateString()
            const validToDate = new Date(couponForm.validTo).toDateString()
            validityString = `${validFromDate} - ${validToDate}`

            if (couponForm.usageLimited) {
                usageLimitString = couponForm.usageLimit
            } else {
                usageLimitString = "UNLIMITED"
            }

            if (couponForm.perUserLimited) {
                perUserLimitString = couponForm.perUserLimit
            } else {
                perUserLimitString = "UNLIMITED"
            }

            try {
                emailList.map((mail) => {
                    return sendDiscountToUser({
                        courseDocId: courseDocId,
                        userEmail: mail.email,
                        userName: mail.emailName,
                        adminName: user.fullName,
                        discountCode: couponForm.code,
                        discountAmount: discountAmountString,
                        validity: validityString,
                        categories: segmentString,
                        usageLimit: usageLimitString,
                        perUserLimit: perUserLimitString,
                    }).catch((e) => {
                        console.error(e.message)
                    })
                })
            } catch (e) {
                console.log(e)
            }
            wait(3000).then(() => setEmailStatus(initStatus))
        }

        const createCoupon = httpsCallable(functions, "createCoupon")
        const updateCoupon = httpsCallable(functions, "editCoupon")

        const callCoupon = createState ? createCoupon : updateCoupon
        const validToEndOfDay = new Date(couponForm.validTo).setHours(
            23,
            59,
            59
        )
        return callCoupon({
            courseDocId,
            ...couponForm,
            validTo: validToEndOfDay,
        })
            .then(() => {
                store.dispatch({ type: CLEARCREATEFORM })
                store.dispatch({ type: SETACTIVECOUPONSNOTLOADED })
                setEmailList([])
                setStatus({ ...initStatus, success: true })
                wait(3000).then(() => {
                    setStatus(initStatus)
                })
            })
            .catch((e) => {
                console.error(e.message)
                setStatus({
                    ...initStatus,
                    error: true,
                    errorMessage: e.message,
                })
                wait(4000).then(() => setStatus(initStatus))
            })
    }

    return (
        <div className={classes.root}>
            <CreateHeader />
            <form onSubmit={handleSubmit} className={classes.formStyle}>
                <Grid
                    container
                    alignContent="center"
                    direction="column"
                    spacing={1}
                >
                    <Grid item xs style={{ marginTop: `${24}px` }}>
                        <Grid container direction="column">
                            <Grid item xs>
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Discount Code"
                                    size={textFieldSize}
                                    autoComplete="off"
                                    value={couponForm.code}
                                    name="code"
                                    placeholder="EAGL20"
                                    color="secondary"
                                    variant="outlined"
                                    onChange={handleInputChange}
                                    className={classes.yellowTextBox}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Discount Name"
                                    size={textFieldSize}
                                    value={couponForm.name}
                                    autoComplete="off"
                                    name="name"
                                    placeholder="EAGL partner discount"
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleInputChange}
                                    className={classes.yellowTextBox}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs>
                                {priceOptions.map((setting, index) => (
                                    <TabPanel
                                        horizontal
                                        key={index}
                                        value={couponForm.isPercentage ? 0 : 1}
                                        noPad
                                        index={index}
                                    >
                                        <TextField
                                            required
                                            id="standard-number"
                                            label={setting}
                                            size={textFieldSize}
                                            type="number"
                                            name="discount"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {couponForm.isPercentage
                                                            ? "%"
                                                            : currency}
                                                    </InputAdornment>
                                                ),
                                                inputProps: {
                                                    min: 0,
                                                    step: 1,
                                                },
                                            }}
                                            value={
                                                couponForm.isPercentage
                                                    ? couponForm.discountPercentage
                                                    : couponForm.discountFixed
                                            }
                                            color="secondary"
                                            variant="outlined"
                                            onChange={handleDiscountChange}
                                            className={classes.yellowTextBox}
                                        />
                                    </TabPanel>
                                ))}
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isPercent"
                                            color="secondary"
                                            checked={!couponForm.isPercentage}
                                            onClick={handleDiscountTypeChange}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Fixed Amount"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs>
                                {usageLimitLabels.map((setting, index) => (
                                    <TabPanel
                                        horizontal
                                        key={index}
                                        value={couponForm.usageLimited ? 0 : 1}
                                        noPad
                                        index={index}
                                    >
                                        <TextField
                                            id="standard-number"
                                            label={setting}
                                            type="number"
                                            name="usage-limit"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1,
                                                },
                                            }}
                                            size={textFieldSize}
                                            disabled={!couponForm.usageLimited}
                                            value={
                                                couponForm.usageLimited
                                                    ? couponForm.usageLimit
                                                    : ""
                                            }
                                            color="secondary"
                                            variant="outlined"
                                            onChange={handleLimitChange}
                                            className={classes.yellowTextBox}
                                        />
                                    </TabPanel>
                                ))}
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isLimitedCheckbox"
                                            color="secondary"
                                            checked={!!couponForm.usageLimited}
                                            onClick={handleUsageLimit}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Max Uses Limit"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs>
                                {perUserLimitLabels.map((setting, index) => (
                                    <TabPanel
                                        horizontal
                                        key={index}
                                        value={
                                            couponForm.perUserLimited ? 0 : 1
                                        }
                                        noPad
                                        index={index}
                                    >
                                        <TextField
                                            id="standard-number"
                                            label={setting}
                                            size={textFieldSize}
                                            type="number"
                                            name="per-user-limit"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1,
                                                },
                                            }}
                                            disabled={
                                                !couponForm.perUserLimited
                                            }
                                            value={
                                                couponForm.perUserLimited
                                                    ? couponForm.perUserLimit
                                                    : ""
                                            }
                                            variant="outlined"
                                            onChange={handleLimitChange}
                                            className={classes.yellowTextBox}
                                        />
                                    </TabPanel>
                                ))}
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isLimitedCheckbox"
                                            color="secondary"
                                            onClick={handlePerUserLimit}
                                            checked={
                                                !!couponForm.perUserLimited
                                            }
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Per User Limit"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs>
                                <LocalizationProvider
                                    adapterLocale={enLocale}
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        autoOk
                                        emptyLabel
                                        size={textFieldSize}
                                        disableToolbar
                                        variant="inline"
                                        label="Valid From"
                                        id="date-picker-inline"
                                        inputFormat="dd/MM/yyyy"
                                        value={couponForm.validFrom}
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        onChange={(e) =>
                                            handleDateChange(e, "validFrom")
                                        }
                                        color="secondary"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs paddingBottom={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={enLocale}
                                >
                                    <DatePicker
                                        autoOk
                                        emptyLabel
                                        size={textFieldSize}
                                        disableToolbar
                                        variant="inline"
                                        label="Valid To"
                                        id="date-picker-inline"
                                        inputFormat="dd/MM/yyyy"
                                        value={couponForm.validTo}
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        onChange={(e) =>
                                            handleDateChange(e, "validTo")
                                        }
                                        color="secondary"
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider />
                    <Grid item xs paddingBottom={1}>
                        <Typography variant="subtitle1">
                            Applies to Category(s):
                        </Typography>
                        <Grid container direction="column">
                            {segments.map((seg, index) => (
                                <Grid key={index} item xs>
                                    <Grid container direction="row">
                                        <Grid item xs>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        onClick={
                                                            handleSegChange
                                                        }
                                                        name={seg}
                                                        checked={
                                                            !!(
                                                                couponForm.segments.indexOf(
                                                                    seg
                                                                ) > -1
                                                            )
                                                        }
                                                    />
                                                }
                                                label={seg}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        alignItems="space-between"
                        marginTop={2}
                        marginBottom={2}
                    >
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" paddingLeft={1}>
                                (OPTIONAL) Send to user(s) :
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            {!openEmail ? (
                                <Button
                                    type="submit"
                                    id="standard-full-width"
                                    fullWidth
                                    variant={"contained"}
                                    color="secondary"
                                    label="submit"
                                    margin="normal"
                                    onClick={setOpenEmail}
                                >
                                    Add emails
                                </Button>
                            ) : (
                                <div />
                            )}
                        </Grid>
                    </Grid>
                    <Divider />
                    {openEmail ? (
                        <Grid container direction="column" paddingLeft={1}>
                            <Grid item xs>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={1}
                                    justifyContent="center"
                                >
                                    <Grid item>
                                        <TextField
                                            required
                                            fullWidth
                                            id="filled-required"
                                            label="User email"
                                            value={emailRecipientEmail}
                                            autoComplete="off"
                                            name="emailInput"
                                            placeholder="user@email.com"
                                            onChange={(e) => {
                                                setEmailRecipientEmail(
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            required
                                            fullWidth
                                            id="filled-required"
                                            value={emailRecipientName}
                                            autoComplete="off"
                                            name="nameInput"
                                            placeholder="John Walker"
                                            onChange={(e) => {
                                                setemailRecipientName(
                                                    e.target.value
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs marginTop={2}>
                                        <SuccessLoader
                                            handleSubmit={handleAddEmail}
                                            error={emailStatus.errorMessage}
                                            status={emailStatus}
                                        >
                                            Add
                                        </SuccessLoader>
                                    </Grid>

                                    <Grid item>
                                        <List dense component="div" role="list">
                                            {emailList.length != 0 &&
                                                emailList.map((value, key) => {
                                                    const labelId = `transfer-list-item-${key}-label`
                                                    return (
                                                        <ListItem
                                                            key={key}
                                                            role="listitem"
                                                        >
                                                            <IconButton
                                                                color="secondary"
                                                                aria-label="add to shopping cart"
                                                                onClick={handleRemoveEmail(
                                                                    value
                                                                )}
                                                            >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                            <ListItemText
                                                                id={labelId}
                                                                primary={`${value.emailName}: ${value.email}`}
                                                            />
                                                        </ListItem>
                                                    )
                                                })}
                                        </List>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <div></div>
                    )}

                    {Object.keys(partnerCourses).length ? (
                        <Grid
                            container
                            direction="row"
                            alignItems="space-between"
                            marginTop={2}
                            marginBottom={2}
                        >
                            <Grid item>
                                <Typography variant="subtitle1" paddingLeft={1}>
                                    (OPTIONAL) Multi-Course Discount:
                                </Typography>
                            </Grid>
                            <Grid item xs paddingLeft={1}>
                                <FormGroup>
                                    {Object.values(partnerCourses).map(
                                        (course, index) => (
                                            <Box key={index}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={
                                                                !!(
                                                                    couponForm.partnerCourses.indexOf(
                                                                        course.courseDocId
                                                                    ) > -1
                                                                )
                                                            }
                                                            onChange={
                                                                handlePartnerCourseChange
                                                            }
                                                            name={
                                                                course.courseDocId
                                                            }
                                                        />
                                                    }
                                                    label={course.name + "*"}
                                                />
                                            </Box>
                                        )
                                    )}
                                </FormGroup>
                                <Grid item>
                                    <Typography variant="subtitle2">
                                        *Partner Course needs matching category
                                        for discount to work
                                    </Typography>
                                </Grid>
                            </Grid>
                            <div></div>
                        </Grid>
                    ) : (
                        <div></div>
                    )}

                    <Divider />
                    <Grid item xs marginTop={2} marginBottom={2}>
                        <SuccessLoader
                            handleSubmit={handleSubmit}
                            error={status.errorMessage}
                            status={status}
                        >
                            {createState
                                ? "Create Discount"
                                : "Update Discount"}
                        </SuccessLoader>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
