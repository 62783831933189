import React from "react"
import {
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Table,
    Divider,
} from "@mui/material"
import { styled } from "@mui/styles"
import { useSelector } from "react-redux"
import { dateToHourMinute } from "../../dateTools"

const TableStyled = styled(Table)(({ theme }) => ({
    width: "100%",
    margin: "8px",
}))
const TableBodyStyled = styled(TableBody)(({ theme }) => ({
    width: "100%",
    borderCollapse: "collapse",
    borderSpacing: 0,
    display: "table",
    tableLayout: "fixed",
}))
const TableRowStyled = styled(TableRow)(({ theme }) => ({
    border: "none",
}))

const TableCellStyle = styled(TableCell)(({ theme }) => ({
    border: "none",
    padding: 0,
    height: "100%",
    textAlign: "center",
    verticalAlign: "center",
}))
const TableCellContent = styled("div")(() => ({
    height: "100%",
    width: "100%",
    wordWrap: "anywhere",
    textAlign: "left",
}))

const TableCellStyled = ({ colSpan, rowSpan, children }) => (
    <TableCellStyle rowSpan={rowSpan} colSpan={colSpan}>
        <TableCellContent>{children}</TableCellContent>
    </TableCellStyle>
)

export default function NextBookingRow(props) {
    const { cartDocId } = props

    const nextBooking = useSelector(
        (state) => state.cartReadings?.[cartDocId]?.nextBooking || {}
    )

    if (!nextBooking || Object.keys(nextBooking).length === 0) {
        return null
    }

    const { startTime } = nextBooking

    const today = new Date()
    if (startTime.toDate().toDateString() !== today.toDateString()) {
        /** If the bookign is the next day, the info is irrelevant*/
        return null
    }

    return (
        <>
            <Divider orientation="horizontal" />
            <TableStyled>
                <TableBodyStyled>
                    <TableRowStyled>
                        <TableCellStyled colSpan={1}>
                            <Typography fontSize={"10pt"} textAlign="center">
                                Next booking:{" "}
                                <Typography
                                    component={"span"}
                                    fontSize="inherit"
                                    color="secondary"
                                >
                                    {dateToHourMinute(startTime.toDate())}
                                </Typography>
                            </Typography>
                        </TableCellStyled>
                    </TableRowStyled>
                </TableBodyStyled>
            </TableStyled>
        </>
    )
}
