import React, { useEffect, useState } from "react"
import { Box, Typography } from "@mui/material"
import CardElement from "./cardElement"
import getSubscriptionStatus, {
    activeSubStatus,
} from "../../Components/SubscriptionStatus"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import SigninStatus from "../../models/signinStatus"
import CardInput from "./cardInput"
import { useSearchParams } from "react-router-dom"
const getPaymentCardForSub = httpsCallable(functions, "getPaymentCardForSub")
const createSetupIntent = httpsCallable(functions, "createSetupIntent")
const deletePaymentCardForSub = httpsCallable(
    functions,
    "deletePaymentCardForSub"
)

export default function CardSection() {
    const { courseDocId } = SigninStatus()
    const [cardData, setCardData] = useState()
    const [setupIntentToConfirm, setSetupIntent] = useState()
    const [searchParams, setSearchParams] = useSearchParams()
    const [sureToDelete, setSureToDelete] = useState(false)
    const [cardOpen, setCardOpen] = useState(false)

    const updateCard = new URLSearchParams(window.location.search).get("update")
    useEffect(() => {
        getPaymentCardForSub({
            courseDocId,
        })
            .then((res) => {
                setCardData(res.data)
            })
            .catch((e) => {
                console.error(e.message)
            })
        if (updateCard) {
            const search = searchParams.delete("update")
            setSearchParams(search)
        }
    }, [courseDocId, updateCard])

    const noCard = Object.keys(cardData || {}).length === 0
    const { subscriptionStatus } = getSubscriptionStatus()

    const isActive = activeSubStatus.indexOf(subscriptionStatus) > -1
    const handleReplaceCard = async () => {
        setCardOpen((open) => !open)
        const res = await createSetupIntent({ courseDocId })
        const setupIntent = res.data
        console.log("Setup intent,", setupIntent)
        setSetupIntent(setupIntent)
    }
    const deleteCard = async () => {
        const res = await deletePaymentCardForSub({ courseDocId })
        if (res) {
            setCardOpen(false)
            setCardData({})
        }
    }

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Box sx={{ maxWidth: 300 }}>
                <CardElement cardData={cardData} />
                <Typography
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    textAlign={"left"}
                >
                    <Typography
                        component="span"
                        onClick={() => handleReplaceCard()}
                    >
                        {noCard ? "Add card" : "Replace card"}
                    </Typography>

                    {sureToDelete ? (
                        <Typography
                            onClick={() => deleteCard()}
                            component="span"
                            sx={{
                                float: "right",
                                textDecoration: "underline",
                                cursor:
                                    isActive || noCard
                                        ? "not-allowed"
                                        : "pointer",
                                color:
                                    isActive || noCard
                                        ? "#DCDCDC99"
                                        : "inherit",
                            }}
                        >
                            Confirm Delete
                        </Typography>
                    ) : (
                        <Typography
                            onClick={() =>
                                !(isActive || noCard) && setSureToDelete(true)
                            }
                            component="span"
                            sx={{
                                float: "right",
                                textDecoration: "underline",
                                cursor:
                                    isActive || noCard
                                        ? "not-allowed"
                                        : "pointer",
                                color:
                                    isActive || noCard
                                        ? "#DCDCDC99"
                                        : "inherit",
                            }}
                        >
                            Delete card
                        </Typography>
                    )}
                </Typography>
            </Box>
            <CardInput setupIntent={cardOpen && setupIntentToConfirm} />
        </Box>
    )
}
