import React from "react"
import { Step, StepButton, Stepper } from "@mui/material"
import { Box } from "@mui/system"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"

export default function StepperSidebar(props) {
    const {
        activePage,
        steps,
        completed,
        handleStepPress,
        handleBack,
        handleNext,
    } = props
    return (
        <Box
            sx={{
                width: 200,
                padding: 16 + "px",
                boxShadow: 16,
                borderRadius: 2,
                borderWidth: 2 + "px",
                borderColor: "gray",
                borderStyle: "solid",
                minHeight: 322 + "px",
                ml: "auto",
            }}
        >
            <Stepper orientation="vertical" nonLinear activeStep={activePage}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton onClick={handleStepPress(index)}>
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}

StepperSidebar.propTypes = {
    activePage: PropTypes.number,
    steps: PropTypes.array,
    completed: PropTypes.object,
    handleStepPress: PropTypes.func,
    handleBack: PropTypes.func,
    handleNext: PropTypes.func,
}
