import { httpsCallable } from "firebase/functions"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import getSubscriptionStatus from "../Components/SubscriptionStatus"
import { SET_PAYMENT_MODEL, SET_PRICE_MODEL } from "../constants/actionTypes"
import { functions } from "../rootWrapper/firebase"
import store from "../store"

const getSubModel = httpsCallable(functions, "getSubModel")

export default function PriceSetter() {
    const [searchParams] = useSearchParams()
    const code = searchParams.has("code") && searchParams.get("code")
    const user = useSelector((state) => state.firestore?.data?.user)
    const courseDocId = user?.courseDocId
    const userCode = user?.registerCode
    const combinedCode = code || userCode || ""
    const existingCode = useSelector((state) => state.register.code)
    const priceModel = useSelector((state) => state.register.priceModel)
    const { subData } = getSubscriptionStatus()

    useEffect(() => {
        if (!existingCode && combinedCode) {
            console.log("CODE", combinedCode)
            store.dispatch({
                type: SET_PAYMENT_MODEL,
                payload: { code: combinedCode },
            })
        }
    }, [combinedCode, existingCode])

    useEffect(() => {
        async function getModel(existingCode) {
            console.log(existingCode)
            const res = await getSubModel({
                code: existingCode,
                courseDocId,
            }).catch((e) => console.error(e.message))
            console.log("model", res.data.model)
            store.dispatch({
                type: SET_PRICE_MODEL,
                payload: { priceModel: res.data.model },
            })
        }
        if (existingCode && courseDocId && !Object.keys(priceModel).length) {
            getModel(existingCode)
        }
    }, [existingCode, priceModel, courseDocId])
}
