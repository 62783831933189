export const requiredConsent = (analytics) => ({
    /** Enables storage, such as cookies, related to advertising */
    ad_storage: "denied",
    /** Enables storage, such as cookies, related to analytics (for example, visit duration) */
    analytics_storage: analytics ? "granted" : "denied",
    /**
     * Enables storage that supports the functionality of the website or app such as language settings
     */
    functionality_storage: "denied",
    /** Enables storage related to personalization such as video recommendations */
    personalization_storage: "denied",
    /**
     * Enables storage related to security such as authentication functionality, fraud prevention,
     * and other user protection.
     */
    security_storage: "denied",
})
