import React from "react"
import withStyles from "@mui/styles/withStyles"
import MuiDialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { ReactComponent as GolfCartIcon } from "../../assets/img/GolfCart.svg"
import { ReactComponent as GolfCartLIcon } from "../../assets/img/GolfCartLong.svg"
import { ReactComponent as GolfCartLIconUN } from "../../assets/img/GolfCartLongUN.svg"
import { ReactComponent as ScooterIcon } from "../../assets/img/Scooter.svg"
import { ReactComponent as ScooterIconUN } from "../../assets/img/ScooterUNIcon.svg"
import { ReactComponent as MarshallIcon } from "../../assets/img/Marshall.svg"
import { ReactComponent as MarshallIconUN } from "../../assets/img/MarshallUN.svg"
import { ReactComponent as Wheeler4Icon } from "../../assets/img/4Wheeler.svg"
import { ReactComponent as Wheeler4UN } from "../../assets/img/4WheelerUN.svg"
import { ReactComponent as GolfCartIconUN } from "../../assets/img/GolfCartUnavailable.svg"
import { ReactComponent as FinnScooter } from "../../assets/img/finnScooter.svg"
import { ReactComponent as FinnScooterUN } from "../../assets/img/finnScooterUN.svg"

import { getBorderStyle } from "./getCartStyles"
import { makeStyles } from "@mui/styles"

export const iconOptions = [
    { value: "0", label: "Golf Cart", icon: GolfCartIcon },
    { value: "1", label: "Scooter", icon: ScooterIcon },
    { value: "2", label: "4 Wheeler", icon: Wheeler4Icon },
    { value: "3", label: "Golf Cart Long", icon: GolfCartLIcon },
    { value: "4", label: "Marshall", icon: MarshallIcon },
    { value: "5", label: "Golf Cart", icon: GolfCartIcon },
    { value: "6", label: "Finn Scooter", icon: FinnScooter },
]

export const iconUnavailableOptions = [
    { value: "0", label: "Golf Cart", icon: GolfCartIconUN },
    { value: "1", label: "Scooter", icon: ScooterIconUN },
    { value: "2", label: "4 Wheeler", icon: Wheeler4UN },
    { value: "3", label: "Golf Cart Long", icon: GolfCartLIconUN },
    { value: "4", label: "Marshall", icon: MarshallIconUN },
    { value: "5", label: "Golf Cart", icon: GolfCartIconUN },
    { value: "6", label: "Finn Scooter", icon: FinnScooterUN },
]

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: "secondary",
    },
})

const useStyles = makeStyles((theme) => ({
    overlay: {
        top: "44px",
        opacity: 0.95,
        zIndex: 1,
        width: `${100}%`,
        minWidth: `${300}px`,
        position: "absolute",
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.warning.main,
        padding: "2%",
        borderColor: getBorderStyle(),
    },
}))

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props
    return (
        <MuiDialogTitle className={classes.root} {...other}>
            <Typography variant="div">{children}</Typography>
        </MuiDialogTitle>
    )
})

const timeDate = (timestamp) =>
    `${timestamp.getHours() < 10 ? "0" : ""}${timestamp.getHours()}:${
        timestamp.getMinutes() < 10 ? "0" : ""
    }${timestamp.getMinutes()}:${
        timestamp.getSeconds() < 10 ? "0" : ""
    }${timestamp.getSeconds()} - ${
        timestamp.getDate() < 10 ? "0" : ""
    }${timestamp.getDate()}/${timestamp.getMonth() < 10 ? "0" : ""}${
        timestamp.getMonth() + 1
    }/${timestamp.getFullYear()}`
