import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import theme from "../../assets/styles/muiTheme"
import { getLocale } from "../dateTools"
import StarIcon from "@mui/icons-material/Star"

const useStyles = makeStyles((theme) => ({
    rootBox: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "10px",
        padding: "15px",
        overflowY: "auto",
    },
}))

const getRideString = (booking) => {
    return (
        booking.startTime.toDate().toLocaleDateString(getLocale(), {
            weekday: "short",
        }) +
        " " +
        booking.startTime.toDate().toLocaleDateString(getLocale(), {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        })
    )
}
const getRideStringTimePeriod = (booking) => {
    return (
        booking.startTime.toDate().toLocaleTimeString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit",
        }) +
        "-" +
        booking.endTime.toDate().toLocaleTimeString(getLocale(), {
            hour: "2-digit",
            minute: "2-digit",
        })
    )
}

export const getStars = (stars) => {
    if (stars === 0) {
        return <div></div>
    }
    return [...Array(5)].map((_, index) => {
        const color = index + 1 <= stars ? "secondary" : "white"
        return <StarIcon key={index} color={color} />
    })
}

export default function RatingCard(props) {
    const classes = useStyles(theme)
    return (
        <Box className={classes.rootBox} width="500px" height="160px">
            <Grid
                height={"100%"}
                container
                direction="column"
                justifyContent="space-around"
            >
                <Grid item xs>
                    <Grid container direction="column">
                        <Grid height={"30px"} item xs>
                            <Grid container direction="row">
                                <Grid item xs>
                                    <Typography variant="h6">
                                        Vehicle No. {props.cartId}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    container
                                    direction="column"
                                    justifyItems="start"
                                >
                                    <Grid sx={{ textAlign: "right" }} item xs>
                                        <Typography
                                            style={{ display: "inline-block" }}
                                            fontSize="9pt"
                                        >
                                            {getRideString(props)}
                                        </Typography>
                                    </Grid>
                                    <Grid sx={{ textAlign: "right" }} item xs>
                                        <Typography
                                            style={{ display: "inline-block" }}
                                            fontSize="9pt"
                                        >
                                            {getRideStringTimePeriod(props)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="subtitle1">
                                {props.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                variant="body2"
                                sx={{ maxHeight: "80px", overflow: "auto" }}
                                fontSize="10pt"
                            >
                                {props.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item direction="row">
                    <Grid item xs>
                        <Box>
                            <Typography fontSize="10pt">
                                Phone:{" "}
                                <Typography
                                    component="span"
                                    display={"inline-block"}
                                    color="#DCDCDCC9"
                                    fontSize="10pt"
                                >
                                    {props.phonePrefix} {props.tlf}
                                </Typography>
                            </Typography>
                            {props.email ? (
                                <Typography fontSize="10pt">
                                    Email:{" "}
                                    <Typography
                                        component="span"
                                        display={"inline-block"}
                                        color="#DCDCDCC9"
                                        fontSize="10pt"
                                    >
                                        {props.email}
                                    </Typography>
                                </Typography>
                            ) : null}
                        </Box>
                    </Grid>

                    <Grid item xs sx={{ textAlign: "right" }}>
                        {getStars(props.starRating)}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
