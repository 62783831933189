import {
    SET_BOOKING_QUERY,
    SET_BOOKING_QUERY_EXECUTE,
    SET_BOOKINGS,
    SET_COUPON_HISTORY,
    SET_STATS,
    SET_REPORT_LIMIT,
    SET_REPORTS,
    SET_REPORTS_NOT_LOADED,
} from "../constants/actionTypes"

const initialQuery = {
    lastUpdated: Date.now(),
    reloadCount: 0,
    queryComplete: false,
    edited: false,
    start: 0,
    end: 0,
    queryStart: Date.now() - 7 * 24 * 60 * 60 * 1000,
    queryEnd: Date.now(),
    startSearch: Date.now() - 7 * 24 * 60 * 60 * 1000,
    endSearch: Date.now(),
    // Include coupon stats to load coupon purchase from same query
    completeCoupon: false,
    startCoupon: 0,
    endCoupon: 0,
}

const initStats = {
    totalBookings: 0,
    totalRentalTime: 0,
    incomeTotal: 0,
    starsTotal: 0,
    starBookings: 0,
    totalDashIncome: 0,
    totalRefund: 0,
    totalDiscount: 0,
    totalAppIncome: 0,
    totalCouponIncome: 0,
    appStarted: 0,
    segmentSum: {},
    weekdayBookings: {},
    appVsDash: {},
}

const initialState = {
    bookings: [],
    coupons: [],
    reports: [],
    stats: {
        ...initStats,
    },
    bookingQuery: {
        ...initialQuery,
    },
    reportLimit: 5,
    reportLoaded: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOKING_QUERY_EXECUTE:
            return {
                ...state,
                bookingQuery: {
                    ...state.bookingQuery,
                    reloadCount: state.bookingQuery.reloadCount + 1,
                    queryComplete: false,
                    completeCoupon: false,
                    startSearch: state.bookingQuery.queryStart,
                    endSearch: state.bookingQuery.queryEnd,
                },
            }
        case SET_BOOKING_QUERY:
            return {
                ...state,
                bookingQuery: {
                    ...initialQuery,
                    ...state.bookingQuery,
                    ...action.payload.query,
                    edited: true,
                },
            }
        case SET_BOOKINGS:
            return {
                ...state,
                bookings: action.payload.bookings,
                bookingQuery: {
                    ...initialQuery,
                    ...state.bookingQuery,
                    ...action.payload.query,
                    queryComplete: true,
                    edited: false,
                },
            }
        case SET_COUPON_HISTORY:
            return {
                ...state,
                coupons: action.payload.coupons,
                bookingQuery: {
                    ...initialQuery,
                    ...state.bookingQuery,
                    ...action.payload.query,
                    completeCoupon: true,
                },
            }
        case SET_STATS:
            return {
                ...state,
                stats: {
                    ...initStats,
                    ...state.stats,
                    ...action.payload.stats,
                },
            }
        case SET_REPORTS:
            return {
                ...state,
                reports: action.payload.reports,
                reportLoaded: true,
            }
        case SET_REPORT_LIMIT:
            return {
                ...state,
                reportLimit: state.reportLimit + 5,
                reportLoaded: false,
            }
        case SET_REPORTS_NOT_LOADED:
            return {
                ...state,
                reportLoaded: false,
            }
        default:
            return state
    }
}
