import { Box, IconButton, TextField, Tooltip } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../../../store"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"

const title = <>Unlock window</>
const desc = (
    <>
        The time period after the booked pick up time where the player can start
        the vehicle. We recommend setting this to a minimum of 30 minutes.
        <br />
        <br />
        The rentee will not be able to start the vehicle after the period ends
        and the booking will be marked as expired.
    </>
)

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const unlockDur = currentForm.unlockDur || {}
    const currentHours = unlockDur.hr
    const currentMinutes = unlockDur.min
    return `${currentHours} hours ${currentMinutes} minutes`
}

export const minutesToHourMin = (fullMinutes) => {
    const hours = Math.floor(fullMinutes / 60) || 0
    const minutes = fullMinutes - hours * 60
    return {
        hours,
        minutes,
    }
}

function UnlockDurationItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    let unlockDur = {}
    if (currentForm.unlockDur) {
        unlockDur = currentForm.unlockDur
    } else {
        const hourMin = minutesToHourMin(currentForm.bufferStart)
        unlockDur = {
            hr: Math.floor(hourMin.hours),
            min: Number(hourMin.minutes.toFixed(2)),
        }
    }
    useEffect(() => {
        if (currentForm.settingsLoaded && !currentForm.unlockDur) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "unlockDur",
                    value: {
                        ...unlockDur,
                    },
                },
            })
        }
    }, [currentForm.settingsLoaded, currentForm.unlockDur])

    const currentHours = unlockDur.hr
    const currentMinutes = unlockDur.min

    const handleInputChangeHr = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "unlockDur",
                    value: {
                        hr: Math.min(parseInt(value), 23),
                        min: unlockDur.min || 0,
                    },
                },
            })
        }
    }
    const handleInputChangeMin = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "unlockDur",
                    value: {
                        hr: unlockDur.hr || 0,
                        min: Math.min(Number(parseFloat(value).toFixed(2)), 59),
                    },
                },
            })
        }
    }
    return (
        <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
        >
            <TextField
                id="unlockDuration"
                label="Hours"
                size="small"
                value={!isNaN(currentHours) ? currentHours : ""}
                name="unlockDuration"
                type="number"
                variant="standard"
                onChange={handleInputChangeHr}
                margin="normal"
                sx={{ width: "45%" }}
                inputProps={{
                    min: 0,
                    max: 12,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="unlockDuration"
                label="Minutes"
                size="small"
                value={!isNaN(currentMinutes) ? currentMinutes : ""}
                name="unlockDuration"
                type="number"
                variant="standard"
                onChange={handleInputChangeMin}
                sx={{ width: "45%" }}
                margin="normal"
                inputProps={{
                    min: 0,
                    max: 60,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: UnlockDurationItem,
}
