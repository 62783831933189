import { createTheme } from "@mui/material/styles"

export const PRIMARY = "#1B2026"
const PRIMARYLIGHT = "#2c353f"
const PRIMARYDARK = "#15191e"
export const SECONDARY = "#ffc200"
const SECONDARY_GRAY = "#CCB955"
const SECONDARYLIGHT = "#ffce32"
const SECONDARYDARK = "#cc9b00"
const BACKGROUND = "#13161B"
const COLOR_PEACH = "#ffbb84"
const COLOR_GRAY_RED = "#c1564c"
const COLOR_GRAY_GREEN = "#00d19d"
const COLOR_DEACTIVATED_PRIMARY = "#98b0a9"
const DEFAULT_GRAY = "#272727"

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: PRIMARYLIGHT,
            main: PRIMARY,
            dark: BACKGROUND,
            darker: "#191e24",
        },
        secondary: {
            main: SECONDARY,
        },
        background: {
            default: BACKGROUND,
        },
        forms: {
            main: "#e5e5e5",
        },
        red: {
            light: "#ff4861",
            dark: "#eb2a1c",
        },
        green: {
            main: "#04C300",
        },
        success: {
            main: "#00FF00",
        },
        warning: {
            main: "#FF9900",
        },
        error: {
            main: "#FF0000",
        },
        lightgreen: {
            light: "#b8e986",
            dark: "#a2bd1c",
        },
        blue: {
            light: "#7edbff",
            main: "#00aaff",
            dark: "#48b5ff",
        },
        darkgreen: {
            dark: "#198207",
            light: "#a6efda",
        },
        pink: {
            main: "#F87FAA",
        },
        purple: {
            main: "#C981F9",
        },
        neongreen: {
            main: "#5BE2BD",
        },
        moneyblue: {
            main: "#59A3E9",
        },
        yellow: {
            main: "#E3DB4F",
        },
        white: {
            main: "rgba(255, 255, 255, 0.7);",
        },
        gray: {
            default: DEFAULT_GRAY,
            main: "#C4C4C4",
            light: "#DCDCDC",
        },
    },
    components: {
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    color: COLOR_DEACTIVATED_PRIMARY,
                    "&.Mui-active": {
                        color: SECONDARY_GRAY,
                        textColor: "black",
                    },
                    "&.Mui-completed": {
                        color: SECONDARY,
                    },
                },
            },
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    "&.Mui-completed": {
                        color: "rgba(255, 255, 255, 0.7)",
                        fontWeight: "normal",
                    },
                    "&.Mui-active.Mui-completed": {
                        color: "white",
                        fontWeight: "500",
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: BACKGROUND,
                },
            },
        },

        MuiPickersDay: {
            styleOverrides: {
                root: {
                    backgroundColor: PRIMARYLIGHT,
                    "&:hover": {
                        backgroundColor: SECONDARYLIGHT,
                    },
                },
            },
        },
        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    backgroundColor: PRIMARY,
                    maxHeight: `${300}px`,
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: "none",
                },
            },
        },
        MuiAppBar: {
            defaultProps: {
                color: "secondary",
                position: "static",
            },
            styleOverrides: {
                root: {
                    backgroundImage: "none",
                    backgroundColor: PRIMARY,
                },
            },
        },
        MuiTabs: {
            defaultProps: {
                color: "secondary",
                textColor: "secondary",
                indicatorColor: "secondary",
            },
        },
        MuiCheckbox: {
            defaultProps: {
                color: "secondary",
            },
        },

        MuiTextField: {
            defaultProps: {
                color: "secondary",
            },
            styleOverrides: {
                root: {
                    marginTop: "8px",
                    "& .Mui-focused": {
                        color: "white",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "white",
                    },
                    "& MuiInputLabel": {
                        "&.Mui-focused": {
                            color: SECONDARY,
                        },
                    },
                    "& .MuiFormLabel-root": {
                        color: "white",
                    },
                    "& .MuiInputLabel-root": {
                        color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "none",
                        },
                        "&:hover fieldset": {
                            borderColor: SECONDARY,
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "white",
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            defaultProps: {
                color: "secondary",
                size: "small",
            },
            styleOverrides: {
                root: {
                    marginTop: "8px",
                    "& .Mui-focused": {
                        color: "white",
                    },
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "white",
                    },
                    "& MuiInputLabel": {
                        "&.Mui-focused": {
                            color: SECONDARY,
                        },
                    },
                    "& .MuiFormLabel-root": {
                        color: "white",
                    },
                    "& .MuiInputLabel-root": {
                        color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: "none",
                        },
                        "&:hover fieldset": {
                            borderColor: SECONDARY,
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "white",
                        },
                    },
                },
            },
        },
    },
})

const prettyColorScale = [
    theme.palette.moneyblue.main,
    theme.palette.neongreen.main,
    theme.palette.purple.main,
    theme.palette.pink.main,
    theme.palette.yellow.main,
]

export { prettyColorScale }
export default theme
