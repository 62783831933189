import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import CircleLoader from "../loader"
import store from "../../store"
import { SET_SERVICE_EXPANDED } from "../../constants/actionTypes"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { styled } from "@mui/styles"
import theme from "../../assets/styles/muiTheme"

const useStyles = makeStyles((theme) => ({
    profilePictureWrap: {
        position: "relative",
        display: "block",
        overflow: "hidden",
        height: "200px",
        width: "100%",
        paddingTop: 20,
    },
    profilePicture: {
        display: "flex",
        margin: "auto",
        width: "auto",
        height: "130px",
        borderRadius: `${25}px`,
    },
    userInfo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        bottom: 0,
        display: "flex",
        margin: "auto",
        width: "100%",
        textAlign: "center",
    },
    altText: {
        margin: "auto",
        width: "80%",
    },
    accordionSummary: {
        display: "flex",
        alignItems: "center",
        width: "100%",
    },
    appInfoStyle: {
        "& a": {
            color: "#FCC200",
        },
    },
}))
const PrimaryAccordation = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none",
}))

const handlePressExpanded = (expanded) => {
    store.dispatch({
        type: SET_SERVICE_EXPANDED,
        payload: {
            expanded,
        },
    })
}

const getServiceColor = (severity) => {
    if (severity === 3) {
        return theme.palette.error.main
    }
    if (severity === 2) {
        return theme.palette.warning.main
    }
    if (severity === 1) {
        return theme.palette.blue.main
    }
    return theme.palette.success.main
}

export default function StatusBar() {
    const classes = useStyles()

    const { serviceStatus, expanded } = useSelector(
        (state) => state.serviceStatus
    )
    const worstServiceStatus = Object.keys(serviceStatus).reduce(
        (lowest, key) => {
            if (serviceStatus[key].severity > lowest) {
                return serviceStatus[key].severity
            }
            return lowest
        },
        0
    )
    useEffect(() => {
        if (worstServiceStatus > 1) {
            store.dispatch({
                type: SET_SERVICE_EXPANDED,
                payload: {
                    expanded: true,
                },
            })
        }
    }, [worstServiceStatus])

    if (serviceStatus && Object.keys(serviceStatus).length) {
        return (
            <PrimaryAccordation
                expanded={expanded}
                onChange={() => handlePressExpanded(!expanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <div className={classes.accordionSummary}>
                        <Typography sx={{ width: "60%", flexShrink: 0 }}>
                            Service Status
                        </Typography>
                        <div
                            style={{
                                display: { xs: "none", sm: "block" },
                                height: 20 + "px",
                                width: 20 + "px",
                                backgroundColor:
                                    getServiceColor(worstServiceStatus),
                                borderRadius: 100 + "px",
                                marginLeft: "auto",
                                marginRight: "5px",
                            }}
                        ></div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    {Object.keys(serviceStatus)
                        .sort((x, y) => x.localeCompare(y))
                        .map((statusKey) => {
                            return (
                                <div
                                    key={statusKey}
                                    style={{ paddingBottom: 10 + "px" }}
                                >
                                    <div className={classes.accordionSummary}>
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={"bold"}
                                            sx={{ width: "60%", flexShrink: 0 }}
                                        >
                                            {statusKey
                                                //Regex to pascal case
                                                .replace(
                                                    /(\w)(\w*)/g,
                                                    function (g0, g1, g2) {
                                                        return (
                                                            g1.toUpperCase() +
                                                            g2.toLowerCase()
                                                        )
                                                    }
                                                )}
                                        </Typography>
                                        <div
                                            style={{
                                                display: {
                                                    xs: "none",
                                                    sm: "block",
                                                },
                                                height: 20 + "px",
                                                width: 20 + "px",
                                                backgroundColor:
                                                    getServiceColor(
                                                        serviceStatus[statusKey]
                                                            .severity
                                                    ),
                                                borderRadius: 100 + "px",
                                                marginLeft: "auto",
                                                marginRight: "5px",
                                            }}
                                        />
                                    </div>
                                    <div
                                        className={classes.appInfoStyle}
                                        dangerouslySetInnerHTML={{
                                            __html: serviceStatus[statusKey]
                                                .message,
                                        }}
                                    />
                                </div>
                            )
                        })}
                </AccordionDetails>
            </PrimaryAccordation>
        )
    }
    return <CircleLoader />
}
