import {
    Box,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { Link } from "react-router-dom"
import theme from "../../../../assets/styles/muiTheme"

const title = "Open for app bookings"
const desc = "Defines if the course can be found in the .EAGL App"

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    return currentForm.appBooking ? "Yes" : "No"
}

function AppBookingItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    const handleInputChange = () => {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "appBooking",
                value: Boolean(!currentForm.appBooking),
            },
        })
    }

    return (
        <Box sx={{ textAlign: "center" }}>
            <Switch
                sx={{ margin: "auto" }}
                onClick={handleInputChange}
                checked={currentForm.appBooking || false}
                disabled={!currentForm.stripeCompleted}
                color="secondary"
                align="right"
            >
                Press to turn on cart
            </Switch>
            {!currentForm.stripeCompleted ? (
                <Tooltip
                    title={
                        <Typography fontSize="12pt">
                            App booking is disabled until you finish stripe
                            registration. You need to register in stripe to be
                            able to receive payments.{" "}
                            <Link
                                style={{ color: theme.palette.secondary.main }}
                                to="/setup?page=2"
                                component="a"
                            >
                                Click here to set up now
                            </Link>
                        </Typography>
                    }
                    placement="top"
                >
                    <IconButton size="small">
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: AppBookingItem,
}
