import { Box, Typography } from "@mui/material"
import React from "react"
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryLine,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from "victory"
import theme from "../../assets/styles/muiTheme"
import { statusFinishedWithAdmin } from "../../constants/statusTypes"
import { intTwoDigitString } from "../dateTools"
import PropTypes from "prop-types"

const dateToHourInt = (dateMs) => {
    return (dateMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
}

const intToHour = (hourInt) => {
    let halfHour = false
    if (hourInt % 1 > 0.4) {
        halfHour = true
    }
    return intTwoDigitString(Math.floor(hourInt)) + (halfHour ? ":30" : ":00")
}

const intToTimeRange = (hourInt) => {
    const hourIntStop = hourInt + 0.5
    return intToHour(hourInt) + " - " + intToHour(hourIntStop)
}

export default function LineChartOneDay(props) {
    const hours = [0, 6, 12, 18, 24]
    const { bookings } = props

    const bookingHours = bookings
        .filter((x) => statusFinishedWithAdmin.indexOf(x.status) > -1)
        .map((x) => x.startTime.toMillis())
        .map((time) => dateToHourInt(time)) // Remove date and spread to 0 - 24
        .map((time) => Math.floor(time * 2) / 2) // Round to closes half an hour
    const bookingHourData = bookingHours.reduce((data, key) => {
        // Accumulate equal values
        const match = data.filter((x) => x.x === key)
        if (match.length) {
            const noMatch = data.filter((x) => x.x !== key)
            return [
                ...noMatch,
                {
                    x: key,
                    y: match[0].y + 1,
                },
            ]
        }

        return [
            ...data,
            {
                x: key,
                y: 1,
            },
        ]
    }, [])
    const maxCount = bookingHourData
        .map((x) => x.y)
        .reduce((max, x) => Math.max(max, x), 5)

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <VictoryChart
                padding={{ top: 60, bottom: 55, right: 20, left: 30 }}
                domainPadding={{ top: 50 }}
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiDimension="x"
                        labels={({ datum }) =>
                            `${intToTimeRange(datum.x)} : ${datum.y}`
                        }
                        labelComponent={
                            <VictoryTooltip
                                cornerRadius={0}
                                flyoutStyle={{
                                    fill: "white",
                                    stroke: "none",
                                    color: "black",
                                }}
                            />
                        }
                    />
                }
            >
                <VictoryLabel
                    text={"Pick-Up Time Trend"}
                    x={0}
                    y={30}
                    style={{ fill: "white", fontSize: 18 }}
                />
                <VictoryAxis
                    domain={[0, 24]}
                    tickValues={hours}
                    tickFormat={(datum) => intToHour(datum)}
                    style={{
                        tickLabels: {
                            color: "white",
                            fill: "white",
                        },
                    }}
                />
                <VictoryAxis
                    minDomain={0}
                    domain={[0, maxCount]}
                    dependentAxis={true}
                    style={{
                        grid: {
                            stroke: "#DCDCDC40",
                        },
                        tickLabels: {
                            fill: "white",
                        },
                    }}
                />
                <VictoryLine
                    data={bookingHourData}
                    style={{
                        data: {
                            stroke: theme.palette.secondary.main,
                            strokeWidth: 3,
                        },

                        labels: { fill: theme.palette.secondary.main },
                    }}
                />
            </VictoryChart>
        </Box>
    )
}

LineChartOneDay.propTypes = {
    bookings: PropTypes.arrayOf(Object),
}
