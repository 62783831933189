import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { golfCourses } from "../../constants/dbPaths"
import {
    SETTALLSETTINGS,
    SET_SETTINGS_SAVED,
} from "../../constants/actionTypes"
import store from "../../store"
import { wait } from "../TimeUtils"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

export const intToHourDate = (hourInt) => {
    let hourString = String(hourInt)
    if (hourString.length < 4) {
        hourString = "0".repeat(4 - hourString.length) + hourString
    }
    return `${hourString.substring(0, 2)}:${hourString.substring(2)}`
}

const setSubEmail = httpsCallable(functions, "setSubEmail")

const handleEmailUpdate = async (email, courseDocId) => {
    await setSubEmail({
        email,
        courseDocId,
    })
}

export default function SettingsSubmit() {
    const settings = useSelector((state) => state.firestore.data.field)
    const fire = useFirebase()
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const initState = {
        ...settings,
    }
    const initStatus = {
        loading: false,
        success: false,
        error: false,
    }
    const form = useSelector((state) => state.settingsForm)

    useEffect(() => {
        if (!form.settingsLoaded) {
            store.dispatch({ type: SETTALLSETTINGS, payload: initState })
        }
    }, [])

    const [error, setError] = useState("")
    const [status, setStatus] = useState(initStatus)

    const handleSubmit = async (e) => {
        e.preventDefault()
        let error = false
        if (!settingsAccess) {
            setError("Limited permissions - talk to admin")
            setStatus({ ...status, error: true })
            return
        }
        const currentForm = {
            ...form,
            costStructure: {
                ...form.costStructure,
                percentageVat: form.costStructure.percentageVat || 0,
            },
        }
        const vat = Number(currentForm.costStructure?.percentageVat)
        if (vat < 0 || isNaN(vat) || vat > 1) {
            error = true
            setError("Invalid percentage vat")
            setStatus({ ...status, loading: false, error: true })
            wait().then(() => {
                setError("")
                setStatus(initStatus)
            })
        }

        const checkNumber = (number, minValue, errorType, errorMessage) => {
            if (isNaN(number) || number === "") {
                error = true
                setError(`Invalid ${errorType} duration`)
                setStatus({ ...status, loading: false, error: true })
                wait().then(() => {
                    setError("")
                    setStatus(initStatus)
                })
            }
            if (number < minValue) {
                error = true
                console.error(number)
                setError(errorMessage)
                setStatus({ ...status, loading: false, error: true })
                wait(5000).then(() => {
                    setError("")
                    setStatus(initStatus)
                })
            }
        }
        checkNumber(
            currentForm.reservationDays,
            0,
            "days in advance",
            "Invalid days in advance"
        )
        if (currentForm.unlockDur !== undefined) {
            const dur = currentForm.unlockDur
            currentForm.bufferStart = dur.hr * 60 + dur.min
        }
        if (currentForm.standardTMP !== undefined) {
            const standardTMP = currentForm.standardTMP
            currentForm.standard = standardTMP.hr * 60 + standardTMP.min
        }
        if (currentForm.averageTimeTMP !== undefined) {
            const averageTimeTMP = currentForm.averageTimeTMP
            currentForm.averageTime =
                averageTimeTMP.hr * 60 + averageTimeTMP.min
        }
        if (settings.webAppIntegrationEnabled) {
            const dur = currentForm.unlockDur
            const bufferTeeTMP = currentForm.bufferTeeTMP
            currentForm.bufferTee = bufferTeeTMP.hr * 60 + bufferTeeTMP.min
            currentForm.bufferStart =
                dur.hr * 60 + dur.min + currentForm.bufferTee ?? 0
            checkNumber(
                currentForm.bufferTee,
                0,
                "buffer tee",
                "Invalid buffer tee time"
            )
        }
        if (settings.nineHolesEnabled) {
            const shortTMP = currentForm.shortTMP
            currentForm.short = shortTMP.hr * 60 + shortTMP.min
            checkNumber(
                currentForm.short,
                45,
                "nine holes reserved time",
                "Invalid nine holes reserved time. Needs to be at least 45 minutes."
            )
        }
        delete currentForm.unlockDur
        delete currentForm.shortTMP
        delete currentForm.averageTimeTMP
        delete currentForm.standardTMP
        delete currentForm.bufferTeeTMP

        checkNumber(
            currentForm.bufferStart,
            10,
            "unlock window",
            "Invalid unlock window duration. Needs to be at least 10 minutes"
        )
        checkNumber(
            currentForm.standard,
            60,
            "reserved time",
            "Invalid reserved time. Needs to be at least 1 hour"
        )
        checkNumber(
            currentForm.averageTime,
            30,
            "average time",
            "Invalid average time, less than 30 minutes?"
        )
        if (currentForm.averageTime > currentForm.standard) {
            error = true
            setError("Average time is higher than standard time?")
            setStatus({ ...status, loading: false, error: true })
            wait(5000).then(() => {
                setError("")
                setStatus(initStatus)
            })
        }

        if (currentForm.nightLockEnabled) {
            if (
                currentForm.openingTimes.nightLock >=
                    currentForm.openingTimes.open &&
                currentForm.openingTimes.nightLock <=
                    currentForm.openingTimes.close
            ) {
                setError("Can't lock all carts while the course is open.")
                setStatus({ ...status, loading: false, error: true })
                wait().then(() => {
                    setError("")
                    setStatus(initStatus)
                })
            }
            const niLock = currentForm.openingTimes.nightLock
            const vehicleGroups = currentForm?.vehicleGroups || {}
            const bookableGroups = Object.keys(vehicleGroups).filter(
                (x) => vehicleGroups[x].bookable
            )
            bookableGroups.map((vehicleGroup) => {
                const { name, open, close } = vehicleGroups[vehicleGroup]
                if (close <= open) {
                    error = true
                    setError(
                        `Closing time must be after opening time for ${name}`
                    )
                    setStatus({ ...status, loading: false, error: true })
                    wait().then(() => {
                        setError("")
                        setStatus(initStatus)
                    })
                } else if (
                    (niLock < close + 130 && niLock > close) || // Normal case of end = 14, lock = 15
                    (niLock + 2400 - close < 130 && // lock < close and the distance is less than 1.5 hours
                        niLock < close)
                ) {
                    error = true
                    setError(
                        "Earliest time to lock is 90 minutes after closing time."
                    )
                    setStatus({ ...status, loading: false, error: true })
                    wait().then(() => {
                        setError("")
                        setStatus(initStatus)
                    })
                }
            })
        }
        const vehicleGroups = currentForm?.vehicleGroups || {}
        const bookableGroups = Object.keys(vehicleGroups).filter(
            (x) => vehicleGroups[x].bookable
        )
        const minOpen = bookableGroups
            .map((x) => vehicleGroups[x].open)
            .reduce((min, current) => Math.min(min, current), 2400)
        const maxClose = bookableGroups
            .map((x) => vehicleGroups[x].close)
            .reduce((max, current) => Math.max(max, current), 0)
        currentForm.openingTimes = {
            ...currentForm.openingTimes,
            open: minOpen,
            close: maxClose,
        }
        bookableGroups.map((vehicleGroup) => {
            const { name, open, close } = vehicleGroups[vehicleGroup]
            if (close <= open) {
                error = true
                setError(`Closing time must be after opening time for ${name}`)
                setStatus({ ...status, loading: false, error: true })
                wait().then(() => {
                    setError("")
                    setStatus(initStatus)
                })
            }
            checkNumber(
                open,
                0,
                "Opening time",
                "Opening time must be 0 or more"
            )
            checkNumber(
                close,
                open,
                "Closing time",
                "Closing time must be more than opening time"
            )
        })
        if (error || currentForm.error) {
            return
        }

        const toSave = {
            ...currentForm,
        }
        delete toSave["segments"]
        delete toSave["classes"]
        delete toSave["savedChanges"]
        delete toSave["settingsLoaded"]
        delete toSave["selectedSegment"]
        setStatus({ ...status, loading: true })

        await handleEmailUpdate(currentForm.stripeSubEmail, courseDocId).catch(
            (e) => {
                console.error(e.message)
            }
        )

        fire.firestore()
            .collection(golfCourses)
            .doc(courseDocId)
            .update(toSave)
            .then(() => {
                setStatus({ ...status, loading: false, success: true })
                store.dispatch({
                    type: SET_SETTINGS_SAVED,
                    payload: initState,
                })
            })
            .catch((e) => {
                setStatus({ ...status, error: true, loading: false })
                setError(e.message)
            })
            .then(() => {
                new Promise((r) => setTimeout(r, 3000)).then(() => {
                    setStatus(initStatus)
                    setError("")
                })
            })
    }
    return { status, error, handleSubmit }
}
