import { useSelector } from "react-redux"

export default function getSubscriptionType() {
    const subscriptionType = useSelector(
        (state) => state.firestore.data?.field?.keyless
    )
    return {
        keyless: subscriptionType,
    }
}
