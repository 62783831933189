import {
    SET_LOADED_COI,
    SET_COMPLETED_COI,
    SET_REDIRECT_ON_LOGIN,
    UNSET_REDIRECT_ON_LOGIN,
} from "../constants/actionTypes"

const initialState = {
    redirectLink: "",
    redirect: false,
    coi: {
        completed: false,
        statistical: false,
        loaded: false,
    },
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_REDIRECT_ON_LOGIN:
            return {
                ...state,
                redirect: true,
                redirectLink: action.payload.redirectLink,
            }
        case UNSET_REDIRECT_ON_LOGIN:
            return {
                ...state,
                ...initialState,
            }
        case SET_LOADED_COI:
            return {
                ...state,
                coi: {
                    ...state.coi,
                    loaded: true,
                    ...action.payload,
                },
            }
        case SET_COMPLETED_COI:
            return {
                ...state,
                coi: {
                    ...state.coi,
                    completed: true,
                    ...action.payload,
                },
            }
        default:
            return state
    }
}
