import styled from "@emotion/styled"
import { Box } from "@mui/material"
import React from "react"
import CurrentBookingRow from "./CurrentBookingRow"
import NextBookingRow from "./NextBookingRow"
import ReadingRow from "./ReadingRow"

const StyledBox = styled(Box)(({ theme }) => ({}))

export default function CartPopupBody(props) {
    return (
        <StyledBox>
            <ReadingRow {...props} />
            <CurrentBookingRow {...props} />
            <NextBookingRow {...props} />
        </StyledBox>
    )
}
