import React from "react"
import PropTypes from "prop-types"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { TextField } from "@mui/material"
import { useSelector } from "react-redux"
import store from "../../store"
import enLocale from "date-fns/locale/en-GB"
import { SET_BOOKING_FIELDS } from "../../constants/actionTypes"

export default function BookingDateInput(props) {
    const { setCurrentProgress } = props
    const { startTime, endTime } = useSelector((state) => state.createBooking)

    const handleDateChange = (date) => {
        if (date && typeof date.getMonth === "function") {
            const dateOfMonth = date.getDate()
            const month = date.getMonth()
            const year = date.getFullYear()
            const newStart = new Date(startTime).setFullYear(
                year,
                month,
                dateOfMonth
            )
            const newEnd = new Date(endTime).setFullYear(
                year,
                month,
                dateOfMonth
            )

            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    startTime: newStart,
                    endTime: newEnd,
                },
            })
            setCurrentProgress((currentProgress) => ({
                ...currentProgress,
                error: false,
                errorMessage: "",
            }))
            return
        }
        setCurrentProgress((currentProgress) => ({
            ...currentProgress,
            error: true,
            errorMessage: "Invalid Date format",
        }))
    }

    return (
        <LocalizationProvider
            adapterLocale={enLocale}
            dateAdapter={AdapterDateFns}
        >
            <DatePicker
                disableToolbar
                variant="inline"
                color="secondary"
                inputFormat="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Choose date"
                value={new Date(startTime)}
                renderInput={(props) => <TextField fullWidth {...props} />}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    "aria-label": "change date",
                }}
            />
        </LocalizationProvider>
    )
}

BookingDateInput.propTypes = {
    setCurrentProgress: PropTypes.func.isRequired,
}
