import SimpleMap from "../views/maps"
import Settings from "../views/settings"
import StatisticsWrap from "../rootWrapper/statisticsWrap"
import Vehicles from "../views/vehicles"
import BookingWrap from "../rootWrapper/bookingWrap"
import Coupons from "../views/coupons"
import InstallationMenu from "../Components/Installer/installationMain"
import Ridepass from "../views/ridepasses"
import UpgradeView from "../views/upgrade/upgradeView"
import Reviews from "../views/reviews"

const accessPermissionRoutes = {
    viewMap: {
        key: 0,
        text: "Map",
        path: "/map",
        component: SimpleMap,
    },

    viewBooking: {
        key: 10,
        text: "Booking",
        path: "/booking",
        component: BookingWrap,
    },
    viewVehicles: {
        key: 20,
        text: "Vehicles",
        path: "/vehicles",
        component: Vehicles,
    },
    viewStatistics: {
        key: 30,
        text: "Statistics",
        path: "/statistics",
        component: StatisticsWrap,
    },
    viewReviews: {
        key: 35,
        text: "Reviews",
        path: "/reviews",
        component: Reviews,
    },
    viewCoupons: {
        key: 40,
        text: "Discounts",
        path: "/discounts",
        component: Coupons,
    },
    viewRidepass: {
        key: 50,
        text: "Coupons",
        path: "/coupons",
        component: Ridepass,
    },

    viewSettings: {
        key: 100,
        text: "Settings",
        path: "/settings",
        component: Settings,
    },
    viewInstallerPage: {
        key: 120,
        text: "Install",
        path: "/install",
        component: InstallationMenu,
    },
    viewUpgrade: {
        key: 130,
        text: "Upgrade",
        path: "/upgrade",
        component: UpgradeView,
    },
}

export { accessPermissionRoutes }
