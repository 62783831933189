import CircularProgress from "@mui/material/CircularProgress"
import React from "react"
import { CssBaseline } from "@mui/material"
import useAppStyle from "../../assets/styles/App"

export default function CircleLoader(props) {
    const loadingStyle = useAppStyle()
    if (props.fillScreen) {
        return (
            <CssBaseline>
                <div className={loadingStyle.fillScreen}>
                    <div className={loadingStyle.root}>
                        <CircularProgress color="secondary" />
                    </div>
                </div>
            </CssBaseline>
        )
    }
    return (
        <div className={loadingStyle.root}>
            <CircularProgress color="secondary" />
        </div>
    )
}
