import React from "react"
import { Box, Button, LinearProgress } from "@mui/material"
import {
    SET_CART_STATUS,
    SET_COMPLETE_CART,
} from "../../../constants/actionTypes"
import store from "../../../store"
import { useSelector } from "react-redux"
import LockCart from "../../lock/unlocking-cart"

export default function InstallationTestButton(props) {
    const { currentCartDocId } = useSelector((state) => state.installation)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const handleClick = async () => {
        const result = await LockCart(1, courseDocId, currentCartDocId)
        store.dispatch({
            type: SET_CART_STATUS,
            payload: {
                cartsStatus: {
                    [currentCartDocId]: result,
                },
            },
        })
        if (result && result.status === 201) {
            store.dispatch({
                type: SET_COMPLETE_CART,
                payload: {
                    cartsComplete: { [currentCartDocId]: true },
                },
            })
        }
    }
    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading

    return (
        <Box>
            {currentCartDocId && lockLoading(currentCartDocId) && (
                <LinearProgress color="secondary" />
            )}
            <Button
                sx={{ margin: 1 }}
                onClick={handleClick}
                disabled={
                    (currentCartDocId && lockLoading(currentCartDocId)) || false
                }
                fullWidth
                variant="contained"
                color="secondary"
            >
                Press to turn on cart
            </Button>
        </Box>
    )
}
