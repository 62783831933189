import { httpsCallable } from "firebase/functions"
import {
    CARTLOCKLOADING,
    CARTLOCKNOTLOADING,
    SETENGINE,
} from "../../constants/actionTypes"
import { functions } from "../../rootWrapper/firebase"
import store from "../../store"

const lockAndLoad = httpsCallable(functions, "lockAndLoad")
const lockAndLoadCallable = (engine, courseDocId, cartDocId) =>
    lockAndLoad({
        engine,
        courseDocId,
        cartDocId,
    })

async function LockCart(engine, courseDocId, cartDocId) {
    if (!cartDocId || engine === null) {
        console.log("tried lock illegal cart", courseDocId, cartDocId)
        return {
            status: 400,
            error: true,
            res: "illegal cart",
        }
    }
    if (engine) {
        console.log(`Turning on cart ${cartDocId}`)
    } else {
        console.log(`Turning off cart ${cartDocId}`)
    }
    store.dispatch({
        type: CARTLOCKLOADING,
        payload: {
            cart: cartDocId,
            boardId: cartDocId,
            status: {
                loading: true,
                completed: false,
                error: false,
                success: false,
            },
        },
    })
    return lockAndLoadCallable(engine, courseDocId, cartDocId)
        .then((r) => {
            console.log(r.data)
            store.dispatch({
                type: CARTLOCKNOTLOADING,
                payload: { cart: cartDocId, boardId: cartDocId },
            })
            if (r.data.status === 201) {
                store.dispatch({
                    type: SETENGINE,
                    payload: {
                        engine: !!r.data.engine,
                        cartDocId,
                        boardId: cartDocId.toString(),
                        ts: Date.now(),
                    },
                })
                store.dispatch({
                    type: CARTLOCKNOTLOADING,
                    payload: {
                        cart: cartDocId,
                        boardId: cartDocId,
                        status: {
                            loading: false,
                            completed: true,
                            error: false,
                            success: true,
                            errorMessage: "",
                        },
                    },
                })
            } else {
                store.dispatch({
                    type: SETENGINE,
                    payload: {
                        engine: !r.data.engine,
                        boardId: cartDocId.toString(),
                        ts: Date.now(),
                    },
                })
                store.dispatch({
                    type: CARTLOCKNOTLOADING,
                    payload: {
                        cart: cartDocId,
                        boardId: cartDocId,
                        status: {
                            loading: false,
                            completed: true,
                            error: true,
                            success: false,
                            errorMessage: r.data.res,
                        },
                    },
                })
            }
            console.log(r.data)
            return r.data
        })
        .catch((e) => {
            console.error(e.message)
            store.dispatch({
                type: CARTLOCKNOTLOADING,
                payload: {
                    cart: cartDocId,
                    boardId: cartDocId,
                    status: {
                        loading: false,
                        completed: true,
                        error: true,
                        success: false,
                        errorMessage: e.message,
                    },
                },
            })
            return {
                error: true,
                status: 400,
                res: e.message,
            }
        })
}

export default LockCart
