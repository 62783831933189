import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_VEHICLE_QUERY } from "../../constants/actionTypes"
import CircleLoader from "../loader"
import { getHourMinTimerString } from "../dateTools"
import { getHourMinString } from "../NextBooking/bookingInfoCard"
import {
    statusAppBooking,
    statusCancelled,
    statusFailed,
} from "../../constants/statusTypes"
import { getPrice, hasPayed } from "../Booking/bookingUtils"
import { getStars } from "../RatingDescVehicle/RatingCard"
import { DataGrid } from "@mui/x-data-grid"

const BOOKINGS_PER_PAGE = 10

const columns = [
    {
        field: "date",
        headerName: "Date",
        type: "date",
        width: 150,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "startTime",
        headerName: "Start time",
        renderCell: (params) => getHourMinString(params.value),
        type: "time",
        width: 150,
    },
    {
        field: "phone",
        headerName: "Phone",
        width: 150,
    },
    {
        field: "category",
        headerName: "Category",
        width: 150,
    },
    {
        field: "rating",
        headerName: "Review",
        width: 150,
        renderCell: (params) => params.value,
    },
    {
        field: "type",
        headerName: "Type",
        width: 150,
    },
    {
        field: "price",
        headerName: "Price",
        width: 150,
    },
    {
        field: "paid",
        headerName: "Paid",
        width: 150,
        type: "boolean",
    },
]

export default function BookingHistoryTable() {
    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const query = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })
    const vehicleBookings = useSelector((state) => {
        return state.vehicleSelector?.vehicleBooking[cartDocId] || []
    })

    const { queryLimit, queryComplete } = query
    useEffect(() => {
        if (courseDocId && cartDocId) {
            if (!queryLimit) {
                store.dispatch({
                    type: SET_VEHICLE_QUERY,
                    payload: {
                        cartDocId,
                        query: {
                            limit: BOOKINGS_PER_PAGE,
                        },
                    },
                })
            }
        }
    }, [courseDocId, cartDocId])

    const handleViewMore = () => {
        store.dispatch({
            type: SET_VEHICLE_QUERY,
            payload: {
                cartDocId,
                query: {
                    limit: queryLimit + BOOKINGS_PER_PAGE,
                },
            },
        })
    }

    if (!query || (!queryComplete && !vehicleBookings)) {
        return <CircleLoader />
    }
    const rows = () =>
        vehicleBookings
            .filter(
                (x) => [statusCancelled, statusFailed].indexOf(x.status) === -1
            )
            .map((x, index) => {
                const isAppBooking = statusAppBooking.indexOf(x.status) > -1
                return {
                    id: index,
                    date: x.startTime.toDate(),
                    name: x.name,
                    startTime: x.startTime.toDate(),
                    duration: getHourMinTimerString(
                        x.endTime.toMillis() / 1000 -
                            x.startTime.toMillis() / 1000
                    ),
                    phone: x.phonePrefix ? x.phonePrefix + " " + x.tlf : x.tlf,
                    category: x.userSegment,
                    rating: isAppBooking ? getStars(x.starRating || 0) : null,
                    type: isAppBooking ? "App" : "Dashboard",
                    price: getPrice(x),
                    paid: hasPayed(x),
                }
            })
    return (
        <Box sx={{ width: "100%" }}>
            <DataGrid
                rows={rows()}
                columns={columns}
                disableSelectionOnClick
                pageSize={30}
                autoHeight
                density="compact"
            />
            {vehicleBookings.length >= queryLimit && (
                <Typography
                    fontSize={"8"}
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    fontWeight="300"
                    textAlign="right"
                    onClick={handleViewMore}
                >
                    Load More
                </Typography>
            )}
        </Box>
    )
}
