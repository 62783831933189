import React, { useEffect, useState } from "react"
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js"
import { Button, Typography } from "@mui/material"
import CircleLoader from "../loader"
import PropTypes from "prop-types"

export function ErrorMessage(props) {
    const { error } = props
    return error ? (
        <Typography
            sx={{ p: 2, textAlign: "center" }}
            color="error"
            fontSize="14pt"
        >
            {error}
        </Typography>
    ) : null
}
ErrorMessage.propTypes = {
    error: PropTypes.string,
}

export default function UpdateSetupIntent() {
    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (event) => {
        event.preventDefault()

        if (elements === null) {
            return
        }
        setLoading(true)

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${location.href}/confirm`,
            },
        })

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message)
            setError(result.error.message)
        }
        setLoading(false)
    }

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement type="payment" />
            <Button
                variant="contained"
                fullWidth
                color="secondary"
                type="submit"
                sx={{ fontWeight: "600" }}
                disabled={!stripe || !elements}
            >
                Confirm new payment method
            </Button>
            {loading ? <CircleLoader /> : null}
            <ErrorMessage error={error} />
        </form>
    )
}
