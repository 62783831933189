const isCellEditable = (params) => {
    if (params.field === "name") {
        return params.row.status === "admin"
    }
    if (params.field === "customerPhone") {
        return (
            params.row.status === "maintenance" || params.row.status === "admin"
        )
    }
    return true
}

export default isCellEditable
