import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import {
    SETACTIVECOUPONSLOADED,
    SET_BOOKING_FIELDS,
} from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"
import PropTypes from "prop-types"
import { functions } from "../../rootWrapper/firebase"
import BookingOptionsBase from "./optionsBase"

export default function BookingOptions(props) {
    const {
        userSegment,
        price,
        payed,
        tax,
        isNineHoles,
        couponCode,
        amountOfCarts,
        startTime,
        vehicleGroup,
    } = useSelector((state) => state.createBooking)
    const { setCurrentProgress, note, name, tlf, email } = props
    const coupons = useSelector((state) => state.coupons)
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId
    )
    const { vehicleGroups, segments } = useSelector(
        (state) => state.firestore.data.field
    )

    useEffect(() => {
        if (!userSegment || userSegment.length === 0) {
            return
        }
        const data = {
            userSegment,
            fixedPrice: true,
            startTime,
            couponCode,
            isNineHoles: isNineHoles ?? false,
            courseDocId,
            vehicleGroup,
        }
        console.log([userSegment, vehicleGroup, couponCode])
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                loading: true,
            },
        })

        httpsCallable(
            functions,
            "getCalculateAmountV2"
        )(data)
            .then((r) => {
                setPrice(r.data.price, r.data.fixedTax)
                store.dispatch({
                    type: SET_BOOKING_FIELDS,
                    payload: {
                        loading: false,
                    },
                })
            })
            .catch((e) => {
                console.error(e.message)
            })
    }, [userSegment, vehicleGroup, couponCode, startTime, isNineHoles])

    useEffect(() => {
        const defaultSegment = segments[0]?.name || ""
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                vehicleGroup: Object.keys(vehicleGroups)[0],
                userSegment: defaultSegment,
            },
        })
    }, [])
    useEffect(() => {
        if (!coupons.activeCouponsLoaded) {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    loading: true,
                },
            })
            console.log("get all active")

            httpsCallable(
                functions,
                "getAllCoupons"
            )({
                courseDocId,
                active: true,
                onlyValid: true,
                startTime,
            })
                .then((r) => {
                    console.log(r)
                    store.dispatch({
                        type: SETACTIVECOUPONSLOADED,
                        payload: { activeCoupons: r.data },
                    })
                    store.dispatch({
                        type: SET_BOOKING_FIELDS,
                        payload: {
                            loading: false,
                        },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [coupons.activeCouponsLoaded])
    const couponCodes = useSelector((state) => {
        if (state.coupons.activeCoupons && state.coupons.activeCoupons.length) {
            return state.coupons.activeCoupons.map((coupon) => coupon.code)
        }
        return []
    })

    const { currency, costStructure, nineHolesEnabled } = useSelector(
        (state) => state.firestore.data.field
    )
    const taxAdd = costStructure.taxAddEnabled
    const percentageVat = costStructure.percentageVat

    const setUserSegment = (segment) => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                userSegment: segment,
            },
        })
    }

    const setPrice = (price, tax = 0) => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                price,
                tax,
            },
        })
    }

    const handleValueChange = (e) => {
        const { name, value } = e.target
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                [name]: value,
            },
        })
    }

    const setHasPayed = (paid) => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                paid: Boolean(paid),
            },
        })
    }
    const couponChange = (e, value) => {
        if (value !== "" && couponCodes.includes(value)) {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    couponCode: value,
                },
            })
        } else {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    couponCode: "",
                },
            })
        }
    }
    const changePayed = () => {
        setHasPayed(!payed)
    }

    return (
        <BookingOptionsBase
            {...{
                setCurrentProgress,
                userSegment,
                setUserSegment,
                setPrice,
                tlf,
                name,
                couponCodes,
                couponChange,
                coupons,
                amountOfCarts,
                handleValueChange,
                isNineHoles,
                nineHolesEnabled,
                note,
                percentageVat,
                taxAdd,
                price,
                tax,
                currency,
                payed,
                changePayed,
                email,
            }}
        />
    )
}

BookingOptions.propTypes = {
    name: PropTypes.object.isRequired,
    tlf: PropTypes.object.isRequired,
    setCurrentProgress: PropTypes.func.isRequired,
    multiBooking: PropTypes.bool,
    note: PropTypes.object.isRequired,
    email: PropTypes.object.isRequired,
}
