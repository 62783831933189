import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { Typography } from "@mui/material"
import DropBox from "./image/image"
import Preview from "../previews"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
    },
    headline: {
        margin: theme.spacing(2),
        color: theme.palette.secondary.light,
    },
}))

export default function CourseImage(props) {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Preview />
            <Typography className={classes.headline} variant="h5">
                Drag and drop images of the course to display in the App!
            </Typography>
            <DropBox />
        </div>
    )
}
