import makeStyles from "@mui/styles/makeStyles"

export const useBookingStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: theme.palette.primary.main,
    },
    container: {
        width: "100%",
        height: "100%",
        borderRadius: 10,
    },

    tableCell: {
        // fontSize: '8pt',
        padding: "5px",
    },

    bottomrow: {
        width: "100%",
    },

    pagination: {
        display: "flex",
        // alignContent: "flex-end",
        flexDirection: "flex-end",
    },
}))
