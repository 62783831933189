import React from "react"
import { Box, Step, StepIcon, StepLabel, Stepper } from "@mui/material"
import styled from "@emotion/styled"
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector"
import { Check } from "@mui/icons-material"

const steps = [
    "Register for EAGL Connect",
    "Get EAGL approved",
    "Start Keyless Plan",
    "Smartbox Shipping",
    "Installation and launch",
]

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 9,
        left: "calc(-50% + 12px)",
        right: "calc(50% + 12px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.grey[800],
        borderRadius: 1,
    },
}))

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: theme.palette.grey[700],
    ...(ownerState.active && {
        backgroundColor: theme.palette.secondary.main,
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.secondary.main,
    }),
    "& .QontoStepIcon-circle": {
        backgroundColor: theme.palette.grey[700],
    },
}))
function QontoStepIcon(props) {
    const { active, completed, className } = props

    return (
        <QontoStepIconRoot
            ownerState={{ active, completed }}
            className={className}
        >
            <div className="QontoStepIcon-circle" />
        </QontoStepIconRoot>
    )
}

export default function PromoStepper() {
    return (
        <Box sx={{ pt: 3, pb: 2 }}>
            <Stepper
                activeStep={2}
                alternativeLabel
                connector={<ColorlibConnector />}
            >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    )
}
