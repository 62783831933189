import React, { useEffect, useState } from "react"
import { Box, Divider, Grid, Switch, Typography } from "@mui/material"
import db, { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import { getAuth } from "firebase/auth"
import { cartsPath, golfCourses, users } from "../../constants/dbPaths"
import theme from "../../assets/styles/muiTheme"
import CircleLoader from "../loader"

const advantagesList = (plan, currency, priceModel) => {
    if (plan === "keyless") {
        return (
            <ul style={{ marginTop: "4px" }}>
                <li>Access to EAGL Dashboard</li>
                <li>Self-servcie golf cart rental through the .EAGL App</li>
                <li>
                    Keyless unlocking and GPS tracking of your vehicle fleet
                </li>
                <li>Unlimited free bookings on the EAGL Dashboard</li>
                <li>
                    {currency && priceModel.startingFee !== undefined ? (
                        <>
                            ({Number(priceModel.startingFee / 100).toFixed(2)}{" "}
                            {currency} data fee per booking both with the app
                            and the dashboard)
                        </>
                    ) : (
                        <CircleLoader />
                    )}
                </li>
            </ul>
        )
    }

    return (
        <ul style={{ marginTop: "4px" }}>
            <li>Access to EAGL Dashboard</li>
            <li>Access to EAGL App (listing)</li>
            <li>Support</li>
            <li>Unlimited free bookings on the EAGL Dashboard</li>
            <li>
                {currency && priceModel.startingFee !== undefined ? (
                    <>
                        ({Number(priceModel.startingFee / 100).toFixed(2)}{" "}
                        {currency} data fee per booking both with the app and
                        the dashboard)
                    </>
                ) : (
                    <CircleLoader />
                )}
            </li>
        </ul>
    )
}

export default function SubTrialPromoBox(props) {
    const { payMonthly, setPayMonthly, code, disabled } = props
    const [unitPriceMonth, setUnitPriceMonth] = useState(0)
    const [unitPriceAnual, setUnitPriceAnual] = useState(0)
    const [priceModel, setPriceModel] = useState({})
    const [course, setCourse] = useState({})
    const [cartsCount, setCartsCount] = useState(0)
    const [currency, setCurrency] = useState("")

    const getSubscriptionPrices = httpsCallable(
        functions,
        "getSubscriptionPrices"
    )
    const uid = getAuth().currentUser?.uid
    useEffect(() => {
        async function getCourse() {
            const userRef = await db.collection(users).doc(uid).get()
            const courseDocId = userRef.data().courseDocId
            const carts = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .collection(cartsPath)
                .get()
            setCartsCount(carts.docs.length)
            const course = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .get()
            const courseData = course.data()
            setCourse(courseData)
            setCurrency(courseData.currency)
        }
        if (Object.keys(course).length === 0 && uid && !currency) {
            getCourse()
        }
    }, [course, uid, currency])

    useEffect(() => {
        async function getAnualSubPrices() {
            console.log("Currency", currency)
            const userRef = await db.collection(users).doc(uid).get()
            const courseDocId = userRef.data().courseDocId

            await getSubscriptionPrices({
                monthly: false,
                currency,
                countryCode: course.countryCode,
                courseDocId,
                code,
            })
                .then((prices) => {
                    console.log(prices.data)
                    const firstTier = prices.data.firstTier
                    setUnitPriceAnual(firstTier.unit_amount)
                    setCurrency(prices.data.currency)
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
        console.log(currency, unitPriceAnual)
        if (currency && !unitPriceAnual) {
            getAnualSubPrices()
        }
    }, [unitPriceAnual, currency])

    useEffect(() => {
        async function getMonthSubPrices() {
            console.log("Currency", currency)
            const userRef = await db.collection(users).doc(uid).get()
            const courseDocId = userRef.data().courseDocId

            await getSubscriptionPrices({
                monthly: true,
                currency,
                countryCode: course.countryCode,
                courseDocId,
                code,
            })
                .then((prices) => {
                    console.log(prices.data)
                    const firstTier = prices.data.firstTier
                    setUnitPriceMonth(firstTier.unit_amount)
                    setCurrency(prices.data.currency)
                    setPriceModel(prices.data.priceModel)
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
        if (currency && !unitPriceMonth) {
            getMonthSubPrices()
        }
    }, [unitPriceMonth, currency])

    return (
        <Box
            width={"100%"}
            sx={{
                borderColor: theme.palette.secondary.main,
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "4px",
                pr: 4,
                pl: 4,
                pb: 2,
                backgroundColor: theme.palette.primary.main,
            }}
        >
            <Grid
                container
                justifyContent={"center"}
                alignItems="center"
                direction={"column"}
            >
                <Grid item xs>
                    <Typography fontSize="18pt" p={1}>
                        EAGL Connect
                        {Object.keys(priceModel).length &&
                        priceModel.trialDays ? (
                            <>
                                {" ― "}
                                {priceModel.trialDays} days{" "}
                                <Typography
                                    component="span"
                                    display={"inline-block"}
                                    color="secondary"
                                    fontSize="inherit"
                                >
                                    free trial
                                </Typography>
                            </>
                        ) : null}
                    </Typography>
                </Grid>
                <Divider flexItem />
                <Grid item xs minHeight="60px" pt={1}>
                    <Grid
                        container
                        justifyContent={"center"}
                        direction="column"
                        height="100%"
                    >
                        <Grid item xs maxHeight="38px">
                            <Typography display="inline-block" pr="8px">
                                Pay monthly{" "}
                            </Typography>
                            <Switch
                                checked={!payMonthly}
                                color="secondary"
                                disabled={Boolean(disabled)}
                                onChange={() => setPayMonthly(!payMonthly)}
                            />
                            <Box
                                compontent="p"
                                display={"inline-block"}
                                position="relative"
                            >
                                <Typography display="inline-block">
                                    Pay annually
                                </Typography>
                                <Box
                                    position="absolute"
                                    top="-13px"
                                    left="30px"
                                >
                                    {unitPriceMonth && unitPriceAnual ? (
                                        <Typography
                                            width={"140px"}
                                            fontSize="8pt"
                                            display={"inline-block"}
                                            color={theme.palette.blue.main}
                                        >
                                            {"Pay upfront - Save " +
                                                Number(
                                                    ((unitPriceMonth * 12 -
                                                        unitPriceAnual) *
                                                        100) /
                                                        (unitPriceMonth * 12)
                                                ).toFixed() +
                                                "%"}
                                        </Typography>
                                    ) : (
                                        <CircleLoader />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider flexItem />
                <Grid item xs minHeight="140px" width="100%">
                    <Grid
                        sx={{ ".MuiGrid-item": { pt: 1, pr: 1 } }}
                        container
                        direction="row"
                        minHeight={"100%"}
                    >
                        <Grid item xs minHeight="100%">
                            {currency &&
                            unitPriceMonth &&
                            cartsCount &&
                            unitPriceMonth &&
                            unitPriceAnual ? (
                                <Box>
                                    <Typography
                                        sx={{ pt: 1 }}
                                        fontSize={"10pt"}
                                        fontWeight={"600"}
                                    >
                                        After trial period
                                    </Typography>
                                    <Typography fontSize={"12pt"}>
                                        {currency}{" "}
                                        {Number(unitPriceMonth / 100).toFixed(
                                            2
                                        )}
                                        ,- per vehicle,- / month
                                    </Typography>
                                    <Typography
                                        fontSize="16pt"
                                        fontWeight={"500"}
                                    >
                                        {cartsCount || "-"} Vehicles
                                    </Typography>
                                    <Typography
                                        fontSize="14pt"
                                        color="secondary"
                                    >
                                        {currency}{" "}
                                        {Number(
                                            ((payMonthly
                                                ? unitPriceMonth
                                                : unitPriceAnual / 12) /
                                                100) *
                                                cartsCount
                                        ).toFixed(2)}
                                        ,- / month
                                    </Typography>
                                    {payMonthly ? null : (
                                        <Typography fontSize={"12pt"}>
                                            Billed at{" "}
                                            <s>
                                                {currency}{" "}
                                                {Number(
                                                    unitPriceMonth *
                                                        0.12 *
                                                        cartsCount
                                                ).toFixed(2)}
                                                ,-
                                            </s>{" "}
                                            {currency}{" "}
                                            {Number(
                                                (unitPriceAnual / 100) *
                                                    cartsCount
                                            ).toFixed(2)}
                                            ,- / year
                                        </Typography>
                                    )}
                                </Box>
                            ) : (
                                <CircleLoader />
                            )}
                        </Grid>
                        <Divider
                            sx={{ mr: 2 }}
                            flexItem
                            orientation="vertical"
                        />
                        <Grid item xs>
                            <Box sx={{ lineHeight: 1.5, fontSize: "10pt" }}>
                                Includes
                                {advantagesList(code, currency, priceModel)}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
