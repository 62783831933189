/* Case 0  Lock Table
 * Case 1 Availability table
 *  */
export const textTrueSwitchMapTable = (variant, boolVal) => {
    switch (variant) {
        case 0:
            if (boolVal) {
                return "ON"
            }
            return "OFF"

        case 1:
            if (boolVal) {
                return "Available"
            }
            return "Unavailable"

        default:
            return ""
    }
}

export const textTableHeaderMapTable = (variant) => {
    switch (variant) {
        case 0:
            return "Use the switch to turn the carts on and off."
        case 1:
            return "Makes the cart unavailable for booking and allows you to rebook this cart's bookings the next 7 days. If not made available after 7 days, all subsequent bookings must be rebooked manually."
        default:
            return ""
    }
}
