import React from "react"
import Grid from "@mui/material/Grid"
import { Divider } from "@mui/material"
import RidepassList from "../Components/CouponsList/ridepassList.js"
import CreateRidepass from "../Components/RidepassCreate/index.js"

export default function Ridepass(props) {
    return (
        <Grid container direction="row" style={{ height: `${100}%` }}>
            <Grid item xs={12} md={8}>
                <RidepassList />
            </Grid>
            <Divider flexItem orientation="vertical" />
            <Grid item xs>
                <CreateRidepass />
            </Grid>
        </Grid>
    )
}
