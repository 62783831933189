import { TextField } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SETSETTINGSTIMEUPDATED } from "../../../../constants/actionTypes"
import store from "../../../../store"
import { intToHourDate } from "../../settingsForm"

const title = "First flight time"
const desc = "The time of the first flight of the day"

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const time = intToHourDate(currentForm.openingTimes?.open)
    return `${time}`
}

function OpenTimeItem() {
    const currentForm = useSelector((state) => state.settingsForm)
    const vehicleGroups = currentForm?.vehicleGroups || {}
    const bookableGroups = Object.keys(vehicleGroups).filter(
        (x) => vehicleGroups[x].bookable
    )
    const groups = bookableGroups.length

    const handleInputChange = (vehicleGroup) => (e) => {
        e.preventDefault()
        const { name, value } = e.target
        const number = parseInt(value.replace(":", ""))
        store.dispatch({
            type: SETSETTINGSTIMEUPDATED,
            payload: { key: name, value: number, vehicleGroup },
        })
    }

    return (
        <>
            {bookableGroups.map((vehicleGroup, index) => {
                const { name, open } = vehicleGroups[vehicleGroup]
                const fieldName = groups > 1 ? ` ${name}` : "First flight"
                return (
                    <TextField
                        key={name}
                        id="open-time"
                        label={fieldName}
                        type="time"
                        variant="standard"
                        sx={{ pr: groups > 1 && index === 0 ? 2 : 0 }}
                        value={intToHourDate(open)}
                        name={"open"}
                        fullWidth={groups === 1}
                        onChange={handleInputChange(vehicleGroup)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )
            })}
        </>
    )
}
export default {
    desc,
    title,
    CurrentValue,
    Element: OpenTimeItem,
}
