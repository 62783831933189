import { Box, Divider, Typography } from "@mui/material"
import { getAuth } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SuccessLoader } from "../../../Components/settingsForm/loader/successLoader"
import { golfCourses, users } from "../../../constants/dbPaths"
import db, { functions } from "../../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import store from "../../../store"
import {
    SET_PRICE_MODEL,
    SET_REGISTER_STEP,
} from "../../../constants/actionTypes"
import VehicleTable, {
    ToolTipYellow,
} from "../../../Components/CreateCartTable"
import YesNoSwitch from "../../../Components/YesNoSwitch"
import CreateCartSummary from "../../../Components/CreateCartTable/summaryBox"
import CircleLoader from "../../../Components/loader"
import { useSelector } from "react-redux"

const initStatus = {
    success: false,
    loading: false,
    error: false,
    errorMessage: "",
}

const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))

const handleAmountChange = (value, setAmount, setVehicles) => {
    if (!isNaN(value)) {
        if (value === "") {
            setAmount("")
        } else {
            let valueParsed = Math.abs(parseInt(Math.min(Number(value), 60)))
            setAmount(valueParsed)
            if (valueParsed >= 0) {
                const base = [...Array(Number(valueParsed))].reduce(
                    (val, _, i) => ({
                        ...val,
                        [i]: {
                            brand: "",
                            seats: "",
                            desc: "",
                            name: `${i + 1}`,
                        },
                    }),
                    {}
                )
                setVehicles((veh) => ({
                    ...base,
                    ...veh,
                }))
            }
        }
    }
}

const handleChange = (setVehicles) => (e, car, field) => {
    if (["brand", "seats", "other"].indexOf(field) > -1 && car === 0) {
        setVehicles((veh) =>
            Object.keys(veh).reduce(
                (veh, key) => ({
                    ...veh,
                    [key]: {
                        ...veh[key],
                        [field]: e.target?.value,
                    },
                }),
                veh
            )
        )
    }
    setVehicles((veh) => ({
        ...veh,
        [car]: { ...veh[car], [field]: e.target?.value },
    }))
}

function getPrices(props) {
    const { monthly } = props
    const [loading, setLoading] = useState(true)
    const [pricesPerUnit, setPricePerUnit] = useState()
    const [currencyToUse, setCurrencyToUse] = useState()
    const [showPriceBox, setShowPriceBox] = useState(false)

    const code = useSelector((state) => state.register?.code)

    const getSubscriptionPrices = httpsCallable(
        functions,
        "getSubscriptionPrices"
    )
    const uid = getAuth().currentUser?.uid

    useEffect(() => {
        setLoading(true)
        async function getPrice() {
            const userRef = await db.collection(users).doc(uid).get()
            const courseDocId = userRef.data().courseDocId
            if (!courseDocId) {
                // Only happens if no access, could reroute
                setLoading(false)
                return
            }

            const course = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .get()
            await getSubscriptionPrices({
                monthly: true,
                currency: course.data().currency,
                countryCode: course.data().countryCode,
                courseDocId,
                code,
            })
                .then((r) => {
                    console.log(r.data)
                    const firstTier = r.data.firstTier
                    const unit_amount = firstTier.unit_amount / 100
                    const priceModel = r.data.priceModel
                    store.dispatch({
                        type: SET_PRICE_MODEL,
                        payload: { priceModel },
                    })
                    if (!priceModel.skipPay) {
                        setShowPriceBox(true)
                        setPricePerUnit(Number(unit_amount.toFixed(2)))
                        setCurrencyToUse(r.data.currency)
                    }
                })
                .catch((e) => {
                    console.error(e.message)
                })
            setLoading(false)
        }
        if (uid) {
            getPrice()
        }
    }, [monthly, uid])
    return { loading, pricesPerUnit, currencyToUse, showPriceBox }
}

export default function CreateCarts() {
    const [vehiclesG1, setVehiclesG1] = useState({})
    const [vehiclesG2, setVehiclesG2] = useState({})
    const [vehiclesG3, setVehiclesG3] = useState({})
    const [vehcileOptions, setVehicleOptions] = useState({
        groupOne: {
            enabled: true,
            name: "",
            amount: 0,
            icon: "",
        },
        groupTwo: {
            enabled: false,
            name: "",
            amount: 0,
            icon: "",
        },
        nonRentable: {
            enabled: false,
            name: "Administrative",
            amount: 0,
            icon: "",
        },
    })
    const [status, setStatus] = useState(initStatus)
    const navigate = useNavigate()
    const code = useSelector((state) => state.register?.code)

    const setAmountGroup = (groupType) => {
        return (amount) => {
            setVehicleOptions((options) => ({
                ...options,
                [groupType]: {
                    ...options[groupType],
                    amount,
                },
            }))
        }
    }
    const setNameGroup = (groupType) => {
        return (name) => {
            setVehicleOptions((options) => ({
                ...options,
                [groupType]: {
                    ...options[groupType],
                    name,
                },
            }))
        }
    }

    const setIconGroup = (groupType) => {
        return (icon) => {
            setVehicleOptions((options) => ({
                ...options,
                [groupType]: {
                    ...options[groupType],
                    icon,
                },
            }))
        }
    }
    const { loading, pricesPerUnit, currencyToUse, showPriceBox } = getPrices({
        monthly: true,
    })

    const changeAmountG1 = (value) =>
        handleAmountChange(value, setAmountGroup("groupOne"), setVehiclesG1)
    const changeAmountG2 = (value) =>
        handleAmountChange(value, setAmountGroup("groupTwo"), setVehiclesG2)
    const changeAmountG3 = (value) =>
        handleAmountChange(value, setAmountGroup("nonRentable"), setVehiclesG3)

    const amountG1 = vehcileOptions.groupOne.amount
    const amountG2 = vehcileOptions.groupTwo.amount
    const amountG3 = vehcileOptions.nonRentable.amount

    useEffect(() => {
        store.dispatch({ type: SET_REGISTER_STEP, payload: { step: 2 } })
        changeAmountG1(2)
        changeAmountG2(2)
        changeAmountG3(2)
    }, [])

    const getSubmitableVehicles = (vehicles, amount) =>
        Object.keys(vehicles)
            .filter((x) => x < amount)
            .reduce((veh, key) => {
                return {
                    ...veh,
                    [key]: {
                        ...vehicles[key],
                        cartType:
                            vehicles[key].brand.length === 4
                                ? vehicles[key].brand
                                : String(
                                      vehicles[key].brand +
                                          vehicles[key].other +
                                          "--"
                                  )
                                      .toUpperCase()
                                      .substring(0, 4),
                        sortKey: Number(key),
                    },
                }
            }, {})

    const submitForm = async (e) => {
        e.preventDefault()
        setStatus({ ...initStatus, loading: true })
        const vehiclesG1ToSubmit = getSubmitableVehicles(vehiclesG1, amountG1)
        const vehiclesG2ToSubmit = getSubmitableVehicles(
            vehiclesG2,
            vehcileOptions.groupTwo.enabled ? amountG2 : 0
        )
        const vehiclesG3ToSubmit = getSubmitableVehicles(
            vehiclesG3,
            vehcileOptions.nonRentable.enabled ? amountG3 : 0
        )
        console.log(vehiclesG1ToSubmit)
        console.log(vehiclesG2ToSubmit)
        console.log(vehiclesG3ToSubmit)
        const vehicleNames = Object.values(vehcileOptions).map((x) => x.name)
        const vehicleIcons = Object.values(vehcileOptions).map((x) => x.icon)
        const uid = getAuth().currentUser.uid
        const userRef = await db.collection(users).doc(uid).get()
        const courseDocId = userRef.data().courseDocId
        const createCartsByUser = httpsCallable(functions, "createCartsByUser")
        createCartsByUser({
            courseDocId,
            vehicleNames,
            vehicleIcons,
            vehiclesG1: Object.values(vehiclesG1ToSubmit),
            vehiclesG2: Object.values(vehiclesG2ToSubmit),
            vehiclesG3: Object.values(vehiclesG3ToSubmit),
            code,
        })
            .then(() => {
                setStatus({ ...initStatus, success: true })
            })
            .catch((e) => {
                setStatus({
                    ...initStatus,
                    error: true,
                    errorMessage: e.message,
                })
            })
    }

    useEffect(() => {
        async function clearError() {
            const countdown = await wait(3000)
            if (status.error) {
                setStatus(initStatus)
            }
            return () => clearTimeout(countdown)
        }
        clearError()
    }, [status.error])

    useEffect(() => {
        async function clearError() {
            const countdown = await wait(3000)
            if (status.success) {
                setStatus(initStatus)
                if (showPriceBox) {
                    navigate("/register/course/payment")
                } else {
                    navigate("/setup")
                }
            }
            return () => clearTimeout(countdown)
        }
        clearError()
    }, [status.success])
    const vehiclesCount =
        amountG1 +
        (vehcileOptions.groupTwo.enabled && amountG2) +
        (vehcileOptions.nonRentable.enabled && amountG3)
    return (
        <Box
            sx={{
                width: "80%",
                maxWidth: "900px",
                margin: "auto",
                paddingBottom: 4,
            }}
        >
            <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ mb: 3 }} fontSize={"24pt"} variant="h1">
                    Your golf{" "}
                    <Typography
                        component="span"
                        fontWeight={500}
                        fontSize={"24pt"}
                        variant="h3"
                    >
                        vehicle
                    </Typography>{" "}
                    fleet
                </Typography>
                <Typography
                    sx={{ textAlign: "left" }}
                    fontSize={"12pt"}
                    variant="body1"
                >
                    EAGL Connect allows you to gain full control and overview of
                    both the rentable and non-rentable parts of your vehicle
                    fleet.{" "}
                    <ToolTipYellow
                        toolTip="lorem"
                        input={"Click here for help."}
                    />
                </Typography>
            </Box>
            <Box width="100%">
                <Divider sx={{ mt: 4 }} />
                <YesNoSwitch
                    text={
                        "Do you have more than one type of rentable vehicles?"
                    }
                    value={vehcileOptions?.groupTwo?.enabled}
                    handleChange={() => {
                        setVehicleOptions((vehcileOpt) => ({
                            ...vehcileOpt,
                            groupTwo: {
                                ...vehcileOpt?.groupTwo,
                                enabled: !vehcileOpt.groupTwo?.enabled,
                            },
                        }))
                    }}
                />
                <YesNoSwitch
                    text={"Do you have any non-rentable vehicles?"}
                    value={vehcileOptions?.nonRentable?.enabled}
                    handleChange={() => {
                        setVehicleOptions((vehcileOpt) => ({
                            ...vehcileOpt,
                            nonRentable: {
                                ...vehcileOpt?.nonRentable,
                                enabled: !vehcileOpt.nonRentable?.enabled,
                            },
                        }))
                    }}
                />
                <Divider sx={{ mb: 4 }} />
            </Box>
            <form onSubmit={submitForm}>
                <VehicleTable
                    amount={amountG1}
                    vehicles={vehiclesG1}
                    handleChange={handleChange(setVehiclesG1)}
                    namePlaceholder={"Golf cart"}
                    changeAmount={changeAmountG1}
                    setGroupName={(e) =>
                        setNameGroup("groupOne")(e?.target?.value)
                    }
                    setGroupIcon={(e) =>
                        setIconGroup("groupOne")(e?.target?.value)
                    }
                    rentable
                />
                {vehcileOptions.groupTwo.enabled ? (
                    <VehicleTable
                        amount={amountG2}
                        vehicles={vehiclesG2}
                        handleChange={handleChange(setVehiclesG2)}
                        changeAmount={changeAmountG2}
                        namePlaceholder={"Ex: Scooter"}
                        setGroupName={(e) =>
                            setNameGroup("groupTwo")(e?.target?.value)
                        }
                        setGroupIcon={(e) =>
                            setIconGroup("groupTwo")(e?.target?.value)
                        }
                        rentable
                    />
                ) : null}
                {vehcileOptions.nonRentable.enabled ? (
                    <VehicleTable
                        amount={amountG3}
                        vehicles={vehiclesG3}
                        namePlaceholder={"Ex: Marshall and Greenkeeper"}
                        handleChange={handleChange(setVehiclesG3)}
                        changeAmount={changeAmountG3}
                        setGroupName={(e) =>
                            setNameGroup("nonRentable")(e?.target?.value)
                        }
                        setGroupIcon={(e) =>
                            setIconGroup("nonRentable")(e?.target?.value)
                        }
                    />
                ) : null}

                <Box width="500px" margin={"auto"} marginTop="120px">
                    {showPriceBox ? (
                        <CreateCartSummary amount={vehiclesCount} />
                    ) : null}

                    <Box sx={{ mt: 6 }}>
                        <SuccessLoader
                            status={status}
                            error={status?.errorMessage}
                            handleSubmit={() => true}
                        >
                            <Typography fontWeight={"600"}>Continue</Typography>
                        </SuccessLoader>
                    </Box>
                    {loading ? (
                        <CircleLoader />
                    ) : showPriceBox ? (
                        <Box mt={2}>
                            <Typography>
                                *After 60 days trial:{" "}
                                {Number(pricesPerUnit).toFixed(2)}{" "}
                                {currencyToUse} per vehicle,- / month
                            </Typography>
                            <Typography>
                                {Number(pricesPerUnit * vehiclesCount).toFixed(
                                    2
                                )}{" "}
                                {currencyToUse}, Total- / month (billed
                                annually)
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </form>
        </Box>
    )
}
