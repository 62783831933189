import { PAGE_CHANGE_ACADEMY, COMPLETE_ACADEMY } from "../constants/actionTypes"

const initialState = {
    academyComplete: false,
    academyPage: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case COMPLETE_ACADEMY:
            return {
                ...state,
                academyComplete: true,
            }
        case PAGE_CHANGE_ACADEMY:
            return {
                ...state,
                academyPage: action.payload.page,
            }

        default:
            return state
    }
}
