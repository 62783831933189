import { Grid, Autocomplete, TextField } from "@mui/material"
import React from "react"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import BookingTimeInput from "../Booking/BookingTimeInput"
import ReoccurSection from "../Booking/reoccurSection"

export default function AdvancedOptions(props) {
    const {
        setCurrentProgress,
        couponCodes,
        couponChange,
        coupons,
        amountOfCarts,
        handleValueChange,
        note,
    } = props

    const style = useDialogStyles()

    return (
        <>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <TextField
                            id="customer-note"
                            maxRows={4}
                            multiline
                            label="Booking Note"
                            name="note"
                            inputRef={note}
                            defaultValue={note?.current?.value ?? ""}
                            fullWidth
                            inputProps={{ className: style.input }}
                            InputLabelProps={{
                                shrink: true,
                                className: style.input,
                            }}
                            style={{
                                color: "secondary",
                                backgroundColor: "primary",
                            }}
                        />
                    </Grid>
                    <Grid item xs>
                        <BookingTimeInput
                            isStartTime={false}
                            setCurrentProgress={setCurrentProgress}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container direction="row" spacing={2}>
                    <Grid item xs>
                        <Autocomplete
                            id="couponCode"
                            label="Discount Code"
                            name="code"
                            options={couponCodes}
                            loading={!coupons.activeCouponsLoaded}
                            onChange={couponChange}
                            autoComplete={true}
                            renderInput={(params) => (
                                <TextField {...params} label="Discount code" />
                            )}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            id="carts-count"
                            label="Amount of Carts to book"
                            name="amountOfCarts"
                            type="number"
                            defaultValue={amountOfCarts}
                            onChange={handleValueChange}
                            fullWidth
                            inputProps={{ className: style.input }}
                            InputLabelProps={{
                                shrink: true,
                                className: style.input,
                            }}
                            style={{
                                color: "secondary",
                                backgroundColor: "primary",
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <ReoccurSection />
            </Grid>
        </>
    )
}
