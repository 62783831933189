import React, { useEffect, useState } from "react"
import { Grid, Box, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import store from "../store"
import { SET_VEHICLE_RATING } from "../constants/actionTypes"
import CircleLoader from "../Components/loader/index"
import db from "../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../constants/dbPaths"
import { statusEnded } from "../constants/statusTypes"
import RatingGrid from "../Components/RatingDescVehicle/ratingGrid"
import { updateFrequency } from "../Components/bookingHistory/cartSpesificBookingHistoryLoader"

const BASE_AMOUNT = 30

export default function Reviews() {
    const { selectedVehicle, vehicleRatings } = useSelector((state) => {
        return state.vehicleSelector
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const [requestAmount, setRequestAmount] = useState(BASE_AMOUNT)
    const [loading, setLoading] = useState(false)
    const { cartDocId } = selectedVehicle
    const currentRatingsObject = vehicleRatings[cartDocId]
    const requestedAmount = currentRatingsObject?.requestedAmount
    const currentBookingsWithRating = currentRatingsObject?.bookingsWithRating

    const query = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })

    const { lastUpdated, reloadCount } = query

    useEffect(() => {
        if (courseDocId) {
            if (
                !requestedAmount ||
                requestAmount > requestedAmount ||
                lastUpdated + updateFrequency < Date.now()
            ) {
                setLoading(true)
                db.collection(golfCourses)
                    .doc(courseDocId)
                    .collection(bookingPath)
                    .where("status", "==", statusEnded)
                    .where("descDefined", "==", true)
                    .orderBy("startTime", "desc")
                    .limit(requestAmount)
                    .get()
                    .then((res) => {
                        let ratings = []
                        if (!res.empty) {
                            ratings = res.docs.map((x) => x.data())
                        }

                        store.dispatch({
                            type: SET_VEHICLE_RATING,
                            payload: {
                                cartDocId,
                                requestedAmount: requestAmount,
                                bookingsWithRating: ratings,
                            },
                        })
                        setLoading(false)
                    })
                    .catch((e) => {
                        console.error(e.message)
                        setLoading(false)
                    })
            }
        }
    }, [cartDocId, requestedAmount, requestAmount, lastUpdated, reloadCount])

    const RatingViewer = () => {
        if (loading && !currentBookingsWithRating) {
            return <CircleLoader />
        }
        if (
            !loading &&
            (!currentBookingsWithRating ||
                currentBookingsWithRating.length === 0)
        ) {
            return (
                <Grid
                    container
                    style={{ height: "100%", minHeight: "200px" }}
                    direction="row"
                    alignItems={"center"}
                    textAlign="center"
                >
                    <Grid item xs>
                        <Typography>No ratings received</Typography>
                    </Grid>
                </Grid>
            )
        }
        return (
            <Box>
                <RatingGrid gridSpacing="space-around" />
                {currentBookingsWithRating.length === requestedAmount && (
                    <Typography
                        fontSize={"8"}
                        variant="body2"
                        padding="16px"
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                        fontWeight="300"
                        textAlign="right"
                        onClick={handleViewMore}
                    >
                        View More
                    </Typography>
                )}
            </Box>
        )
    }

    const handleViewMore = () => {
        setRequestAmount((value) => value + BASE_AMOUNT)
    }
    return (
        <Box sx={{ minHeight: "200px" }}>
            <Grid container xs direction="row">
                <Grid item>
                    <RatingViewer />
                </Grid>
            </Grid>
        </Box>
    )
}
