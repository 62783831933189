import React, { useState } from "react"
import { Checkbox, FormControlLabel, Icon, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import { StatusBooker } from "../statusBooker"
import { useDialogStyles } from "../../../assets/styles/components/bookingPopup"
import CheckIcon from "@mui/icons-material/Check"
import store from "../../../store"
import { getPrice, getRefundAmount } from "../bookingUtils"
import {
    SELECT_BOOKING,
    UPDATE_BOOKING_VIEW,
} from "../../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../../rootWrapper/firebase"

export const ConfirmedStatus = (message) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
            }}
        >
            <CheckIcon color="success" />
            <span>{message}</span>
        </div>
    )
}

export default function PaymentSection(props) {
    const {
        cartDocId,
        bookingId,
        customPrice,
        hasPayed,
        multiBookingId,
        expectedCaptureAmount,
        capturedAmount,
    } = useSelector((state) => state.bookingSelector)
    const [multiSelect, setMultiSelect] = useState(false)
    const [payed, setHasPayed] = useState(hasPayed)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })
    const isMultiBooking = !!(multiBookingId && multiBookingId.length)

    const paymentBooking = httpsCallable(functions, "paymentBooking")
    const initialProgress = {
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    }
    const [currentProgress, setCurrentProgress] = useState(initialProgress)
    const style = useDialogStyles()

    const submitChangePayment = async () => {
        setCurrentProgress({
            ...currentProgress,
            inProgress: true,
        })
        await paymentBooking({
            hasPayed: true,
            bookingDocId: bookingId,
            courseDocId,
            multiSelect,
            multiBookingId,
        })
            .then(() => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: true,
                })
                store.dispatch({
                    type: SELECT_BOOKING,
                    payload: {
                        hasPayed: true,
                    },
                })
                setHasPayed(true)
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
            })
            .catch((e) => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: false,
                    error: true,
                    errorMessage: e.message,
                })
            })
        await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
            props.handleClose()
        })
    }
    function CheckboxMultiBooking() {
        if (isMultiBooking) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={multiSelect}
                            onChange={() => setMultiSelect(!multiSelect)}
                            name="multi-delete"
                        />
                    }
                    label="Apply to group"
                />
            )
        }
        return null
    }

    return (
        <StatusBooker
            disabled={hasPayed}
            currentProgress={currentProgress}
            defaultText="Confirm Payment"
            doBooking={submitChangePayment}
        />
    )
}
