import { Box, Typography } from "@mui/material"
import React from "react"

export default function UpgradePromoTextBox() {
    return (
        <Box maxWidth={"90%"}>
            <Typography color="#DCDCDC" fontSize="11pt">
                Gain{" "}
                <Typography
                    component="span"
                    display={"inline-block"}
                    color="secondary"
                    fontSize="inherit"
                >
                    full
                </Typography>{" "}
                control with{" "}
                <Typography
                    component="span"
                    display={"inline-block"}
                    color="secondary"
                    fontSize="inherit"
                >
                    GPS tracking
                </Typography>
                , manage your vehicle fleet from{" "}
                <Typography
                    component="span"
                    display={"inline-block"}
                    color="secondary"
                    fontSize="inherit"
                >
                    anywhere
                </Typography>{" "}
                with EAGL Dashboard and provide a{" "}
                <Typography
                    component="span"
                    display={"inline-block"}
                    color="secondary"
                    fontSize="inherit"
                >
                    self-service
                </Typography>{" "}
                golf cart rental for your players via the EAGL app!
            </Typography>
        </Box>
    )
}
