import * as React from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import parse from "autosuggest-highlight/parse"
import throttle from "lodash/throttle"

export default function MapAutoComplete(props) {
    const { maps, apiClient, setMapConf, setDetails, fullWidth } = props
    const [value, setValue] = React.useState(null)
    const [inputValue, setInputValue] = React.useState("")
    const [options, setOptions] = React.useState([])

    const handleValueChange = async (value) => {
        if (apiClient) {
            setValue(value)
            if (value) {
                console.log(value)
                console.log(value.formatted_address)
                const service = new apiClient.places.PlacesService(maps)
                service.getDetails(
                    {
                        placeId: value.place_id,
                        fields: [
                            "place_id",
                            "name",
                            "geometry",
                            "address_components",
                            "formatted_phone_number",
                        ],
                    },
                    (place, status) => {
                        if (
                            status ===
                                apiClient.places.PlacesServiceStatus.OK &&
                            place &&
                            place.geometry &&
                            place.geometry.location
                        ) {
                            console.log(
                                place.name,
                                place.formatted_phone_number
                            )
                            console.log(place)
                            const address = place.address_components.reduce(
                                (seed, { long_name, short_name, types }) => {
                                    types.forEach((t) => {
                                        seed[t] = long_name
                                        if (t === "country") {
                                            seed[t] = short_name
                                        }
                                    })
                                    return seed
                                },
                                {}
                            )
                            const details = {
                                name: place.name,
                                phone: place.formatted_phone_number,
                                ...address,
                            }
                            setDetails && setDetails(details)
                            console.log(details)
                            if (place.geometry.viewport) {
                                maps.fitBounds(place.geometry.viewport)
                                setMapConf({
                                    zoom: maps.zoom,
                                    pos: {
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    },
                                })
                            } else {
                                setMapConf({
                                    zoom: 14,
                                    pos: {
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    },
                                })
                                console.log(place, [
                                    place.geometry.location.lat(),
                                    place.geometry.location.lng(),
                                ])
                            }
                        }
                    }
                )
            }
        } else {
            if (value) {
                console.error("Apiclient not defined", apiClient, value)
            }
        }
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                if (!apiClient || !apiClient.places) {
                    console.error(
                        "Apiclient does not have places",
                        apiClient?.places
                    )
                    return undefined
                }
                const service = new apiClient.places.AutocompleteService()
                service.getPlacePredictions(request, callback)
            }, 200),
        [apiClient]
    )

    React.useEffect(() => {
        let active = true
        if (inputValue && !apiClient) {
            console.log("apiclient is not defined")
        }

        if (!apiClient) {
            return undefined
        }

        if (inputValue === "") {
            setOptions(value ? [value] : [])
            return undefined
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                console.log("res", inputValue, results)
                let newOptions = []

                if (value) {
                    newOptions = [value]
                }

                if (results) {
                    newOptions = [...new Set([...newOptions, ...results])]
                }

                setOptions(newOptions)
            }
        })

        return () => {
            active = false
        }
    }, [value, inputValue, fetch])

    return (
        <Autocomplete
            id="google-map"
            sx={{ minWidth: 300, maxWidth: "100%" }}
            getOptionLabel={(option) =>
                typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options)
                handleValueChange(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Search by Course Name..."
                    fullWidth
                />
            )}
            renderOption={(props, option) => {
                const matches =
                    option?.structured_formatting?.main_text_matched_substrings
                const parts =
                    matches &&
                    parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [
                            match.offset,
                            match.offset + match.length,
                        ])
                    )

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box
                                    component={LocationOnIcon}
                                    sx={{ color: "text.secondary", mr: 2 }}
                                />
                            </Grid>
                            <Grid item xs>
                                {parts &&
                                    parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight
                                                    ? 700
                                                    : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}

                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {
                                        option.structured_formatting
                                            .secondary_text
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                )
            }}
        />
    )
}
