import React, { useState } from "react"
import { Grid, Box, Typography, Button } from "@mui/material"
import PropTypes from "prop-types"
import CheckboxList, { outrowListItems } from "./checkBoxList"
import MediaBankImage from "../../assets/img/mediabank.jpg"
import getSubscriptionType from "../GetSubscriptionType"

export default function SetupOutro(props) {
    const [checked, setChecked] = useState([])
    //const [hasPressedImg, setPressedImg] = useState(false)
    const { handleComplete } = props
    const subtype = getSubscriptionType()

    const allBoxesChecked =
        checked.length === Object.keys(outrowListItems).length
    const meetingAndBoxed = allBoxesChecked || !subtype.keyless

    return (
        <Box sx={{ padding: "40px" }}>
            <Grid container direction="row" spacing={6}>
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="h4">
                                This is important before launching EAGL on your
                                golf club.
                            </Typography>
                            <br />
                            <Typography variant="h6">
                                Do members and guests have access to information
                                about how EAGL works on your golf club?
                            </Typography>

                            <br />
                            <Typography variant="h6">Checklist:</Typography>
                        </Grid>
                        <Grid item xs>
                            <CheckboxList
                                checked={checked}
                                setChecked={setChecked}
                            />
                        </Grid>
                        <Grid item xs>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={handleComplete}
                            >
                                Finish
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={0}>
                        <Grid item xs>
                            <a
                                href="https://eaglapp.sharepoint.com/:f:/s/EAGLGUESTS/EtOOlUh5vzlAmsQzU0nF3owBZjSGcAGbX_4Soi9D665cUA?e=N2I73S"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    variant="contained"
                                    color="info"
                                    sx={{ mt: 4, mb: 4 }}
                                >
                                    Media bank
                                </Button>
                            </a>
                            <Typography
                                sx={{ marginTop: "auto" }}
                                variant="body1"
                            >
                                Here you will find template text and images that
                                can be used to inform members and guests on
                                places they seek information. Feel free to
                                customize this to your members and guests.
                            </Typography>
                        </Grid>
                        <Grid item xs paddingTop={2}>
                            <img
                                src={MediaBankImage}
                                width="90%"
                                height="90%"
                            ></img>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

SetupOutro.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
