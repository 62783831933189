import React, { useEffect, useState } from "react"
import { Alert, AlertTitle, Tooltip } from "@mui/material"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import { Box } from "@mui/system"
import { useSettingsFormStyle } from "../../assets/styles/components/settingsForm"
import { SuccessLoader } from "./loader/successLoader"
import theme from "../../assets/styles/muiTheme"
import CourseLinkingIdDisplay from "../Permissions/courseLinkingId"
import SettingsTable from "./settingsTable"

import SettingsSubmit from "./handleSubmit"

export const intToHourDate = (hourInt) => {
    let hourString = String(hourInt)
    if (hourString.length < 4) {
        hourString = "0".repeat(4 - hourString.length) + hourString
    }
    return `${hourString.substring(0, 2)}:${hourString.substring(2)}`
}

export default function CourseSetting() {
    const classes = useSettingsFormStyle(theme)

    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const form = useSelector((state) => state.settingsForm)

    const settingsSubmit = SettingsSubmit()
    const { error, status } = settingsSubmit

    return (
        <div className={classes.root}>
            <Box right={0} sx={{ pr: 2 }} zIndex={1} position="absolute">
                <CourseLinkingIdDisplay />
            </Box>

            <Grid container sx={{ pt: 3 }} direction="column">
                <Grid item xs>
                    {form.savedChanges ? null : (
                        <Alert severity="error">
                            <AlertTitle>Unsaved Changes</AlertTitle>
                            Remember to save!
                        </Alert>
                    )}
                </Grid>
                <Grid container item xs alignItems={"center"}>
                    <Grid item xs width={"600px"}>
                        <Box
                            style={{
                                margin: "auto",
                                maxWidth: "600px",
                            }}
                        >
                            <SettingsTable />
                            <Box mt={2}>
                                <SuccessLoader
                                    disabled={!settingsAccess}
                                    noAccess={!settingsAccess}
                                    status={status}
                                    error={error}
                                    handleSubmit={settingsSubmit.handleSubmit}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
