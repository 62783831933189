import React from "react"
import { Grid, Box, Typography, Button } from "@mui/material"
import PropTypes from "prop-types"
import welcomeImage from "../../assets/img/cartgirl.jpg"
import theme from "../../assets/styles/muiTheme"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    imageStyle: {
        width: "50vh",
        margin: "auto",
        height: "50vh",
        objectFit: "cover",
        borderRadius: "1000px",
        display: "block",
    },
}))

export default function SetupIntro(props) {
    const { handleComplete } = props
    const introStyle = useStyles(theme)

    return (
        <Box sx={{ padding: "40px" }}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4">
                        Welcome to the EAGL Dashboard!
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        We will now guide you through a quick setup of your
                        account before moving forward.
                    </Typography>
                    <br />
                    <Typography variant="body1">
                        It will only take 10-15 minutes and will cover
                        everything you need to configure before going live with
                        EAGL. If you have any questions, feel free to contact us
                        at
                        <strong> clubsupport@eagl.app</strong>.
                    </Typography>
                    <br />
                    <Typography sx={{ marginTop: "auto" }} variant="body1">
                        Note that all info can be changed later and will be
                        reviewed before EAGL launch date for your club. It is
                        not recommended to do this from a phone (smaller
                        screens)
                    </Typography>
                    <Button
                        onClick={handleComplete}
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 4, mb: 4 }}
                    >
                        Let&apos;s get started
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <img
                        src={welcomeImage}
                        className={introStyle.imageStyle}
                        alt="Welcome image"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

SetupIntro.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
