const formatNum = (num) => {
    const positiveInt = Math.max(num, 0)
    const rounded = Number(positiveInt.toFixed(2))
    return rounded
}

const baseReturn = {
    courseShare: formatNum(0),
    courseGross: formatNum(0),
    vatAmount: formatNum(0),
    eaglTotal: formatNum(0),
    stripeFees: formatNum(0),
}

const getAmountDistributionDash = (amount, costStructure) => {
    const { fixedEaglFeeDash, eaglCutDash, taxAddEnabled, percentageVat } =
        costStructure
    if (amount <= fixedEaglFeeDash) {
        return {
            ...baseReturn,
            courseShare: formatNum(0),
            eaglTotal: formatNum(fixedEaglFeeDash),
            stripeFees: formatNum(0),
        }
    }
    const taxToSubtract = taxAddEnabled ? percentageVat : 0
    const priceWithoutTax = Number(
        Number(amount / (1 + taxToSubtract)).toFixed(2)
    )
    const vatAmount = Number(Number(amount - priceWithoutTax).toFixed(2))
    const amountToShare = Math.max(priceWithoutTax - fixedEaglFeeDash, 0)
    const courseShareOfAmountToShare = amountToShare * (1 - eaglCutDash)
    const courseTotal = courseShareOfAmountToShare + vatAmount
    const eaglTotal = fixedEaglFeeDash + amountToShare * eaglCutDash
    return {
        courseShare: formatNum(courseTotal),
        courseGross: formatNum(courseShareOfAmountToShare),
        eaglTotal: formatNum(eaglTotal),
        vatAmount: formatNum(vatAmount),
        stripeFees: formatNum(0),
    }
}

const getEaglShareDash = (amountToCapture, costStructure) => {
    const { eaglTotal } = getAmountDistributionDash(
        amountToCapture,
        costStructure
    )
    return eaglTotal
}

const getCourseShareDash = (amountToCapture, costStructure) => {
    const { courseShare, eaglTotal, stripeFees } = getAmountDistributionDash(
        amountToCapture,
        costStructure
    )
    return courseShare
}

export { getCourseShareDash, getEaglShareDash }

export default getAmountDistributionDash
