import React, { useEffect } from "react"
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { NotInstalledIcon, InstalledIcon } from "../installationMain"
import { sortCarts } from "../../timeline"

const GetInstalledIcon = (props) => {
    if (props.installed) {
        return <InstalledIcon />
    }
    return <NotInstalledIcon />
}

export default function InstallCartList() {
    const navigate = useNavigate()
    const carts = useSelector((state) => state.firestore.data.carts || {})
    const vehicleGroups = useSelector(
        (state) => state.firestore.data.field.vehicleGroups
    )
    const installation = useSelector((state) => state.installation)
    const entities = Object.keys(carts).length
        ? Object.keys(carts)
              .sort((x, y) => sortCarts(carts[x], carts[y]))
              .map((key) => ({
                  label: `${vehicleGroups[carts[key].vehicleGroup].name} ${
                      carts[key].id
                  }`,
                  docId: key,
                  id: carts[key].id,
                  installed: installation.cartsComplete[key],
              }))
        : []
    const handlePress = (id) => {
        navigate("../cart/" + String(id))
    }
    return (
        <Box sx={{ borderStyle: "solid", borderWidth: "2px", margin: 1 }}>
            <Table size="small">
                <TableBody>
                    {entities.map((entity, index) => (
                        <TableRow key={index}>
                            <TableCell align="left">
                                <Typography component="div">
                                    <Box
                                        fontWeight="fontWeightMedium"
                                        display="inline"
                                    >
                                        {entity.label}
                                    </Box>
                                </Typography>
                            </TableCell>
                            <TableCell
                                align="right"
                                sx={{ "&:hover": { cursor: "pointer" } }}
                                onClick={() => handlePress(entity.id)}
                            >
                                <GetInstalledIcon
                                    installed={entity.installed}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    )
}
