import React, { useRef, useState } from "react"
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    TextField,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import { SET_VEHICLE_NOTE_RELOAD } from "../../constants/actionTypes"
import store from "../../store"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { getAvailabilityAccess } from "../../constants/accessPermissionsChecks"

export default function PostNewNote() {
    const { selectedVehicle } = useSelector((state) => {
        return state.vehicleSelector
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const [loading, setLoading] = useState(false)
    const textRef = useRef()
    const canChangeAv = getAvailabilityAccess()

    const publishNote = httpsCallable(functions, "createCartNote")

    const handleSubmit = () => {
        setLoading(true)
        const note = textRef.current.value
        console.log(note)
        publishNote({
            note,
            courseDocId,
            cartDocId: selectedVehicle.cartDocId,
        })
            .then(() => {
                textRef.current.value = ""
                console.log("success")
                store.dispatch({
                    type: SET_VEHICLE_NOTE_RELOAD,
                    payload: {
                        cartDocId: selectedVehicle.cartDocId,
                    },
                })
                setLoading(false)
            })
            .catch((e) => {
                console.error(e.message)
                setLoading(false)
            })
    }

    return (
        <Box>
            <Grid container direction="column" spacing={1}>
                <Grid item xs={2}>
                    <Typography>Service Notes (internal use)</Typography>
                </Grid>
                <Grid item xs={12}>
                    {loading ? <LinearProgress color="secondary" /> : null}
                    <TextField
                        inputRef={textRef}
                        multiline
                        rows={4}
                        placeholder="Ex: Golf bag attachment clip on driver side broken"
                        variant="filled"
                        sx={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Grid
                        container
                        direction="row"
                        justifyContent={"space-between"}
                        alignItems="center"
                    >
                        <Grid item xs>
                            <Typography fontSize={16}>
                                Add note to the vehicle:
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs
                            style={{
                                maxWidth: "fit-content",
                            }}
                        >
                            <Button
                                onClick={handleSubmit}
                                size="small"
                                disabled={!canChangeAv}
                                variant="contained"
                                color="secondary"
                            >
                                ADD
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
