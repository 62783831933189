export const intToString = (s) => {
    const sString = String(Math.floor(s))
    let newString = ""
    let count = 0
    for (let i = sString.length - 1; i >= 0; i--) {
        if (count === 3) {
            count = 0
            newString += " "
        }
        const character = sString[i]
        newString += character
        count += 1
        if (character === ".") {
            count = 0
        }
    }
    return [...newString].reverse().join("")
}
