import React from "react"
import { Box, Button } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { sortCarts } from "../timeline"

export default function CartInstallationNavigationToolbar() {
    const navigate = useNavigate()
    const { cartsComplete, currentCart } = useSelector(
        (state) => state.installation
    )

    const carts = useSelector((state) => state.firestore.data.carts)

    const getAllCarts = () => {
        const allCarts = Object.keys(cartsComplete)
            .sort((x, y) => sortCarts(carts[x], carts[y]))
            .reduce((obj, key) => {
                return [...obj, key]
            }, [])
        return allCarts
    }

    const getCurrentIndex = () => {
        const allCarts = getAllCarts()
        return Object.values(allCarts).reduce((x, current, ind) => {
            if (carts[current].boardId === currentCart.boardId) {
                return ind
            }
            return x
        }, -1)
    }
    const getAllCartsSortedAfterCurrent = () => {
        const carts = getAllCarts()
        const index = getCurrentIndex()
        const cartsBefore = carts.slice(0, index)
        const cartsAfter = carts.slice(index + 1, carts.length)
        return [...cartsAfter, ...cartsBefore]
    }
    const getNextCart = () => {
        const nextCarts = getAllCartsSortedAfterCurrent()
        console.log("next carts", nextCarts)

        if (nextCarts.length) {
            return nextCarts[0]
        }
    }

    const getPreviousCart = () => {
        console.log(getAllCartsSortedAfterCurrent())
        const cartsAfter = getAllCartsSortedAfterCurrent()
        const previousCarts = cartsAfter.reverse()
        if (previousCarts.length) {
            return previousCarts[0]
        }
    }

    const handleBack = () => {
        const nextCart = getPreviousCart()
        navigate("../cart/" + carts[nextCart].boardId)
    }

    const handleNext = () => {
        const nextCart = getNextCart()
        navigate("../cart/" + carts[nextCart].boardId)
    }

    return (
        <Box display={"flex"}>
            <Button
                sx={{ margin: 1, maxWidth: "45vw" }}
                onClick={handleBack}
                color="secondary"
                variant="contained"
                fullWidth
            >
                Back
            </Button>
            <Button
                sx={{ margin: 1, maxWidth: "45vw" }}
                onClick={handleNext}
                color="secondary"
                variant="contained"
                fullWidth
            >
                Next
            </Button>
        </Box>
    )
}
