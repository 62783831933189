import { Box, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { dayMonthYear } from "../../Components/dateTools"
import CancelSubButton from "../../Components/Subscription/cancelSubscriptionButton"
import { activeSubStatus } from "../../Components/SubscriptionStatus"

export default function CancelSubscription(props) {
    const { subData, status } = props

    if (!subData) {
        return null
    }

    if (activeSubStatus.indexOf(status) > -1) {
        if (subData?.stripeSubscription?.cancel_at_period_end) {
            return (
                <Box sx={{ pt: 2 }}>
                    <Typography>
                        Ending subscription, ends at{" "}
                        {dayMonthYear(
                            new Date(
                                subData?.stripeSubscription
                                    ?.current_period_end * 1000
                            )
                        )}
                    </Typography>
                    <Typography>
                        If you do not reactivate you will loose all access.
                    </Typography>
                    <CancelSubButton subData={subData} />
                </Box>
            )
        }
        return <CancelSubButton subData={subData} />
    }
    return null
}
