import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { CartInstallationHeader } from "./InstallCart/introInstallationText"
import InstallOverviewSuccessText from "./overview/SuccessText"
import InstallOverviewRetryText from "./overview/RetryText"
import InstallCartList from "./cartListOverview/InstallCartList"

export default function InstallOverview() {
    const { allCartsComplete } = useSelector((state) => state.installation)

    const OverviewText = () => {
        if (allCartsComplete) {
            return <InstallOverviewSuccessText />
        } else {
            return <InstallOverviewRetryText />
        }
    }
    return (
        <Box sx={{ margin: 2 }}>
            <CartInstallationHeader />
            <OverviewText />
            <InstallCartList />
        </Box>
    )
}
