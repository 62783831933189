import React, { memo, useEffect, useRef, useState } from "react"
import GoogleMapReact from "google-map-react"
import { useSelector } from "react-redux"
import { Grid } from "@mui/material"
import Hidden from "@mui/material/Hidden"
import { mapStyle } from "../assets/styles/components/map"
import LockTable from "../Components/VehicleControl/LockTable"
import CartOnMap from "../Components/Carts/cartOnMap"
import MapHelpDialog from "../Components/helpDialog/mapHelp"
import PopupAvailability from "../Components/AvailabilityRebooking/popupAvailability"
import useSupercluster from "use-supercluster"
import ClusterMarker from "../Components/Carts/clusterMarker"

const Marker = ({ children }) => children

function Map() {
    const Carts = useSelector((state) => state.firestore.data.carts)
    const classes = mapStyle()
    const fieldOptions = useSelector((state) => state.firestore.data.field)
    const mapRef = useRef()
    const [bounds, setBounds] = useState(null)
    const [zoom, setZoom] = useState(fieldOptions.zoom)

    const mapConf = {
        center: {
            lat: fieldOptions.pos.latitude,
            lng: fieldOptions.pos.longitude,
        },
        zoom: fieldOptions.zoom,
    }
    const maxZoom = fieldOptions.maxZoom || 20
    const points =
        Carts &&
        Object.keys(Carts).length &&
        Object.keys(Carts).map((key, index) => {
            const cart = Carts[key]
            const { boardId } = cart
            if (cart.LOC === undefined)
                return {
                    cartDocId: key,
                }
            return {
                type: "Feature",
                properties: {
                    cluster: false,
                    cartId: boardId,
                    cartDocId: key,
                    category: "vehicles",
                },
                geometry: {
                    type: "Point",
                    coordinates: [cart.LOC.longitude, cart.LOC.latitude],
                },
            }
        })
    const { clusters, supercluster } = useSupercluster({
        points: points ?? [],
        bounds,
        zoom,
        options: {
            radius: 500,
            maxZoom,
        },
    })

    const handleGetVehiclePosition = (vehicle, coordinates) => {
        if (coordinates) {
            mapRef.current.setZoom(maxZoom)
            mapRef.current.panTo({
                lat: coordinates._lat,
                lng: coordinates._long,
            })
        }
    }

    return (
        <div className={classes.root}>
            <Hidden mdDown>
                {Carts && Object.keys(Carts).length ? (
                    <div className={classes.overlay}>
                        <Grid container direction="column" spacing={2}>
                            <Grid item xs={12}>
                                <LockTable
                                    handleGetVehiclePosition={
                                        handleGetVehiclePosition
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : null}
                <MapHelpDialog />
            </Hidden>
            <GoogleMapReact
                defaultCenter={mapConf.center}
                defaultZoom={mapConf.zoom}
                options={(map) => ({
                    mapTypeId: map.MapTypeId.HYBRID,
                    fullscreenControl: false,
                    zoomControl: false,
                    maxZoom,
                })}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map }) => {
                    mapRef.current = map
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom)
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat,
                    ])
                }}
            >
                {clusters.map((cluster, index) => {
                    const [longitude, latitude] = cluster.geometry.coordinates
                    const { cluster: isCluster, point_count: pointCount } =
                        cluster.properties

                    if (isCluster) {
                        if (zoom === maxZoom) {
                            return (
                                <Marker
                                    key={`cluster-${index}`}
                                    lat={latitude}
                                    lng={longitude}
                                >
                                    {supercluster
                                        .getChildren(cluster.id)
                                        .map((item, index) => {
                                            if (!item.properties.cartDocId) {
                                                console.log(
                                                    "EMPTY",
                                                    item,
                                                    cluster
                                                )
                                                return null
                                            }
                                            const cart =
                                                Carts[item.properties.cartDocId]
                                            if (!cart) {
                                                return null
                                            }
                                            return (
                                                <CartOnMap
                                                    key={index}
                                                    boardId={cart.boardId}
                                                    cartDocId={cart.cartDocId}
                                                    cart={cart}
                                                />
                                            )
                                        })}
                                </Marker>
                            )
                        }
                        return (
                            <Marker
                                key={`cluster-${index}`}
                                lat={latitude}
                                lng={longitude}
                                style={{ position: "relative" }}
                            >
                                <ClusterMarker
                                    onClick={() => {
                                        const expansionZoom = Math.min(
                                            supercluster.getClusterExpansionZoom(
                                                cluster.id
                                            ),
                                            20
                                        )
                                        mapRef.current.setZoom(expansionZoom)
                                        mapRef.current.panTo({
                                            lat: latitude,
                                            lng: longitude,
                                        })
                                    }}
                                >
                                    {pointCount}
                                </ClusterMarker>
                            </Marker>
                        )
                    }
                    const cart = Carts[cluster.properties.cartDocId]
                    if (!cart) {
                        return null
                    }
                    return (
                        <Marker
                            key={`cluster-${index}`}
                            lat={latitude}
                            lng={longitude}
                        >
                            <CartOnMap
                                key={index}
                                boardId={cart.boardId}
                                cartDocId={cart.cartDocId}
                                cart={cart}
                            />
                        </Marker>
                    )
                })}
            </GoogleMapReact>
        </div>
    )
}

export default Map
