import React, { useState } from "react"
import { useSelector } from "react-redux"
import Paper from "@mui/material/Paper"
import TableContainer from "@mui/material/TableContainer"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { Box, TableCell } from "@mui/material"
import TableBody from "@mui/material/TableBody"
import Typography from "@mui/material/Typography"
import { CheckboxRows } from "./checkboxRows"
import {
    PERMISSIONSSHOULDUPDATESTATE,
    SETPERMISSION,
    SETUSERACCESSPERMISSION,
} from "../../constants/actionTypes"
import store from "../../store"
import theme from "../../assets/styles/muiTheme"
import { useBookingStyles } from "../../assets/styles/components/bookingTable"
import { functions } from "../../rootWrapper/firebase"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { httpsCallable } from "firebase/functions"

export default function PermissionCheckbox(props) {
    const adminUser = useSelector((state) => state.firestore.data.user)
    const field = adminUser.courseDocId

    const permissions = useSelector((state) => state.permissionEdit)
    const initStatus = {
        success: false,
        loading: false,
        error: false,
    }
    const [status, setStatus] = useState(initStatus)
    const [error, setError] = useState("")

    const classes = useBookingStyles(theme)

    const modifyUserPermissions = httpsCallable(
        functions,
        "modifyUserToPermissions"
    )

    const permissionUser = useSelector((state) => {
        if (Object.keys(permissions).indexOf("userId") > -1) {
            return state.userPermissionList
        }
        return null
    })

    const changePermissions = (feature, value) => {
        console.log(feature, value)
        store.dispatch({
            type: SETPERMISSION,
            payload: { key: feature, value },
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus((state) => ({ ...state, loading: true }))
        console.log(permissions.permission)
        modifyUserPermissions({
            courseDocId: field,
            userDocId: user.id,
            accessPermissions: permissions.permission,
        })
            .then(() => {
                setStatus((state) => ({
                    error: false,
                    success: true,
                    loading: false,
                }))
                store.dispatch({
                    type: SETUSERACCESSPERMISSION,
                    payload: {
                        id: permissions.userId,
                        accessPermissions: permissions.permission,
                    },
                })
                store.dispatch({ type: PERMISSIONSSHOULDUPDATESTATE })
            })
            .catch((e) => {
                setStatus((state) => ({
                    ...state,
                    loading: false,
                    error: true,
                }))
                setError(e.message)
            })
            .then(
                () =>
                    new Promise(() =>
                        setTimeout(() => setStatus(initStatus), 2000)
                    )
            )
    }
    const user = permissionUser[permissions.userId]
    console.log(user)
    if (!user) {
        return <div></div>
    }
    console.log(permissions.permission)
    return (
        <Box>
            <Typography variant="h5">
                Permissions of {user.fullName}, email:
                {user.email}
            </Typography>
            <Paper className={classes.root} style={{ marginBottom: 32 }}>
                <TableContainer className={classes.container}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Feature</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Permission</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <CheckboxRows
                                boxes={permissions.permission}
                                changePermissions={changePermissions}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                <SuccessLoader
                    status={status}
                    error={error}
                    handleSubmit={handleSubmit}
                />
            </Paper>
        </Box>
    )
}
