import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { Route, Routes } from "react-router-dom"
import ConfirmPage from "../../Components/CheckoutForm/confirmPage"
import UpdateSetupIntent from "../../Components/CheckoutForm/submitSetupIntent"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const getOptions = (secret) => ({
    clientSecret: secret,
    appearance: {
        theme: "night",
    },
})

export default function CardInput(props) {
    const clientSecret = new URLSearchParams(window.location.search).get(
        "setup_intent_client_secret"
    )
    const { setupIntent } = props
    const [option, setOption] = useState({})
    const optionsIsDefined = Object.keys(option).length > 0

    useEffect(() => {
        if (
            ((setupIntent && setupIntent.client_secret) || clientSecret) &&
            !optionsIsDefined
        ) {
            setOption(getOptions(setupIntent?.client_secret || clientSecret))
        }
    }, [setupIntent?.client_secret, optionsIsDefined, clientSecret])

    if (!(setupIntent || clientSecret) || !optionsIsDefined) {
        return null
    }

    return (
        <Elements stripe={stripePromise} options={option}>
            <Routes>
                <Route index element={<UpdateSetupIntent />} />
                <Route path="confirm" element={<ConfirmPage />} />
            </Routes>
        </Elements>
    )
}
