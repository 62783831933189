import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import theme from "../../assets/styles/muiTheme"
import SettingTopbar from "../settingsTabs/settingTab"
import {
    SET_ACTIVE_RIDEPASS,
    SET_ACTIVE_RIDEPASS_LOADED,
    SET_INACTIVE_RIDEPASS,
    SET_INACTIVE_RIDEPASS_LOADED,
} from "../../constants/actionTypes"
import store from "../../store"
import CouponGrid from "../CouponGrid"
import { CouponLoader, useCouponListStyles } from "."
import RidepassCard, { COUPONCARDWIDTH } from "../RidePassCard"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const ridepassOptions = ["Store", "Archive"]

export default function RidepassList() {
    const classes = useCouponListStyles(theme)
    const ridepass = useSelector((state) => state.ridepass)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const getAllRidepasses = httpsCallable(functions, "getAllRidepasses")
    useEffect(() => {
        if (!ridepass.activePassesLoaded) {
            console.log("get all active")

            getAllRidepasses({
                courseDocId,
                active: true,
            })
                .then((r) => {
                    console.log(r)
                    store.dispatch({
                        type: SET_ACTIVE_RIDEPASS_LOADED,
                        payload: { activePasses: r.data },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [ridepass.activePassesLoaded, getAllRidepasses])
    useEffect(() => {
        if (!ridepass.inactivePassesLoaded) {
            getAllRidepasses({
                courseDocId,
                active: false,
            })
                .then((r) => {
                    console.log(r)
                    store.dispatch({
                        type: SET_INACTIVE_RIDEPASS_LOADED,
                        payload: { inactivePasses: r.data },
                    })
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [ridepass.inactivePassesLoaded, getAllRidepasses])

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            store.dispatch({ type: SET_ACTIVE_RIDEPASS })
        } else {
            store.dispatch({ type: SET_INACTIVE_RIDEPASS })
        }
    }
    return (
        <div className={classes.root}>
            <CouponLoader
                active={ridepass.active}
                activeCouponsLoaded={ridepass.activePassesLoaded}
                inactiveCouponsLoaded={ridepass.inactivePassesLoaded}
            />
            <SettingTopbar
                settings={ridepassOptions}
                allSettings={ridepassOptions}
                settingFocused={ridepass.active ? 0 : 1}
                handleChange={handleChange}
                variant="fullWidth"
            />
            <CouponGrid
                gridSpacing="start"
                Component={RidepassCard}
                couponList={
                    ridepass.active
                        ? ridepass.activePasses
                        : ridepass.inactivePasses
                }
                cardWidth={COUPONCARDWIDTH}
            />
        </div>
    )
}
