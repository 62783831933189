import React, { useEffect } from "react"
import { Box, Button } from "@mui/material"
import { Route, Routes, useNavigate } from "react-router-dom"
import IntroInstallationText from "./InstallCart/introInstallationText"
import { useSelector } from "react-redux"
import InstallCartList from "./cartListOverview/InstallCartList"

const NoCarts = () => {
    return (
        <>
            <div>{"No carts registered for this course"}</div>
            <div>{"Please contact support. clubsupport@eagl.app"}</div>
        </>
    )
}

export default function InstallIntroPage() {
    const navigate = useNavigate()
    const carts = useSelector((state) => {
        return state.installation.cartIdToDocId
    })
    const hasMoreThanOneCart = Object.keys(carts).length > 0
    const firstCart = Object.keys(carts)[0]
    return (
        <Box sx={{ margin: 2 }}>
            <IntroInstallationText />
            {hasMoreThanOneCart ? (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate("cart/" + firstCart)}
                    fullWidth
                >
                    Start with the first cart
                </Button>
            ) : (
                <NoCarts />
            )}
            <InstallCartList />
        </Box>
    )
}
