import { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_STATS } from "../../constants/actionTypes"
import {
    getDiscountAmount,
    getEndTime,
    getPriceApp,
    getPriceDash,
    getPriceNumber,
    getRefundAmountNumber,
} from "../Booking/bookingUtils"
import {
    statusCancelled,
    statusFinishedWithAdmin,
} from "../../constants/statusTypes"

export default function StatsCounter() {
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )

    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })
    const bookings = useSelector((state) => {
        return state.bookingHistory.bookings || []
    })
    const coupons = useSelector((state) => {
        return state.bookingHistory.coupons || []
    })

    const { queryComplete, completeCoupon } = query

    useEffect(() => {
        if (completeCoupon) {
            console.log("Counting coupon stats")
            const couponPrice = coupons.reduce(
                (sum, coupon) => sum + coupon.price,
                0
            )
            store.dispatch({
                type: SET_STATS,
                payload: {
                    stats: {
                        totalCouponIncome: couponPrice,
                    },
                },
            })
        }
    }, [courseDocId, completeCoupon])

    useEffect(() => {
        if (queryComplete) {
            console.log("counting stats")

            const finnisedBookings = bookings.filter(
                (x) => statusFinishedWithAdmin.indexOf(x.status) > -1
            )
            const paidCancelledBookings = bookings
                .filter((x) => x.status === statusCancelled)
                .filter((x) => x?.capturedAmount || x?.rpValid)
            const allPaidBookings = [
                ...finnisedBookings,
                ...paidCancelledBookings,
            ]

            const totalBookings = allPaidBookings.length
            const totalAppIncome = allPaidBookings.reduce(
                (sum, currentBooking) => sum + getPriceApp(currentBooking),
                0
            )
            const totalDashIncome = allPaidBookings.reduce(
                (sum, currentBooking) => sum + getPriceDash(currentBooking),
                0
            )
            const totalRefund = allPaidBookings.reduce(
                (sum, currentBooking) =>
                    sum + getRefundAmountNumber(currentBooking),
                0
            )
            const totalDiscount = allPaidBookings.reduce(
                (sum, currentBooking) =>
                    sum + getDiscountAmount(currentBooking),
                0
            )
            const totalIncome = totalAppIncome + totalDashIncome
            const { starBookings, starsTotal } = finnisedBookings.reduce(
                (current, currentBooking) => {
                    if (currentBooking.starRating) {
                        return {
                            starsTotal:
                                current.starsTotal + currentBooking.starRating,
                            starBookings: current.starBookings + 1,
                        }
                    }
                    return current
                },
                { starsTotal: 0, starBookings: 0 }
            )
            const totalRentalTime = allPaidBookings.reduce(
                (totalTime, currentBooking) => {
                    const start = currentBooking.startTime.toMillis()
                    const end = getEndTime(currentBooking).toMillis()
                    return totalTime + (end - start)
                },
                0
            )
            const totalTimeBefore = allPaidBookings.reduce(
                (totalTime, currentBooking) => {
                    const start = currentBooking.startTime.toMillis()
                    const createdTs =
                        currentBooking?.createdAt?.toMillis() ||
                        currentBooking.startTime.toMillis()
                    return totalTime + (start - createdTs)
                },
                0
            )
            const averageTimeBefore = totalTimeBefore / allPaidBookings.length
            const segmentSum = allPaidBookings.reduce(
                (segmentCount, booking) => {
                    const segment = booking.userSegment
                    const bookingPrice = getPriceNumber(booking)
                    if (segment in segmentCount) {
                        return {
                            ...segmentCount,
                            [segment]: {
                                count: segmentCount[segment].count + 1,
                                value:
                                    segmentCount[segment].value + bookingPrice,
                            },
                        }
                    }
                    return {
                        ...segmentCount,
                        [segment]: {
                            count: 1,
                            value: bookingPrice,
                        },
                    }
                },
                {}
            )
            const weekdayBookings = allPaidBookings.reduce((count, booking) => {
                const day = booking.startTime.toDate().getDay()
                if (day in count) {
                    return {
                        ...count,
                        [day]: count[day] + 1,
                    }
                }
                return {
                    ...count,
                    [day]: 1,
                }
            }, {})

            const appVsDash = allPaidBookings.reduce(
                (count, booking) => {
                    if (
                        booking.bookingType === "app" ||
                        booking.bookingType === "webApp"
                    ) {
                        return {
                            ...count,
                            app: count.app + 1,
                        }
                    }
                    if (booking.bookingType === "admin") {
                        return {
                            ...count,
                            dash: count.dash + 1,
                        }
                    }
                    return count
                },
                {
                    app: 0,
                    dash: 0,
                }
            )
            const appStarted = allPaidBookings.filter(
                (x) => x.actualStartTime
            ).length

            store.dispatch({
                type: SET_STATS,
                payload: {
                    stats: {
                        totalBookings,
                        totalRentalTime,
                        averageTimeBefore,
                        incomeTotal: totalIncome,
                        starsTotal,
                        starBookings,
                        totalAppIncome,
                        totalDashIncome,
                        totalRefund,
                        totalDiscount,
                        segmentSum,
                        weekdayBookings,
                        appVsDash,
                        appStarted,
                    },
                },
            })
        }
    }, [courseDocId, queryComplete])

    return null
}
