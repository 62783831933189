const readings = "readingsFromAPI"
const cartsPath = "carts"
const users = "webUser"
const mobileUsers = "users"
const golfCourses = "golfcourses"
const lockingRequests = "lockReq"
const trips = "trips"
const storageImagesBasePath = "image"
const financeReportPath = "financeReport"
const bookingPath = "booking"
const ridepassPurchasedPath = "ridepassPurchased"
const serviceStatusPath = "serviceStatus"
const subscriptionsPath = "subscriptions"
const reportRunPath = "bookingReportRun"

export {
    cartsPath,
    readings,
    users,
    golfCourses,
    lockingRequests,
    trips,
    storageImagesBasePath,
    bookingPath,
    mobileUsers,
    serviceStatusPath,
    subscriptionsPath,
    financeReportPath,
    ridepassPurchasedPath,
    reportRunPath,
}
