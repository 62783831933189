import {
    SELECTSETTINGSFIELD,
    SETSETTINGSTIME,
    SETSETTINGSTIMEUPDATED,
    SETTALLSETTINGS,
    SETTINGS_ERROR_SET,
    SETTINGS_ERROR_AUTOLOCK,
    SET_SETTINGS_SAVED,
    SET_SEGMENT,
    DELETE_SEGMENT,
    SET_PERCENTAGE_VAT,
} from "../constants/actionTypes"

const initState = {
    averageTime: "",
    standard: "",
    segments: {},
    openingTimes: {
        open: "",
        close: "",
        nightLock: "",
    },
    nightLockEnabled: false,

    errors: {
        errorAutoLock: "",
        error: "",
    },
    settingsLoaded: false,
    savedChanges: true,
}
const savedChangesIsTrue = ["stripeBillingEmail"]

export default (state = initState, action) => {
    switch (action.type) {
        case SETTALLSETTINGS:
            return {
                ...state,
                ...action.payload,
                settingsLoaded: true,
            }
        case SELECTSETTINGSFIELD:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
                savedChanges:
                    savedChangesIsTrue.indexOf(action.payload.key) > -1,
            }
        case SET_SEGMENT:
            return {
                ...state,
                segments: [
                    ...state.segments.filter(
                        (x) => x.key != action.payload.key
                    ),
                    action.payload.value,
                ],
                savedChanges: false,
            }
        case DELETE_SEGMENT:
            return {
                ...state,
                segments: [
                    ...state.segments.filter(
                        (x) => x.key != action.payload.key
                    ),
                ],
                savedChanges: false,
            }
        case SETSETTINGSTIME:
            return {
                ...state,
                openingTimes: {
                    ...state.openingTimes,
                    [action.payload.key]: action.payload.value,
                },
                savedChanges: false,
            }
        case SETSETTINGSTIMEUPDATED:
            return {
                ...state,
                openingTimes: {
                    ...state.openingTimes,
                    [action.payload.key]: action.payload.value,
                },
                vehicleGroups: {
                    ...state.vehicleGroups,
                    [action.payload.vehicleGroup]: {
                        ...state.vehicleGroups[action.payload.vehicleGroup],
                        [action.payload.key]: action.payload.value,
                    },
                },
                savedChanges: false,
            }
        case SETTINGS_ERROR_SET:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    error: action.payload.error,
                },
            }
        case SETTINGS_ERROR_AUTOLOCK:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    errorAutoLock: action.payload.error,
                },
            }
        case SET_PERCENTAGE_VAT:
            return {
                ...state,
                costStructure: {
                    ...state.costStructure,
                    percentageVat: action.payload.value,
                },
            }
        case SET_SETTINGS_SAVED:
            return {
                ...state,
                savedChanges: true,
            }

        default:
            return state
    }
}
