import React, { useState, useEffect } from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom"
import validateEmail from "../Components/firebaseUtils/ValidateEmail"
import { formStyle } from "../assets/styles/views/formStyle"
import Copyright from "../Components/Copyright/copyright"
import { fire } from "../rootWrapper/firebase"
import store from "../store"
import { SET_REDIRECT_ON_LOGIN } from "../constants/actionTypes"
import RegisterContainer from "../Components/RegisterContainer"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"

const useStyles = makeStyles(formStyle)

export default function Login(props) {
    const [searchParams] = useSearchParams()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [unsafepass, setUnsafePass] = useState(false)
    const [invalidEmail, setinvalidEmail] = useState(false)
    const [success, setSuccess] = useState(false)
    const styles = useStyles()
    const navigate = useNavigate()
    const redirectQuery =
        searchParams.has("redirect") && searchParams.get("redirect")
    const { redirectLink, redirect } = useSelector((state) => state.signin)

    const handleInputChange = (e) => {
        e.preventDefault()
        const { id } = e.target
        const { value } = e.target
        switch (id) {
            case "email":
                setEmail(value.replace(/\s+/g, ""))
                break
            case "password":
                setPassword(value)
                break
            default:
                break
        }
    }

    const loginUserWithEmailAndPassword = (e) => {
        e.preventDefault()
        if (email && password) {
            setSubmit(false)
            if (email.length < 5 || !validateEmail(email)) {
                setinvalidEmail(true)
                return
            }
            setinvalidEmail(false)

            if (password.length < 10) {
                setUnsafePass(true)
                return
            }
            setUnsafePass(false)

            // setting the error message
            signInWithEmailAndPassword(getAuth(), email, password)
                .then((result) => {
                    setSuccess(true)
                    console.log(redirect)
                    if (redirect && redirectLink.length) {
                        navigate("/" + redirectLink)
                    } else {
                        navigate("/")
                    }
                })
                .catch((error) => {
                    // Handle Errors here.
                    setSubmit(true)
                    const errorCode = error.code
                    const errorMessage = error.message
                    console.log(errorCode, errorMessage)
                })
        }
    }
    const error = <h3 className={styles.error}> Wrong credentials</h3>
    const longerPass = (
        <h3 className={styles.error}>
            {" "}
            Your password must be at least 10 characters
        </h3>
    )
    const invalidEmailError = (
        <h3 className={styles.error}> Please enter a valid email</h3>
    )
    const successMessage = (
        <h3 className={styles.successMessageStyle}> Success, please wait</h3>
    )

    const userIsLoaded = useSelector((state) => "uid" in state.firebase.auth)
    const authUid = useSelector((state) => state.firebase.auth.uid)

    useEffect(() => {
        if (redirectQuery && redirectQuery.length) {
            store.dispatch({
                type: SET_REDIRECT_ON_LOGIN,
                payload: { redirectLink: redirectQuery },
            })
        }
    }, [redirectQuery])

    useEffect(() => {
        if (userIsLoaded && authUid && !submit) {
            if (redirect && redirectLink.length) {
                navigate("/" + redirectLink)
            } else {
                navigate("/")
            }
        }
    }, [userIsLoaded])

    return (
        <RegisterContainer>
            <Avatar className={styles.avatar}>
                <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <form
                className={styles.form}
                style={{ maxWidth: "400px" }}
                noValidate
            >
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={email}
                    autoComplete="email"
                    autoFocus
                    onChange={handleInputChange}
                    variant="filled"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={handleInputChange}
                    variant="filled"
                />
                <Grid className={styles.errorGrid}>
                    {invalidEmail && !submit && invalidEmailError}
                    {unsafepass && !submit && longerPass}
                    {submit && error}
                    {success && successMessage}
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={styles.submit}
                    onClick={loginUserWithEmailAndPassword}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <Link
                            to="/forgotpass"
                            variant="body2"
                            style={{ color: "#c3c3c3" }}
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            to="/register"
                            variant="body2"
                            style={{ color: "#c3c3c3" }}
                        >
                            Don&apos;t have an account? Sign Up
                        </Link>
                    </Grid>
                </Grid>
            </form>

            <Box mt={8}>
                <Copyright />
            </Box>
        </RegisterContainer>
    )
}
