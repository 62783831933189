import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        overflowX: "hidden",
        [theme.breakpoints.up("md")]: {
            width: "calc(100vw - 270px)",
        },
    },
}))

export default function News() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <iframe
                src="https://www.eagl.app/news/category/EAGL+Fleet+Dashboard+Internal"
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-modal allow-same-origin"
                style={{ border: "none" }}
            ></iframe>
        </div>
    )
}
