import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import PropTypes from "prop-types"

export default function StatsItem(props) {
    const { StatsIcon, headerText, subheaderText } = props
    return (
        <Box height={"100%"} minHeight={"120px"} minWidth="150px">
            <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                direction="column"
                sx={{ height: "100%" }}
            >
                <Grid item xs sx={{ mb: -2 }}>
                    <StatsIcon color="secondary" />
                </Grid>
                <Grid item xs sx={{ mb: -1 }}>
                    <Typography fontSize={"36pt"} fontWeight="bold">
                        {headerText}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography
                        fontSize={"12pt"}
                        color="#DCDCDCC9"
                        fontWeight="bold"
                    >
                        {subheaderText}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

StatsItem.propTypes = {
    StatsIcon: PropTypes.any.isRequired,
    headerText: PropTypes.string.isRequired,
    subheaderText: PropTypes.string.isRequired,
}
