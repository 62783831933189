import {
    CARTAVLOADING,
    CARTAVNOTLOADING,
    CARTLOCKLOADING,
    CARTLOCKNOTLOADING,
} from "../constants/actionTypes"

export default (state = {}, action) => {
    switch (action.type) {
        case CARTAVLOADING:
            return {
                ...state,
                [action.payload.cart]: {
                    ...state[action.payload.cart],
                    avLoading: true,
                },
            }
        case CARTAVNOTLOADING:
            return {
                ...state,
                [action.payload.cart]: {
                    ...state[action.payload.cart],
                    avLoading: false,
                },
            }
        case CARTLOCKLOADING:
            return {
                ...state,
                [action.payload.cart]: {
                    ...action.payload,
                    ...state[action.payload.cart],
                    lockLoading: true,
                    status: {
                        ...action.payload.status,
                    },
                },
            }
        case CARTLOCKNOTLOADING:
            return {
                ...state,
                [action.payload.cart]: {
                    ...action.payload,
                    ...state[action.payload.cart],
                    lockLoading: false,
                    status: {
                        ...state[action.payload.cart].status,
                        ...action.payload.status,
                    },
                },
            }
        default:
            return state
    }
}
