import { httpsCallable } from "firebase/functions"
import {
    CARTLOCKLOADING,
    CARTLOCKNOTLOADING,
} from "../../constants/actionTypes"
import { functions } from "../../rootWrapper/firebase"
import store from "../../store"

const PingCartFunc = httpsCallable(functions, "pingCart")
const pingCartCallable = (courseDocId, cartDocId) =>
    PingCartFunc({
        courseDocId,
        cartDocId,
    })

const wait = (seconds) => new Promise((r) => setTimeout(r, seconds * 1000))

async function PingCart(courseDocId, cartDocId) {
    if (!cartDocId) {
        console.log("tried lock illegal cart", courseDocId, cartDocId)
        return {
            status: 400,
            error: true,
            res: "illegal cart",
        }
    }
    store.dispatch({
        type: CARTLOCKLOADING,
        payload: {
            cart: cartDocId,
            boardId: cartDocId,
            status: {
                loading: true,
                completed: false,
                error: false,
                success: false,
                errorMessage: "",
            },
        },
    })
    const res = await pingCartCallable(courseDocId, cartDocId)
        .then((r) => r.data)
        .catch((e) => {
            console.error(e.message)
            store.dispatch({
                type: CARTLOCKNOTLOADING,
                payload: {
                    cart: cartDocId,
                    boardId: cartDocId,
                    status: {
                        loading: false,
                        completed: true,
                        error: true,
                        success: false,
                        errorMessage: e.message,
                    },
                },
            })
            return {
                loading: false,
                completed: true,
                error: true,
                status: 400,
                res: e.message,
                errorMessage: e.message,
            }
        })
    console.log("Res", res)
    if (res.status !== 201) {
        store.dispatch({
            type: CARTLOCKNOTLOADING,
            payload: {
                cart: cartDocId,
                boardId: cartDocId,
                status: {
                    loading: false,
                    completed: true,
                    error: true,
                    success: false,
                    errorMessage: res?.res,
                },
            },
        })
        return { ...res, errorMessage: res?.res }
    }
    await wait(30)

    store.dispatch({
        type: CARTLOCKNOTLOADING,
        payload: { cart: cartDocId },
    })
    store.dispatch({
        type: CARTLOCKNOTLOADING,
        payload: {
            cart: cartDocId,
            boardId: cartDocId,
            status: {
                loading: false,
                completed: true,
                error: false,
                success: true,
                errorMessage: res?.res,
            },
        },
    })

    console.log(res)
    return { ...res, errorMessage: res.res }
}

export default PingCart
