import { Timestamp } from "firebase/firestore"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import {
    SET_CURRENT_BOOKING,
    SET_LOADING_CURRENT_BOOKING,
} from "../../constants/actionTypes"
import { bookingPath, golfCourses } from "../../constants/dbPaths"
import {
    statusAdmin,
    statusAllUpcomingOngoing,
    statusMaintenance,
    statusNotStarted,
    statusStarted,
} from "../../constants/statusTypes"
import db from "../../rootWrapper/firebase"
import store from "../../store"

export default function CurrentBookingLoading() {
    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )

    useEffect(() => {
        if (courseDocId && cartDocId) {
            store.dispatch({
                type: SET_LOADING_CURRENT_BOOKING,
            })
            const unsubscribe = db
                .collection(golfCourses)
                .doc(courseDocId)
                .collection(bookingPath)
                .where("cartDocId", "==", cartDocId)
                .where("status", "in", statusAllUpcomingOngoing)
                .where("startTime", "<=", Timestamp.now())
                .orderBy("startTime", "desc")
                .limit(1)
                .onSnapshot(
                    (snapshot) => {
                        if (!snapshot.empty) {
                            const doc = snapshot.docs[0].data()
                            console.log(doc)
                            if (
                                ((doc.status === statusAdmin ||
                                    doc.status === statusMaintenance) &&
                                    doc.endTime.toMillis() >= Date.now()) ||
                                doc.status === statusStarted ||
                                doc.status === statusNotStarted
                            ) {
                                store.dispatch({
                                    type: SET_CURRENT_BOOKING,
                                    payload: {
                                        currentBooking: doc,
                                    },
                                })
                            } else {
                                store.dispatch({
                                    type: SET_CURRENT_BOOKING,
                                    payload: {
                                        currentBooking: null,
                                    },
                                })
                            }
                        } else {
                            store.dispatch({
                                type: SET_CURRENT_BOOKING,
                                payload: {
                                    currentBooking: null,
                                },
                            })
                        }
                    },
                    (error) => {
                        console.error(error)
                        store.dispatch({
                            type: SET_CURRENT_BOOKING,
                            payload: {
                                currentBooking: null,
                            },
                        })
                    }
                )
            return unsubscribe
        }
    }, [courseDocId, cartDocId])

    return null
}
