import React, { useEffect, useState, useMemo } from "react"
import Divider from "@mui/material/Divider"

import Drawer from "@mui/material/Drawer"
import Hidden from "@mui/material/Hidden"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import PermIdentityIcon from "@mui/icons-material/PermIdentity"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import ContactSupportIcon from "@mui/icons-material/ContactSupport"
import MenuIcon from "@mui/icons-material/Menu"
import Badge from "@mui/material/Badge"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import ProfileRow from "../Components/Profile/profileRow"
import { useDrawerStyles } from "../assets/styles/wrappers/drawerStyle"
import theme from "../assets/styles/muiTheme"
import { accessPermissionRoutes } from "../constants/accessPermissionRoutes"
import { accessPermissionsSchema } from "../constants/accessPermissionSchema"
import { routesInSidebar } from "../constants/accessPermissions"
import { PrimaryAppbar } from "../Components/settingsTabs/settingTab"
import SigninStatus from "../models/signinStatus"
import StatusRow from "../Components/StatusRow/statusRow"
import statisticsIcon from "../assets/img/StatisticIcon.svg"
import reviewsIcon from "../assets/img/ReviewsIcon.svg"
import bookingIcon from "../assets/img/BookingIcon.svg"
import mapIcon from "../assets/img/MapIcon.svg"
import discountIcon from "../assets/img/DiscountIcon.svg"
import couponIcon from "../assets/img/CouponIcon.svg"
import settingsIcon from "../assets/img/SettingIcon.svg"
import helpIcon from "../assets/img/SelfHelpIcon.svg"
import vehiclesIcon from "../assets/img/CartIcon.svg"
import BuildIcon from "@mui/icons-material/Build"
import FeedIcon from "@mui/icons-material/Feed"
import NotificationsIcon from "@mui/icons-material/Notifications"
import db from "../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../constants/dbPaths"

import StarRoundedIcon from "@mui/icons-material/StarRounded"
import { Box, ListItemButton, Tooltip } from "@mui/material"
import getSubscriptionStatus from "../Components/SubscriptionStatus"
import { isEqual } from "date-fns"
import { getFirestore, collection, onSnapshot } from "firebase/firestore"
import store from "../store"
import { SET_NOTIFICATION } from "../constants/actionTypes"

const getIconForNavigationElement = {
    Map: mapIcon,
    Statistics: statisticsIcon,
    Reviews: reviewsIcon,
    Booking: bookingIcon,
    Discounts: discountIcon,
    Coupons: couponIcon,
    Vehicles: vehiclesIcon,
    Settings: settingsIcon,
    Install: BuildIcon,
    Setup: settingsIcon,
    Upgrade: StarRoundedIcon,
}

const userViewPermissions = (
    section,
    courseData,
    permissionsOfUser,
    isAdmin,
    subData
) => {
    if (!courseData) {
        return []
    }
    return Object.keys(accessPermissionsSchema)
        .sort(
            (x, y) =>
                accessPermissionsSchema[x].key - accessPermissionsSchema[y].key
        )
        .map((key) => {
            if (key.startsWith("view")) {
                return key
            }
            return null
        })
        .filter((x) => x)
        .filter(
            (x) =>
                Object.values(routesInSidebar(courseData, subData)).indexOf(x) >
                -1
        )
        .map((key) => ({
            ...accessPermissionRoutes[key],
            access:
                (Object.keys(permissionsOfUser).indexOf(key) > -1 &&
                    (permissionsOfUser[key].access ||
                        accessPermissionsSchema[key].access)) ||
                isAdmin,
        }))
        .filter((x) => x.key >= section * 100)
        .filter((x) => x.key < (section + 1) * 100)
}

export default function ResponsiveDrawer(props) {
    const { window } = props
    const classes = useDrawerStyles(theme)
    const [mobileOpen, setMobileOpen] = useState(false)
    const navigate = useNavigate()
    const [viewList, setViewList] = useState([])
    const [viewListSecond, setViewListSecond] = useState([])
    const [notifications, setNotifications] = useState([])

    const viewObject = (text, path, Icon, access = true) => (
        <Box key={text}>
            {access ? null : (
                <Tooltip
                    placement="right"
                    title={"Limited permissions - talk to admin"}
                >
                    <Box
                        sx={{
                            // border: "1px solid", // Use to check where the helper box is
                            position: "absolute",
                            width: "150px",
                            height: "50px",
                        }}
                    ></Box>
                </Tooltip>
            )}

            <ListItemButton
                onClick={() => {
                    navigate(path)
                }}
                disabled={!access}
                key={text}
            >
                <ListItemIcon>
                    {typeof Icon === "string" ? <img src={Icon} /> : <Icon />}
                </ListItemIcon>

                <ListItemText primary={text} />
            </ListItemButton>
        </Box>
    )

    const permissionsOfUser = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf(
                "accessPermissions"
            ) > -1
        ) {
            return state.firestore.data.user.accessPermissions
        }
        return false
    })
    const isAdmin = useSelector((state) => {
        if (
            state.firestore.data &&
            Object.keys(state.firestore.data).indexOf("user") > -1
        ) {
            return state.firestore.data.user.admin
        }
        return false
    })
    const courseData = useSelector((state) => {
        return state.firestore.data.field
    })
    const { init, initiatedSub, subData } = getSubscriptionStatus()
    const { courseIsConfigured } = SigninStatus()

    useEffect(() => {
        if (courseData) {
            const notificationRef = db
                .collection(golfCourses)
                .doc(courseData.courseDocId)
                .collection("notifications")

            const fetchNotifications = async () => {
                onSnapshot(notificationRef, (snapshot) => {
                    setNotifications(snapshot.docs.map((doc) => doc.data()))
                    const notifications = snapshot.docs.map((doc) => doc.data())
                    notifications.sort((a, b) => a.createdAt < b.createdAt)

                    store.dispatch({
                        type: SET_NOTIFICATION,
                        payload: {
                            notifications,
                        },
                    })
                })
            }

            fetchNotifications().catch(console.log())
        }
    }, [courseData === undefined])

    useEffect(() => {
        const permissions = userViewPermissions(
            0,
            courseData,
            permissionsOfUser,
            isAdmin,
            subData
        )
        setViewList(
            permissions.map((obj) =>
                viewObject(
                    obj.text,
                    obj.path,
                    getIconForNavigationElement[obj.text],
                    obj.access
                )
            )
        )
        const permissionsSecond = userViewPermissions(
            1,
            courseData,
            permissionsOfUser,
            isAdmin,
            subData
        )
        setViewListSecond(
            permissionsSecond.map((obj) =>
                viewObject(
                    obj.text,
                    obj.path,
                    getIconForNavigationElement[obj.text],
                    obj.access
                )
            )
        )
    }, [
        permissionsOfUser,
        isAdmin,
        courseData === undefined,
        init && initiatedSub && Object.keys(subData).length,
    ])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }
    function AppBarCustom() {
        return (
            <PrimaryAppbar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        EAGL
                    </Typography>
                </Toolbar>
            </PrimaryAppbar>
        )
    }

    const drawer = (
        <div>
            <StatusRow />
            <ProfileRow />
            <Divider />

            <ListItemButton
                style={{ padding: 20 }}
                onClick={() => navigate("/notificationCenter")}
                key="notificationCenter"
            >
                <ListItemText primary="Notification Center:" />

                <ListItemIcon>
                    <Badge
                        badgeContent={
                            notifications.filter((x) => x.read === false).length
                        }
                        color="secondary"
                        max={99}
                    >
                        <NotificationsIcon />
                    </Badge>
                </ListItemIcon>
            </ListItemButton>

            <Divider />

            <List style={{ paddingTop: 20 }}>
                {viewList}
                <Box>
                    <ListItemButton
                        onClick={() => navigate("/news")}
                        key="news"
                    >
                        <ListItemIcon>
                            <FeedIcon />
                        </ListItemIcon>
                        <ListItemText primary="News" />
                    </ListItemButton>
                </Box>
            </List>

            <Divider />
            <List style={{ paddingTop: 10 }}>
                {viewListSecond}
                {courseIsConfigured
                    ? null
                    : viewObject(
                          "Setup",
                          "/setup",
                          getIconForNavigationElement["Setup"]
                      )}

                <ListItemButton onClick={() => navigate("/faq")} key="faq">
                    <ListItemIcon>
                        <img src={helpIcon} />
                    </ListItemIcon>
                    <ListItemText primary="Self Help" />
                </ListItemButton>

                <ListItemButton onClick={() => props.signOut()} key="loginOut">
                    <ListItemIcon>
                        <PermIdentityIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
            <Divider />
            <List style={{ paddingTop: 10 }}>
                <ListItemButton
                    key="Email"
                    component="a"
                    href="mailto:clubsupport@eagl.app?body=To be able to assist you in the best way possible,
                                please fill out the details below %0D%0A %0D%0A %0D%0A Golf Club: %0D%0A %0D%0A Reason for contact:
                                 %0D%0A %0D%0A %0D%0A FILL IN IF POSSIBLE/RELEVANT %0D%0A %0D%0A Customer ID (name, receipt etc.): 
                                 %0D%0A %0D%0A Cart number: %0D%0A %0D%0A Time of incident/occurrence: %0D%0A %0D%0A Error code: 
                                 %0D%0A %0D%0A %0D%0A %0D%0A PS: Please attach any relevant pictures or documentation which can help us assist you"
                >
                    <ListItemIcon className={classes.icon}>
                        <ContactSupportIcon />
                    </ListItemIcon>
                    <ListItemText
                        style={{
                            textDecoration: "none",
                            color: "secondary",
                        }}
                        primary="Contact Us"
                    />
                </ListItemButton>
                <ListItemButton
                    key="meetingbook"
                    component="a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://meetings.hubspot.com/oliver-bugten/eagl-software-education"
                >
                    <ListItemIcon className={classes.icon}>
                        <ContactSupportIcon />
                    </ListItemIcon>
                    <ListItemText
                        style={{
                            textDecoration: "none",
                            color: "secondary",
                        }}
                        primary="Book a meeting"
                    />
                </ListItemButton>
            </List>
        </div>
    )

    const container =
        window !== undefined ? () => window().document.body : undefined

    const renderChildren = (children) =>
        useMemo(() => {
            return children
        }, [])

    return (
        <Grid container direction="row" wrap="nowrap">
            <Grid item>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css">
                        <AppBarCustom />
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={
                                theme.direction === "rtl" ? "right" : "left"
                            }
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden mdDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
            </Grid>
            <Grid item xs>
                <Hidden mdUp implementation="js">
                    <div className={classes.toolbar} />
                </Hidden>
                <main className={classes.content}>
                    {renderChildren(props.children)}
                </main>
            </Grid>
        </Grid>
    )
}
