import {
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React from "react"

export default function TableItem(props) {
    const { title, desc, children } = props

    return (
        <TableRow
            sx={{
                height: 80,
                borderBottom: 1,
                borderColor: "rgba(81, 81, 81, 1)",
            }}
        >
            <TableCell sx={{ pl: 1, borderBottom: 0 }}>
                <Typography component="div" fontSize={18}>
                    {title}{" "}
                    <Tooltip title={<h3>{desc}</h3>} placement="right">
                        <IconButton size="small">
                            <HelpOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </TableCell>
            <TableCell sx={{ pr: 1, maxWidth: 180, borderBottom: 0 }}>
                {children}
            </TableCell>
        </TableRow>
    )
}
