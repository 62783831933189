import { initializeApp } from "firebase/app"
import { firebaseConfig } from "../constants/keys.js"
import { getFunctions, connectFunctionsEmulator } from "firebase/functions"
import { getAnalytics, setConsent } from "firebase/analytics"
import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import { requiredConsent } from "../assets/analyticsConsent.js"

const app = initializeApp(firebaseConfig)

firebase.initializeApp(firebaseConfig)

const legacy_firebase = firebase.app()
const db = firebase.firestore()

const functions = getFunctions(app, process.env.REACT_APP_REGION)
const analytics = getAnalytics(app, process.env.REACT_APP_REGION)
setConsent(requiredConsent(false))

if (process.env.NODE_ENV === "development") {
    console.log("Connected to localhost")
    console.log("region", process.env.REACT_APP_REGION)
    console.log("project", process.env.REACT_APP_PROJECT_ID)
    connectFunctionsEmulator(functions, "localhost", 5001)
}

export default db
export { legacy_firebase, functions, analytics }
