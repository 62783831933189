import { Box } from "@mui/material"
import React from "react"
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryLabel,
    VictoryStack,
    VictoryTheme,
    VictoryTooltip,
} from "victory"
import theme from "../../assets/styles/muiTheme"

export default function WeekdayCount(props) {
    const { dayCount } = props
    const days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"]
    const dayToFullday = {
        MON: "Monday",
        TUE: "Tuesday",
        WED: "Wednesday",
        THU: "Thursdag",
        FRI: "Friday",
        SAT: "Saturday",
        SUN: "Sunday",
    }

    const data = [
        Object.keys(dayCount).map((day) => ({
            day: days[(day - 1 + 7) % 7],
            count: dayCount?.[day] || 0,
        })),
    ]
    return (
        <Box sx={{ height: "100%", minHeight: "400px" }}>
            <VictoryChart
                theme={VictoryTheme.material}
                domainPadding={50}
                width={800}
            >
                <VictoryLabel
                    text="Rental Trend by Weekday"
                    x={80}
                    y={30}
                    style={{ fill: "white", fontSize: 18 }}
                />
                <VictoryAxis
                    style={{
                        grid: { strokeWidth: 0 },
                    }}
                    tickFormat={days}
                />
                <VictoryAxis
                    dependentAxis
                    tickFormat={(x) => `${x}`}
                    style={{
                        grid: {
                            stroke: "white",
                            strokeOpacity: 0.1,
                            strokeDasharray: "0",
                        },
                    }}
                />
                <VictoryStack colorScale="warm">
                    {data.map((data, key) => (
                        <VictoryBar
                            barWidth={20}
                            barRatio={1}
                            key={key}
                            cornerRadius={10}
                            labels={({ datum }) =>
                                `${dayToFullday[datum.day]} rentals: ${
                                    datum.count
                                }`
                            }
                            color="url(#gradient2)"
                            labelComponent={
                                <VictoryTooltip style={{ fontSize: 12 }} />
                            }
                            data={data}
                            x="day"
                            y="count"
                        />
                    ))}
                </VictoryStack>
            </VictoryChart>
            <svg height={0} width={0}>
                <defs>
                    <linearGradient
                        id="gradient2"
                        x1="0%"
                        y1="0%"
                        x2="0%"
                        y2="100%"
                    >
                        <stop
                            offset="0%"
                            stopColor={theme.palette.secondary.light}
                        />
                        <stop
                            offset="100%"
                            stopColor={theme.palette.secondary.dark}
                        />
                    </linearGradient>
                </defs>
            </svg>
        </Box>
    )
}
