import {
    boxOpenHeight,
    boxOpenWidth,
} from "../../../Components/Carts/getCartStyles"
import theme from "../muiTheme"

const K_SIZE = 45

const RED = "#FF0000"
const YELLOW = theme.palette.secondary.main
const GREEN = "#00FF00"
const GRAY = "#CCCCCb"
const BLUE = "#00aaff"
const DARKYELLOW = "#FFA500"
const DARKGRAY = theme.palette.gray.default

const root = () => ({
    width: "120px",
    minHeight: "40px",
    border: "4px solid",
    borderRadius: "10px",
    cursor: "auto",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
})

const customPointer = (borderColor) => ({
    content: "",
    position: "absolute",
    left: "-2.5px",
    top: "-2.5px",
    width: "5px",
    height: "5px",
    border: "3px solid",
    borderRadius: "50%",
    borderColor: borderColor,
    outline: "4px solid",
    outlineColor: theme.palette.primary.main,
})
const openCloseButton = (open) => {
    const base = {
        cursor: "pointer",
        position: "absolute",
        left: "35px",
        top: "auto",
        width: "50px",
        height: "16px",
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "0% 0% 8px 8px",
        borderWidth: "0 3px 3px 3px",
        borderStyle: "solid",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        textAlign: "center",
    }
    if (open) {
        return {
            ...base,
            // top: boxOpenHeight + "px",
            left: boxOpenWidth / 2 - 50 / 2 + "px",
        }
    }
    return base
}

const openCloseButtonText = () => ({
    position: "absolute",
    top: "-1px",
    textAlign: "center",
    width: "100%",
    cursor: "pointer",
})

const cartStyleHover = {
    borderColor: theme.palette.gray.light,
}

const rootRed = {
    backgroundColor: RED,
}

const rootGray = {
    backgroundColor: theme.palette.primary.main,
}

const rootYellow = {
    backgroundColor: YELLOW,
}
const rootGreen = {
    backgroundColor: GREEN,
}

const cartBorder = (color) => ({
    borderColor: color,
    // boxShadow: `inset 0 0 0 1px ${color},` + `0 0 0 1px ${color}`,
})

const cartBorderAdmin = cartBorder(BLUE)
const cartBorderApp = cartBorder(DARKYELLOW)
const cartBorderMaintenance = cartBorder(GRAY)

const cartBorderNone = {
    borderColor: theme.palette.primary.main,
    outlineColor: theme.palette.primary.main,
}

const arrowBorderAdmin = {
    BLUE,
}
const arrowBorderApp = {
    DARKYELLOW,
}

const arrowBorderMaintenance = {
    GRAY,
}

const cartStyle = {
    root,
    cartStyleHover,
    rootRed,
    rootGreen,
    rootYellow,
    cartBorderAdmin,
    cartBorderMaintenance,
    cartBorderApp,
    cartBorderNone,
    rootGray,
    arrowBorderMaintenance,
    arrowBorderApp,
    arrowBorderAdmin,
    customPointer,
    openCloseButton,
    openCloseButtonText,
}

export { cartStyle, K_SIZE }
