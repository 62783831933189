import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import React from "react"
import CircleLoader from "../loader"

export function StatusDelete(props) {
    if (props.currentProgress.error) {
        return (
            <Typography variant="h6" align="center" color="error">
                {props.currentProgress.errorMessage}
            </Typography>
        )
    }
    if (props.currentProgress.success) {
        return (
            <Typography variant="h6" align="center">
                Success!
            </Typography>
        )
    }
    if (props.currentProgress.inProgress) {
        return <CircleLoader />
    }
    return (
        <Button
            variant="contained"
            color="secondary"
            fullWidth={props.fullWidth}
            style={{ marginTop: 10, alignSelf: "center", display: "flex" }}
            onClick={props.deleteBooking}
        >
            Confirm Cancellation
        </Button>
    )
}
