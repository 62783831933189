import React from "react"
import { Grid } from "@mui/material"
import PostNewNote from "./postNewNote"
import NoteList from "./noteList"

export default function ServiceNotes() {
    return (
        <Grid
            sx={{ minHeight: 240 + "px" }}
            container
            spacing={4}
            direction="row"
        >
            <Grid item xs={8} md={4}>
                <PostNewNote />
            </Grid>
            <Grid item xs>
                <NoteList />
            </Grid>
        </Grid>
    )
}
