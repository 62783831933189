import { Box, TextField, Tooltip, IconButton } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"
import { minutesToHourMin } from "./unlockTimeItem"

const title = <>Booking reservation period</>

const desc = (
    <>
        The total time reserved for a booking of a vehicle. Should allow any
        player to finish their round with a good margin.
    </>
)

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const unlockDur = currentForm.standardTMP || {}
    const currentHours = unlockDur.hr
    const currentMinutes = unlockDur.min
    return `${currentHours} hours ${currentMinutes} minutes`
}

function ReservedPlayingTimeItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    let standardTMP = {}
    if (currentForm.standardTMP) {
        standardTMP = currentForm.standardTMP
    } else {
        const hourMin = minutesToHourMin(currentForm.standard)
        standardTMP = {
            hr: Math.floor(hourMin.hours),
            min: Number(hourMin.minutes.toFixed(2)),
        }
    }
    useEffect(() => {
        if (currentForm.settingsLoaded && !currentForm.standardTMP) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "standardTMP",
                    value: {
                        ...standardTMP,
                    },
                },
            })
        }
    }, [currentForm.settingsLoaded, currentForm.standardTMP])
    const currentHours = standardTMP.hr
    const currentMinutes = standardTMP.min

    const handleInputChangeHr = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "standardTMP",
                    value: {
                        hr: Math.min(parseInt(value), 23),
                        min: standardTMP.min || 0,
                    },
                },
            })
        }
    }
    const handleInputChangeMin = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "standardTMP",
                    value: {
                        hr: standardTMP.hr || 0,
                        min: Math.min(Number(parseFloat(value).toFixed(2)), 59),
                    },
                },
            })
        }
    }

    return (
        <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
        >
            <TextField
                id="reserved"
                label="Hours"
                size="small"
                value={!isNaN(currentHours) ? currentHours : ""}
                name="reserved"
                type="number"
                variant="standard"
                onChange={handleInputChangeHr}
                margin="normal"
                sx={{ width: "45%" }}
                inputProps={{
                    min: 0,
                    max: 12,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="reserved"
                label="Minutes"
                size="small"
                value={!isNaN(currentMinutes) ? currentMinutes : ""}
                name="reserved"
                type="number"
                variant="standard"
                onChange={handleInputChangeMin}
                sx={{ width: "45%" }}
                margin="normal"
                inputProps={{
                    min: 0,
                    max: 60,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: ReservedPlayingTimeItem,
}
