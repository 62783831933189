import React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import AppBar from "@mui/material/AppBar"
import styled from "@emotion/styled"
import store from "../../store"
import { CLEARCREATEFORM } from "../../constants/actionTypes"
import { useSelector } from "react-redux"

const PrimaryAppbar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "none",
}))

export default function CreateHeader() {
    const dispatchCreateCoupon = (e) => {
        e.preventDefault()
        store.dispatch({ type: CLEARCREATEFORM })
    }
    const { createState } = useSelector((state) => state.coupons)
    const tab = createState ? 0 : 1

    return (
        <PrimaryAppbar position="static">
            <Tabs
                indicatorColor="secondary"
                textColor="secondary"
                variant="fullWidth"
                value={tab}
            >
                <Tab
                    key={0}
                    onClick={dispatchCreateCoupon}
                    label={"Create New Discount"}
                />
                <Tab key={1} disabled={createState} label={"Edit Discount"} />
            </Tabs>
        </PrimaryAppbar>
    )
}
