import * as React from "react"
import Grid from "@mui/material/Grid"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import ListItemText from "@mui/material/ListItemText"
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import { useSelector } from "react-redux"
import { IconButton } from "@mui/material"
import { sortCarts } from "../timeline"

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1)
}

export default function TransferList(props) {
    const { cartsToSelect, setCartsToSelect, cartIdList, setCartIdList } = props
    const carts = useSelector((state) => state.firestore.data.carts)
    const vehicleGroups = useSelector(
        (state) => state.firestore.data.field?.vehicleGroups
    )

    const handlePress = (value) => () => {
        const isInLeft = cartsToSelect.indexOf(value) > -1
        if (isInLeft) {
            const newList = [...cartIdList, value]
            const sortedCarts = newList.sort((x, y) =>
                sortCarts(carts[x], carts[y])
            )
            setCartIdList(sortedCarts)
            setCartsToSelect(not(cartsToSelect, value))
        } else {
            const newList = [...cartsToSelect, value]
            const sortedCarts = newList.sort((x, y) =>
                sortCarts(carts[x], carts[y])
            )
            setCartsToSelect(sortedCarts)
            setCartIdList(not(cartIdList, value))
        }
    }

    const customList = (items, add) => (
        <Paper
            sx={{
                width: 250,
                height: 230,
                overflow: "auto",
            }}
        >
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handlePress(value)}
                        >
                            <IconButton
                                color="secondary"
                                aria-label="add to shopping cart"
                            >
                                {add ? <AddIcon /> : <RemoveIcon />}
                            </IconButton>
                            <ListItemText
                                id={labelId}
                                primary={`${
                                    vehicleGroups[carts[value].vehicleGroup]
                                        .name
                                } ${carts[value].id}`}
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Paper>
    )

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>All carts{customList(cartsToSelect, true)}</Grid>
            <Grid item>Carts to book{customList(cartIdList, false)}</Grid>
        </Grid>
    )
}
