import Grid from "@mui/material/Grid"
import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import DeleteDialog from "../Booking/bookingDeletePopup"
import store from "../../store"
import { SELECT_BOOKING } from "../../constants/actionTypes"
import { statusNotStarted } from "../../constants/statusTypes"
import { mobileUsers } from "../../constants/dbPaths"
import db from "../../rootWrapper/firebase"

export default function DeleteBookingMessageIcon(props) {
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [name, setName] = useState("")

    const handleClose = () => {
        setDeleteOpen(false)
    }

    useEffect(() => {
        if (props.status === statusNotStarted) {
            db.collection(mobileUsers)
                .doc(props.userDocId)
                .get()
                .then((r) => {
                    if (!r.exists) {
                        console.error("No user")
                        setName("Nan")
                    } else {
                        setName(r.data().name)
                    }
                })
        } else {
            setName(props.name)
        }
    }, [props.userDocId])
    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <Button
                    color="secondary"
                    variant="outlined"
                    aria-label="rebook"
                    onClick={(e) => {
                        console.log("delete")
                        const bookingInfo = {
                            selected: true,
                            cartDocId: props.cartDocId,
                            bookingId: props.docRef,
                            startTime: props.startTime,
                            endTime: props.endTime,
                            name,
                            hourMinString: props.timeString,
                            status: props.status,
                        }
                        store.dispatch({
                            type: SELECT_BOOKING,
                            payload: bookingInfo,
                        })
                        setDeleteOpen(true)
                    }}
                >
                    Cancel Booking
                </Button>
                <DeleteDialog
                    {...props}
                    open={deleteOpen}
                    handleClose={handleClose}
                />
            </Grid>
        </Grid>
    )
}
