import React from "react"
import currenciesJson from "./currencies.json"
import { Autocomplete, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"

export default function CurrencySelector(props) {
    const { currency, setCurrency } = props
    const [currencyOptions, setCurrencyOptions] = useState()
    const handleChange = (change) => {
        console.log(change)
        setCurrency(change)
    }
    const currencies = () => {
        return Object.keys(currenciesJson).map((key) => {
            return {
                ...currenciesJson[key],
                key,
            }
        })
    }
    useEffect(() => {
        setCurrencyOptions(currencies())
    }, [])
    return (
        <Autocomplete
            sx={{ width: 225 }}
            value={currency || null}
            options={currencyOptions || []}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            onChange={(e, val) => handleChange(val)}
            getOptionLabel={(option) => option.name + ` (${option.key}) `}
            renderInput={(params) => <TextField {...params} label="Currency" />}
        />
    )
}
