import Grid from "@mui/material/Grid"
import React from "react"

export default function RowMessage(props) {
    return (
        <Grid container alignItems="center">
            <Grid item xs>
                {props.text}
            </Grid>
        </Grid>
    )
}
