import React from "react"
import {
    Box,
    Button,
    Grid,
    LinearProgress,
    Switch,
    Tooltip,
    Typography,
} from "@mui/material"
import { useSelector } from "react-redux"
import { SET_CART_STATUS, SET_COMPLETE_CART } from "../../constants/actionTypes"
import store from "../../store"
import PingCart from "../lock/PingCart"
import getLockAccess from "../../constants/accessPermissionsChecks"

export default function PingButton(props) {
    const { vehicle, width, style, fontSize, fontWeight } = props

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const lockAccess = getLockAccess()
    const { cartDocId } = vehicle
    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading

    const handleClick = async () => {
        if (lockLoading(cartDocId) || !lockAccess) {
            return
        }
        const result = await PingCart(courseDocId, cartDocId)
        store.dispatch({
            type: SET_CART_STATUS,
            payload: {
                cartsStatus: {
                    [cartDocId]: result,
                },
            },
        })
        if (result && result.status === 201) {
            store.dispatch({
                type: SET_COMPLETE_CART,
                payload: {
                    cartsComplete: { [cartDocId]: true },
                },
            })
        }
    }

    return (
        <Box
            sx={{
                margin: 1,
                width,
                textAlign: "center",
                position: "relative",
                ...style,
            }}
        >
            {cartDocId && lockLoading(cartDocId) && (
                <LinearProgress color="secondary" />
            )}
            {lockAccess ? null : (
                <Tooltip
                    placement="right"
                    title={"Limited permissions - talk to admin"}
                >
                    <Box
                        sx={{
                            // border: "1px solid", // Use to check where the helper box is
                            position: "absolute",
                            width: "100%",
                            height: "37px",
                        }}
                    ></Box>
                </Tooltip>
            )}
            <Button
                variant="contained"
                color="secondary"
                disabled={lockLoading(cartDocId) || !lockAccess}
                fullWidth
            >
                <Typography
                    fontSize={"10pt"}
                    fontWeight="bold"
                    color={"gray"}
                    onClick={handleClick}
                >
                    Refresh
                </Typography>
            </Button>
        </Box>
    )
}
