import { combineReducers, compose, createStore } from "redux"
import {
    createFirestoreInstance,
    firestoreReducer,
    reduxFirestore,
} from "redux-firestore"
import { firebaseReducer } from "react-redux-firebase"
import { rrfConfig } from "./constants/keys"
import cartLoading from "./reducers/cartLoading"
import avCartSelector from "./reducers/avCartSelector"
import bookingSelector from "./reducers/bookingSelector"
import cartReadings from "./reducers/cartReadings"
import userPermissionList from "./reducers/userPermissionList"
import settingsForm from "./reducers/settingsForm"
import permissionEdit from "./reducers/permissionEdit"
import rainDay from "./reducers/rainDay"
import coupons from "./reducers/coupons"
import ridepass from "./reducers/ridepasses"
import setupflow from "./reducers/setupflow"
import academyflow from "./reducers/academyflow"
import profileImage from "./reducers/profileImage"
import DetailsForm from "./reducers/detailsForm"
import signin from "./reducers/signin"
import installation from "./reducers/cartInstallation"
import createBooking from "./reducers/createBooking"
import bookingView from "./reducers/bookingView"
import appInfo from "./reducers/settingsAppInfoText"
import serviceStatus from "./reducers/serviceStatus"
import vehicleSelector from "./reducers/vehicleSelector"
import nextBooking from "./reducers/nextBooking"
import register from "./reducers/register"
import bookingHistory from "./reducers/bookingHistory"
import { USER_LOG_OUT } from "./constants/actionTypes"
import { legacy_firebase } from "./rootWrapper/firebase"
import notifications from "./reducers/notifications"

const createStoreWithFirebase = compose(
    reduxFirestore(legacy_firebase) // firebase instance as first argument, rfConfig as optional second
)(createStore)

const appReducer = combineReducers({
    firebase: firebaseReducer, // include if auth also should be in redux
    firestore: firestoreReducer,
    signin,
    cartLoading,
    avCartSelector,
    bookingSelector,
    cartReadings,
    userPermissionList,
    settingsForm,
    permissionEdit,
    rainDay,
    coupons,
    ridepass,
    setupflow,
    academyflow,
    profileImage,
    DetailsForm,
    installation,
    createBooking,
    bookingView,
    appInfo,
    serviceStatus,
    notifications,
    vehicleSelector,
    nextBooking,
    register,
    bookingHistory,
})

const rootReducer = (state, action) => {
    if (action.type === USER_LOG_OUT) {
        state = undefined
    }
    return appReducer(state, action)
}

const initialState = {}
let store
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    store = createStoreWithFirebase(
        rootReducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    )
} else {
    store = createStoreWithFirebase(rootReducer, initialState)
}

const rrfProps = {
    firebase: legacy_firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
}

export { rrfProps }

export default store
