import React, { useEffect, useState } from "react"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { Typography } from "@mui/material"
import CircleLoader from "../loader"
import { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import { logger } from "workbox-core/_private"
import SigninStatus from "../../models/signinStatus"
import { useNavigate } from "react-router-dom"

const confirmNewSetupIntent = httpsCallable(functions, "confirmNewSetupIntent")

export default function ConfirmPage() {
    const stripe = useStripe()
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(true)
    const { courseDocId } = SigninStatus()
    const [complete, setComplete] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        let timeout = null
        if (complete) {
            timeout = setTimeout(() => {
                navigate("/settings/billing?update=true")
            }, 1000)
        }
        return () => clearTimeout(timeout)
    }, [complete])

    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "setup_intent_client_secret"
        )

        if (!clientSecret) {
            return
        }
        setLoading(true)

        stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
            if (setupIntent.status === "requires_payment_method") {
                setError("Something went wrong.")
            } else if (
                setupIntent.status === "succeeded" ||
                setupIntent.status === "processing"
            ) {
                confirmNewSetupIntent({
                    courseDocId,
                    setupIntent: setupIntent.id,
                })
                    .then((res) => {
                        logger.log(res)
                        setLoading(false)
                        setComplete(true)
                        setMessage("Successfully changed card")
                    })
                    .catch((e) => {
                        console.log(e.message)
                        setError(e.message)
                        setLoading(false)
                    })
            } else {
                logger.error(setupIntent.status)
            }
        })
    }, [stripe])

    return (
        <>
            {loading ? <CircleLoader /> : null}
            {error ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="error"
                    fontSize="14pt"
                >
                    {error}
                </Typography>
            ) : null}
            {message ? (
                <Typography
                    sx={{ p: 2, textAlign: "center" }}
                    color="secondary"
                    fontSize="14pt"
                >
                    {message}
                </Typography>
            ) : null}
        </>
    )
}
