import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { TextField } from "@mui/material"
import { dateToHourMinute } from "../dateTools"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_BOOKING_FIELDS } from "../../constants/actionTypes"

const startTimeSwitcher = (isStartTime) => {
    if (isStartTime) {
        return {
            name: "open",
            label: "Booking start time",
            timeSelector: "startTime",
        }
    } else {
        return {
            name: "close",
            label: "Booking end time",
            timeSelector: "endTime",
        }
    }
}

export default function BookingTimeInput(props) {
    const style = useDialogStyles()
    const { isStartTime, setCurrentProgress } = props
    const { name, label, timeSelector } = startTimeSwitcher(isStartTime)
    const { startTime, endTime } = useSelector((state) => state.createBooking)
    const time = useSelector((state) => state.createBooking[timeSelector])
    const standardTime = useSelector((state) => {
        if (
            state.firestore.data &&
            "field" in state.firestore.data &&
            Object.keys(state.firestore.data.field).indexOf("standard") > -1
        ) {
            return state.firestore.data.field.standard * 60 * 1000
        }
        return 5 * 60 * 60 * 1000
    })

    const handleTimeChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        console.log(value)
        const values = value.split(":")
        const hours = parseInt(values[0])
        const minutes = parseInt(values[1])
        if (!(typeof startTime === "number" && !isNaN(startTime))) {
            setCurrentProgress((currentProgress) => ({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid start time",
            }))
            return
        }
        if (!(typeof endTime === "number" && !isNaN(endTime))) {
            setCurrentProgress((currentProgress) => ({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid end time",
            }))
            return
        }
        if (
            hours !== parseInt(hours, 10) ||
            minutes !== parseInt(minutes, 10)
        ) {
            setCurrentProgress((currentProgress) => ({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid Time",
            }))
            return
        }
        if (name === "open") {
            const newTime = new Date(startTime).setHours(hours, minutes, 0, 0)
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    startTime: newTime,
                    endTime: newTime + standardTime,
                },
            })
        } else if (name === "close") {
            const newTime = new Date(endTime).setHours(hours, minutes, 0, 0)
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    endTime: newTime,
                },
            })
        }
        setCurrentProgress((currentProgress) => ({
            ...currentProgress,
            error: false,
            errorMessage: "",
        }))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TextField
                id="time-input"
                label={label}
                type="time"
                value={dateToHourMinute(new Date(time))}
                name={name}
                onChange={handleTimeChange}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                    className: isStartTime ? style.input : style.grayInput,
                }}
                InputProps={{
                    className: isStartTime ? style.input : style.grayInput,
                }}
            />
        </LocalizationProvider>
    )
}

BookingTimeInput.propTypes = {
    isStartTime: PropTypes.bool.isRequired,
    setCurrentProgress: PropTypes.func.isRequired,
}
