import React, { useEffect } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { useStyles } from "../assets/styles/views/noPermissions"
import theme from "../assets/styles/muiTheme"
import SignOutController from "../Components/Signout"
import { Link, useNavigate } from "react-router-dom"
import SigninStatus from "../models/signinStatus"
import db from "../rootWrapper/firebase"
import { users } from "../constants/dbPaths"

const NotPaid = {
    header: "This subscription has not been paid, please update the payment details to keep using the system.",
}
const Canceled = {
    header: "This subscription has been canceled, please choose a new plan keep using the system.",
    link: "/settings/billing",
    linkText: "Billing",
}
const NoPerm = {
    header: "You do not have permissions to view anything yet.",
    link: "/settings/permissions",
    linkText: "/settings/permissions",
}
const NoAccess = {
    header: "You do not have permission to this feature.",
    link: "/settings/permissions",
    linkText: "/settings/permissions",
}

const typeSwitch = {
    unpaid: NotPaid,
    canceled: Canceled,
    noperm: NoPerm,
    noacc: NoAccess,
}

export default function NoPermissions(props) {
    const { type } = props
    const Type = typeSwitch[type]
    const classes = useStyles(theme)
    const { signOut } = SignOutController()
    const navigate = useNavigate()

    const { userIsLoaded, authUid, userHasAnyViewPermissions } = SigninStatus()
    console.log("uid", authUid, "user loaded", userIsLoaded)

    useEffect(() => {
        if (authUid && !userIsLoaded) {
            db.collection(users)
                .doc(authUid)
                .onSnapshot(
                    (snap) => {
                        if (snap.exists) {
                            const user = snap.data()
                            if (user.courseDocId && user.admin) {
                                navigate("/")
                            }
                            if (
                                user.courseDocId &&
                                userHasAnyViewPermissions()
                            ) {
                                navigate("/")
                            }
                        }
                    },
                    (error) => {
                        console.error(error)
                    }
                )
        }
    }, [userIsLoaded, authUid])
    return (
        <div className={classes.root}>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={5}
            >
                <Grid item xs>
                    <Typography component="h1" variant="h5">
                        {Type?.header ||
                            "You do not have permissions to view anything yet."}
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography component="h2">
                        To get permissions, contact your club administrator to
                        give you suitable permissions from his settings tab.
                    </Typography>
                    <Typography component="h2">
                        If you club admin struggles, please check out{" "}
                        <Link
                            style={{ color: theme.palette.secondary.main }}
                            to={Type?.link || "/faq"}
                        >
                            {Type?.linkText || "FAQ"}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={signOut}
                    >
                        Logout
                    </Button>
                </Grid>
                <Grid item xs>
                    <Typography component="h2">
                        If you still struggle, contact support at
                        clubsupport@eagl.app
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
