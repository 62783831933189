import GoogleMapReact from "google-map-react"
import { Box } from "@mui/material"
import React, { useState } from "react"
import MapAutoComplete from "./searchInput"

export default function MapPicker(props) {
    const { fullWidth, mapConf, heigth, setDetails, setMapConf } = props
    const [mapApiMaps, setMapApi] = useState(null)
    const onChange = (change) => {
        const { center, zoom } = change
        const { lat, lng } = center
        setMapConf({
            zoom,
            pos: { lat, lng },
        })
    }
    return (
        <Box sx={{ width: "100%", height: fullWidth ? "100%" : null }}>
            <MapAutoComplete
                apiClient={mapApiMaps && mapApiMaps[0]}
                maps={mapApiMaps && mapApiMaps[1]}
                setMapConf={setMapConf}
                setDetails={setDetails}
                fullWidth
            />
            <Box
                width={fullWidth ? "100%" : "240px"}
                height={fullWidth ? heigth : "240px"}
                sx={{
                    mt: 1,
                    borderWidth: "2px",
                    borderStyle: "solid",
                    borderColor: "#F00",
                }}
            >
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals={true}
                    center={{
                        lat: mapConf?.pos?.lat || 48,
                        lng: mapConf?.pos?.lng || 12,
                    }}
                    zoom={mapConf?.zoom || 3}
                    onChange={onChange}
                    options={(map) => ({
                        mapTypeId: map.MapTypeId.HYBRID,
                        fullscreenControl: false,
                        zoomControl: false,
                    })}
                    onGoogleApiLoaded={({ maps, map }) =>
                        setMapApi([maps, map])
                    }
                ></GoogleMapReact>
            </Box>
        </Box>
    )
}
