import { makeStyles } from "@mui/styles"

const redirectStyle = makeStyles({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: 0,
        },
    },
})

export default redirectStyle
