import React from "react"
import { Box, Typography, Button } from "@mui/material"
import logo from "../../../assets/img/logo.svg"
import golfflag from "../../../assets/img/golf-flag.svg"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles({
    imageFlex: {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
})

export default function InstallFinishedText() {
    const styles = useStyles()
    return (
        <Box sx={{ height: "100%" }}>
            <Box
                sx={{
                    margin: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alitItems: "center",
                }}
            >
                <Typography variant="h4">Congratulations!</Typography>
                <br />
                <Typography variant="body1">
                    You finished the installation of your carts.
                </Typography>
                <Typography variant="body1">
                    You can now close this webpage
                </Typography>
            </Box>

            <Box
                sx={{
                    height: "60%",
                    minHeight: "200px",
                    margin: 2,
                    display: "flex",
                    justifyContent: "center",
                    alitItems: "center",
                }}
            >
                <Box
                    sx={{
                        width: "80%",
                        justifyContent: "center",
                        display: "flex",
                        alitItems: "center",
                    }}
                >
                    <img
                        className={styles.imageFlex}
                        src={golfflag}
                        alt="Finishing flag"
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    position: "absolute",
                    left: "0",
                    bottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alitItems: "flex-end",
                }}
            >
                <img src={logo} alt=".EAGL logo" />
            </Box>
        </Box>
    )
}
