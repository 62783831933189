import React from "react"
import { Box, Button, Alert, AlertTitle, LinearProgress } from "@mui/material"
import { SET_ALL_CARTS_INSTALLED } from "../../../constants/actionTypes"
import store from "../../../store"
import { useSelector } from "react-redux"
import InstallationTestButton from "./InstallationButton"
import LockCart from "../../lock/unlocking-cart"
import { useNavigate } from "react-router-dom"
import db from "../../../rootWrapper/firebase"
import { golfCourses } from "../../../constants/dbPaths"
import { sortCarts } from "../../timeline"

export default function InstallResultText(props) {
    const navigate = useNavigate()
    const { cartsComplete, cartsStatus, currentCartDocId, currentCart } =
        useSelector((state) => state.installation)

    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const carts = useSelector((state) => state.firestore.data.carts)
    const { cartDocId } = currentCart
    const getAllCarts = () => {
        const allCarts = Object.keys(cartsComplete)
            .sort((x, y) => sortCarts(carts[x], carts[y]))
            .reduce((obj, key) => {
                return [...obj, key]
            }, [])
        return allCarts
    }

    const getCurrentIndex = () => {
        const allCarts = getAllCarts()
        return Object.values(allCarts).reduce((x, current, ind) => {
            if (carts[current].boardId === currentCart.boardId) {
                return ind
            }
            return x
        }, -1)
    }
    const getAllCartsSortedAfterCurrent = () => {
        const carts = getAllCarts()
        const index = getCurrentIndex()
        const cartsBefore = carts.slice(0, index)
        const cartsAfter = carts.slice(index + 1, carts.length)
        return [...cartsAfter, ...cartsBefore]
    }
    const getUnfinishedCarts = () => {
        return Object.keys(cartsComplete)
            .sort((x, y) => sortCarts(carts[x], carts[y]))
            .reduce((obj, key) => {
                if (!cartsComplete[key]) {
                    return [...obj, key]
                }
                return obj
            }, [])
    }
    const getNextCart = () => {
        const cartsAfterThis = getAllCartsSortedAfterCurrent()
        const unfinishedCarts = getUnfinishedCarts()
        if (unfinishedCarts.length > 0) {
            const cartIdsOver = cartsAfterThis
                .map((cartDocId) => {
                    if (unfinishedCarts.indexOf(cartDocId) > -1) {
                        return cartDocId
                    }
                    return null
                })
                .filter((x) => x !== null)
            if (cartIdsOver.length) {
                return carts[cartIdsOver[0]].boardId
            }
            return carts[cartsAfterThis[0]].boardId
        }
        return null
    }
    const lockAndHandleNext = async () => {
        await LockCart(0, courseDocId, currentCartDocId)
        await handleNext()
    }

    const handleNext = async () => {
        const unfinishedCarts = getUnfinishedCarts()

        if (unfinishedCarts.length === 0) {
            store.dispatch({ type: SET_ALL_CARTS_INSTALLED })
            const courseSnap = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .get()
            const courseData = courseSnap.data()
            const completedSteps = courseData.onboardingCompletedSteps || {}
            completedSteps[4] = true
            db.collection(golfCourses).doc(courseDocId).update({
                onboardingCompletedSteps: completedSteps,
            })
            navigate("../overview")
            return
        }
        const nextCart = getNextCart()
        navigate("../cart/" + nextCart)
    }
    const handleOverview = async () => {
        navigate("../overview/")
    }
    const isComplete = () => {
        if (currentCartDocId in cartsComplete) {
            return cartsComplete[currentCartDocId]
        }
    }
    const cartStatus = () => {
        if (currentCartDocId in cartsStatus) {
            const status = cartsStatus[currentCartDocId]
            return status
        }
        return { res: "", error: false }
    }

    const wasPressed = () => {
        if (currentCartDocId in cartsStatus) {
            return true
        }
        return false
    }
    const cartLoader = useSelector((state) => state.cartLoading)
    const lockLoading = (cartDocId) =>
        Object.keys(cartLoader).indexOf(cartDocId) > -1 &&
        Object.keys(cartLoader[cartDocId]).indexOf("lockLoading") > -1 &&
        cartLoader[cartDocId].lockLoading

    if (!wasPressed()) {
        return <InstallationTestButton />
    }
    if (isComplete()) {
        return (
            <Box sx={{ margin: 2 }}>
                {cartDocId && lockLoading(cartDocId) && (
                    <LinearProgress color="secondary" />
                )}
                <Alert severity="success">
                    <AlertTitle>Successfull unlock</AlertTitle>
                    Cart is installed
                </Alert>
                <Button
                    disabled={lockLoading(cartDocId) || false}
                    onClick={lockAndHandleNext}
                    color="secondary"
                    variant="contained"
                    fullWidth
                >
                    Turn off cart and go to the next one
                </Button>
            </Box>
        )
    } else {
        return (
            <Box sx={{ margin: 2 }}>
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Error Message: {cartStatus().res}
                </Alert>
                <InstallationTestButton />
                <Button
                    sx={{ margin: 1 }}
                    onClick={handleNext}
                    color="secondary"
                    variant="contained"
                    fullWidth
                >
                    Skip and try again later
                </Button>
                <Button
                    sx={{ margin: 1 }}
                    onClick={handleOverview}
                    color="secondary"
                    variant="contained"
                    fullWidth
                >
                    Go to overview
                </Button>
            </Box>
        )
    }
}
