import React from "react"
import { Route, Routes } from "react-router-dom"
import ConfirmEmail from "./confirmEmail"
import RegisterAditionalUser from "./registerAdditionalUser"

export default function RegisterUser() {
    return (
        <Routes>
            <Route index element={<RegisterAditionalUser />} />
            <Route path="confirmEmail" element={<ConfirmEmail />} />
        </Routes>
    )
}
