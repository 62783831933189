import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import getAmountDistribution from "./getAmountDistributionApp"
import getAmountDistributionDash from "./getAmountDistributionDash"
import theme from "../../../assets/styles/muiTheme"
import { SELECTSETTINGSFIELD } from "../../../constants/actionTypes"
import store from "../../../store"

const closeQuestionClick = () => {
    store.dispatch({
        type: SELECTSETTINGSFIELD,
        payload: {
            key: "selectedSegment",
            value: {
                selected: false,
            },
        },
    })
}

const getVehicleGroupsToPaymentGroup = (carts) => {
    // for hver cart, for hver vehilce group finne typisk payment group
    const vehicleGroupToPaymentGroupCount = Object.values(carts).reduce(
        (vehicleGroupCount, currentCart) => {
            const vehicleG = currentCart.vehicleGroup
            const paymentG = currentCart.paymentGroup
            if (vehicleG in vehicleGroupCount) {
                if (paymentG in vehicleGroupCount[vehicleG]) {
                    vehicleGroupCount[vehicleG][paymentG] += 1
                } else {
                    vehicleGroupCount[vehicleG][paymentG] = 1
                }
            } else {
                vehicleGroupCount[vehicleG] = {
                    [paymentG]: 1,
                }
            }
            return vehicleGroupCount
        },
        {}
    )
    const vehicleGroupToMaxPaymentGroup = Object.keys(
        vehicleGroupToPaymentGroupCount
    ).reduce((vehicleGroupToPaymentGroup, groupKey) => {
        const groups = vehicleGroupToPaymentGroupCount[groupKey]
        let maxKey = ""
        let currentMax = 0
        for (const key in Object.keys(groups)) {
            if (groups[key] > currentMax) {
                maxKey = key
                currentMax = groups[key]
            }
        }
        return {
            ...vehicleGroupToPaymentGroup,
            [groupKey]: maxKey,
        }
    }, {})
    return vehicleGroupToMaxPaymentGroup
}

const TableHead = (props) => {
    const {
        fixedFee,
        currency,
        cut,
        fixedstripe,
        percentStripe,
        percentageVat,
        taxAdd,
    } = props
    return (
        <TableRow>
            <TableCell>Price</TableCell>
            <TableCell>Customer facing VAT ({percentageVat * 100}%)</TableCell>
            <TableCell>
                .EAGL share{" "}
                {taxAdd ? null : `(${fixedFee} ${currency} + ${cut * 100}%)`}
            </TableCell>
            <TableCell>Course Gross Share</TableCell>
            <TableCell>
                Stripe Fees* ({fixedstripe.toFixed(2)} {currency} +{" "}
                {Number(percentStripe * 100).toFixed(2)}%)
            </TableCell>
            <TableCell>Course Deposit</TableCell>
        </TableRow>
    )
}

export default function RevenueShares() {
    const currentForm = useSelector((state) => state.settingsForm)
    const [vehicleGroupToPaymentGroup, setVToG] = useState({})
    const {
        selectedSegment,
        paymentStructureType,
        costStructure,
        vehicleGroups,
        startingFee,
        averageTime,
        currency,
    } = currentForm ?? {}
    const carts = useSelector((state) => state.firestore.data.carts)
    useEffect(() => {
        if (carts) {
            const vToG = getVehicleGroupsToPaymentGroup(carts)
            setVToG(vToG)
            console.log(vToG)
        }
    }, [carts.length])

    if (!selectedSegment?.selected) {
        return null
    }
    if (!vehicleGroups) {
        return null
    }
    const rentableGroups = Object.keys(vehicleGroups)
        .filter((x) => vehicleGroups[x].bookable)
        .reduce(
            (current, key) => ({ ...current, [key]: vehicleGroups[key] }),
            {}
        )
    const { selectedGroup, ppm, segment } = selectedSegment

    if (!(selectedGroup in vehicleGroupToPaymentGroup)) {
        console.error(
            "selectedGroup not in vehicleGroupToPaymentGroup",
            selectedGroup
        )
        return null
    }
    const paymentGroupId = vehicleGroupToPaymentGroup[selectedGroup]

    if (!(paymentGroupId in paymentStructureType)) {
        console.error(
            "paymentGroupId not in paymentStructureType",
            paymentGroupId
        )
        return null
    }
    const groupName = rentableGroups?.[selectedGroup]?.name
    const paymentGroup = paymentStructureType[paymentGroupId]
    const costStruct = { ...costStructure, ...paymentGroup }
    const {
        eaglCutDash,
        fixedEaglFeeDash,
        eaglCut,
        fixedEaglFee,
        fixedStripeFee,
        percentStripeFee,
        percentageVat,
        taxAddEnabled,
    } = costStruct

    const segmentName = segment?.name
    const indexFullPrice = ppm === 1 ? 2 : 0
    const fullPrice =
        segment?.vehicleGroups?.[selectedGroup]?.[indexFullPrice] ?? 0
    const ppmPrice = segment?.vehicleGroups?.[selectedGroup]?.[1] ?? 0
    console.log(segmentName, fullPrice, groupName, ppm, taxAddEnabled)
    let totalPrice = fullPrice
    const maxPrice = fullPrice + 30 * ppmPrice
    if (ppm === 2) {
        totalPrice = ppmPrice * averageTime + startingFee
        if (totalPrice > maxPrice) {
            totalPrice = maxPrice
        }
    }
    let priceWithTax = fullPrice
    let totalPriceWithTax = totalPrice
    let startingFeeWithTax = startingFee
    let ppmWithTax = ppmPrice

    if (taxAddEnabled) {
        priceWithTax = fullPrice * (1 + percentageVat)
        ppmWithTax = ppmPrice * (1 + percentageVat)
        startingFeeWithTax = startingFeeWithTax * (1 + percentageVat)
        totalPriceWithTax = totalPrice * (1 + percentageVat)
    }
    const staringFeeVat =
        startingFeeWithTax - startingFeeWithTax / (1 + percentageVat)
    const ppmVat = ppmWithTax - ppmWithTax / (1 + percentageVat)
    const vat = priceWithTax - priceWithTax / (1 + percentageVat)
    const { courseShare, courseGross, vatAmount, eaglTotal, stripeFees } =
        getAmountDistribution(totalPriceWithTax, costStruct)
    const dashDist = getAmountDistributionDash(totalPriceWithTax, costStruct)

    return (
        <Box
            maxWidth={"800px"}
            sx={{
                bgcolor: theme.palette.primary.light,
                borderRadius: 4,
                p: 4,
                mb: 4,
            }}
        >
            <Typography variant="h1" fontWeight={600} fontSize={"24pt"}>
                Revenue share
            </Typography>
            <Typography>
                {`Rental of a ${groupName} with category ${segmentName}`}
            </Typography>
            <Typography>
                Price model:{" "}
                {ppm === 0 &&
                    `${fullPrice.toFixed(
                        2
                    )} ${currency} fixed price for 18 holes.`}
                {ppm === 1 &&
                    `${fullPrice.toFixed(
                        2
                    )} ${currency} fixed price for 9 holes.`}
                {ppm === 2 &&
                    `${startingFee.toFixed(2)} ${currency} + ${ppmPrice.toFixed(
                        2
                    )} ${currency} per minute. `}
                {ppm === 2
                    ? `Max price is ${fullPrice.toFixed(
                          2
                      )} ${currency} + 30 min of pay per minute, resulting in a maxium price of ${maxPrice.toFixed(
                          2
                      )} ${currency}.`
                    : null}
                {taxAddEnabled
                    ? ppm === 2
                        ? ` Adding ${Number(staringFeeVat).toFixed(
                              2
                          )} ${currency}  + ${Number(ppmVat).toFixed(
                              2
                          )} ${currency}  per minute tax`
                        : ` Adding ${Number(vatAmount).toFixed(
                              2
                          )} ${currency} of vat to total price `
                    : null}
            </Typography>
            {ppm === 2 ? (
                <Typography>
                    Example using the average time of {averageTime} minutes
                </Typography>
            ) : null}
            <Typography pt={2} variant="h6">
                App Booking
            </Typography>
            <Table>
                <TableBody>
                    <TableHead
                        {...{
                            fixedFee: fixedEaglFee,
                            currency,
                            cut: eaglCut,
                            fixedstripe: fixedStripeFee,
                            percentStripe: percentStripeFee,
                            percentageVat,
                            taxAdd: taxAddEnabled,
                        }}
                    />
                    <TableRow>
                        <TableCell>
                            {Number(totalPriceWithTax).toFixed(2)} {currency}
                        </TableCell>
                        <TableCell>
                            {Number(vat).toFixed(2)} {currency}
                        </TableCell>
                        <TableCell>
                            {eaglTotal.toFixed(2)} {currency}
                        </TableCell>
                        <TableCell>
                            {courseGross.toFixed(2)} {currency}
                        </TableCell>
                        <TableCell>
                            {stripeFees.toFixed(2)} {currency}
                        </TableCell>
                        <TableCell>
                            {courseShare.toFixed(2)} {currency}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            {ppm === 2 ? null : (
                <>
                    <Typography pt={2} variant="h6">
                        Dashboard Booking
                    </Typography>
                    <Table>
                        <TableBody>
                            <TableHead
                                {...{
                                    fixedFee: fixedEaglFeeDash,
                                    currency,
                                    cut: eaglCutDash,
                                    fixedstripe: 0,
                                    percentStripe: 0,
                                    percentageVat,
                                    taxAdd: taxAddEnabled,
                                }}
                            />
                            <TableRow>
                                <TableCell>
                                    {Number(totalPriceWithTax).toFixed(2)}{" "}
                                    {currency}
                                </TableCell>
                                <TableCell>
                                    {Number(vat).toFixed(2)} {currency}
                                </TableCell>
                                <TableCell>
                                    {dashDist.eaglTotal.toFixed(2)} {currency}
                                </TableCell>
                                <TableCell>
                                    {dashDist.courseGross.toFixed(2)} {currency}
                                </TableCell>
                                <TableCell>
                                    {dashDist.stripeFees.toFixed(2)} {currency}
                                </TableCell>
                                <TableCell>
                                    {Number(dashDist.courseShare).toFixed(2)}{" "}
                                    {currency}{" "}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </>
            )}

            <Box sx={{ mt: 2 }}>
                <Typography display="inline" color="#DCDCDC">
                    * Real stripe fees may vary on the card type of the customer
                </Typography>
                <Button
                    onClick={() => closeQuestionClick()}
                    sx={{ marginLeft: "auto", float: "right" }}
                    color="secondary"
                    variant="outlined"
                >
                    Close
                </Button>
            </Box>
        </Box>
    )
}
