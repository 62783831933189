import React from "react"
import { styled } from "@mui/styles"
import { Typography } from "@mui/material"

const CLUSTER_SIZE = 80

const Marker = styled("div")(({ theme }) => ({
    position: "absolute",
    top: -CLUSTER_SIZE / 2,
    left: -CLUSTER_SIZE / 2,
    height: CLUSTER_SIZE + "px",
    width: CLUSTER_SIZE + "px",
    textAlign: "center",
    backgroundColor: theme.palette.gray.default,
    borderRadius: CLUSTER_SIZE + "px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "5px solid",
    borderColor: theme.palette.secondary.main,
    boxShadow:
        `inset 0 0 0 2px ${theme.palette.primary.main},` +
        `0 0 0 2px ${theme.palette.primary.main}`,
    "&:hover": {
        borderColor: "#FFF",
    },
}))

const ClusterMarker = ({ onClick, children }) => (
    <Marker onClick={onClick}>
        <div>
            <Typography color="#DCDCDC" fontWeight={600}>
                {children}
            </Typography>
            <Typography color="#DCDCDC" fontSize={"8pt"} fontWeight={600}>
                Vehicles
            </Typography>
        </div>
    </Marker>
)

export default ClusterMarker
