import React from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import UserWrap from "./userWrap"
import MapWrap from "./mapWrap"
import { accessPermissionRoutes } from "../constants/accessPermissionRoutes"
import { accessPermissionsSchema } from "../constants/accessPermissionSchema"
import {
    routesInSidebar,
    viewInstallerPage,
} from "../constants/accessPermissions"
import CircleLoader from "../Components/loader"
import SetupFlow from "../views/setupflow"
import SigninStatus from "../models/signinStatus"
import InstallationMenu from "../Components/Installer/installationMain"
import getSubscriptionStatus, {
    blockInitialPayment,
    blockNeedPayment,
} from "../Components/SubscriptionStatus"
import NoPermissions from "../views/noPermissions"
import News from "../views/news"
import NotificationCenter from "../views/notificationCenter"
import Reviews from "../views/reviews"
import FAQ from "../views/faq"
import { useSelector } from "react-redux"

export default function ProtectedRoutes() {
    const {
        authIsLoaded,
        userIsLoaded,
        userHasAnyViewPermissions,
        courseDocId,
        authUid,
        courseLoaded,
        authError,
        authIsEmpty,
        uidExists,
    } = SigninStatus()

    if (!authIsLoaded) {
        return <CircleLoader fillScreen />
    }

    if (userIsLoaded && courseDocId === "" && !userHasAnyViewPermissions()) {
        console.log("user loaded, no view permissions", courseDocId)
        return <Navigate to="/nopermission" replace />
    }
    if (
        (authIsLoaded && authIsEmpty) ||
        (authIsLoaded && !uidExists) ||
        (authIsLoaded && authError)
    ) {
        return <Navigate to="/login" />
    }

    return (
        <UserWrap uid={authUid} field={courseDocId}>
            <MapWrap field={courseDocId} fieldLoaded={courseLoaded}>
                <RoutesToRender />
            </MapWrap>
        </UserWrap>
    )
}

function RoutesToRender() {
    const {
        userHasPermission,
        permissionsOfUser,
        courseIsConfigured,
        isAdmin,
        userIsLoaded,
        courseLoaded,
        courseDocId,
        courseData,
        courseAddedCarts,
    } = SigninStatus()
    const { pathname } = useLocation()
    const skipPayment = useSelector((state) => state.bookingSelector)
    const { init, initiatedSub, subscriptionStatus, stripeBasedSub, subData } =
        getSubscriptionStatus()

    function UserViewPermissions(permissionsOfUser, blockAll) {
        if (userIsLoaded && courseLoaded) {
            const routes = routesInSidebar(courseData, subData)
                .sort(
                    (x, y) =>
                        accessPermissionsSchema[x].key -
                        accessPermissionsSchema[y].key
                )
                .map((key) => {
                    const hasAccess =
                        (Object.keys(permissionsOfUser).indexOf(key) > -1 &&
                            (permissionsOfUser[key].access ||
                                accessPermissionsSchema[key].access)) ||
                        isAdmin

                    return {
                        hasAccess,
                        comp: accessPermissionRoutes[key],
                    }
                })
            const routesWAccess = routes.filter((x) => x.hasAccess)

            if (routesWAccess.length === 0) {
                return (
                    <Route
                        path="*"
                        element={<Navigate to="/nopermission" replace />}
                    />
                )
            }
            const ZeroComp = routesWAccess[0].comp.component
            return [
                <Route key={1000} exact path="/" element={<ZeroComp />} />,
                ...routes.map((elem, index) => {
                    const route = elem.comp
                    const Component = route.component
                    let ComponentToRender = elem.hasAccess ? (
                        <Component />
                    ) : (
                        <NoPermissions type={"noacc"} />
                    )
                    if (blockAll) {
                        if (!route.path.includes("settings")) {
                            ComponentToRender = (
                                <NoPermissions type={subscriptionStatus} />
                            )
                        }
                    }
                    return (
                        <Route
                            key={index}
                            path={route.path + "/*"}
                            element={ComponentToRender}
                        />
                    )
                }),
            ]
        }
        return <></>
    }

    if (userIsLoaded && courseLoaded && !courseIsConfigured && !isAdmin) {
        if (userHasPermission(viewInstallerPage)) {
            return (
                <Routes>
                    <Route path="/install/*" element={<InstallationMenu />} />
                    <Route
                        path="*"
                        element={
                            pathname.includes("install") ? null : (
                                <Navigate to="/install" />
                            )
                        }
                    />
                </Routes>
            )
        }
    }
    if (userIsLoaded && courseDocId && courseLoaded && !courseAddedCarts) {
        console.log(
            "redirecting to register",
            userIsLoaded,
            courseDocId,
            courseAddedCarts
        )
        return <Navigate to="/register/course/carts" />
    }
    if (
        userIsLoaded &&
        courseDocId &&
        courseAddedCarts &&
        init &&
        !initiatedSub &&
        !skipPayment
    ) {
        return <Navigate to="/register/course/payment" />
    }
    if (
        userIsLoaded &&
        courseDocId &&
        courseAddedCarts &&
        init &&
        stripeBasedSub &&
        blockInitialPayment.indexOf(subscriptionStatus) > -1 &&
        !skipPayment
    ) {
        return <Navigate to="/register/course/payment" />
    }
    const blockAll =
        userIsLoaded &&
        courseDocId &&
        courseAddedCarts &&
        init &&
        stripeBasedSub &&
        blockNeedPayment.indexOf(subscriptionStatus) > -1

    return (
        <Routes>
            {userIsLoaded && isAdmin && courseLoaded && !courseIsConfigured ? (
                <>
                    <Route path="setup" element={<SetupFlow />} />
                    <Route path="install/*" element={<InstallationMenu />} />
                </>
            ) : null}
            <Route path="/news" element={<News />} />
            <Route
                path="/notificationCenter"
                element={<NotificationCenter />}
            />
            <Route path="/reviews" element={<Reviews />} />
            <Route path="/faq" element={<FAQ />} />
            {UserViewPermissions(permissionsOfUser, blockAll)}
        </Routes>
    )
}
