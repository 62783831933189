import {
    Button,
    Grid,
    IconButton,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import {
    SELECTSETTINGSFIELD,
    SETSETTINGSTIME,
} from "../../../../constants/actionTypes"
import store from "../../../../store"
import { intToHourDate } from "../../settingsForm"
import { wait } from "../../../TimeUtils"

const desc = (
    <>
        The auto lock will lock all the vehicles at the given time. Make sure
        you allow the players enough time to finish their rounds. The night-lock
        has to be at least 90 minutes after the last pickup time. The night-lock
        can only be set to the closest half hour.
    </>
)
const title = <>Lock all vehicles at this time</>

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const enabled = currentForm.nightLockEnabled
    const enabledString = enabled ? "ON" : "OFF"
    const time = intToHourDate(currentForm.openingTimes?.nightLock ?? 0)
    return enabledString + " - " + time
}

function NightLockItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    const [errorAutoLock, setErrorAutoLock] = useState("")

    const handleTimeChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        const number = parseInt(value.replace(":", ""))
        store.dispatch({
            type: SETSETTINGSTIME,
            payload: { key: name, value: number },
        })
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: { key: "nightLockEnabled", value: false },
        })
    }
    const enableNightLock = async (e) => {
        e.preventDefault()
        let number = currentForm.openingTimes.nightLock
        const remainder = number % 100
        number -= remainder
        if (remainder > 44) {
            number += 100
        } else if (remainder > 14) {
            number += 30
        }
        if (number >= 2400) {
            number -= 2400
        }
        store.dispatch({
            type: SETSETTINGSTIME,
            payload: { key: "nightLock", value: number },
        })
        if (currentForm.nightLockEnabled) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: { key: "nightLockEnabled", value: false },
            })
        } else if (
            currentForm.openingTimes.nightLock !== null &&
            currentForm.openingTimes.nightLock !== undefined
        ) {
            if (
                number >= currentForm.openingTimes.open &&
                number <= currentForm.openingTimes.close
            ) {
                setErrorAutoLock(
                    "Can't lock all carts while the course is open."
                )
                await wait()
                setErrorAutoLock("")
            } else if (
                (number < currentForm.openingTimes.close + 130 &&
                    number > currentForm.openingTimes.close) || // Normal case of end = 14, lock = 15
                (number + 2400 - currentForm.openingTimes.close < 130 && // lock < close and the distance is less than 1.5 hours
                    number < currentForm.openingTimes.close)
            ) {
                setErrorAutoLock(
                    "Earliest time to lock is 90 minutes after closing time."
                )
                await wait()
                setErrorAutoLock("")
            } else {
                store.dispatch({
                    type: SELECTSETTINGSFIELD,
                    payload: { key: "nightLockEnabled", value: true },
                })
            }
        } else {
            setErrorAutoLock("Lock time must be set, cant be --:--")
            await wait()
            setErrorAutoLock("")
        }
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
        >
            <Grid
                item
                xs
                justifyContent="center"
                alignItems="center"
                container
                direction="row"
            >
                <Grid item xs>
                    <TextField
                        id="lock-time"
                        label="Auto lock"
                        type="time"
                        value={intToHourDate(
                            currentForm.openingTimes.nightLock
                        )}
                        name="nightLock"
                        variant="standard"
                        onChange={handleTimeChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            step: 1800, // Does not work any more
                        }}
                        style={{
                            color: "secondary",
                            backgroundColor: "primary",
                        }}
                    />
                </Grid>
                <Grid item xs style={{ textAlign: "center" }}>
                    <Switch
                        variant="outlined"
                        onClick={enableNightLock}
                        checked={currentForm.nightLockEnabled}
                        color="secondary"
                    >
                        Set
                    </Switch>
                </Grid>
            </Grid>
            <Grid item xs>
                <Typography>
                    {errorAutoLock === ""
                        ? currentForm.nightLockEnabled
                            ? `Auto lock is on at ${intToHourDate(
                                  currentForm.openingTimes.nightLock
                              )}`
                            : "Auto lock is off"
                        : errorAutoLock}
                </Typography>
            </Grid>{" "}
        </Grid>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: NightLockItem,
}
