import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import CartSelectorInstallerHeader from "./InstallCart/installHeaderCartSelector"
import { CartInstallationHeader } from "./InstallCart/introInstallationText"
import CartInstallationCheck from "./InstallCart/cartInformationCheck"
import BoardIdDisplay from "./InstallCart/BoardIdDisplay"
import { SET_CURRENT_CART_INSTALL } from "../../constants/actionTypes"
import store from "../../store"
import InstallResultText from "./InstallCart/InstallResultText"
import CartInstallationNavigationToolbar from "./navigationToolbar"

export default function InstallCartView() {
    const params = useParams()
    const { cartId } = params
    const carts = useSelector((state) => state.firestore.data.carts)
    const installation = useSelector((state) => state.installation)

    useEffect(() => {
        console.log(cartId, installation)
        if (
            installation.cartIdToDocId &&
            cartId in installation.cartIdToDocId
        ) {
            console.log(installation)
            console.log(cartId)
            const cartDocId = installation.cartIdToDocId[cartId]
            if (cartDocId in carts) {
                const cart = carts[cartDocId]
                store.dispatch({
                    type: SET_CURRENT_CART_INSTALL,
                    payload: {
                        currentCartDocId: cartDocId,
                        currentCart: cart,
                    },
                })
            }
        }
    }, [cartId, installation.cartIdToDocId, Object.keys(carts).length])

    return (
        <Box sx={{ margin: 2 }}>
            <CartInstallationHeader />
            <CartSelectorInstallerHeader />
            <BoardIdDisplay />
            <CartInstallationCheck />
            <InstallResultText />
            <CartInstallationNavigationToolbar />
        </Box>
    )
}
