import {
    Box,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"

const desc = (
    <>Determines if the 9 hole option is available for customers in the App.</>
)

const title = <>9 Holes Enabled</>

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const nineHolesEnabled = currentForm.nineHolesEnabled ? "On" : "Off"

    return `9 holes enabled ${nineHolesEnabled}`
}

function nineHolesEnabled() {
    const currentForm = useSelector((state) => state.settingsForm)

    const handleInputChangeApp = () => {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "nineHolesEnabled",
                value: Boolean(!currentForm.nineHolesEnabled),
            },
        })
    }

    return (
        <Box>
            <Table size="small">
                <TableBody
                    sx={{
                        "& .MuiTableCell-root": {
                            borderBottom: "none",
                            paddingLeft: "0",
                        },
                        textAlign: "left",
                    }}
                >
                    <Box sx={{ textAlign: "center" }}>
                        <Switch
                            sx={{ margin: "auto" }}
                            onClick={handleInputChangeApp}
                            checked={currentForm.nineHolesEnabled || false}
                            color="secondary"
                            align="right"
                        />
                    </Box>
                </TableBody>
            </Table>
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: nineHolesEnabled,
}
