const featureEngine = "featEngine"
const featAvailability = "featAvailability"
const featBooking = "featBooking"
const featRebook = "featRebook"
const featCoupons = "featCoupons"
const featSettings = "featSettings"
const featRidepass = "featRidepass"
const featBilling = "featBilling"
const featPrice = "featPrice"

const featurePermissions = [
    featureEngine,
    featAvailability,
    featBooking,
    featRebook,
    featCoupons,
    featSettings,
    featRidepass,
    featBilling,
    featPrice,
]

export {
    featureEngine,
    featAvailability,
    featBooking,
    featRebook,
    featSettings,
    featurePermissions,
    featBilling,
    featPrice,
}

const viewMap = "viewMap"
const viewBooking = "viewBooking"
const viewVehicles = "viewVehicles"
const viewCoupons = "viewCoupons"
const viewRidepass = "viewRidepass"
const viewStatistics = "viewStatistics"
const viewReviews = "viewReviews"
const viewSettings = "viewSettings"
const viewInstallerPage = "viewInstallerPage"
const viewBilling = "viewBilling"
const viewUpgrade = "viewUpgrade"

const viewPermissions = [
    viewMap,
    viewBooking,
    viewVehicles,
    viewCoupons,
    viewRidepass,
    viewSettings,
    viewStatistics,
    viewReviews,
    viewInstallerPage,
]
const sidebarViews = [
    viewMap,
    viewBooking,
    viewVehicles,
    viewStatistics,
    viewReviews,
    viewCoupons,
    viewRidepass,
    viewSettings,
]
const routesInSidebar = (courseData, subData) => {
    if (!courseData.registrationComplete) {
        return []
    }
    let sidebarRoutes = [...sidebarViews]
    if (!courseData.courseComplete && courseData.keyless) {
        sidebarRoutes = [...sidebarRoutes, viewInstallerPage]
    }
    if (subData && subData?.upgrade?.exists) {
        sidebarRoutes = [...sidebarRoutes, viewUpgrade]
    }

    return sidebarRoutes
}

export {
    viewMap,
    viewBooking,
    viewVehicles,
    viewCoupons,
    viewSettings,
    viewReviews,
    viewPermissions,
    viewInstallerPage,
    viewBilling,
    routesInSidebar,
}
