import React, { useEffect } from "react"
import db from "../rootWrapper/firebase"
import SigninStatus from "./signinStatus"
import { useSelector } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { golfCourses } from "../constants/dbPaths"
import store from "../store"
import {
    COMPLETE_SETUP,
    COMPLETE_STEP,
    ONBOARDING_SET_STRIPE_COMPLETED,
    PAGE_CHANGE_SETUP_FLOW,
} from "../constants/actionTypes"
import PropTypes from "prop-types"

export default function SetupFlowController(props) {
    const { steps } = props
    const navigate = useNavigate()
    const { courseDocId } = SigninStatus()
    const [searchParams, setSearchParams] = useSearchParams()

    const completeCourseSetup = async () => {
        await db.collection(golfCourses).doc(courseDocId).update({
            courseComplete: true,
        })
        store.dispatch({ type: COMPLETE_SETUP })
        navigate("/")
    }

    const handleStep = (step) => {
        searchParams.set("page", step + 1)
        setSearchParams(searchParams)
        store.dispatch({
            type: PAGE_CHANGE_SETUP_FLOW,
            payload: { page: step },
        })
    }
    const handleStepPress = (step) => () => {
        searchParams.delete("completed")
        setSearchParams(searchParams)
        handleStep(step)
    }
    const setCompleted = (steps) => {
        store.dispatch({
            type: COMPLETE_STEP,
            payload: { completed: steps },
        })
    }
    const { activePage, completed } = useSelector((state) => state.setupflow)
    const completedStepsList = Object.keys(completed).filter(
        (x) => completed[String(x)]
    )

    const urlPage = searchParams.has("page") && searchParams.get("page")
    useEffect(() => {
        if (urlPage !== null) {
            const page = parseInt(urlPage)
            if (!isNaN(page) && activePage !== page - 1) {
                handleStep(page - 1)
            }
        }
    }, [urlPage, activePage])

    useEffect(() => {
        async function getStripe() {
            const courseSnap = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .get()
            const courseData = courseSnap.data()
            const completedSteps = courseData.onboardingCompletedSteps
            const { stripeCompleted } = courseData
            setCompleted(completedSteps)
            if (stripeCompleted) {
                store.dispatch({ type: ONBOARDING_SET_STRIPE_COMPLETED })
            }
        }
        getStripe()
    }, [])

    const totalSteps = () => {
        return steps.length
    }

    const completedSteps = () => {
        return Object.values(completed).reduce((sum, val) => sum + val, 0)
    }

    const isLastStep = () => {
        return activePage === totalSteps() - 1
    }

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps()
    }

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex(
                      (step, i) => !(String(i) in completedStepsList)
                  )
                : activePage + 2
        handleStep(newActiveStep - 1)
    }

    const handleBack = () => {
        handleStep(activePage - 1)
    }

    const handleComplete = async () => {
        const newCompleted = completed
        newCompleted[activePage] = true
        setCompleted(newCompleted)
        await db.collection(golfCourses).doc(courseDocId).update({
            onboardingCompletedSteps: newCompleted,
        })
        handleNext()
    }

    const handleReset = () => {
        handleStep(0)
        setCompleted({})
    }
    return {
        completeCourseSetup,
        handleReset,
        handleComplete,
        handleBack,
        handleNext,
        allStepsCompleted,
        isLastStep,
        completedSteps,
        totalSteps,
        activePage,
        completed,
        setCompleted,
        handleStepPress,
        handleStep,
    }
}

SetupFlowController.propTypes = {
    steps: PropTypes.arrayOf(PropTypes.string),
}
