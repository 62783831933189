import { useEffect } from "react"
import { useSelector } from "react-redux"
import { SET_REPORTS } from "../../constants/actionTypes"
import { golfCourses, reportRunPath } from "../../constants/dbPaths"
import db from "../../rootWrapper/firebase"
import store from "../../store"

export default function ReportController() {
    const { reportLoaded, reportLimit } = useSelector((state) => {
        return state.bookingHistory || {}
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    useEffect(() => {
        const getReports = async () => {
            const reports = await db
                .collection(golfCourses)
                .doc(courseDocId)
                .collection(reportRunPath)
                .orderBy("createdTs", "desc")
                .limit(reportLimit)
                .get()
            const docs = reports.docs
            const docsParsed = docs.map((x) => x.data())
            store.dispatch({
                type: SET_REPORTS,
                payload: { reports: docsParsed },
            })
        }

        if (!reportLoaded && reportLimit) {
            getReports()
        }
    }, [reportLoaded, reportLimit])
}
