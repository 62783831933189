import { SELECTAVCART, UNSELECTAVCART } from "../constants/actionTypes"

export default (
    state = {
        selected: false,
        boardId: null,
        cartDocId: "",
        id: null,
        rowNum: null,
        availability: null,
        courseDocId: "",
    },
    action
) => {
    switch (action.type) {
        case SELECTAVCART:
            return {
                ...state,
                selected: true,
                boardId: action.payload.boardId,
                cartDocId: action.payload.cartDocId,
                courseDocId: action.payload.courseDocId,
                id: action.payload.id,
                rowNum: action.payload.rowNum,
                availability: action.payload.availability,
            }
        case UNSELECTAVCART:
            return {
                ...state,
                selected: false,
                boardId: null,
                cartDocId: "",
                courseDocId: "",
                id: null,
                rowNum: null,
                availability: null,
            }
        default:
            return state
    }
}
