import {
    Box,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Tooltip,
    Typography,
} from "@mui/material"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { styled } from "@mui/styles"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { HelpQuestion } from "../../views/statistics/reportSection"
import DataTimePicker from "../vehicleStatsRow/timeDataPicker"
import store from "../../store"
import { SET_REPORTS_NOT_LOADED } from "../../constants/actionTypes"

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: "#272727",
    minWidth: "250px",
    minHeight: "200px",
    maxWidth: "400px",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#575757",
}))

const createBookingReport = httpsCallable(functions, "createBookingReport")

export default function ReportGeneratorBox() {
    const [loading, setLoading] = useState(false)

    const { startSearch, endSearch } = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const handleClick = async () => {
        setLoading(true)
        await createBookingReport({
            startTime: startSearch,
            endTime: endSearch,
            courseDocId,
        }).catch((e) => {})
        store.dispatch({ type: SET_REPORTS_NOT_LOADED })
        setLoading(false)
    }

    return (
        <StyledBox>
            <Grid
                padding={"32px"}
                paddingBottom="0"
                paddingTop="0"
                alignItems="center"
                container
                direction="column"
                sx={{ minHeight: "200px" }}
                justifyContent="center"
            >
                <Grid
                    item
                    xs
                    alignItems="center"
                    justifyContent="center"
                    container
                    direction="row"
                >
                    <Grid item xs sx={{ pb: 1 }}>
                        <Box width="125px" marginRight="auto">
                            <Grid container direction="column">
                                <Grid item xs>
                                    <Typography
                                        color="#DCDCDC"
                                        fontSize={"9pt"}
                                        display={"inline-block"}
                                        sx={{ pr: 1 }}
                                    >
                                        Start date
                                    </Typography>
                                    <HelpQuestion tooltip="test" />
                                </Grid>
                                <Grid item xs>
                                    <DataTimePicker type="startSearch" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs>
                        <Box width="125px" marginLeft="auto">
                            <Grid container direction="column">
                                <Grid item xs>
                                    <Typography
                                        fontSize={"9pt"}
                                        color="#DCDCDC"
                                        display={"inline-block"}
                                        sx={{ pr: 1 }}
                                    >
                                        End date
                                    </Typography>
                                    <HelpQuestion tooltip="test" />
                                </Grid>
                                <Grid item xs>
                                    <DataTimePicker type="endSearch" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="center"
                    direciton="column"
                    xs
                >
                    <Grid item maxHeight={"50px"} xs>
                        {loading ? <LinearProgress color="secondary" /> : null}
                        <Button
                            disabled={loading}
                            style={{
                                fontWeight: 600,
                                height: "50px",
                            }}
                            color="secondary"
                            variant="contained"
                            fullWidth
                            onClick={handleClick}
                        >
                            Generate report
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </StyledBox>
    )
}
