import React, { useEffect } from "react"
import { Box, Grid, Typography } from "@mui/material"
import getSubscriptionStatus from "../../Components/SubscriptionStatus"
import { dayMonthYear } from "../../Components/dateTools"
import CircleLoader from "../../Components/loader"
import theme from "../../assets/styles/muiTheme"

const getNextPayment = (subscription, status) => {
    if (status === "trialing") {
        return (
            <>
                <Typography>
                    Trial Ending:{" "}
                    {dayMonthYear(new Date(subscription?.trial_end * 1000))}
                </Typography>
            </>
        )
    }
    if (status === "active" || status === "past_due") {
        return (
            <>
                <Typography>
                    Next Invoice:{" "}
                    {dayMonthYear(
                        new Date(subscription?.current_period_end * 1000)
                    )}
                </Typography>
            </>
        )
    }
}
const status = {
    unpaid: "Not paid",
    canceled: "Canceled",
    past_due: "Past due",
    active: "Active",
    trialing: "Free Trial",
    incomplete_expired: "Expired Incomplete",
    incomplete: "Incomplete",
}
const getStatusMessage = (subscriptionStatus) => {
    if (subscriptionStatus in status) {
        return status[subscriptionStatus]
    }
    return subscriptionStatus
}

export default function BillingInfo() {
    const { initiatedSub, subscriptionStatus, subData, subscription } =
        getSubscriptionStatus()

    useEffect(() => {
        console.log(subscriptionStatus)
        console.log(subData)
    }, [subscription, subData])

    if (!initiatedSub) {
        return <CircleLoader />
    }
    const currency = subData?.priceModel?.currency || ""

    return (
        <Box
            sx={{
                maxWidth: 300,
                mt: 2,
                borderRadius: 2,
                p: 1,
                height: 170,
            }}
            bgcolor={theme.palette.primary.main}
        >
            <Typography fontSize={"16pt"}>Current Plan</Typography>
            <br />
            <Typography>
                Status: {getStatusMessage(subscriptionStatus)}
            </Typography>
            {getNextPayment(subscription, subscriptionStatus)}
            <Typography>
                Billing period: {subData.monthly ? "Monthly" : "Yearly"}
            </Typography>
            <Typography>
                Billing amount:{" "}
                {Number(subData?.priceModel?.unitPrice / 100).toFixed(2)}{" "}
                {currency.toUpperCase()}
            </Typography>
        </Box>
    )
}
