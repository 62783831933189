import React from "react"
import Button from "@mui/material/Button"
import { Alert, AlertTitle, Box, LinearProgress, Tooltip } from "@mui/material"

export function SuccessLoader(props) {
    const {
        size,
        status,
        error,
        disabled,
        noAccess,
        outlined,
        handleSubmit,
        sx,
        children,
    } = props
    if (status.success) {
        return (
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Successfully saved
            </Alert>
        )
    }
    if (status.error) {
        return (
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {error}
            </Alert>
        )
    }
    return (
        <Box position={"relative"}>
            {status.loading ? <LinearProgress color="secondary" /> : null}
            {!noAccess ? null : (
                <Tooltip
                    placement="right"
                    title={"Limited permissions - talk to admin"}
                >
                    <Box
                        sx={{
                            // border: "1px solid", // Use to check where the helper box is
                            position: "absolute",
                            width: "100%",
                            height: "37px",
                        }}
                    ></Box>
                </Tooltip>
            )}
            <Button
                size={size}
                type="submit"
                id="standard-full-width"
                disabled={status.loading || disabled || noAccess}
                fullWidth
                variant={outlined ? "outlined" : "contained"}
                color="secondary"
                label="submit"
                margin="normal"
                onClick={handleSubmit}
                sx={{ ...sx }}
            >
                {children ? children : "Save"}
            </Button>
        </Box>
    )
}
