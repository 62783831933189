import React from "react"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import { Box, Divider, Grid, Typography } from "@mui/material"
import HeaderWithCourseName from "../Components/HeaderWithCourseName"
import VehicleSelector from "../Components/vehicleComponents/vehicleSelector"
import VoltageText from "../Components/vehicleComponents/voltageDisplayer"
import VehicleActionCell from "../Components/vehicleComponents/actionCell"
import PowerOnSwitch from "../Components/PowerOnSwitch"
import { textTableHeaderMapTable } from "../assets/applicationText/mapTable"
import RentalLimitChange from "../Components/RentalLimitPerDayLimit"
import AvailabilitySwitch from "../Components/AvailabilitySwitch"
import ServiceNotes from "../Components/SeriveNotes"
import RatingVehicleDescription from "../Components/RatingDescVehicle"
import CurrentNextBookingSection from "../Components/NextBooking/currentNextBookingSection"
import BookingHistorySection from "../Components/bookingHistory"
import BookingHistoryLoader from "../Components/bookingHistory/cartSpesificBookingHistoryLoader"
import VehicleStatsRow from "../Components/vehicleStatsRow/statsRow"
import PingButton from "../Components/PowerOnSwitch/pingButton"
import { ReadingChecker } from "../Components/Carts/cartPopupComponents/ReadingRow"
import ChangeSmartboxId from "../Components/PowerOnSwitch/changeSmartboxId"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        overflowY: "auto",
        padding: "35px",
    },
    noPad: {
        padding: `${0}%`,
    },
}))

const ModuleType = ({ vehicle }) => {
    if (!vehicle) {
        return null
    }
    if (vehicle.keylessType === "dp") {
        return <Typography>SID: {vehicle?.boardId}</Typography>
    }
    if (vehicle.keylessType === "xirgo") {
        return <Typography>SID: {vehicle?.moduleId}</Typography>
    }
    if (vehicle.keylessType === "none") {
        return <Typography>No smartbox</Typography>
    }
    console.error("Bad vehicle type")
    return <Typography>NaN smartbox</Typography>
}

export default function Vehicles() {
    const classes = useStyles()

    const course = useSelector((state) => {
        return state.firestore.data.field
    })
    const { selectedVehicle } = useSelector((state) => {
        return state.vehicleSelector
    })
    const { currentBooking } = useSelector((state) => state.nextBooking)

    BookingHistoryLoader()

    return (
        <Box className={classes.root}>
            <Grid container direction="row" spacing={1}>
                <Grid item xs container direction="column">
                    <Grid item xs>
                        <HeaderWithCourseName
                            header={"Vehicles"}
                            courseName={course.name}
                        />
                    </Grid>
                    <Grid item xs sx={{ mt: 1 }}>
                        <VehicleSelector />
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Box
                        style={{
                            marginLeft: "auto",
                            width: "400px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                        }}
                    >
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                            }}
                        >
                            <VoltageText />
                            <Grid
                                container
                                direction="row"
                                alignItems="flex-end"
                                justifyContent="flex-end"
                                spacing={1}
                            >
                                <Grid item width="130px">
                                    <ReadingChecker
                                        readingTs={
                                            selectedVehicle.reading?.timestampReceived?.toDate() ||
                                            0
                                        }
                                    />
                                </Grid>
                                <Grid item width="150px">
                                    <PingButton
                                        vehicle={{
                                            boardId: selectedVehicle.boardId,
                                            cartDocId:
                                                selectedVehicle.cartDocId,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container direction="row" spacing={1}>
                                <Grid
                                    container
                                    item
                                    width="130px"
                                    justifyContent="flex-end"
                                    alignContent="center"
                                >
                                    <ModuleType vehicle={selectedVehicle} />
                                </Grid>
                                {process.env.REACT_APP_PROJECT_ID ===
                                "gsw-db" ? (
                                    <Grid item width="150px">
                                        <ChangeSmartboxId
                                            vehicle={selectedVehicle}
                                        />
                                    </Grid>
                                ) : (
                                    <div />
                                )}
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid sx={{ mt: 1 }} container direction="column" spacing={1}>
                <Grid item xs>
                    <Grid
                        container
                        sx={{
                            minHeight: "70px",
                            "& > div": {
                                borderStyle: "solid",
                                borderWidth: "1px 0px 1px 0px",
                                borderColor: "rgb(220, 220, 220, 0.5)",
                            },
                        }}
                        direction="row"
                        justifyContent={"space-between"}
                    >
                        <Grid item xs>
                            <VehicleActionCell
                                FunctionalComponent={PowerOnSwitch}
                                toolTip={textTableHeaderMapTable(0)}
                                desc={"Power"}
                                prop={{
                                    width: "140px",
                                    fontSize: "1rem",
                                    currentBooking,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs
                            style={{
                                borderWidth: "1px 1px 1px 1px",
                            }}
                        >
                            <VehicleActionCell
                                FunctionalComponent={AvailabilitySwitch}
                                toolTip={textTableHeaderMapTable(1)}
                                desc={"Available for Booking"}
                            />
                        </Grid>
                        <Grid item xs>
                            <VehicleActionCell
                                FunctionalComponent={RentalLimitChange}
                                toolTip={
                                    "Use this to set the maximum amount of rentals " +
                                    "the vehicle can achieve in one day. For example if " +
                                    "you know this vehicle has a bad battery you can set this limit to 1 per day."
                                }
                                desc={"Daily Booking Limit"}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Divider flexItem />
                <Grid item xs>
                    <VehicleStatsRow />
                </Grid>
                <Divider flexItem />
                <Grid item xs sx={{ mb: 1 }}>
                    <ServiceNotes />
                </Grid>
                <Divider flexItem />
                <Grid item xs sx={{ mb: 1 }}>
                    <RatingVehicleDescription />
                </Grid>
                <Divider flexItem />
                <CurrentNextBookingSection />
                <Divider flexItem />
                <Grid item xs sx={{ mb: 1 }}>
                    <BookingHistorySection />
                </Grid>
            </Grid>
        </Box>
    )
}
