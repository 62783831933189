const voltageMultiples = [
    0.95875, 0.9715277778, 0.9847222222, 0.9965277778, 1.008472222, 1.02,
    1.03125, 1.041527778, 1.051527778, 1.06125, 2,
]

export const VoltageCalc = (voltage, maxVoltage) => {
    const voltageFactor = voltage / maxVoltage
    for (let i = 1; i < voltageMultiples.length; i++) {
        if (voltageFactor <= voltageMultiples[i]) {
            return i * 10
        }
    }
    return 0
}
