import React, { useEffect, useState, forwardRef, useCallback } from "react"
import Paper from "@mui/material/Paper"
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid"
import { useSelector } from "react-redux"
import { Box, Checkbox } from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import EditIcon from "@mui/icons-material/Edit"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import { bookingInProgress } from "../AvailabilityRebooking/bookingAvList"
import { dateToHourMinute, getHourMinTimerString, timeDate } from "../dateTools"
import {
    statusAdmin,
    statusEnded,
    statusExpired,
    validStatusTable,
} from "../../constants/statusTypes"
import { SELECT_BOOKING } from "../../constants/actionTypes"
import store from "../../store"
import { useBookingStyles } from "../../assets/styles/components/bookingTable"
import { httpsCallable } from "firebase/functions"
import {
    getCouponCode,
    getDiscountedPrice,
    getEndTime,
    getIsOnOvertime,
    hasPayed,
} from "./bookingUtils"
import isCellEditable from "./helperfunctions/isRowEditable"
import getNameFromBooking from "./helperfunctions/getNameFromBooking"
import getTlfFromBooking from "./helperfunctions/getTlfFromBooking"
import getVehicleGroupName from "./helperfunctions/getVehicleGroupName"
import { functions } from "../../rootWrapper/firebase"

const handleRowClick = (item) => {
    if (item && item.doc) {
        console.log(item)
        const bookingInfo = {
            ...item,
            ...item.doc,
            selected: true,
            cartDocId: item.group,
            bookingId: item.id,
            startTime: item.startTime.getTime(),
            endTime: item.endTime.getTime(),
            name: item.name,
            hourMinString: item.hourMinString,
        }
        store.dispatch({
            type: SELECT_BOOKING,
            payload: bookingInfo,
        })
    }
}

const handleEditClick = (row) => (event) => {
    event.stopPropagation()
    handleRowClick(row)
}

const columns = [
    {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id, row }) => [
            <GridActionsCellItem
                key={id}
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(row)}
                color="inherit"
            />,
        ],
    },
    {
        field: "cart",
        headerName: "Cart",
        align: "center",
        headerAlign: "center",
    },
    {
        field: "vehicleGroupName",
        headerName: "Vehicle\u00a0Type",
        minWidth: 120,
        hide: true,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "status",
        headerName: "Booking\u00a0Status",
        minWidth: 120,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "startTime",
        headerName: "Start\u00a0Time",
        minWidth: 200,
        type: "dateTime",
        editable: true,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "endTime",
        headerName: "End\u00a0Time",
        minWidth: 200,
        type: "dateTime",
        editable: true,
        hide: true,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "elapsedTime",
        headerName: "Timer",
        minWidth: 30,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => params.value,
    },
    {
        field: "name",
        headerName: "Customer\u00a0Name",
        minWidth: 120,
        editable: true,
        flex: 1,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "createdBy",
        headerName: "Created\u00a0By",
        minWidth: 120,
        flex: 1,
        hide: true,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "customerPhone",
        headerName: "Customer\u00a0Phone",
        flex: 1,
        editable: true,
        minWidth: 100,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "userSegment",
        headerName: "Category",
        minWidth: 100,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "couponCode",
        headerName: "Discount",
        minWidth: 50,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "price",
        headerName: "Price",
        minWidth: 100,
        align: "center",
        headerAlign: "center",
        type: "number",
    },
    {
        field: "isNineHoles",
        headerName: "Nine Holes",
        type: "boolean",
        hide: true,
        minWidth: 15,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "bookingRef",
        headerName: "Booking Ref",
        hide: true,
        minWidth: 15,
        align: "center",
        headerAlign: "center",
    },
    {
        field: "payed",
        headerName: "Paid",
        type: "boolean",
        editable: true,
        minWidth: 15,
        align: "center",
        headerAlign: "center",
    },
]

export const paymentBookingPaid = async (
    courseDocId,
    bookingDocId,
    hasPayed
) => {
    const paymentBookingCallable = httpsCallable(functions, "paymentBooking")
    return paymentBookingCallable({
        courseDocId,
        bookingDocId,
        hasPayed,
        customPrice: -1,
        onlyPaid: true,
    })
}

const getStatus = (status) => {
    if (status === "admin") {
        return "Dashboard Booking"
    }
    if (status === "started") {
        return "Started"
    }
    if (status === "ended") {
        return "Ended"
    }
    if (status == "not_started") {
        return "Not Started"
    }
    if (status === "expired") {
        return "No-show - ended"
    }
    if (status === "cancelled") {
        return "Cancelled"
    }
    return status
}

export const getFullStatus = (booking) => {
    if (!booking) {
        return ""
    }
    const { status, dashStatus } = booking
    if (dashStatus) {
        return `${getStatus(dashStatus)} (${getStatus(status)})`
    }
    return `${getStatus(status)}`
}

export const getTimer = (doc, nowTime) => {
    const { status } = doc
    const nowMs = nowTime * 1000
    let timeString
    const startSeconds = Math.floor(doc.startTime.getTime() / 1000)
    const endSeconds = Math.floor(doc.endTime.getTime() / 1000)
    if (
        doc.startTime.getTime() < nowMs &&
        ((doc.endTime.getTime() > nowMs && status === statusAdmin) ||
            bookingInProgress(status))
    ) {
        timeString = getHourMinTimerString(nowTime - startSeconds)
        if (doc.isOnOvertime)
            return <span style={{ color: "red" }}>{timeString}</span>
        return timeString
    }
    if (doc.status === statusEnded || doc.status === statusExpired) {
        if (doc.isOnOvertime && doc.actualEndTime) {
            timeString = getHourMinTimerString(
                doc.actualEndTime.seconds - startSeconds
            )
            if (doc.isOnOvertime)
                return <span style={{ color: "red" }}>{timeString}</span>
        }
        timeString = getHourMinTimerString(endSeconds - startSeconds)
        return timeString
    }

    return "-"
}

export default function BookingTable(props) {
    const classes = useBookingStyles()
    const [nowTime, setTime] = useState(Math.round(Date.now() / 1000))
    const [snackbar, setSnackbar] = React.useState(null)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const course = useSelector((state) => {
        return state.firestore.data.field
    })
    const { startTime, endTime, updateNumber } = useSelector(
        (state) => state.bookingView
    )

    const handleCloseSnackbar = () => setSnackbar(null)

    // Increment counter of time
    useEffect(() => {
        const timeout = setTimeout(() => {
            setTime(nowTime + 1)
        }, 1000)
        return () => clearTimeout(timeout)
    }, [nowTime])

    useEffect(() => {
        setRows((prev) =>
            prev.map((row) => ({
                ...row,
                elapsedTime: getTimer(row.doc, nowTime),
            }))
        )
    }, [nowTime, startTime, updateNumber])

    const hasPayedTable = (doc) => {
        if (doc.status === statusAdmin) {
            return doc.hasPayed
        }
        return "-"
    }
    const rowSelector = useSelector((state) => {
        if (state.bookingView.bookings.length) {
            return state.bookingView.bookings
                .filter((x) => x)
                .filter(
                    (doc) =>
                        doc.status === null ||
                        validStatusTable.indexOf(doc.status) > -1
                )
                .filter((doc) => getEndTime(doc).toMillis() > startTime) // + 60 * 60 * 24 * 1000 - 6 * 60 * 60 * 1000
                .filter((doc) => doc.startTime.toMillis() < endTime) // - 60 * 60 * 24 * 1000 + 6 * 60 * 60 * 1000
                .map((doc) => {
                    const name = getNameFromBooking(doc)
                    const tlf = getTlfFromBooking(doc)
                    const endTime = getEndTime(doc)
                    const hourMinString = dateToHourMinute(
                        doc.startTime.toDate()
                    )
                    const price = getDiscountedPrice(doc)
                    const payed = hasPayedTable(doc)
                    const couponCode = getCouponCode(doc)
                    const isOnOvertime = getIsOnOvertime(doc)
                    const vehicleGroupName = getVehicleGroupName(doc, course)
                    const status = getFullStatus(doc)
                    return {
                        ...doc,
                        id: doc.id,
                        startTimeDisplayed: timeDate(doc.startTime.toDate()),
                        startTime: doc.startTime.toDate(),
                        customerPhone: tlf,
                        tlf,
                        isOnOvertime,
                        hasPayed: hasPayed(doc),
                        hasPayedTable: payed,
                        payed: hasPayed(doc),
                        name,
                        price,
                        couponCode,
                        vehicleGroupName,
                        status,
                        createdBy: doc.createdBy ?? "-",
                        bookingRef: doc.keyless ? "-" : doc.verificationCode,
                        cartDocId: doc.cartDocId,
                        endTime: endTime.toDate(),
                        cart: state.firestore.data.carts[doc.cartDocId]
                            ? state.firestore.data.carts[doc.cartDocId].id
                            : "Cart does not exist",
                        hourMinString,
                        group: doc.cartDocId,
                        doc: {
                            ...doc,
                            startTime: doc.startTime.toDate(),
                            endTime: endTime.toDate(),
                        },
                    }
                })
        }
        return []
    })
    const [rows, setRows] = useState(rowSelector)

    useEffect(() => {
        setRows(rowSelector)
        console.log("Rows reload")
    }, [startTime, rowSelector.length, updateNumber])

    function StyledCheckbox(props, ref) {
        return <Checkbox {...props} ref={ref} color="secondary" />
    }
    const changeName = async (courseDocId, bookingDocId, newName) => {
        const rowListFiltered = rowSelector.filter((x) => x.id === bookingDocId)
        if (!(rowListFiltered.length === 1)) {
            console.error("Row wrong size", rowListFiltered)
            return
        }
        const row = rowListFiltered[0]

        const changeBookingDetails = httpsCallable(
            functions,
            "changeBookingDetails"
        )
        const res = changeBookingDetails({
            courseDocId,
            bookingDocId,
            name: newName,
            tlf: row.tlf,
        })
        setRows((prev) =>
            prev.map((row) =>
                row.id === bookingDocId
                    ? {
                          ...row,
                          doc: {
                              ...row.doc,
                              name: newName,
                          },
                          name: newName,
                      }
                    : row
            )
        )
        return res
    }
    const changeTlf = async (courseDocId, bookingDocId, newTlf) => {
        const rowListFiltered = rowSelector.filter((x) => x.id === bookingDocId)
        if (!(rowListFiltered.length === 1)) {
            console.error("Row wrong size", rowListFiltered)
            return
        }
        const row = rowListFiltered[0]

        const changeBookingDetails = httpsCallable(
            functions,
            "changeBookingDetails"
        )
        const res = changeBookingDetails({
            courseDocId,
            bookingDocId,
            name: row.name,
            tlf: newTlf,
        }).catch((e) => console.error(e.message))
        setRows((prev) =>
            prev.map((row) =>
                row.id === bookingDocId
                    ? {
                          ...row,
                          doc: {
                              ...row.doc,
                              tlf: newTlf,
                          },
                          tlf: newTlf,
                      }
                    : row
            )
        )
        return res
    }
    const changeStartTime = async (courseDocId, bookingDocId, newStartTime) => {
        const rowListFiltered = rowSelector.filter((x) => x.id === bookingDocId)
        if (!(rowListFiltered.length === 1)) {
            console.error("Row wrong size", rowListFiltered)
            return
        }
        const row = rowListFiltered[0]
        const oldEndTime = row.endTime.getTime()
        const oldStartTime = row.startTime.getTime()
        const diff = oldEndTime - oldStartTime
        const newEndTime = newStartTime.getTime() + diff

        const moveBookingCallable = httpsCallable(functions, "moveBooking")
        const res = moveBookingCallable({
            courseDocId,
            bookingDocId,
            startTime: newStartTime.getTime(),
            endTime: newEndTime,
        })
        setRows((prev) =>
            prev.map((row) =>
                row.id === bookingDocId
                    ? {
                          ...row,
                          doc: {
                              ...row.doc,
                              startTime: new Date(newStartTime),
                              endTime: new Date(newEndTime),
                          },
                          endTime: new Date(newEndTime),
                      }
                    : row
            )
        )
        return res
    }
    const changeEndTime = async (courseDocId, bookingDocId, newEndTime) => {
        const rowListFiltered = rowSelector.filter((x) => x.id === bookingDocId)
        if (!(rowListFiltered.length === 1)) {
            console.error("Row wrong size", rowListFiltered)
            return
        }
        const row = rowListFiltered[0]
        const oldStartTime = row.startTime.getTime()

        const moveBookingCallable = httpsCallable(functions, "moveBooking")
        const res = moveBookingCallable({
            courseDocId,
            bookingDocId,
            startTime: oldStartTime,
            endTime: newEndTime.getTime(),
        })
        setRows((prev) =>
            prev.map((row) =>
                row.id === bookingDocId
                    ? {
                          ...row,
                          doc: { ...row.doc, endTime: new Date(newEndTime) },
                          endTime: new Date(newEndTime),
                      }
                    : row
            )
        )
        return res
    }

    const handleCellEditCommit = useCallback(
        async (params) => {
            /*
             * params  {id: "zlCAxLMQCxhrZKKmJROY", field: "price", value: 2 }
             */
            try {
                // Make the HTTP request to save in the backend
                let submitFunction = async () => null
                switch (params.field) {
                    case "payed":
                        submitFunction = paymentBookingPaid
                        break
                    case "startTime":
                        submitFunction = changeStartTime
                        break
                    case "endTime":
                        submitFunction = changeEndTime
                        break
                    case "name":
                        submitFunction = changeName
                        break
                    case "customerPhone":
                        submitFunction = changeTlf
                        break
                    default:
                        break
                }

                const response = await submitFunction(
                    courseDocId,
                    params.id,
                    params.value
                )
                console.log(response.data)

                setSnackbar({
                    children: "Successfully Updated",
                    severity: "success",
                })
                setRows((prev) =>
                    prev.map((row) =>
                        row.id === params.id
                            ? { ...row, [params.field]: params.value }
                            : row
                    )
                )
            } catch (error) {
                console.error(error.message)
                setSnackbar({
                    children: "Error while updating",
                    severity: "error",
                })
                // Restore the row in case of error
                setRows((prev) => [...prev])
            }
        },
        [rows]
    )

    return (
        <Paper className={classes.root}>
            <Box>
                <DataGrid
                    columns={columns}
                    rows={rows}
                    onCellEditCommit={handleCellEditCommit}
                    onColumnVisibilityModelChange={(model) =>
                        console.log(model)
                    }
                    autoHeight
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    components={{
                        BaseCheckbox: forwardRef(StyledCheckbox),
                        BooleanCellTrueIcon: () => (
                            <CheckBoxIcon color="secondary" />
                        ),
                        BooleanCellFalseIcon: CheckBoxOutlineBlankIcon,
                    }}
                    isCellEditable={isCellEditable}
                    density="compact"
                    pagination
                />
                {!!snackbar && (
                    <Snackbar
                        open
                        onClose={handleCloseSnackbar}
                        autoHideDuration={6000}
                        anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                        }}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar} />
                    </Snackbar>
                )}
            </Box>
        </Paper>
    )
}
