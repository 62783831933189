import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import Checkbox from "@mui/material/Checkbox"
import ListItem from "@mui/material/ListItem"
import Divider from "@mui/material/Divider"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import theme from "../../assets/styles/muiTheme"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemIcon from "@mui/material/ListItemIcon"
import notifications from "../../reducers/notifications"
import { dateShort, timeShort } from "../dateTools/index.js"
import MarkunreadIcon from "@mui/icons-material/Markunread"
import MailOutlineIcon from "@mui/icons-material/MailOutline"
import Markunread from "@mui/icons-material/Markunread"
import { Icon, IconButton } from "@mui/material"

const useStyles = makeStyles((theme) => ({
    altText: {
        margin: "auto",
        width: "80%",
    },
    unreadIcon: {
        "&:hover": { color: "green" },
    },
}))

export default function CustomerReviewNotification(props) {
    const { notification, key, index, setRead } = props

    const setNotificationStatus = (status) => {
        setRead(notification)
    }

    const style = useStyles

    return (
        <ListItem
            key={index}
            alignItems="flex-start"
            style={{
                marginTop: 20,
                padding: 20,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 10,
            }}
        >
            <ListItemIcon>
                <Grid
                    item
                    xs
                    marginRight="10px"
                    style={{
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: 6,
                        padding: 4,
                    }}
                >
                    <Typography color="black">CUSTOMER REVIEW</Typography>
                </Grid>
            </ListItemIcon>
            <ListItemText
                primary={
                    <Grid container direction="row">
                        <Grid item xs>
                            {notification.customerName} -{" Vehicle "}
                            {notification.customerVehicle}
                        </Grid>
                    </Grid>
                }
                secondary={
                    <Grid container direction="column">
                        <Grid item xs>
                            {notification.message}
                        </Grid>
                    </Grid>
                }
            />
            <ListItemSecondaryAction>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    width="400px"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs>
                        <Typography>
                            {dateShort(notification.createdAt.toDate()) +
                                " - " +
                                timeShort(notification.createdAt.toDate())}
                        </Typography>
                    </Grid>

                    <Grid item xs>
                        {notification.read ? (
                            <MarkunreadIcon />
                        ) : (
                            <IconButton
                                onClick={() => {
                                    setNotificationStatus(true)
                                }}
                            >
                                <MailOutlineIcon style={style.unreadIcon} />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
