import React from "react"
import makeStyles from "@mui/styles/makeStyles"

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
        overflowX: "hidden",
        width: "100%",
    },
}))

export default function News() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <iframe
                src="https://9471419.hs-sites.com/knowledge"
                width="100%"
                height="100%"
                sandbox="allow-scripts allow-top-navigation-to-custom-protocols allow-top-navigation allow-modals allow-same-origin"
                style={{ border: "none" }}
            ></iframe>
        </div>
    )
}
