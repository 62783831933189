import { Box, Button, Grid, Typography } from "@mui/material"
import { GeoPoint } from "firebase/firestore"
import React, { useEffect } from "react"
import { useState } from "react"
import { useSelector } from "react-redux"
import MapPicker from "."
import { SET_SETTINGS_SAVED } from "../../constants/actionTypes"
import { golfCourses } from "../../constants/dbPaths"
import db from "../../rootWrapper/firebase"
import store from "../../store"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { wait } from "../TimeUtils"

const initStatus = {
    error: false,
    success: false,
    loading: false,
    errorMessage: "",
}

const checkForm = (mapConf, setStatus) => {
    if (
        !mapConf ||
        !mapConf.zoom ||
        isNaN(mapConf.zoom) ||
        !mapConf.pos ||
        !(Object.keys(mapConf.pos).length === 2) ||
        isNaN(mapConf.pos.lat) ||
        isNaN(mapConf.pos.lng)
    ) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage: "Invalid map position",
        })
        return false
    }
    if (mapConf.zoom <= 11) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage:
                "Invalid map position, carefully select where your golf course is",
        })
        return false
    }
    return true
}

export default function EditMapPicker(props) {
    const [status, setStatus] = useState(initStatus)

    const fieldOptions = useSelector((state) => state.firestore?.data?.field)

    const initMapConf = {
        pos: {
            lat: fieldOptions?.pos?.latitude,
            lng: fieldOptions?.pos?.longitude,
        },
        zoom: fieldOptions?.zoom,
    }
    const [mapConf, setMapConf] = useState(initMapConf)

    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    const handleMapConf = (any) => {
        setStatus(initStatus)
        setMapConf(any)
    }
    const settingsAccess = useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featSettings
                ?.access || state.firestore.data?.user?.admin
    )

    const handleSubmit = () => {
        if (!settingsAccess) {
            setStatus({ ...status, error: true })
            return
        }
        setStatus({ ...initStatus, loading: true })
        console.log(mapConf)
        const isCorrect = checkForm(mapConf, setStatus)
        if (!isCorrect) {
            return
        }
        db.collection(golfCourses)
            .doc(courseDocId)
            .update({
                pos: new GeoPoint(mapConf.pos.lat, mapConf.pos.lng),
                zoom: mapConf.zoom,
            })
            .then(() => {
                console.log("saved")
                setStatus({ ...status, loading: false, success: true })
                store.dispatch({ type: SET_SETTINGS_SAVED })
            })
            .catch((e) => {
                console.error(e.message)
                setStatus({
                    ...status,
                    error: true,
                    loading: false,
                    errorMessage: e.message,
                })
            })
    }
    useEffect(() => {
        async function count() {
            const countdown = await wait(3000)
            if (status.error) {
                setStatus(initStatus)
            }
            return () => clearTimeout(countdown)
        }
        count()
    }, [status.error])

    return (
        <Box
            sx={{ pr: 1, pl: 1, width: "100%", height: "100%" }}
            container="true"
            direction="row"
            alignItems="center"
            justifyContent={"center"}
        >
            <MapPicker
                fullWidth
                heigth={"calc(100vh - 220px)"}
                mapConf={mapConf}
                setMapConf={handleMapConf}
            />
            <Typography sx={{ mt: 1 }}>
                Adjust the map and zoom to match the red border with the course
                outer limits.
            </Typography>
            <SuccessLoader
                status={status}
                error={status.errorMessage}
                handleSubmit={handleSubmit}
                disabled={!settingsAccess}
                noAccess={!settingsAccess}
            >
                Submit new position
            </SuccessLoader>
        </Box>
    )
}
