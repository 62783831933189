import React from "react"
import { Grid, Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import CircleLoader from "../loader"
import {
    statusEnded,
    statusNotStarted,
    statusStarted,
} from "../../constants/statusTypes"
import { getLocale } from "../dateTools"
import { getCouponCode, getPrice } from "../Booking/bookingUtils"

export const getDateString = (date) => {
    const yeardateOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }
    return date.toLocaleDateString(getLocale(), yeardateOptions)
}
export const getHourMinString = (date) => {
    const yeardateOptions = {
        hour: "numeric",
        minute: "numeric",
    }
    return date.toLocaleTimeString(getLocale(), yeardateOptions)
}
const getDiscountString = (booking) => {
    if (booking.couponValid) {
        return "Discount"
    }
    if (booking.rpValid) {
        return "Coupon"
    }
    return "Discount"
}

const TableItem = (header, value) => {
    return (
        <Grid container direction="column">
            <Grid item xs>
                <Typography color="#DCDCDCC9">{header}</Typography>
            </Grid>
            <Grid item xs>
                <Typography fontSize={16} fontWeight={"bold"}>
                    {value}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default function BookingInfoCard(props) {
    const { loading, booking, bookingText } = props
    if (loading) {
        return <CircleLoader />
    }
    if (!booking) {
        return null
    }
    const appBooking =
        [statusNotStarted, statusStarted, statusEnded].indexOf(booking.status) >
        -1
    return (
        <Box sx={{ height: 180 + "px" }}>
            <Box>
                <Typography>{bookingText}</Typography>
            </Box>
            <Grid container spacing={4} direction="row">
                <Grid item xs={4}>
                    <Grid container direction="column" spacing={1}>
                        <Grid item xs sx={{ mt: 2 }}>
                            <Typography fontSize="32pt">
                                {booking.name}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Box>
                                {appBooking ? (
                                    <Typography
                                        color="#DCDCDCC9"
                                        fontSize="10pt"
                                    >
                                        {booking.phonePrefix} {booking.tlf}
                                    </Typography>
                                ) : (
                                    <Typography
                                        color="#DCDCDCC9"
                                        fontSize="10pt"
                                    >
                                        {booking.tlf}
                                    </Typography>
                                )}
                                {booking.email ? (
                                    <Typography
                                        color="#DCDCDCC9"
                                        fontSize="10pt"
                                    >
                                        {booking.email}
                                    </Typography>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Grid item xs>
                                    {TableItem(
                                        "Date",
                                        getDateString(
                                            booking.startTime.toDate()
                                        )
                                    )}
                                </Grid>
                                <Grid item xs>
                                    {TableItem(
                                        "Time",
                                        getHourMinString(
                                            booking.startTime.toDate()
                                        )
                                    )}
                                </Grid>
                                <Grid item xs>
                                    {TableItem("Category", booking.userSegment)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row">
                                <Grid item xs>
                                    {TableItem(
                                        "Booked in",
                                        appBooking ? "App" : "Dash"
                                    )}
                                </Grid>
                                <Grid item xs>
                                    {TableItem("Price", getPrice(booking))}
                                </Grid>
                                <Grid item xs>
                                    {TableItem(
                                        getDiscountString(booking),
                                        getCouponCode(booking)
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
BookingInfoCard.propTypes = {
    loading: PropTypes.bool,
    booking: PropTypes.object,
    bookingText: PropTypes.string,
}
