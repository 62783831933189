import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import LinearProgress from "@mui/material/LinearProgress"
import { ThumbsRow } from "./thumbRow"
import { golfCourses, storageImagesBasePath } from "../../constants/dbPaths"
import { storage } from "../../rootWrapper/firebase"
import redirectStyle from "../../assets/styles/components/redirectStyle"
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage"

const useStyles = makeStyles((theme) => ({
    headline: {
        margin: theme.spacing(2),
        color: theme.palette.secondary.light,
    },
}))

export default function Preview(props) {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [fileNames, setFileNames] = useState([])
    const classes = useStyles()
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const storage = getStorage()
    const listRef = ref(
        storage,
        `${storageImagesBasePath}/${golfCourses}/assets/${courseDocId}`
    )
    useEffect(() => {
        const items = []
        const fileNames = []
        listAll(listRef)
            .then((r) =>
                Promise.all(
                    r.items.map((item) =>
                        getDownloadURL(item).then((url) => {
                            items.push(url)
                            fileNames.push(item.fullPath)
                        })
                    )
                ).then(() => {
                    setItems(items)
                    setFileNames(fileNames)
                })
            )
            .then(() => {
                setLoading(false)
            })
    }, [])

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            items.forEach((file) => URL.revokeObjectURL(file))
        },
        [items]
    )

    function HeadLine() {
        if (items.length) {
            return (
                <Typography className={classes.headline} variant="h5">
                    Currently displayed images:
                </Typography>
            )
        }
        if (loading) {
            const classes = redirectStyle()
            return (
                <div className={classes.root}>
                    <LinearProgress color="secondary" />
                </div>
            )
        }
        return (
            <Typography className={classes.headline} variant="h5">
                There are currently no images displayed.
            </Typography>
        )
    }
    return (
        <div>
            <HeadLine />
            <ThumbsRow items={items} fileNames={fileNames} />
        </div>
    )
}
