import { Box, Divider, Grid, Switch, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"

const YesNoSwitch = ({ text, handleChange, value }) => {
    return (
        <Grid container alignItems={"center"} sx={{ m: 2 }} direction="row">
            <Grid item xs>
                <Typography fontSize={"16pt"}>{text}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Grid
                    container
                    component="label"
                    alignItems="center"
                    direction="row"
                    justifyContent="center"
                    sx={{ width: "140px" }}
                >
                    <Grid item xs sx={{ margin: 0 }}>
                        <Typography sx={{ marginRight: -5 }} fontWeight={"300"}>
                            No
                        </Typography>
                    </Grid>
                    <Grid item xs sx={{ margin: 0 }}>
                        <Switch
                            sx={{ marginLeft: -2 }}
                            onClick={handleChange}
                            checked={value}
                            disabled={false}
                            color="secondary"
                            align="right"
                        >
                            Press to turn on cart
                        </Switch>
                    </Grid>
                    <Grid item xs sx={{ margin: 0 }}>
                        <Typography sx={{ marginLeft: -1 }} fontWeight={"300"}>
                            Yes
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default YesNoSwitch
