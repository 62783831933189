import Grid from "@mui/material/Grid"
import React from "react"
import Button from "@mui/material/Button"

export function RebookableMessage(prop) {
    const { rebookCart } = prop
    return (
        <Grid container alignItems="center">
            <Grid item xs>
                <Button
                    color="secondary"
                    variant="outlined"
                    aria-label="rebook"
                    onClick={(e) => {
                        console.log("rebooking ")
                        rebookCart()
                    }}
                >
                    Rebook Cart
                </Button>
            </Grid>
        </Grid>
    )
}
