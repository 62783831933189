import React, { useState } from "react"
import { Grid, Box } from "@mui/material"
import { useSelector } from "react-redux"

import db from "../rootWrapper/firebase"
import { golfCourses } from "../constants/dbPaths"

import List from "@mui/material/List"

import NotificationRow from "../Components/Notifications/notificationRow"

const BASE_AMOUNT = 30

export default function notificationCenter() {
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )

    const notifications = useSelector(
        (state) => state.notifications.notifications
    )

    const setRead = (notification) => {
        db.collection(golfCourses)
            .doc(courseDocId)
            .collection("notifications")
            .doc(notification.notificationDocId)
            .update({ read: true })
    }

    return (
        <Box sx={{ minHeight: "200px" }}>
            <Grid container direction="row">
                <List
                    sx={{
                        width: "100%",
                        margin: 5,
                    }}
                >
                    {Object.keys(notifications).length
                        ? Object.keys(notifications).map((key, index) => (
                              <NotificationRow
                                  key={key}
                                  index={index}
                                  notification={notifications[index]}
                                  setRead={setRead}
                              />
                          ))
                        : null}
                </List>
            </Grid>
        </Box>
    )
}
