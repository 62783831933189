import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React from "react"
import { useSelector } from "react-redux"
import CircleLoader from "../../Components/loader"
import { intToString } from "../../Components/stringUtils/numberFormater"
import { CategoryUsage } from "../../Components/Charts/categoryUsage"

export const HelpQuestion = ({ tooltip }) => (
    <Tooltip
        title={<Typography fontSize="14pt">{tooltip}</Typography>}
        placement="top"
    >
        <Typography color="secondary" display={"inline-block"}>
            {"(?)"}
        </Typography>
    </Tooltip>
)
const fontSizeBig = 36
const fontSizeMedium = 24
const fontSizeSmall = 11

export default function IncomeSection() {
    const {
        incomeTotal,
        totalDashIncome,
        totalAppIncome,
        totalDiscount,
        totalRefund,
        totalCouponIncome,
        segmentSum,
    } = useSelector((state) => {
        return state.bookingHistory.stats || {}
    })

    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })

    const currency = useSelector(
        (state) => state.firestore.data?.field?.currency || ""
    )
    const { queryComplete } = query

    if (!query || !queryComplete) {
        return (
            <Box height={450 + "px"}>
                <CircleLoader />
            </Box>
        )
    }

    return (
        <Box position="relative">
            <Typography color="#DCDCDCC9">Key statistics fleet</Typography>
            <Box position="absolute" right={0} top={0}>
                <Tooltip
                    title={
                        <Typography fontSize="14pt">
                            Income statistics, divided into income from the app
                            and registered income in the dashboard.
                        </Typography>
                    }
                    placement="top"
                >
                    <IconButton size="small">
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box
                sx={{
                    p: 4,
                    pb: 0,
                }}
            >
                <Grid
                    container
                    alignItems={"flex-start"}
                    direction="row"
                    minHeight={400 + "px"}
                    sx={{ height: "100%" }}
                >
                    <Grid
                        item
                        container
                        direction={"column"}
                        xs={4}
                        minWidth="300px"
                    >
                        <Grid item xs>
                            <Typography display={"inline"} color="#DCDCDCC9">
                                Gross income{" "}
                            </Typography>
                            <HelpQuestion tooltip="Total income, sum of app income and dashboard registred bookings" />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                fontWeight={"bold"}
                                fontSize={fontSizeBig + "pt"}
                            >
                                {intToString(incomeTotal + totalCouponIncome)}{" "}
                                {currency?.toUpperCase()}
                            </Typography>
                        </Grid>
                        <Grid item xs container direction="row">
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                    >
                                        App Income{" "}
                                    </Typography>
                                    <HelpQuestion tooltip="All income from the app in the period" />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeMedium + "pt"}
                                    >
                                        {intToString(
                                            totalAppIncome + totalCouponIncome
                                        )}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                    >
                                        POS Income{" "}
                                    </Typography>
                                    <HelpQuestion tooltip="All income registered by web users in the booking page. The payment should be done in the courses POS (Point of Sales) system." />
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeMedium + "pt"}
                                    >
                                        {intToString(totalDashIncome)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="row" sx={{ mt: 2 }}>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                        fontSize={fontSizeSmall + "pt"}
                                    >
                                        Bookings{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeSmall + "pt"}
                                    >
                                        {intToString(totalAppIncome)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                        fontSize={fontSizeSmall + "pt"}
                                    >
                                        Coupons{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeSmall + "pt"}
                                    >
                                        {intToString(totalCouponIncome)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs
                                container
                                alignItems={"flex-start"}
                                direction="column"
                            >
                                <Grid item xs={3}>
                                    <Typography
                                        fontSize={fontSizeSmall + "pt"}
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                    >
                                        Bookings{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        fontSize={fontSizeSmall + "pt"}
                                        fontWeight={"bold"}
                                    >
                                        {intToString(totalDashIncome)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs container direction="row" sx={{ mt: 4 }}>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                    >
                                        Discount used/ given{" "}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeMedium + "pt"}
                                    >
                                        {intToString(totalDiscount)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs container direction="column">
                                <Grid item xs>
                                    <Typography
                                        display={"inline"}
                                        color="#DCDCDCC9"
                                    >
                                        Refunds
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography
                                        fontWeight={"bold"}
                                        fontSize={fontSizeMedium + "pt"}
                                    >
                                        {intToString(totalRefund)}{" "}
                                        {currency?.toUpperCase()}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs minWidth={"300px"}>
                        <Box>
                            <Typography display={"inline"} color="#DCDCDCC9">
                                By Category{" "}
                            </Typography>
                            <HelpQuestion tooltip="Total income, sum of app income and dashboard registred bookings" />
                            <CategoryUsage segmentSum={segmentSum} />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
