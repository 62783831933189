import React from "react"
import { Tooltip, Typography } from "@mui/material"

export default function PremiumTypography() {
    return (
        <Tooltip
            title={<Typography fontSize="14pt">{"Lorem ipsum"}</Typography>}
            placement="top"
        >
            <Typography
                sx={{ textDecoration: "underline" }}
                component="span"
                color="secondary"
            >
                Connect Keyless
            </Typography>
        </Tooltip>
    )
}
