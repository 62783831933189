import { Box, Typography } from "@mui/material"
import React from "react"
import BookingHistoryTable from "./bookingHistoryTable"

export default function BookingHistorySection() {
    return (
        <Box minHeight={"400px"}>
            <Typography>Booking history</Typography>
            <BookingHistoryTable />
        </Box>
    )
}
