import React from "react"
import { Box, Typography, Button } from "@mui/material"
import Icon from "@mui/material/Icon"
import ArrowIcon from "../../../assets/img/arrow.svg"
import makeStyles from "@mui/styles/makeStyles"
import { InstalledIcon, NotInstalledIcon } from "../installationMain"

const useStyles = makeStyles({
    imageIcon: {
        display: "flex",
        height: "inherit",
        width: "inherit",
    },
})

export const CartInstallationHeader = () => (
    <Box sx={{ margin: 0 }}>
        <Typography variant="h6">Vehicle Testing</Typography>
    </Box>
)

export default function IntroInstallationText() {
    const style = useStyles()
    return (
        <Box>
            <CartInstallationHeader />
            <Box>
                <br />

                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://vimeo.com/user/123743650/folder/9574286"
                >
                    <Button variant="contained" color="secondary">
                        Click for Installation Videos
                    </Button>
                </a>
                <br />
                <br />

                <Typography variant="body1">
                    Here are all the carts registered to your course. We will
                    test them one by one to make sure they are all installed
                    correctly.
                </Typography>
                <br />
                <Typography variant="body1">
                    If keyless access works and the SmartBox is connected to the
                    EAGL Cloud, the red box will turn green.
                </Typography>
                <Box
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                >
                    <Box>
                        <NotInstalledIcon />
                        <Icon sx={{ width: 100 + "px" }}>
                            <img src={ArrowIcon} className={style.imageIcon} />
                        </Icon>
                        <InstalledIcon />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
