import React from "react"
import { Box, LinearProgress, Typography } from "@mui/material"
import DataTimePicker from "../vehicleStatsRow/timeDataPicker"
import { useSelector } from "react-redux"

const HeaderWithCourseName = (props) => {
    const { header, courseName } = props

    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })

    const query = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })

    const { queryComplete } = query

    return (
        <Box>
            <Typography
                style={{ display: "inline-block", marginRight: "8px" }}
                fontSize={"24pt"}
                variant="h1"
                fontWeight={"bold"}
            >
                {header}:
            </Typography>
            <Typography
                style={{ display: "inline-block" }}
                fontSize={"24pt"}
                variant="h1"
            >
                {courseName}
            </Typography>
            <Box>
                {queryComplete ? null : <LinearProgress color="secondary" />}
                <Typography display={"inline-block"} sx={{ pr: 2 }}>
                    Period:
                </Typography>
                <DataTimePicker type="startVehicle" />
                <Typography display={"inline-block"} sx={{ pr: 2 }}>
                    {" - "}
                </Typography>
                <DataTimePicker type="endVehicle" />
            </Box>
        </Box>
    )
}

export default HeaderWithCourseName
