import React from "react"
import {
    statusAdmin,
    statusAppBooking,
    statusMaintenance,
} from "../../constants/statusTypes"
import { cartStyle } from "../../assets/styles/components/carts"
import theme from "../../assets/styles/muiTheme"

const classes = cartStyle
export const boxOpenWidth = 200
export const boxOpenHeight = 260
export const getColorStyle = (status) => {
    if (status === statusAdmin) {
        return "#00aaff"
    }
    if (status === statusMaintenance) {
        return "#CCCCCb"
    }
    if (statusAppBooking.indexOf(status) > -1) {
        return "#ffc200"
    }
    return "#dcdcdc"
}

export const getBorderStyle = (status) => {
    if (status === statusAdmin) {
        return classes.cartBorderAdmin
    }
    if (status === statusMaintenance) {
        return classes.cartBorderMaintenance
    }
    if (statusAppBooking.indexOf(status) > -1) {
        return classes.cartBorderApp
    }
    return classes.cartBorderNone
}
export const getMainColor = (av, engine) => {
    return classes.rootGray
}
const getBoxSize = (selected) => {
    if (selected) {
        return {
            height: "auto",
            width: boxOpenWidth + "px",
        }
    }
    return {
        height: "40px",
    }
}
export const getCartStyle = (av, engine, status, selected) => {
    const root = cartStyle.root()

    const mainColor = getMainColor(av, engine)
    const boarderStyle = getBorderStyle(status)
    const boxSize = getBoxSize(selected)
    let style = { ...root, ...mainColor, ...boarderStyle, ...boxSize }
    return style
}
