import { Timestamp } from "firebase/firestore"
import {
    statusAdmin,
    statusCancelled,
    statusEnded,
    statusExpired,
    statusFinished,
    statusMaintenance,
    statusPaid,
    statusStarted,
} from "../../constants/statusTypes"

export const getEndTime = (doc) => {
    if (doc.status === statusEnded && doc.actualEndTime) {
        return doc.actualEndTime
    }
    if (doc.status === statusExpired && doc.expiredAtTime) {
        return doc.expiredAtTime
    }
    if (doc.status === statusStarted && doc.endTime.toMillis() < Date.now()) {
        return Timestamp.now()
    }
    return doc.endTime
}

export const getPriceApp = (doc) => {
    if (!doc) {
        return 0
    }
    if (doc.capturedAmountWithRefund) {
        return Number(doc.capturedAmountWithRefund)
    }
    if (statusFinished.indexOf(doc.status) > -1 && doc.capturedAmount) {
        return Number(doc.capturedAmount)
    }
    if (statusFinished.indexOf(doc.status) > -1 && doc.customPrice) {
        return Number(doc.customPrice)
    }
    return 0
}

export const getPriceDash = (doc) => {
    if (doc && Object.keys(doc).indexOf("customPrice") > -1) {
        return !isNaN(parseInt(doc.customPrice)) ? Number(doc.customPrice) : 0
    }
    return 0
}

export const getDiscountAmount = (doc) => {
    if (doc.discountAmount) {
        return Number(doc.discountAmount)
    }
    return 0
}

export const getRefundAmountNumber = (doc) => {
    if (doc.refunded) {
        return Number(doc.refunded)
    }
    return 0
}

export const getPriceNumber = (doc) => {
    if (
        doc.status === statusMaintenance ||
        doc.userSegment === statusMaintenance
    ) {
        return 0
    }
    if (Object.keys(doc).indexOf("customPrice") > -1) {
        return !isNaN(parseInt(doc.customPrice)) ? Number(doc.customPrice) : 0
    }
    if (doc.capturedAmountWithRefund) {
        return doc.capturedAmountWithRefund
    }
    if (statusFinished.indexOf(doc.status) > -1) {
        return Number(doc.capturedAmount)
    }
    return 0
}

export const getRefundAmount = (doc) => {
    if (doc.refunded) {
        return doc.refunded
    }
    if (doc.refundedRidepass) {
        return "1 coupon"
    }
}
export const getDiscountedPrice = (doc) => {
    if (getRefundAmount(doc) === undefined) {
        return getPrice(doc)
    }
    if (doc.refundedRidepass) {
        return "0 coupon"
    }
    return getPrice(doc) - getRefundAmount(doc)
}

export const getPrice = (doc) => {
    if (!doc) {
        return "-"
    }
    if (
        doc?.status === statusMaintenance ||
        doc?.userSegment === statusMaintenance ||
        doc?.bookingType === statusMaintenance
    ) {
        return "-"
    }
    if (doc.rpValid) {
        return "1 coupon"
    }
    if (doc.bookingType === "admin" && doc.status === statusCancelled) {
        return 0
    }
    if (Object.keys(doc).indexOf("customPrice") > -1) {
        return !isNaN(parseInt(doc?.customPrice)) ? doc.customPrice : "Nan"
    }
    if (statusPaid.indexOf(doc?.status) > -1) {
        return doc.capturedAmount
    }
    if (doc.fixedPrice) {
        if (doc?.expectedCaptureAmount !== undefined) {
            return doc.expectedCaptureAmount
        }
        if (doc.classes) {
            const seg = Object.keys(doc.classes)[0]
            return Object.values(doc.classes[seg])[0] + doc.startingFee
        }
        return "-"
    }
    if (Object.keys(doc).indexOf("minuteRate") > -1) {
        return `${String(doc.startingFee)} + ${String(doc.minuteRate)}/min`
    }
    const seg = Object.keys(doc?.classes)?.[0]
    if (seg) {
        return `${String(doc.startingFee)} + ${String(
            Object.values(doc.classes[seg])[1]
        )}/min`
    }
    return "-"
}
export const getCouponCode = (doc) => {
    if (doc.couponValid) {
        return doc.coupon.code
    }
    if (doc.rpValid) {
        return doc.ridepass.name
    }
    return "-"
}
export const hasPayed = (doc) => {
    if (doc.status === statusAdmin) {
        return Boolean(doc.hasPayed)
    }
    return true
}
export const getIsOnOvertime = (doc) => {
    if (doc.status === statusStarted) {
        return doc.endTime.toMillis() < Date.now()
    }
    if (doc.status === statusEnded && doc.actualEndTime) {
        return doc.endTime.toMillis() <= doc.actualEndTime.toMillis()
    }
    return false
}
