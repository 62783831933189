import {
    logEvent,
    setAnalyticsCollectionEnabled,
    setConsent,
} from "firebase/analytics"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { requiredConsent } from "../../assets/analyticsConsent"
import { analytics } from "../../rootWrapper/firebase"
import store from "../../store"
import { SET_LOADED_COI } from "../../constants/actionTypes"

const getPathName = (path) => {
    let newPath = path
    if (newPath === "/") {
        return "index"
    }
    if (newPath.startsWith("/")) {
        newPath = newPath.slice(1)
    }
    return newPath
}
export default function LogView() {
    const coi = useSelector((state) => state.signin.coi)

    const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("coi="))

    useEffect(() => {
        // Initiial hook for user loading
        if (cookie) {
            const value = JSON.parse(cookie.split("=")[1])
            console.log(value)
            store.dispatch({ type: SET_LOADED_COI, payload: value })
        } else {
            store.dispatch({ type: SET_LOADED_COI })
        }
    }, [cookie])

    useEffect(() => {
        /** Update user if accepted but db does not match */
        if (!cookie && coi.completed) {
            console.log("Updating COI cookie", coi)
            document.cookie = `coi=${JSON.stringify(
                coi
            )}; max-age=31536000; secure; samesite=strict`
        }
    }, [cookie, coi.completed])

    useEffect(() => {
        // Update consent from coi
        if (coi.completed) {
            console.log("Stats on ?", coi.statistical)
            setAnalyticsCollectionEnabled(analytics, true)
            setConsent(requiredConsent(coi.statistical))
        }
    }, [coi.loaded, coi.completed, coi.statistical])

    useEffect(() => {
        // Logging of current path
        const path = getPathName(window.location.pathname)
        logEvent(analytics, "screen_view", {
            firebase_screen: path,
            firebase_screen_class: path,
        })
    }, [window.location.pathname])
}
