import React, { useState } from "react"
import { Card, CardActionArea, CardActions, Typography } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import { useSelector } from "react-redux"
import theme from "../../assets/styles/muiTheme"
import { functions } from "../../rootWrapper/firebase"
import { RIDEPASS_CHANGE } from "../../constants/actionTypes"
import store from "../../store"
import { httpsCallable } from "firebase/functions"

export const COUPONCARDWIDTH = 200
export const COUPONCARDHEIGHT = 200

export const getDateFromTs = (ts) => {
    const d = new Date(ts)
    return `${`0${d.getDate()}`.slice(-2)}-${`0${d.getMonth() + 1}`.slice(
        -2
    )}-${d.getFullYear()}`
}

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        width: `${COUPONCARDWIDTH}px`,
        height: props.height ? null : `${COUPONCARDHEIGHT}px`,
        backgroundColor: theme.palette.primary.main,
    }),
    cardAction: (props) => ({
        height: props.height ? null : `${COUPONCARDHEIGHT * 0.75}px`,
        overflowY: "hidden",
    }),
    cardContentAction: (props) => ({
        height: props.height ? null : `${COUPONCARDHEIGHT * 0.75}px`,
        overflowY: "hidden",
    }),
    cardGrid: (props) => ({}),
}))

export default function RidepassCard(props) {
    const {
        courseDocId,
        rpDocId,
        name,
        active,
        validFrom,
        validTo,
        segments,
        timesUsed,
        usageLimited,
        usageLimit,
        price,
        totalPurchases,
    } = props
    const currency = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("currency") > -1) {
            return field.currency.toUpperCase()
        }
        return "Nan"
    })
    const [heightExt, setHeight] = useState(false)
    const handleChangeHeight = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setHeight(!heightExt)
    }

    const handleButtonClick = (active) => {
        const updateCoupon = httpsCallable(functions, "updateRidepass")
        updateCoupon({
            active,
            rpDocId,
            courseDocId,
        })
            .then((r) => {
                store.dispatch({
                    type: RIDEPASS_CHANGE,
                    payload: { rpDocId, active },
                })
            })
            .catch((e) => console.error(e.message))
    }
    const classes = useStyles({ ...theme, height: heightExt })
    return (
        <Card className={classes.root} variant="outlined">
            <CardActionArea
                onClick={handleChangeHeight}
                className={classes.cardContentAction}
            >
                <CardContent className={classes.cardAction}>
                    <Typography gutterBottom variant="body1" component="h4">
                        {name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        Times Purchased: {totalPurchases}
                    </Typography>
                    <Grid
                        container
                        direction="column"
                        spacing={0.8}
                        className={classes.cardGrid}
                    >
                        <Grid item xs>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs>
                                    Valid From {getDateFromTs(validFrom)}
                                </Grid>
                                <Grid item xs>
                                    Valid To {getDateFromTs(validTo)}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="row" spacing={1}>
                                <Grid item xs>
                                    Price: <br /> {price} {currency}
                                </Grid>
                                <Grid item xs>
                                    Max uses:{" "}
                                    {usageLimited
                                        ? String(`${usageLimit}`)
                                        : "Unlimited"}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Grid container direction="column">
                                <Grid item xs>
                                    <Typography variant="body2">
                                        {" "}
                                        Valid Categories:{" "}
                                    </Typography>
                                </Grid>
                                {segments.map((x, index) => (
                                    <Grid key={index} item xs>
                                        <Typography variant="body2">
                                            {x}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
            <CardActions style={{ bottom: 0 }}>
                <>
                    <Button
                        size="small"
                        color="secondary"
                        fullWidth
                        onClick={() => handleButtonClick(!active)}
                    >
                        {active ? "Move to Archive" : "Move to Store"}
                    </Button>
                </>
            </CardActions>
        </Card>
    )
}
