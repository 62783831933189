import { useEffect, useState } from "react"
import SigninStatus from "../../../models/signinStatus"
import getSubscriptionStatus from "../../SubscriptionStatus"

export default function getSettingsRowPermissions() {
    const [stripeSub, setStripeSub] = useState(false)
    const { init, stripeBasedSub } = getSubscriptionStatus()
    const { courseData } = SigninStatus()
    const webAppIntegrationEnabled =
        courseData.webAppIntegrationEnabled || false
    const webIntegrationDisabled = !webAppIntegrationEnabled
    const stripeCompleted = courseData.stripeCompleted
    const nineholes = courseData.nineHolesEnabled

    useEffect(() => {
        setStripeSub(init && stripeBasedSub)
    }, [init, stripeBasedSub])

    const permissions = [
        stripeSub,
        webAppIntegrationEnabled,
        webIntegrationDisabled,
        stripeCompleted,
        nineholes,
    ]

    return permissions
}
