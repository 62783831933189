import { httpsCallable } from "firebase/functions"
import { useEffect, useState } from "react"
import SigninStatus from "../../models/signinStatus"
import { functions } from "../../rootWrapper/firebase"

const checkBillingAccess = httpsCallable(functions, "checkBillingAccess")

export default function CheckBillingAccess() {
    const [accessChecked, setAccessChecked] = useState(false)

    const { courseDocId } = SigninStatus()
    useEffect(() => {
        if (courseDocId) {
            checkBillingAccess({ courseDocId })
                .then(() => {
                    setAccessChecked(true)
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }, [courseDocId])

    return {
        accessChecked,
    }
}
