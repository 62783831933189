import React, { useEffect, useState } from "react"
import { Box, Button, LinearProgress, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"
import { useSelector } from "react-redux"
import theme from "../../assets/styles/muiTheme"

export default function StripeAccessLink(props) {
    const [link, setLink] = useState({
        loading: false,
        error: false,
        errMessage: "",
        url: "",
    })
    const courseData = useSelector(
        (state) => state.firestore?.data?.field || {}
    )

    const getLink = httpsCallable(functions, "getStripeLoginLink")

    useEffect(() => {
        if (courseData?.courseDocId && !link?.url && !link?.loading) {
            setLink((link) => ({ ...link, loading: true }))
            try {
                getLink({ courseDocId: courseData.courseDocId }).then((r) => {
                    const url = r.data.url
                    console.log(url)
                    setLink((link) => ({
                        ...link,
                        loading: false,
                        error: false,
                        errMessage: "",
                        url: url,
                    }))
                })
            } catch (e) {
                console.error(e.message)
                setLink((link) => ({
                    ...link,
                    loading: false,
                    error: true,
                    errMessage: e.message,
                    url: "",
                }))
            }
            console.log(courseData.courseDocId)
        }
    }, [courseData?.courseDocId, link?.loading, link?.url])

    return (
        <Box
            sx={{
                p: 4,
                maxWidth: "400px",
                bgcolor: theme.palette.primary.light,
                borderRadius: "30px",
            }}
        >
            <Typography variant="h3" fontSize={"18pt"}>
                Access the stripe dashboard to view available balance and
                upcoming payments
            </Typography>
            <Box sx={{ mt: 2 }}>
                {link.loading ? <LinearProgress color="secondary" /> : null}
                <Button
                    fullWidth
                    target="_blank"
                    variant="contained"
                    color="secondary"
                    disabled={link.loading || link.error}
                    href={link.url}
                >
                    Access Stripe Dashboard
                </Button>
            </Box>
        </Box>
    )
}
