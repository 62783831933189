import React, { useEffect } from "react"
import { Box, Grid } from "@mui/material"
import getSubscriptionStatus from "../../Components/SubscriptionStatus"
import BillingInfo from "./billingInfo"
import CardSection from "./cardSection"
import CancelSubscription from "./cancelSubscription"
import SubInvoices from "./subInvoices"
import CreatePayment from "../Register/RegisterCourseFlow/payment"
import SubPayouts from "./subPayouts"

function ReSubscribe(props) {
    const { status } = props

    if (status === "canceled") {
        return <CreatePayment />
    }
    return null
}

export default function Billing() {
    const { subscriptionStatus, subData, stripeBasedSub, subscription } =
        getSubscriptionStatus()

    useEffect(() => {
        console.log(subscriptionStatus)
        console.log(subData)
    }, [subscription, subData])

    if (!stripeBasedSub) {
        return null
    }

    return (
        <Box sx={{ p: 4 }}>
            <Grid container direction="column" spacing={4}>
                <Grid container direction="row" item xs>
                    <Grid item xs>
                        <BillingInfo />
                    </Grid>
                    <Grid item xs>
                        <CardSection />
                    </Grid>
                </Grid>
                <Grid item xs>
                    <ReSubscribe status={subscriptionStatus} />
                </Grid>
                <Grid container direction="column" item xs>
                    <Grid item xs>
                        <SubPayouts subData={subData} />
                    </Grid>
                    <Grid item xs>
                        <SubInvoices subData={subData} />
                    </Grid>
                </Grid>
            </Grid>
            <Box mt={4} p={2}>
                <CancelSubscription
                    subData={subData}
                    status={subscriptionStatus}
                />
            </Box>
        </Box>
    )
}
