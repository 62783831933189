import { useEffect, useState } from "react"
import { subscriptionsPath } from "../../constants/dbPaths"
import SigninStatus from "../../models/signinStatus"
import db from "../../rootWrapper/firebase"
/**
 * stripeBasedSub : boolean
 * keyless: boolean
 * subscriptionStatus =  
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid"
 * 
 */
export const activeSubStatus = ["trialing", "active", "past_due"]
export const blockNeedPayment = ["canceled", "unpaid"]
export const blockInitialPayment = ["incomplete_expired", "incomplete"]

export default function getSubscriptionStatus() {
    const [init, setInit] = useState(false)
    const [subscriptionStatus, setSubscriptionStatus] = useState()
    const [keyless, setKeyless] = useState(false)
    const [stripeBasedSub, setStripeBasedSub] = useState(false)
    const [initiatedSub, setInitiatedSub] = useState(false)
    const [subscription, setSubscription] = useState({})
    const [subData, setSubData] = useState({})
    const { authIsLoaded, courseDocId } = SigninStatus()

    useEffect(() => {
        let shouldUpdate = true
        if (authIsLoaded && courseDocId) {
            db.collection(subscriptionsPath)
                .doc(courseDocId)
                .onSnapshot(
                    (snap) => {
                        if (shouldUpdate) {
                            if (!snap.exists) {
                                setSubscriptionStatus("incomplete")
                            } else {
                                if (!snap.data().initiated) {
                                    setSubscriptionStatus("incomplete")
                                } else {
                                    setKeyless(snap.data().keyless)
                                    setStripeBasedSub(
                                        snap.data().stripeBasedSub
                                    )
                                    setSubscriptionStatus(snap.data().status)
                                    setInitiatedSub(snap.data().initiated)
                                    setSubscription(
                                        snap.data().stripeSubscription
                                    )
                                    setSubData(snap.data())
                                }
                            }
                            setInit(true)
                        }
                    },
                    (error) => {
                        console.error(error)
                    }
                )
        }
        return () => {
            shouldUpdate = false
        }
    }, [authIsLoaded, courseDocId])

    return {
        init,
        subscriptionStatus,
        keyless,
        stripeBasedSub,
        initiatedSub,
        subscription,
        subData,
    }
}
