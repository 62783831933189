import React, { useState } from "react"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import { Checkbox, Divider, FormControlLabel } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import {
    DialogActions,
    DialogContent,
    DialogTitle,
} from "../AvailabilityRebooking/popupAvailability"
import { StatusBooker } from "./statusBooker"
import { dateToHourMinute } from "../dateTools"
import store from "../../store"
import { UPDATE_BOOKING_VIEW } from "../../constants/actionTypes"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const useDialogStyles = makeStyles((theme) => ({
    overlay: {
        top: "44px",
        opacity: 1,
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        padding: "2%",
    },
}))

const styles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "25ch",
    },
    gridRow: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

export default function MoveDialog(props) {
    const {
        selected,
        cartDocId,
        bookingId,
        name,
        startTime,
        endTime,
        status,
        multiBookingId,
    } = useSelector((state) => state.bookingSelector)
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })
    const [options, setOptions] = useState({
        startTime: new Date(startTime),
        endTime: new Date(endTime),
    })
    const [multiSelect, setMultiSelect] = useState(false)
    const isMultiBooking = !!(multiBookingId && multiBookingId.length)

    const moveBooking = httpsCallable(functions, "moveBooking")
    const initialProgress = {
        inProgress: false,
        error: false,
        errorMessage: "",
        success: false,
    }
    const [currentProgress, setCurrentProgress] = useState(initialProgress)
    const style = useDialogStyles()
    const classes = styles()

    const submitMoveBooking = async () => {
        if (options.startTime.valueOf() > options.endTime.valueOf()) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "End time must be after start time",
            })
            return
        }
        setCurrentProgress({
            ...currentProgress,
            inProgress: true,
        })
        await moveBooking({
            startTime: options.startTime.valueOf(),
            endTime: options.endTime.valueOf(),
            courseDocId,
            bookingDocId: bookingId,
            cartDocId,
            multiSelect,
            multiBookingId,
        })
            .then(() => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: true,
                })
                store.dispatch({
                    type: UPDATE_BOOKING_VIEW,
                })
            })
            .catch((e) => {
                setCurrentProgress({
                    ...currentProgress,
                    inProgress: false,
                    success: false,
                    error: true,
                    errorMessage: e.message,
                })
            })
        await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
            props.handleClose()
        })
    }

    const cartId = useSelector((state) => {
        if (Object.keys(state.firestore.data.carts).indexOf(cartDocId) > -1) {
            return state.firestore.data.carts[cartDocId].id
        }
        return null
    })
    const handleTimeChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        const values = value.split(":")
        const hours = parseInt(values[0])
        const minutes = parseInt(values[1])
        if (
            !(
                Object.prototype.toString.call(options.startTime) ===
                    "[object Date]" && options.startTime.valueOf()
            )
        ) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid start time",
            })
            return
        }
        if (
            !(
                Object.prototype.toString.call(options.endTime) ===
                    "[object Date]" && options.endTime.valueOf()
            )
        ) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid end time",
            })
            return
        }
        if (
            hours !== parseInt(hours, 10) ||
            minutes !== parseInt(minutes, 10)
        ) {
            setCurrentProgress({
                ...currentProgress,
                error: true,
                errorMessage: "Invalid Time",
            })
            return
        }

        if (name === "open") {
            const newTime = new Date(options.startTime.setHours(hours, minutes))
            setOptions({ ...options, startTime: newTime })
        } else if (name === "close") {
            let newTime = new Date(options.endTime.setHours(hours, minutes))
            if (
                newTime.getTime() >
                options.startTime.getTime() + 24 * 60 * 60 * 1000
            ) {
                newTime = new Date(newTime.getTime() - 24 * 60 * 60 * 1000)
            }
            setOptions({ ...options, endTime: newTime })
        }
        setCurrentProgress({
            ...currentProgress,
            error: false,
            errorMessage: "",
        })
    }
    function CheckboxMultiBooking() {
        if (isMultiBooking) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={multiSelect}
                            onChange={() => setMultiSelect(!multiSelect)}
                            name="multi-delete"
                        />
                    }
                    label="Apply to group"
                />
            )
        }
        return null
    }

    return (
        <div>
            <Dialog
                classes={{ paper: style.overlay }}
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                variant="outlined"
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={props.handleClose}
                >
                    <Typography variant="inherit" align="center">
                        {`Move booking for ${name}, Vehicle No. ${cartId}`}
                    </Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DialogActions
                        style={{ justifyContent: "center", display: "flex" }}
                    >
                        <Grid
                            className={classes.gridRow}
                            container
                            direction="row"
                        >
                            <Grid item xs>
                                <TextField
                                    id="start-time"
                                    label="Booking start time"
                                    type="time"
                                    defaultValue={dateToHourMinute(
                                        options.startTime
                                    )}
                                    name="open"
                                    className={classes.textField}
                                    color="primary"
                                    onChange={handleTimeChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{
                                        color: "secondary",
                                        backgroundColor: "primary",
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <TextField
                                    id="close-time"
                                    label="Booking end time"
                                    type="time"
                                    defaultValue={dateToHourMinute(
                                        options.endTime
                                    )}
                                    name="close"
                                    className={classes.textField}
                                    onChange={handleTimeChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="column">
                            <Grid item xs>
                                {CheckboxMultiBooking()}
                                <StatusBooker
                                    currentProgress={currentProgress}
                                    defaultText="Move booking"
                                    doBooking={() => submitMoveBooking()}
                                />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>
    )
}
