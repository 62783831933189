import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { Provider } from "react-redux"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import App from "./App"
import theme from "./assets/styles/muiTheme"
import store, { rrfProps } from "./store"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

ReactDOM.render(
    <BrowserRouter>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <ReactReduxFirebaseProvider {...rrfProps}>
                        <App />
                    </ReactReduxFirebaseProvider>
                </Provider>
            </ThemeProvider>
        </StyledEngineProvider>
    </BrowserRouter>,
    document.getElementById("root")
)

serviceWorkerRegistration.register()
