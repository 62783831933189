import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage"
import { useEffect, useState } from "react"
import { financeReportPath } from "../../constants/dbPaths"
import SigninStatus from "../../models/signinStatus"
import CheckBillingAccess from "./checkAccess"

export default function getPayoutDocuments() {
    const { accessChecked } = CheckBillingAccess()
    const { courseDocId, courseData } = SigninStatus()
    const [documents, setDocuments] = useState({})
    const [loading, setLoading] = useState(true)

    const accountId = courseData.account_Id
    useEffect(() => {
        if (courseDocId && accountId && accessChecked) {
            if (!Object.keys(documents).length) {
                setLoading(true)
                const storage = getStorage()
                const listRef = ref(
                    storage,
                    `${financeReportPath}/accounts/${accountId}`
                )
                const items = {}
                listAll(listRef).then((r) =>
                    Promise.all(
                        r.items.map(async (item) =>
                            getDownloadURL(item).then(async (url) => {
                                const blob = await fetch(url).then((res) =>
                                    res.blob()
                                )
                                items[item.name] = URL.createObjectURL(blob)
                            })
                        )
                    ).then(() => {
                        setDocuments(items)
                        setLoading(false)
                        console.log("payouturls", items)
                    })
                )
            }
        }
    }, [courseDocId, accessChecked])

    return {
        documents,
        loading,
    }
}
