import React from "react"
import { Box, Typography, Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { sortCarts } from "../../timeline"

export default function InstallOverviewRetryText() {
    const navigate = useNavigate()
    const { cartsComplete, currentCart } = useSelector(
        (state) => state.installation
    )

    const carts = useSelector((state) => state.firestore.data.carts)
    const getAllCarts = () => {
        const allCarts = Object.keys(cartsComplete)
            .sort((x, y) => sortCarts(carts[x], carts[y]))
            .reduce((obj, key) => {
                return [...obj, key]
            }, [])
        return allCarts
    }
    const getCurrentIndex = () => {
        const allCarts = getAllCarts()
        return Object.values(allCarts).reduce((x, current, ind) => {
            if (carts[current].boardId === currentCart.boardId) {
                return ind
            }
            return x
        }, -1)
    }
    const getAllCartsSortedAfterCurrent = () => {
        const carts = getAllCarts()
        const index = getCurrentIndex()
        const cartsBefore = carts.slice(0, index)
        const cartsAfter = carts.slice(index + 1, carts.length)
        return [...cartsAfter, ...cartsBefore]
    }
    const getUnfinishedCarts = () => {
        return Object.keys(cartsComplete)
            .sort((x, y) => sortCarts(carts[x], carts[y]))
            .reduce((obj, key) => {
                if (!cartsComplete[key]) {
                    return [...obj, key]
                }
                return obj
            }, [])
    }
    const getNextCart = () => {
        const cartsAfterThis = getAllCartsSortedAfterCurrent()
        const unfinishedCarts = getUnfinishedCarts()
        if (unfinishedCarts.length > 0) {
            const cartIdsOver = cartsAfterThis
                .map((cartDocId) => {
                    if (unfinishedCarts.indexOf(cartDocId) > -1) {
                        return cartDocId
                    }
                    return null
                })
                .filter((x) => x !== null)
            if (cartIdsOver.length) {
                return carts[cartIdsOver[0]].boardId
            }
            return carts[cartsAfterThis[0]].boardId
        }
        return null
    }
    const handleNext = async () => {
        const nextCart = getNextCart()
        navigate("../cart/" + nextCart)
    }
    return (
        <Box>
            <Typography variant="body1">
                This is the overview of completed carts.
            </Typography>
            <br />
            <Typography variant="body1">
                The carts marked in red did not complete testing.
            </Typography>
            <Button
                onClick={handleNext}
                color="secondary"
                variant="contained"
                fullWidth
            >
                Go to unfinished
            </Button>
        </Box>
    )
}
