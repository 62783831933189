import React, { useState } from "react"
import { Box, Button, Grid, Link, Typography } from "@mui/material"
import UpgradePromoTextBox from "./promoTextBox"
import theme from "../../assets/styles/muiTheme"
import CircleLoader from "../../Components/loader"
import PromoStepper from "./promoStepper"
import SubTrialPromoBox from "../../Components/SubTrialPromoBox"
import { useNavigate } from "react-router-dom"

const loadIfNoValue = (element, value = null) => {
    if (value === null) {
        if (element) {
            return element
        }
    }
    if (value) {
        return element
    }
    return <CircleLoader />
}
export const TermBox = () => (
    <Typography>
        Please read the terms before signing up. The terms can be found{" "}
        <Link
            target="_blank"
            color={"secondary"}
            href="https://www.eagl.app/club-terms"
        >
            here
        </Link>
        .
    </Typography>
)

export default function UpgradePay(props) {
    const { subData, upgradeModel, payMonthly, setPayMonthly } = props
    const code = subData?.upgrade?.to
    const navigate = useNavigate()
    return (
        <Box sx={{ minHeight: "100vh" }}>
            <Grid
                sx={{ height: "100%" }}
                container
                item
                direction="column"
                spacing={2}
                xs
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Grid item xs>
                    <Box sx={{ m: 2, p: 4 }}>
                        {loadIfNoValue(
                            <Typography
                                fontWeight={800}
                                fontSize="18pt"
                                textAlign={"center"}
                                p={1}
                            >
                                .EAGL{" "}
                                <Typography
                                    fontWeight={"inherit"}
                                    component="span"
                                    display={"inline-block"}
                                    color="secondary"
                                    fontSize="inherit"
                                >
                                    {upgradeModel?.headerText}
                                </Typography>{" "}
                            </Typography>,
                            upgradeModel?.headerText
                        )}
                    </Box>
                </Grid>
                <Grid item style={{ width: "600px", minWidth: "60%" }} xs>
                    <Box
                        width={"100%"}
                        sx={{
                            borderColor: theme.palette.secondary.main,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "4px",
                            pr: 4,
                            pl: 4,
                            pb: 4,
                            pt: 4,
                            backgroundColor: theme.palette.primary.main,
                            textAlign: "left",
                        }}
                    >
                        <Box maxWidth={"90%"} p="2">
                            {loadIfNoValue(
                                <Typography
                                    color="#DCDCDC"
                                    pb={2}
                                    fontSize={"16pt"}
                                >
                                    Congratulations!
                                    <br /> You qualify for{" "}
                                    <Typography
                                        component={"span"}
                                        display={"inline-block"}
                                        fontSize="inherit"
                                        textAlign={"center"}
                                    >
                                        .EAGL{" "}
                                        <Typography
                                            component="span"
                                            display={"inline-block"}
                                            color="secondary"
                                            fontSize="inherit"
                                        >
                                            {upgradeModel?.headerText}
                                        </Typography>
                                        !
                                    </Typography>
                                </Typography>,
                                upgradeModel?.headerText
                            )}
                        </Box>
                        <UpgradePromoTextBox />
                    </Box>
                </Grid>
                <Grid item xs sx={{ minWidth: "90%" }}>
                    <PromoStepper />
                </Grid>

                <Grid item xs sx={{ minWidth: "80%" }}>
                    <SubTrialPromoBox
                        code={code}
                        payMonthly={payMonthly}
                        setPayMonthly={setPayMonthly}
                    />
                </Grid>
                <Grid item xs m={2}>
                    <TermBox />
                </Grid>
                <Grid item xs sx={{ minWidth: "80%" }} mb={8}>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => navigate("pay")}
                    >
                        I want Keyless!
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
