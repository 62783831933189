import { Box, TextField } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import store from "../../../../store"
import { minutesToHourMin } from "./unlockTimeItem"

const title = <>Booking reservation period (9 holes)</>

const desc = (
    <>
        The total time reserved for a booking of a vehicle. Should allow any
        player to finish their 9 hole round with a good margin, but not finish
        18 holes.
    </>
)

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const unlockDur = currentForm.shortTMP || {}
    const currentHours = unlockDur.hr
    const currentMinutes = unlockDur.min
    return `${currentHours} hours ${currentMinutes} minutes`
}

function ReservedPlayingTimeNineHolesItem() {
    const currentForm = useSelector((state) => state.settingsForm)

    let shortTMP = {}
    if (currentForm.shortTMP) {
        shortTMP = currentForm.shortTMP
    } else {
        const hourMin = minutesToHourMin(currentForm.short)
        shortTMP = {
            hr: Math.floor(hourMin.hours),
            min: Number(hourMin.minutes.toFixed(2)),
        }
    }
    useEffect(() => {
        if (currentForm.settingsLoaded && !currentForm.shortTMP) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "shortTMP",
                    value: {
                        ...shortTMP,
                    },
                },
            })
        }
    }, [currentForm.settingsLoaded, currentForm.shortTMP])
    const currentHours = shortTMP.hr
    const currentMinutes = shortTMP.min

    const handleInputChangeHr = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "shortTMP",
                    value: {
                        hr: Math.min(parseInt(value), 23),
                        min: shortTMP.min || 0,
                    },
                },
            })
        }
    }
    const handleInputChangeMin = (e) => {
        let value = e?.target?.value
        if (!isNaN(value) && value > -1) {
            store.dispatch({
                type: SELECTSETTINGSFIELD,
                payload: {
                    key: "shortTMP",
                    value: {
                        hr: shortTMP.hr || 0,
                        min: Math.min(Number(parseFloat(value).toFixed(2)), 59),
                    },
                },
            })
        }
    }

    return (
        <Box
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
        >
            <TextField
                id="reserved"
                label="Hours"
                size="small"
                value={!isNaN(currentHours) ? currentHours : ""}
                name="reserved"
                type="number"
                variant="standard"
                onChange={handleInputChangeHr}
                margin="normal"
                sx={{ width: "45%" }}
                inputProps={{
                    min: 0,
                    max: 12,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TextField
                id="reserved"
                label="Minutes"
                size="small"
                value={!isNaN(currentMinutes) ? currentMinutes : ""}
                name="reserved"
                type="number"
                variant="standard"
                onChange={handleInputChangeMin}
                sx={{ width: "45%" }}
                margin="normal"
                inputProps={{
                    min: 0,
                    max: 60,
                    step: 1,
                }}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: ReservedPlayingTimeNineHolesItem,
}
