import React from "react"
import { ReactComponent as GolfCartIcon } from "./img/GolfCart.svg"
import { ReactComponent as GolfCartLIcon } from "./img/GolfCartLong.svg"
import { ReactComponent as ScooterIcon } from "./img/Scooter.svg"
import { ReactComponent as MarshallIcon } from "./img/Marshall.svg"
import { ReactComponent as Wheeler4Icon } from "./img/4Wheeler.svg"
import { ReactComponent as FinnScooter } from "./img/finnScooter.svg"
import { Select, Grid, MenuItem, Typography } from "@mui/material"

export const options = [
    { value: "0", label: "Golf Cart", icon: GolfCartIcon },
    { value: "1", label: "Scooter", icon: ScooterIcon },
    { value: "2", label: "4 Wheeler", icon: Wheeler4Icon },
    { value: "3", label: "Golf Cart Long", icon: GolfCartLIcon },
    { value: "4", label: "Marshall", icon: MarshallIcon },
    { value: "5", label: "Golf Cart", icon: GolfCartIcon },
    { value: "6", label: "Finn Scooter", icon: FinnScooter },
]

function CartIconSelector(props) {
    const { textStyle, onChange } = props
    return (
        <Select
            fullWidth
            size="small"
            variant="outlined"
            required
            style={{
                height: 42,
                marginLeft: "4px",
                marginRight: "4px",
                marginTop: "5px",
            }}
            defaultValue={""}
            onChange={onChange}
            placeholder="Icon"
            color="secondary"
            sx={{
                ...textStyle,
            }}
        >
            {Object.values(options).map((option) => {
                const Icon = option.icon

                return (
                    <MenuItem key={option.value} value={option.value}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid container item xs={3} alignItems="center">
                                <Icon
                                    fill="#DCDCDC"
                                    style={{ width: "30px" }}
                                />
                            </Grid>
                            <Grid item xs style={{ width: "50px" }}>
                                <Typography sx={{ width: "50px" }}>
                                    {option.label}
                                </Typography>
                            </Grid>
                        </Grid>
                    </MenuItem>
                )
            })}
        </Select>
    )
}

export default CartIconSelector
