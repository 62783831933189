import React, { useEffect, useState } from "react"
import {
    Typography,
    TableBody,
    TableRow,
    TableCell,
    Box,
    Table,
    Divider,
} from "@mui/material"
import { styled } from "@mui/styles"
import { useSelector } from "react-redux"
import { dateToHourMinute } from "../../dateTools"
import getNameFromBooking from "../../Booking/helperfunctions/getNameFromBooking"
import getTlfFromBooking from "../../Booking/helperfunctions/getTlfFromBooking"
import { getCouponCode, getPrice } from "../../Booking/bookingUtils"
import LinearProgress from "@mui/material/LinearProgress"

const TableStyled = styled(Table)(({ theme }) => ({
    height: "40px",
    width: "100%",
}))
const TableBodyStyled = styled(TableBody)(({ theme }) => ({
    width: "100%",
    height: "40px",
    borderCollapse: "collapse",
    borderSpacing: 0,
    display: "table",
    tableLayout: "fixed",
}))
const TableRowStyled = styled(TableRow)(({ theme }) => ({
    border: "none",
}))

const TableCellStyle = styled(TableCell)(({ theme }) => ({
    border: "none",
    padding: 0,
    height: "100%",
    textAlign: "center",
    verticalAlign: "center",
}))
const TableCellContent = styled("div")(() => ({
    height: "100%",
    width: "100%",
    wordWrap: "anywhere",
    textAlign: "left",
}))

const TableCellContentCenter = styled("div")(() => ({
    height: "100%",
    width: "100%",
    wordWrap: "anywhere",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
}))

const TableCellStyled = ({ colSpan, rowSpan, children }) => (
    <TableCellStyle rowSpan={rowSpan} colSpan={colSpan}>
        <TableCellContent>{children}</TableCellContent>
    </TableCellStyle>
)

const TableCellCenter = ({ colSpan, rowSpan, children }) => (
    <TableCellStyle rowSpan={rowSpan} colSpan={colSpan}>
        <TableCellContentCenter>{children}</TableCellContentCenter>
    </TableCellStyle>
)

const TypographyCell = styled(Typography)(({ theme }) => ({
    border: "none",
    fontSize: "9pt",
}))

const TypographyCellRight = styled(Typography)(({ theme }) => ({
    border: "none",
    fontSize: "9pt",
    textAlign: "right",
}))

export default function CurrentBookingRow(props) {
    const { cartDocId } = props

    const currency = useSelector(
        (state) => state.firestore?.data?.field?.currency
    )

    const currentBooking = useSelector(
        (state) => state.cartReadings?.[cartDocId]?.booking || {}
    )

    const { startTime, endTime, userSegment } = currentBooking

    const totalTime = endTime?.toMillis() - startTime?.toMillis()
    const duration = Date.now() - startTime?.toMillis()
    const percentage = duration / totalTime
    const [minutes, setMinutes] = useState(duration / (1000 * 60))
    const [hourMin, setHourMin] = useState([0, 0])
    useEffect(() => {
        const min = Math.floor(minutes)
        const hour = Math.floor(min / 60)
        const mins = Math.floor(min % 60)
        setHourMin([hour, mins])
    }, [minutes])

    useEffect(() => {
        const timer = setInterval(() => {
            setMinutes((min) => min + 1)
        }, 60 * 1000)
        return () => clearInterval(timer)
    }, [])

    if (!currentBooking || Object.keys(currentBooking).length === 0) {
        return null
    }
    const name = getNameFromBooking(currentBooking)
    const tlf = getTlfFromBooking(currentBooking)
    const price = getPrice(currentBooking)
    const discountCode = getCouponCode(currentBooking)

    return (
        <>
            <Divider orientation="horizontal" />
            <Box style={{ padding: "8px" }}>
                <TableStyled>
                    <TableBodyStyled>
                        <TableRowStyled>
                            <TableCellStyled colSpan={2}>
                                <Typography
                                    fontSize={"10pt"}
                                    textAlign="center"
                                    color="#DCDCDC"
                                >
                                    Current Booking
                                </Typography>
                            </TableCellStyled>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellStyled>
                                <TypographyCell>{name}</TypographyCell>
                            </TableCellStyled>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellStyled>
                                <TypographyCell>{tlf}</TypographyCell>
                            </TableCellStyled>
                            <TableCellStyled>
                                <TypographyCellRight>
                                    {discountCode}
                                </TypographyCellRight>
                            </TableCellStyled>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellStyled>
                                <TypographyCell>{userSegment}</TypographyCell>
                            </TableCellStyled>
                            <TableCellStyled>
                                <TypographyCellRight>
                                    {currency} {price}
                                </TypographyCellRight>
                            </TableCellStyled>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellStyled colSpan={2}>
                                <Typography
                                    textAlign={"center"}
                                    fontSize={"18pt"}
                                >
                                    <Typography
                                        fontSize={"inherit"}
                                        fontWeight="600"
                                        component="span"
                                    >
                                        {hourMin[0] > 9
                                            ? hourMin[0]
                                            : "0" + hourMin[0]}
                                    </Typography>
                                    h{" "}
                                    <Typography
                                        fontSize={"inherit"}
                                        fontWeight="600"
                                        component="span"
                                    >
                                        {hourMin[1] > 9
                                            ? hourMin[1]
                                            : "0" + hourMin[1]}
                                    </Typography>
                                    m
                                </Typography>
                            </TableCellStyled>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellCenter colSpan={2}>
                                <Box style={{ width: "90%" }}>
                                    <LinearProgress
                                        style={{ borderRadius: "10px" }}
                                        variant="determinate"
                                        color="secondary"
                                        value={percentage * 100}
                                    />
                                </Box>
                            </TableCellCenter>
                        </TableRowStyled>
                        <TableRowStyled>
                            <TableCellStyled>
                                <TypographyCell>
                                    {dateToHourMinute(startTime.toDate())}
                                </TypographyCell>
                            </TableCellStyled>
                            <TableCellStyled>
                                <TypographyCellRight>
                                    {dateToHourMinute(endTime.toDate())}
                                </TypographyCellRight>
                            </TableCellStyled>
                        </TableRowStyled>
                    </TableBodyStyled>
                </TableStyled>
            </Box>
        </>
    )
}
