import { TextField } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SETSETTINGSTIMEUPDATED } from "../../../../constants/actionTypes"
import store from "../../../../store"
import { intToHourDate } from "../../settingsForm"

const title = "Last flight time"
const desc = "The time of the first flight of the day"

function CurrentValue() {
    const currentForm = useSelector((state) => state.settingsForm)
    const time = intToHourDate(currentForm.openingTimes?.close)
    return time
}
function CloseTimeItem() {
    const currentForm = useSelector((state) => state.settingsForm)
    const vehicleGroups = currentForm?.vehicleGroups || {}
    const bookableGroups = Object.keys(vehicleGroups).filter(
        (x) => vehicleGroups[x].bookable
    )
    const groups = bookableGroups.length

    const handleInputChange = (vehicleGroup) => (e) => {
        e.preventDefault()
        const { name, value } = e.target
        const number = parseInt(value.replace(":", ""))
        store.dispatch({
            type: SETSETTINGSTIMEUPDATED,
            payload: { key: name, value: number, vehicleGroup },
        })
    }

    return (
        <>
            {bookableGroups.map((vehicleGroup, index) => {
                const { name, close } = vehicleGroups[vehicleGroup]
                const fieldName = groups > 1 ? `${name}` : "Last flight"
                return (
                    <TextField
                        key={name}
                        id="close-time"
                        label={fieldName}
                        type="time"
                        variant="standard"
                        value={intToHourDate(close)}
                        name={"close"}
                        fullWidth={groups === 1}
                        sx={{ pr: groups > 1 && index === 0 ? 2 : 0 }}
                        onChange={handleInputChange(vehicleGroup)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                )
            })}
        </>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: CloseTimeItem,
}
