import React, { useEffect } from "react"
import db from "../../rootWrapper/firebase"
import { serviceStatusPath } from "../../constants/dbPaths"
import store from "../../store"
import { SET_SERVICE_STATE } from "../../constants/actionTypes"
import StatusBar from "./statusBar"

export default function StatusRow() {
    useEffect(() => {
        db.collection(serviceStatusPath)
            .doc("status")
            .onSnapshot(
                (res) => {
                    if (!res.exists) {
                        console.error("Could not find status doc")
                        return
                    }
                    const data = res.data()
                    store.dispatch({
                        type: SET_SERVICE_STATE,
                        payload: {
                            ...data,
                        },
                    })
                },
                (error) => {
                    console.error(error)
                }
            )
    }, [])

    return <StatusBar />
}
