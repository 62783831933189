import React from "react"
import { useSelector } from "react-redux"
import SettingTopbar from "../Components/settingsTabs/settingTab"
import CourseSetting from "../Components/settingsForm/settingsForm"
import CourseImage from "../Components/settingsForm/settingsImage"
import DetailsForm from "../Components/detailsEditor"
import { useSettingsStyles } from "../assets/styles/views/settingStyle"
import Permissions from "./permissions"
import AppInfoForm from "../Components/settingsAppInfo"
import EditMapPicker from "../Components/PositionPicker/mapUpdate"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import Billing from "./billing"
import getSubscriptionStatus from "../Components/SubscriptionStatus"
import NoSubBilling from "./billing/noSubBilling"
import CoursePricing from "../Components/settingsForm/settingsPricePage"
import InstallationMenu from "../Components/Installer/installationMain"
import { viewInstallerPage } from "../constants/accessPermissions"
import SigninStatus from "../models/signinStatus"

const settings = [
    {
        name: "General",
        path: "general",
        component: <CourseSetting />,
        requires: [],
    },
    {
        name: "Pricing",
        path: "pricing",
        component: <CoursePricing />,
        requires: [],
    },
    {
        name: "Details",
        path: "details",
        component: <DetailsForm />,
        requires: [],
    },
    {
        name: "Rental Info",
        path: "rentalinfo",
        component: <AppInfoForm />,
        requires: [],
    },
    {
        name: "Map Config",
        path: "mapconf",
        component: <EditMapPicker />,
        requires: [],
    },
    {
        name: "Payouts",
        path: "payouts",
        component: <Billing />,
        requires: [1, 2],
    },
    {
        name: "Payouts",
        path: "payouts",
        component: <NoSubBilling />,
        requires: [1, 3],
    },
    {
        name: "Images",
        path: "images",
        component: <CourseImage />,
        requires: [],
    },
    {
        name: "Installation",
        path: "install",
        component: <InstallationMenu />,
        requires: [4],
    },
    {
        name: "Permissions",
        path: "permissions",
        component: <Permissions />,
        requires: [0],
    },
]

function isAdmin() {
    return useSelector((state) => state.firestore.data?.user?.admin)
}

function billingAccess() {
    return useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featBilling
                ?.access ||
            state.firestore.data?.user?.accessPermissions?.viewBilling
                ?.access ||
            state.firestore.data?.user?.admin
    )
}

function hasBilling() {
    const { stripeBasedSub } = getSubscriptionStatus()
    return stripeBasedSub
}

function notHasBilling() {
    const { stripeBasedSub } = getSubscriptionStatus()
    return !stripeBasedSub
}

function hasInstallerPermission() {
    const { userHasPermission } = SigninStatus()
    return userHasPermission(viewInstallerPage)
}

function AccessToSetting(route, security) {
    for (let i = 0; i < route.requires.length; i++) {
        const securityRule = route.requires[i]
        if (i >= security.length || !security[securityRule]) {
            return false
        }
    }
    return true
}

function RouteComp(route) {
    return (
        <Route
            key={route.path}
            path={route.path + "/*"}
            element={route.component}
        />
    )
}

export default function Settings() {
    const classes = useSettingsStyles()
    const navigate = useNavigate()
    const securitySettings = () => [
        isAdmin(),
        billingAccess(),
        hasBilling(),
        notHasBilling(),
        hasInstallerPermission(),
    ]
    const filteredSettings = settings.filter((x) =>
        AccessToSetting(x, securitySettings())
    )
    const getCurrentPathNumber = () => {
        const location = useLocation()
        const { pathname } = location
        for (let i = 0; i < filteredSettings.length; i++) {
            const route = filteredSettings[i]
            if (pathname.includes(route.path)) {
                return i
            }
        }
        return 0
    }

    const handleChange = (_, val) => {
        if (val in filteredSettings) {
            navigate("/settings/" + filteredSettings[val].path)
        }
    }

    return (
        <div className={classes.root}>
            <SettingTopbar
                settings={filteredSettings.map((x) => x.name)}
                allSettings={settings.map((x) => x.name)}
                settingFocused={getCurrentPathNumber()}
                handleChange={handleChange}
                variant="scrollable"
            />
            <Routes>
                <Route key={0} index element={settings[0].component} />
                {filteredSettings.map((route) => RouteComp(route))}
            </Routes>
        </div>
    )
}
