import {
    Box,
    Button,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { SET_REPORT_LIMIT } from "../../constants/actionTypes"
import store from "../../store"
import { getLocale } from "../dateTools"
import getReportDocuments from "./getReports"
import ReportController from "./reportController"

const increaseLimit = () => {
    store.dispatch({ type: SET_REPORT_LIMIT })
}

const getLink = (id, documents) => {
    const link = Object.keys(documents).filter((x) => x.includes(id))
    if (link.length) {
        return documents[link[0]]
    }
    return null
}

export default function ReportTable() {
    ReportController()
    const { documents, loading } = getReportDocuments()
    const { reports, reportLimit, reportLoaded } = useSelector((state) => {
        return state.bookingHistory || {}
    })

    return (
        <Box>
            {loading || !reportLoaded ? (
                <LinearProgress color="secondary" />
            ) : null}
            <TableContainer sx={{ maxHeight: 204 }} component={Paper}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Creation Date</TableCell>
                            <TableCell>Created by</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End date</TableCell>
                            <TableCell>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reports.map((x, index) => {
                            const createdDate = new Date(x?.createdTs?.toDate())
                            const yeardateOptions = {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            }
                            const startDate = new Date(x?.startTime?.toDate())
                            const endDate = new Date(x?.endTime?.toDate())
                            return (
                                <TableRow key={index}>
                                    <TableCell>{x.count}</TableCell>
                                    <TableCell>
                                        {createdDate?.toLocaleDateString(
                                            getLocale(),
                                            yeardateOptions
                                        )}
                                    </TableCell>
                                    <TableCell>{x.createdName}</TableCell>
                                    <TableCell>
                                        {startDate?.toLocaleDateString(
                                            getLocale(),
                                            yeardateOptions
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {endDate?.toLocaleDateString(
                                            getLocale(),
                                            yeardateOptions
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            component="a"
                                            color="secondary"
                                            variant="contained"
                                            target="_blank"
                                            download={`statistics-${x.count}.xlsx`}
                                            href={getLink(x.id, documents)}
                                        >
                                            Download
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {reports.length === reportLimit && (
                <Typography
                    fontSize={"8"}
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    fontWeight="300"
                    textAlign="right"
                    onClick={increaseLimit}
                >
                    View More
                </Typography>
            )}
        </Box>
    )
}
