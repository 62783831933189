import React, { useEffect } from "react"
import { Grid, Box, Typography, Button } from "@mui/material"
import PropTypes from "prop-types"
import welcomeImage from "../../assets/img/gsw-cart.jpg"
import theme from "../../assets/styles/muiTheme"
import makeStyles from "@mui/styles/makeStyles"
import { useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import db, { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import CircleLoader from "../loader"
import { logger } from "workbox-core/_private"
import store from "../../store"
import {
    ONBOARDING_SET_STRIPE_COMPLETED,
    ONBOARDING_SET_STRIPE_URL,
} from "../../constants/actionTypes"
import { golfCourses } from "../../constants/dbPaths"

const useStyles = makeStyles((theme) => ({
    imageStyle: {
        maxWidth: "100%",
        height: "auto",
        objectFit: "cover",
    },
}))
export const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
}

export default function StripeOnboarding(props) {
    const { handleComplete } = props
    const introStyle = useStyles(theme)
    const [searchParams, setSearchParams] = useSearchParams()
    const reauth = searchParams.has("refresh") && searchParams.get("refresh")
    const completed =
        searchParams.has("completed") && searchParams.get("completed")

    const getStripeOnboarding = httpsCallable(functions, "getStripeOnboarding")
    const getStripeOnboardingStatus = httpsCallable(
        functions,
        "getStripeOnboardingStatus"
    )
    const setStripeUrl = (stripeUrl) => {
        store.dispatch({
            type: ONBOARDING_SET_STRIPE_URL,
            payload: { stripeUrl },
        })
    }
    const { stripeUrl, stripeCompleted } = useSelector(
        (state) => state.setupflow
    )
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const getStripeConfigUrl = async () => {
        let returnUrl = ""
        try {
            const stripeOnboardingResult = await getStripeOnboarding({
                courseDocId,
            })
            const { stripeUrl } = stripeOnboardingResult.data
            returnUrl = stripeUrl
        } catch (e) {
            console.error(e.message)
        }
        return returnUrl
    }
    useEffect(() => {
        getStripeConfigUrl().then((url) => {
            setStripeUrl(url)
        })
    }, [])

    useEffect(() => {
        async function getStripeConfig() {
            if (reauth !== null && Boolean(reauth)) {
                console.log("REAUTH")
                searchParams.delete("refresh")
                setSearchParams(searchParams)
                const stripeUrlToSet = await getStripeConfigUrl()
                setStripeUrl(stripeUrlToSet)
                openInNewTab(stripeUrlToSet)
            }
        }
        getStripeConfig()
    }, [reauth])

    useEffect(() => {
        async function checkComplete() {
            if (
                (completed !== null && Boolean(completed)) ||
                !stripeCompleted
            ) {
                console.log("completed")
                const capabilitiesStatus = await getStripeOnboardingStatus({
                    courseDocId,
                }).catch((e) => logger.error(e.message))
                if (!capabilitiesStatus) {
                    return false
                }
                const allComplete = () => {
                    let sum = Object.values(
                        capabilitiesStatus.data.status
                    ).reduce((x, y) => x + y, 0)
                    return (
                        sum ===
                        Object.keys(capabilitiesStatus.data.status).length
                    )
                }
                if (allComplete()) {
                    console.log("COMPLETED")
                    await db.collection(golfCourses).doc(courseDocId).update({
                        stripeCompleted: true,
                    })
                    store.dispatch({ type: ONBOARDING_SET_STRIPE_COMPLETED })
                    handleComplete()
                }
            }
        }
        checkComplete()
    }, [completed, stripeCompleted])

    const handleSetupStripe = () => {
        console.log(stripeUrl)
        return openInNewTab(stripeUrl)
    }

    return (
        <Box sx={{ padding: "40px" }}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="h4">Stripe Payment Setup</Typography>
                    <br />
                    <Typography variant="body1">
                        .EAGL partners with Stripe to deliver the best possible
                        payment solution for our customers.
                    </Typography>
                    <br />
                    <Typography sx={{ marginTop: "auto" }} variant="body1">
                        When you are ready to begin, press the button below. (It
                        may take some time to load)
                    </Typography>
                    {stripeUrl === "" ? (
                        <CircleLoader />
                    ) : stripeCompleted ? (
                        <Button
                            onClick={handleComplete}
                            variant="contained"
                            color="secondary"
                            sx={{ mt: 4, mb: 4 }}
                        >
                            Complete
                        </Button>
                    ) : (
                        <>
                            <Button
                                onClick={handleSetupStripe}
                                variant="contained"
                                color="secondary"
                                sx={{ mt: 4, mb: 4 }}
                            >
                                Setup Stripe
                            </Button>
                        </>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <img
                        src={welcomeImage}
                        className={introStyle.imageStyle}
                        alt="Welcome image"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

StripeOnboarding.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
