import React from "react"
import IconButton from "@mui/material/IconButton"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import { Button, Hidden, Typography } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_BOOKING_VIEW_START } from "../../constants/actionTypes"
import enLocale from "date-fns/locale/en-GB"
import { renderInput } from "../vehicleStatsRow/timeDataPicker"
import { getCreateBookingAccess } from "../../constants/accessPermissionsChecks"

export default function HeaderText(props) {
    const {
        text,
        handleClickOpenNewBooking,
        handleOpenRain,
        addOneDay,
        removeOneDay,
    } = props
    const { startTime } = useSelector((state) => state.bookingView)
    const canCreateBooking = getCreateBookingAccess()

    const handleDateChange = (date, inputText) => {
        if (date instanceof Date && !isNaN(date)) {
            const newStartTime = date.setHours(0, 0, 0, 0)
            if (inputText === undefined) {
                store.dispatch({
                    type: SET_BOOKING_VIEW_START,
                    payload: { startTime: newStartTime },
                })
            } else if (inputText.length === 10) {
                store.dispatch({
                    type: SET_BOOKING_VIEW_START,
                    payload: { startTime: newStartTime },
                })
            }
        }
    }
    return (
        <>
            <Hidden lgDown>
                <div
                    style={{
                        marginRight: "auto",
                        marginLeft: "10px",
                        whiteSpace: "nowrap",
                    }}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!canCreateBooking}
                        style={{
                            borderRadius: "4px",
                            marginRight: "5px",
                            fontWeight: "bold",
                        }}
                        onClick={handleClickOpenNewBooking}
                    >
                        Make reservation
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={!canCreateBooking}
                        style={{ borderRadius: "4px", fontWeight: "bold" }}
                        onClick={handleOpenRain}
                    >
                        Fleet Override
                    </Button>
                </div>
            </Hidden>

            <Typography sx={{ color: "rgb(178, 186, 194)" }}>{text}</Typography>
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "0",
                    whiteSpace: "nowrap",
                }}
            >
                <LocalizationProvider
                    adapterLocale={enLocale}
                    dateAdapter={AdapterDateFns}
                >
                    <IconButton
                        color="inherit"
                        aria-label="previous-day"
                        onClick={removeOneDay}
                        size="large"
                    >
                        <ArrowBackIosIcon color="secondary" />
                    </IconButton>
                    <DatePicker
                        autoOk
                        emptyLabel
                        disableToolbar
                        variant="inline"
                        id="date-picker-inline"
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        value={startTime}
                        onChange={handleDateChange}
                        renderInput={(props) => renderInput(props)}
                    />
                    <IconButton
                        color="inherit"
                        aria-label="next-day"
                        onClick={addOneDay}
                        size="large"
                    >
                        <ArrowForwardIosIcon color="secondary" />
                    </IconButton>
                </LocalizationProvider>
            </div>
        </>
    )
}
