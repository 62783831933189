import React, { useEffect } from "react"
import { Box } from "@mui/material"
import { CartInstallationHeader } from "./InstallCart/introInstallationText"
import InstallFinishedText from "./finish/FinishedText"

export default function InstallFinishedPage() {
    return (
        <Box sx={{ margin: 2 }}>
            <CartInstallationHeader />
            <InstallFinishedText />
        </Box>
    )
}
