import { getAuth, onAuthStateChanged } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import {
    redirect,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom"
import getSubscriptionStatus from "../../Components/SubscriptionStatus"
import { USER_LOG_OUT } from "../../constants/actionTypes"
import { golfCourses, users } from "../../constants/dbPaths"
import db, { functions } from "../../rootWrapper/firebase"
import store from "../../store"
import RegisterType from "./registerChooseType"
import RegisterCourse from "./RegisterCourseFlow"
import RegisterUser from "./User"

export default function Register() {
    const location = useLocation()
    const navigate = useNavigate()
    const [currentUser, setCurrentUser] = useState()
    const [auth, setAuth] = useState()
    const [course, setCourse] = useState()

    const fireAuth = getAuth()
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(fireAuth, (user) => {
            setAuth(user)
        })
        return unsubscribe
    }, [fireAuth])

    useEffect(() => {
        let unsubscribe
        if (auth) {
            const { emailVerified, uid } = auth
            if (uid && emailVerified) {
                unsubscribe = db
                    .collection(users)
                    .doc(uid)
                    .onSnapshot(
                        (res) => {
                            console.log(res.data())
                            setCurrentUser(res.data())
                        },
                        (error) => {
                            console.error(error)
                        }
                    )
            }
        }
        setCurrentUser(null)
        return unsubscribe
    }, [auth])

    useEffect(() => {
        let unsubscribe = null
        if (currentUser) {
            if (currentUser.admin) {
                unsubscribe = db
                    .collection(golfCourses)
                    .doc(currentUser.courseDocId)
                    .onSnapshot(
                        (res) => {
                            console.log(res.data())
                            setCourse(res.data())
                        },
                        (error) => {
                            console.error(error)
                        }
                    )
            }
        }
        return unsubscribe
    }, [currentUser])

    useEffect(() => {
        if (
            location.pathname.includes("register/course") &&
            currentUser &&
            !currentUser.admin &&
            currentUser.courseDocId
        ) {
            navigate("/")
        }
    }, [currentUser, course, auth, location.pathname])
    const { init, initiatedSub, subData } = getSubscriptionStatus()
    useEffect(() => {
        if (init) {
            if (initiatedSub) {
                console.log(init, initiatedSub, subData)
                console.log("Subscription already initiated, redirecting")
                console.log("Stopped redirecting only be after checkout form")
            }
        }
    }, [init, initiatedSub])

    useEffect(() => {
        if (
            location.pathname.includes("course/create") &&
            currentUser &&
            currentUser.admin &&
            currentUser.onboardingStep === 0 &&
            course
        ) {
            if (!course.cartsAdded) {
                navigate("/register/course/carts")
            }
        }
    }, [currentUser, course, auth, location.pathname])

    const register = useSelector((state) => state.register)
    const setSubModel = httpsCallable(functions, "setSubModel")
    const code = useSelector((state) => state.register.code)
    const user = useSelector((state) => state.firestore?.data?.user)
    const courseDocId = user?.courseDocId

    useEffect(() => {
        if (register.code && location.pathname.includes("register")) {
            console.log("Set price model", code)
            setSubModel({
                code,
                courseDocId,
            }).catch((e) => console.error(e.message))
            if (register.priceModel.skipPay && course?.cartsAdded) {
                console.log("Confirmed model", register.priceModel)
                navigate("/")
            }
        }
    }, [register?.code, course?.cartsAdded, location.pathname])

    return (
        <Routes>
            <Route index element={<RegisterType />} />
            <Route path="course/*" element={<RegisterCourse />} />
            <Route path="user/*" element={<RegisterUser />} />
        </Routes>
    )
}
