import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"
import { useSelector } from "react-redux"
import { storage } from "../../rootWrapper/firebase"
import { golfCourses, storageImagesBasePath } from "../../constants/dbPaths"
import CircleLoader from "../loader"
import store from "../../store"
import { SET_IMAGE } from "../../constants/actionTypes"
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage"

const useStyles = makeStyles((theme) => ({
    profilePictureWrap: {
        position: "relative",
        display: "block",
        overflow: "hidden",
        height: "200px",
        width: "100%",
        paddingTop: 20,
    },
    profilePicture: {
        display: "flex",
        margin: "auto",
        width: "auto",
        height: "130px",
        borderRadius: `${10}px`,
    },
    userInfo: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        bottom: 0,
        display: "flex",
        margin: "auto",
        width: "100%",
        textAlign: "center",
    },
    altText: {
        margin: "auto",
        width: "80%",
    },
}))

export default function ProfileRow(props) {
    const classes = useStyles()
    const userInfo = useSelector((state) => state.firestore.data.user)

    const courseDocId = useSelector((state) => {
        return state.firestore?.data?.user?.courseDocId
    })
    const storage = getStorage()
    const pathReference = ref(
        storage,
        `${storageImagesBasePath}/${golfCourses}/assets/${courseDocId}`
    )

    const { image, imageLoaded } = useSelector((state) => state.profileImage)
    useEffect(() => {
        if (courseDocId && !imageLoaded) {
            listAll(pathReference).then((list) => {
                if (list.items.length === 0) {
                    store.dispatch({
                        type: SET_IMAGE,
                        payload: { image: null },
                    })
                } else {
                    return getDownloadURL(ref(storage, list.items[0]))
                        .then((url) => {
                            store.dispatch({
                                type: SET_IMAGE,
                                payload: { image: url },
                            })
                        })
                        .catch((error) => {
                            console.error(error.message)
                            store.dispatch({
                                type: SET_IMAGE,
                                payload: { image: null },
                            })
                        })
                }
            })
        }
    }, [image, imageLoaded, courseDocId])

    function ProfileImage() {
        if (imageLoaded) {
            if (image === null) {
                return (
                    <Typography className={classes.altText} variant="h6">
                        Upload a new course photo in settings and refresh
                    </Typography>
                )
            }
            return (
                <img
                    className={classes.profilePicture}
                    src={image}
                    alt="Profile picture"
                />
            )
        }
        return <CircleLoader />
    }

    return (
        <div className={classes.profilePictureWrap}>
            <Grid
                alignContent="center"
                container
                direction="column"
                style={{ height: "100%" }}
            >
                <Grid item xs>
                    <ProfileImage />
                </Grid>
                <Grid item xs className={classes.userInfo}>
                    <Typography
                        style={{
                            flex: 1,
                            textAlign: "center",
                            alignSelf: "center",
                            justifySelf: "center",
                        }}
                    >
                        {userInfo?.fullName || ""}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
