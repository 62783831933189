import { Box, LinearProgress, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import PayoutTable from "../../Components/InvoiceTable/payoutTable"
import getPayoutDocuments from "../../Components/payoutDocuments"
import { functions } from "../../rootWrapper/firebase"
import { useSelector } from "react-redux"

const getPayouts = httpsCallable(functions, "getPayouts")

export default function SubPayouts(props) {
    const [payouts, setPayouts] = useState()
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const limit = 10
    const { documents, loading } = getPayoutDocuments()

    useEffect(() => {
        if (courseDocId) {
            getPayouts({ courseDocId, limit })
                .then((r) => {
                    const payouts = r.data
                    console.log("payouts", payouts)
                    setPayouts(payouts)
                })
                .catch((e) => {
                    console.error(e.message)
                    setPayouts(null)
                })
        }
    }, [courseDocId])

    return (
        <Box sx={{ p: 2 }}>
            <Typography>Payouts:</Typography>
            {loading ? <LinearProgress color="secondary" /> : null}
            <PayoutTable payoutLinks={documents} payouts={payouts} />
        </Box>
    )
}
