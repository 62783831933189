import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import makeStyles from "@mui/styles/makeStyles"

import ListItem from "@mui/material/ListItem"
import Divider from "@mui/material/Divider"
import ListItemText from "@mui/material/ListItemText"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import Avatar from "@mui/material/Avatar"
import theme from "../../assets/styles/muiTheme"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemIcon from "@mui/material/ListItemIcon"
import notifications from "../../reducers/notifications"
import CustomerReviewNotification from "./customerReviewNotification"

const useStyles = makeStyles((theme) => ({
    altText: {
        margin: "auto",
        width: "80%",
    },
}))

export default function NotificationRow(props) {
    const { key, index, notification, setRead } = props

    function RenderSwitch() {
        switch (notification.type) {
            case "customerReview":
                return (
                    <CustomerReviewNotification
                        notification={notification}
                        key={key}
                        index={index}
                        setRead={setRead}
                    />
                )
            default:
                return null
        }
    }

    return <RenderSwitch />
}
