import { Button, Grid, Typography } from "@mui/material"
import { getAuth } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CircleLoader from "../../../Components/loader"
import RegisterContainer from "../../../Components/RegisterContainer"
import ConfirmEmail from "../../../Components/confirmEmail"
import store from "../../../store"
import { SET_REGISTER_STEP } from "../../../constants/actionTypes"

export default function ConfirmEmailView() {
    const navigate = useNavigate()

    useEffect(() => {
        store.dispatch({ type: SET_REGISTER_STEP, payload: { step: 0 } })
    }, [])
    const auth = getAuth()

    useEffect(() => {
        async function checkAuth() {
            await auth.currentUser?.reload()
            if (auth.currentUser?.emailVerified) {
                navigate("../create")
            }
        }
        const timer = setInterval(() => {
            checkAuth()
        }, 1000)
        checkAuth()
        return () => clearInterval(timer)
    }, [])

    const userIsLoaded = useSelector((state) => "uid" in state.firebase.auth)
    useEffect(() => {
        if (!userIsLoaded) {
            navigate("/register")
        }
    }, [userIsLoaded])

    return (
        <RegisterContainer fullHeigth>
            <ConfirmEmail />
        </RegisterContainer>
    )
}
