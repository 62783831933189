import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { financeReportPath } from "../../constants/dbPaths"

export default function getReportDocuments() {
    const [documents, setDocuments] = useState({})
    const [loading, setLoading] = useState(false)
    const { reportLoaded } = useSelector((state) => {
        return state.bookingHistory || {}
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    useEffect(() => {
        if (courseDocId && !reportLoaded) {
            setLoading(true)
            const storage = getStorage()
            const listRef = ref(
                storage,
                `${financeReportPath}/golfcourses/${courseDocId}`
            )
            const items = {}
            listAll(listRef).then((r) =>
                Promise.all(
                    r.items.map(
                        async (item) =>
                            await getDownloadURL(item).then(async (url) => {
                                const blob = await fetch(url).then((res) =>
                                    res.blob()
                                )
                                items[item.name] = URL.createObjectURL(blob)
                            })
                    )
                ).then(() => {
                    setDocuments(items)
                    setLoading(false)
                })
            )
        }
    }, [courseDocId, reportLoaded])

    return {
        documents,
        loading,
    }
}
