import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import theme from "../../assets/styles/muiTheme"

export default function CreateCartSummary(props) {
    const { amount } = props

    return (
        <Box
            width="100%"
            height="120px"
            borderRadius="4px"
            padding="16px"
            sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.secondary.main,
            }}
            textAlign="center"
            position="relative"
        >
            <Box
                position="absolute"
                top={-15}
                left={10}
                padding="5px"
                pr="10px"
                pl="10px"
                sx={{ backgroundColor: theme.palette.primary.dark }}
            >
                Summary vehicle fleet
            </Box>
            <Box
                height="100%"
                display={"flex"}
                justifyContent="center"
                alignItems="center"
            >
                <Box>
                    <Typography
                        component="div"
                        color="secondary"
                        margin="auto"
                        fontSize="14pt"
                    >
                        <Typography
                            fontSize="14pt"
                            component="span"
                            fontWeight={"600"}
                        >
                            Free
                        </Typography>{" "}
                        trial 60 days*
                    </Typography>
                    <Typography
                        component="div"
                        marginTop={"-10px"}
                        fontSize={"24pt"}
                    >
                        {amount} Vehicles
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}
