import { SET_NOTIFICATION } from "../constants/actionTypes"

const initialState = {
    notifications: {},
}

export default (state = initialState, action) => {
    console.log("setting notifications")
    switch (action.type) {
        case SET_NOTIFICATION:
            return {
                ...state,
                ...action.payload,
            }

        default:
            return state
    }
}
