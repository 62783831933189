import { Grid, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { VoltageCalc } from "../VoltageToProcentageCalculator/voltageCalc"

export default function VoltageText() {
    const { selectedVehicle } = useSelector((state) => {
        return state.vehicleSelector
    })

    return (
        <div style={{ maxWidth: "300px" }}>
            <Grid container direction="row" spacing={1} alignItems="flex-end">
                <Grid item xs>
                    <Typography
                        style={{
                            display: "inline-block",
                            marginLeft: "6px",
                        }}
                        fontSize={"8"}
                        variant="body2"
                        fontWeight={"300"}
                    >
                        ({selectedVehicle.reading?.VLT || 0} V)
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Typography
                        style={{
                            display: "inline-block",
                            marginRight: "8px",
                        }}
                        fontSize={"24pt"}
                        variant="h1"
                        fontWeight={"400"}
                    >
                        {VoltageCalc(
                            selectedVehicle.reading?.VLT,
                            selectedVehicle.maxVoltage
                        )}
                        %
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}
