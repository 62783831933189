import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import {
    SET_VEHICLE_QUERY,
    SET_VEHICLE_STATS,
} from "../../constants/actionTypes"
import { getEndTime, getPriceNumber } from "../Booking/bookingUtils"
import { statusBookingTrips } from "../../constants/statusTypes"

export default function VehicleStatsDataLoader(props) {
    const [filterStart, setFilterStart] = useState(
        Date.now() - 30 * 24 * 60 * 60 * 1000
    )

    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })
    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const query = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })
    const vehicleBookings = useSelector((state) => {
        return state.vehicleSelector?.vehicleBooking[cartDocId] || []
    })

    const { queryStart, queryComplete, lastUpdated } = query
    useEffect(() => {
        if (courseDocId && cartDocId) {
            if (!queryStart) {
                store.dispatch({
                    type: SET_VEHICLE_QUERY,
                    payload: {
                        cartDocId,
                        query: {
                            start: filterStart,
                        },
                    },
                })
            }
        }
    }, [courseDocId, cartDocId])

    useEffect(() => {
        if (queryComplete) {
            const bookingsToFilter = vehicleBookings.filter(
                (x) => statusBookingTrips.indexOf(x.status) > -1
            )
            if (bookingsToFilter.length) {
                const totalBookings = bookingsToFilter.length
                const totalIncome = bookingsToFilter.reduce(
                    (sum, currentBooking) =>
                        sum + getPriceNumber(currentBooking),
                    0
                )
                const { starBookings, starsTotal } = bookingsToFilter.reduce(
                    (current, currentBooking) => {
                        if (currentBooking.starRating) {
                            return {
                                starsTotal:
                                    current.starsTotal +
                                    currentBooking.starRating,
                                starBookings: current.starBookings + 1,
                            }
                        }
                        return current
                    },
                    { starsTotal: 0, starBookings: 0 }
                )
                const totalRentalTime = bookingsToFilter.reduce(
                    (totalTime, currentBooking) => {
                        const start = currentBooking.startTime.toMillis()
                        const end = getEndTime(currentBooking).toMillis()
                        return totalTime + (end - start)
                    },
                    0
                )
                store.dispatch({
                    type: SET_VEHICLE_STATS,
                    payload: {
                        stats: {
                            totalBookings,
                            totalRentalTime,
                            incomeTotal: totalIncome,
                            starsTotal,
                            starBookings,
                        },
                        cartDocId,
                    },
                })
            }
        }
    }, [courseDocId, cartDocId, queryComplete, lastUpdated])

    return null
}
