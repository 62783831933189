const statusNotStarted = "not_started"
const statusStarted = "started"
const statusEnded = "ended"
const statusAdmin = "admin"
const statusExpired = "expired"
const statusMaintenance = "maintenance"
const statusCancelled = "cancelled"
const statusFailed = "failed"

const statusFinished = [statusEnded, statusExpired]
const statusPaid = [statusEnded, statusExpired, statusCancelled]

const statusFinishedWithAdmin = [...statusFinished, statusAdmin]

const statusPaidWithAdmin = [
    statusEnded,
    statusExpired,
    statusCancelled,
    statusAdmin,
]

const statusAppBooking = [
    statusNotStarted,
    statusStarted,
    statusEnded,
    statusExpired,
]
const statusAllUpcoming = [statusNotStarted, statusAdmin, statusMaintenance]

const statusAllUpcomingOngoing = [
    statusNotStarted,
    statusStarted,
    statusAdmin,
    statusMaintenance,
]
const statusBookingTrips = [
    statusNotStarted,
    statusStarted,
    statusEnded,
    statusAdmin,
    statusExpired,
] // Should not have expired - no-show is no trip
const validStatus = [
    statusNotStarted,
    statusStarted,
    statusEnded,
    statusAdmin,
    statusExpired,
    statusMaintenance,
]
const validStatusTable = [
    statusNotStarted,
    statusStarted,
    statusEnded,
    statusAdmin,
    statusExpired,
    statusCancelled,
    statusMaintenance,
]

export {
    statusNotStarted,
    statusStarted,
    statusEnded,
    statusAdmin,
    statusExpired,
    statusMaintenance,
    statusCancelled,
    statusFailed,
    statusFinished,
    statusAllUpcomingOngoing,
    statusAllUpcoming,
    statusAppBooking,
    validStatus,
    validStatusTable,
    statusBookingTrips,
    statusPaidWithAdmin,
    statusFinishedWithAdmin,
    statusPaid,
}
