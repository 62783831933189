import React from "react"
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { useSelector } from "react-redux"

export default function VehicleActionCell(props) {
    const { FunctionalComponent, prop, desc, toolTip } = props
    const { selectedVehicle } = useSelector((state) => {
        return state.vehicleSelector
    })
    return (
        <Box
            position="relative"
            sx={{ height: "100%" }}
            style={{ minHeight: "60px" }}
        >
            <Box position="absolute" right={0} top={0}>
                <Tooltip
                    title={<Typography fontSize="14pt">{toolTip}</Typography>}
                    placement="top"
                >
                    <IconButton size="small">
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Grid
                container
                direction="column"
                alignItems={"center"}
                justifyContent="center"
                sx={{ height: "100%" }}
            >
                <Grid item xs>
                    <Grid
                        container
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent="center"
                        sx={{ height: "100%" }}
                    >
                        <Grid item xs sx={{ paddingRight: "20px" }}>
                            <Typography fontWeight={"600"}>{desc}</Typography>
                        </Grid>
                        <Grid item xs>
                            <FunctionalComponent
                                vehicle={selectedVehicle || {}}
                                {...prop}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
