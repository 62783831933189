import React, { useEffect, useState } from "react"
import Avatar from "@mui/material/Avatar"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import makeStyles from "@mui/styles/makeStyles"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useSelector } from "react-redux"
import { SuccessLoader } from "../../../Components/settingsForm/loader/successLoader"
import validateEmail from "../../../Components/firebaseUtils/ValidateEmail"
import { formStyle } from "../../../assets/styles/views/formStyle"
import Copyright from "../../../Components/Copyright/copyright"
import { httpsCallable } from "firebase/functions"
import RegisterContainer from "../../../Components/RegisterContainer"
import {
    getAuth,
    signInWithEmailAndPassword,
    sendEmailVerification,
} from "firebase/auth"
import { functions } from "../../../rootWrapper/firebase"

const useStyles = makeStyles(formStyle)

export default function RegisterAditionalUser() {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const email = searchParams.get("email") ? searchParams.get("email") : ""
    const linkingId = searchParams.get("linkId")
        ? searchParams.get("linkId")
        : ""

    const initForm = {
        name: "",
        email,
        password: "",
        tlf: "",
        linkingId,
    }
    const initStatus = {
        success: false,
        error: false,
        loading: false,
        courseLinkingId: false,
    }
    const [status, setStatus] = useState(initStatus)

    const [form, setForm] = useState(initForm)
    const [errorMessagesDisplayed, setErrorMessagesDisplayed] = useState([])
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        password: false,
        tlf: false,
        courseLinkingId: false,
    })
    const clearState = () => {
        setForm({ ...initForm })
    }
    const userIsLoaded = useSelector((state) => "uid" in state.firebase.auth)

    useEffect(() => {
        if (userIsLoaded) {
            navigate("confirmEmail")
        }
    }, [userIsLoaded])

    const styles = useStyles()
    function ErrorMessage({ text }) {
        return <h3 className={styles.error}> {text}</h3>
    }
    const errorMessages = errorMessagesDisplayed.map((message, index) => (
        <ErrorMessage key={index} text={message} />
    ))

    function validateAllInput() {
        if (form.name.length < 4) {
            setStatus({ ...initStatus, error: true })
            setErrors((prevState) => ({ ...prevState, name: true }))
            setErrorMessagesDisplayed(["Please your full name. "])
            return false
        }
        setErrors((prevState) => ({ ...prevState, name: false }))

        if (!validateEmail(form.email)) {
            setStatus({ ...initStatus, error: true })
            setErrors((prevState) => ({ ...prevState, email: true }))
            setErrorMessagesDisplayed(["Please enter a valid email. "])
            return false
        }
        setErrors((prevState) => ({ ...prevState, email: false }))

        if (form.linkingId.length < 6) {
            setStatus({ ...initStatus, error: true })
            setErrors((prevState) => ({ ...prevState, courseLinkingId: true }))
            setErrorMessagesDisplayed(["Invalid course linking id"])
            return false
        }
        setErrors((prevState) => ({ ...prevState, courseLinkingId: false }))

        if (form.password.length < 10) {
            setStatus({ ...initStatus, error: true })
            setErrors((prevState) => ({ ...prevState, password: true }))
            setErrorMessagesDisplayed([
                "Your password must be at least 10 characters. ",
            ])
            return false
        }
        setErrors((prevState) => ({ ...prevState, password: false }))
        if (form.tlf.length < 8 || form.tlf.replace(/[^0-9]/g, "").length < 8) {
            setStatus({ ...initStatus, error: true })
            setErrors((prevState) => ({ ...prevState, tlf: true }))
            setErrorMessagesDisplayed(["Please enter a valid phone number "])
            return false
        }
        setErrors((prevState) => ({ ...prevState, tlf: false }))
        return true
    }

    const handleInputChange = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setStatus(initStatus)
        setForm((prevState) => ({ ...prevState, [name]: value }))
    }

    const createWebUser = async (
        fullName,
        email,
        password,
        courseLinkingId,
        tlf
    ) =>
        httpsCallable(
            functions,
            "createWebUser"
        )({
            fullName,
            email,
            password,
            courseLinkingId,
            tlf,
        })

    const createUserWithEmailAndPassword = async (e) => {
        setStatus((status) => ({ ...status, loading: true, error: false }))
        e.preventDefault()
        if (validateAllInput()) {
            // setting the error message
            await createWebUser(
                form.name,
                form.email,
                form.password,
                form.linkingId,
                form.tlf
            )
                .then(() => {
                    signInWithEmailAndPassword(
                        getAuth(),
                        form.email,
                        form.password
                    ).then(() => {
                        const auth = getAuth()
                        if (!auth.currentUser.emailVerified) {
                            sendEmailVerification(auth.currentUser)
                                .then(() => {})
                                .catch((e) =>
                                    console.log(
                                        "failed sending confirmation email",
                                        e
                                    )
                                )
                        }
                        clearState()
                        setStatus(initStatus)
                        return true
                    })
                })
                .catch((error) => {
                    setErrorMessagesDisplayed([
                        "Could not register the user.\n" + error.message,
                    ])
                    const errorCode = error.code
                    const errorMessage = error.message
                    setStatus((status) => ({
                        ...status,
                        loading: false,
                        error: true,
                    }))
                    console.log(errorCode, errorMessage)
                })
        }
    }

    return (
        <RegisterContainer>
            <Box maxWidth={400}>
                <Avatar sx={{ margin: "auto", mt: 1, mb: 1 }}>
                    <AccountCircleIcon />
                </Avatar>
                <Box textAlign={"center"}>
                    <Typography component="h1" variant="h5">
                        Register a user
                    </Typography>
                    <Typography component="h2" variant="h6">
                        Joining to administrate course!
                    </Typography>
                </Box>
                <form className={styles.form}>
                    <TextField
                        variant="filled"
                        fullWidth
                        error={errors.name}
                        id="name"
                        label="Your Full Name"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        onChange={handleInputChange}
                    />
                    <TextField
                        variant="filled"
                        fullWidth
                        error={errors.email}
                        id="email"
                        label="Email Address"
                        name="email"
                        value={form.email}
                        autoComplete="email"
                        onChange={handleInputChange}
                    />
                    <TextField
                        variant="filled"
                        fullWidth
                        error={errors.tlf}
                        id="tlf"
                        label="Phone Number"
                        name="tlf"
                        value={form.tlf}
                        autoComplete="tlf"
                        onChange={handleInputChange}
                    />
                    <TextField
                        variant="filled"
                        fullWidth
                        error={errors.courseLinkingId}
                        id="linkingId"
                        label="Course linking Id"
                        name="linkingId"
                        value={form.linkingId}
                        autoComplete={"off"}
                        onChange={handleInputChange}
                    />
                    <TextField
                        variant="filled"
                        fullWidth
                        error={errors.password}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        sx={{ mb: 2 }}
                        autoComplete="current-password"
                        onChange={handleInputChange}
                    />
                    <SuccessLoader
                        status={status}
                        error={errorMessages}
                        handleSubmit={createUserWithEmailAndPassword}
                    >
                        Register
                    </SuccessLoader>
                </form>
                <Grid container>
                    <Grid item xs>
                        <Link
                            to="/forgotpass"
                            variant="body2"
                            style={{ color: "#c3c3c3" }}
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            to="/login"
                            variant="body2"
                            style={{ color: "#c3c3c3" }}
                        >
                            Already have a user? Sign in!
                        </Link>
                    </Grid>
                </Grid>

                <Box mt={8}>
                    <Copyright />
                </Box>
            </Box>
        </RegisterContainer>
    )
}
