const accessPermissionsSchemaDesc = {
    featureEngine: {
        name: "Vehicle Power Management",
        desc: "Turning vehicles on and off. Access to 'ping' button to trigger a new message from the smartbox.",
    },
    featAvailability: {
        name: "Vehicle Availability management",
        desc: "Ability to set booking availability of vehicles, setting daily booking limit, creating vehicle notes",
    },
    featBooking: {
        name: "Booking Creation",
        desc: "Ability to create Dashboard bookings",
    },
    featRebook: {
        name: "Booking Management",
        desc: "Ability to edit bookings, rebook carts, change time, cancel and refund bookings and start/ end existing bookings",
    },
    featRidepass: {
        name: "Coupon Management",
        desc: "Ability to create and manage coupons",
    },
    featSettings: {
        name: "Settings Management",
        desc: "Ability to manage general course settings, course details, rental info and map config",
    },
    featPrice: {
        name: "Pricing Management",
        desc: "Ability to manage rental pricing",
    },
    featBilling: {
        name: "Billing Management",
        desc: "View access for payouts and payout reports. Ability to update card details and cancel subscription (only valid for subscription customers)",
    },
    featCoupons: {
        name: "Discount Management",
        desc: "Ability to create, manage and deactivate discounts",
    },
    viewMap: {
        name: "Map View Access",
        desc: "Ability to View the Map",
    },
    viewBooking: {
        name: "Booking View Access",
        desc: "Ability to view the boooking page",
    },
    viewVehicles: {
        name: "Vehicle View Access",
        desc: "Ability to view the vehicles page",
    },
    viewSettings: {
        name: "Settings View Access",
        desc: "Ability to view the settings page",
    },
    viewStatistics: {
        name: "Statistics View Access",
        desc: "Ability to view the statistics page, create and download reports",
    },
    viewReviews: {
        name: "Reviews View Access",
        desc: "Ability to view the reviews page",
    },
    viewCoupons: {
        name: "Discount View Access",
        desc: "Ability to view the discounts page",
    },
    viewRidepass: {
        name: "Coupon View Access",
        desc: "Ability to view the coupons page",
    },
    viewBillingPage: {
        name: "Billing View Access",
        desc: "Ability to view the subscription billing page",
    },
    viewInstallerPage: {
        name: "Installation View Access",
        desc: "Ability to view the vehicle installation page",
    },
}

export { accessPermissionsSchemaDesc }
