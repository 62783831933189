import React from "react"
import { Box, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import { useEffect, useState } from "react"
import SigninStatus from "../../models/signinStatus"
import { functions } from "../../rootWrapper/firebase"
import theme from "../../assets/styles/muiTheme"
import CircleLoader from "../../Components/loader"

export default function CardElement(props) {
    const { cardData } = props

    if (!cardData) {
        return <CircleLoader />
    }
    if (Object.keys(cardData).length === 0) {
        return "No registered card"
    }

    return (
        <Box
            sx={{
                mt: 2,
                borderRadius: 2,
                height: 150,
                p: 1,
            }}
            bgcolor={theme.palette.primary.main}
        >
            <Typography fontSize="16pt" textAlign={"left"}>
                {cardData.card.brand.toUpperCase()}
                <Typography
                    fontSize={"14pt"}
                    component="span"
                    sx={{ float: "right" }}
                >
                    **** {cardData.card.last4}
                </Typography>
            </Typography>
            <br />
            <br />
            <br />

            <Typography textAlign={"right"}>
                {cardData.card.exp_month.toLocaleString("en-US", {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                })}{" "}
                / {cardData.card.exp_year}
            </Typography>
        </Box>
    )
}
