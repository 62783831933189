import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { LinearProgress, TableCell } from "@mui/material"
import TableBody from "@mui/material/TableBody"
import Button from "@mui/material/Button"
import { useBookingStyles } from "../../assets/styles/components/bookingTable"
import theme from "../../assets/styles/muiTheme"
import {
    CLEARUSERPERMISSIONEDIT,
    SETUSERFORPERMISSION,
    SETUSERSWPERMISSION,
} from "../../constants/actionTypes"
import store from "../../store"
import { UserRows } from "./userRows"
import { accessPermissionsSchema } from "../../constants/accessPermissionSchema"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

export default function UsersWithPermission(props) {
    const [loading, setLoading] = useState(false)
    const user = useSelector((state) => state.firestore.data.user)
    const editingPermissionReset = useSelector(
        (state) => state.permissionEdit.shouldUpdate
    )
    const field = user.courseDocId

    const getFieldUsers = httpsCallable(functions, "getAllUsersWithAccess")
    let previousUpdateCount = 0

    useEffect(() => {
        if (editingPermissionReset > previousUpdateCount) {
            previousUpdateCount = editingPermissionReset
            setLoading(true)
            getFieldUsers({
                courseDocId: field,
            })
                .then((r) => {
                    setLoading(false)
                    r.data.docs.forEach((doc) => {
                        store.dispatch({
                            type: SETUSERSWPERMISSION,
                            payload: doc,
                        })
                    })
                })
                .catch((e) => console.error(e.message))
        }
    }, [field, editingPermissionReset])

    const classes = useBookingStyles(theme)

    const users = useSelector((state) => state.userPermissionList)

    const handleClick = (e) => {
        e.preventDefault()
        store.dispatch({ type: CLEARUSERPERMISSIONEDIT })
    }

    const handleUserClick = (e, uid) => {
        e.preventDefault()
        const accessPermissionSchemaCombined = Object.keys(
            users[uid].accessPermissions
        ).reduce((schema, key) => {
            if (key in schema) {
                schema[key]["access"] = users[uid].accessPermissions[key].access
            }
            return schema
        }, accessPermissionsSchema)
        store.dispatch({
            type: SETUSERFORPERMISSION,
            payload: { uid, permission: accessPermissionSchemaCombined },
        })
        console.log(uid)
    }

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell align="right">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={handleClick}
                                >
                                    Add new User
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <LinearProgress color="secondary" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            <UserRows
                                users={users}
                                handleUserClick={handleUserClick}
                            />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}
