import { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import db from "../../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../../constants/dbPaths"
import {
    SET_VEHICLE_BOOKINGS,
    SET_VEHICLE_NOTE_RELOAD,
    SET_VEHICLE_QUERY,
} from "../../constants/actionTypes"
import { Timestamp } from "firebase/firestore"

export const updateFrequency = 10 * 60 * 1000

export default function BookingHistoryLoaderOneVehicle() {
    const cartDocId = useSelector((state) => {
        return state.vehicleSelector?.selectedVehicle?.cartDocId || null
    })

    const query = useSelector((state) => {
        return state.vehicleSelector?.vehicleBookingQuery[cartDocId] || {}
    })

    const {
        queryComplete,
        start,
        queryStart,
        end,
        queryEnd,
        limit,
        queryLimit,
        lastUpdated,
        reloadCount,
    } = query

    useEffect(() => {
        if (cartDocId) {
            const now = Date.now()
            if (lastUpdated + updateFrequency < now) {
                store.dispatch({
                    type: SET_VEHICLE_NOTE_RELOAD,
                    payload: { cartDocId },
                })
                store.dispatch({
                    type: SET_VEHICLE_QUERY,
                    payload: { cartDocId },
                })
            } else {
                console.log("new timeout for reload of bookings")
                new Promise((res) =>
                    setTimeout(res, updateFrequency + 2000)
                ).then(() => {
                    console.log("Reload dispatch")
                    store.dispatch({
                        type: SET_VEHICLE_NOTE_RELOAD,
                        payload: { cartDocId },
                    })
                    store.dispatch({
                        type: SET_VEHICLE_QUERY,
                        payload: { cartDocId },
                    })
                })
            }
        }
        return () => clearTimeout()
    }, [lastUpdated])

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const vehicleBookings = useSelector((state) => {
        return state.vehicleSelector?.vehicleBooking[cartDocId] || []
    })

    useEffect(() => {
        async function handleTimeQuery() {
            if (courseDocId && cartDocId) {
                if (!queryComplete) {
                    console.log(start, queryStart, limit, queryLimit)
                    let queryRes = null
                    let currentQuery = {}
                    if (start && (start !== queryStart || end !== queryEnd)) {
                        console.log("startQuery", new Date(start))
                        queryRes = await db
                            .collection(golfCourses)
                            .doc(courseDocId)
                            .collection(bookingPath)
                            .where("cartDocId", "==", cartDocId)
                            .where(
                                "startTime",
                                ">=",
                                Timestamp.fromMillis(start)
                            )
                            .where("startTime", "<=", Timestamp.fromMillis(end))
                            .orderBy("startTime", "desc")
                            .get()
                        currentQuery.queryLimit = Math.max(limit, queryRes.size)
                        currentQuery.limit = Math.max(limit, queryRes.size)
                        currentQuery.queryStart = start
                        currentQuery.queryEnd = end
                        currentQuery.lastUpdated = Date.now()
                        store.dispatch({
                            type: SET_VEHICLE_BOOKINGS,
                            payload: {
                                cartDocId,
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeQuery()
    }, [cartDocId, courseDocId, queryComplete])

    useEffect(() => {
        async function handleLimitQuery() {
            if (courseDocId && cartDocId) {
                if (!queryComplete) {
                    console.log(start, queryStart, limit, queryLimit)
                    let queryRes = null
                    let currentQuery = {}
                    if (limit && limit !== queryLimit) {
                        console.log("Limitquery", limit)
                        const limitMax = Math.max(limit, queryLimit)
                        queryRes = await db
                            .collection(golfCourses)
                            .doc(courseDocId)
                            .collection(bookingPath)
                            .where("cartDocId", "==", cartDocId)
                            .where("startTime", "<", Timestamp.fromMillis(end))
                            .orderBy("startTime", "desc")
                            .limit(limitMax)
                            .get()
                        const firstBookingTime = queryRes.docs
                            .map((x) => x.data().startTime.toMillis())
                            .reduce((x, y) => Math.min(x, y), start)
                        console.log(firstBookingTime, "firstBookingTime")
                        console.log(start, queryStart)
                        currentQuery.queryStart = firstBookingTime
                        currentQuery.start = firstBookingTime
                        currentQuery.queryEnd = end
                        currentQuery.queryLimit = limitMax
                        currentQuery.limit = limitMax
                        currentQuery.lastUpdated = Date.now()
                        store.dispatch({
                            type: SET_VEHICLE_BOOKINGS,
                            payload: {
                                cartDocId,
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleLimitQuery()
    }, [cartDocId, courseDocId, queryComplete, vehicleBookings.length])

    useEffect(() => {
        async function handleTimeUpdate() {
            if (courseDocId && cartDocId) {
                console.log("queryComplete", queryComplete)
                if (
                    !queryComplete &&
                    lastUpdated + updateFrequency < Date.now()
                ) {
                    let queryRes = null
                    let currentQuery = {}
                    console.log("Timeout query")
                    if (
                        queryLimit &&
                        queryLimit === limit &&
                        limit === vehicleBookings.length
                    ) {
                        console.log("Limitquery bc time", limit)
                        queryRes = await db
                            .collection(golfCourses)
                            .doc(courseDocId)
                            .collection(bookingPath)
                            .where("cartDocId", "==", cartDocId)
                            .where(
                                "startTime",
                                ">=",
                                Timestamp.fromMillis(start)
                            )
                            .where("startTime", "<=", Timestamp.fromMillis(end))
                            .orderBy("startTime", "desc")
                            .limit(limit)
                            .get()
                        console.log("res length", queryRes.size)
                        const times = queryRes.docs.map((x) =>
                            x.data().startTime.toMillis()
                        )
                        const minTime = times.reduce(
                            (min, current) => Math.min(min, current),
                            start
                        )
                        const maxTime = times.reduce(
                            (min, current) => Math.max(min, current),
                            end
                        )
                        currentQuery.queryStart = minTime
                        currentQuery.start = minTime
                        currentQuery.queryEnd = maxTime
                        currentQuery.end = maxTime
                        currentQuery.queryLimit = limit
                        currentQuery.lastUpdated = Date.now()
                        store.dispatch({
                            type: SET_VEHICLE_BOOKINGS,
                            payload: {
                                cartDocId,
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    } else if (start && start === queryStart) {
                        console.log("time query bc timeout")
                        let startQuery = Math.min(start, queryStart)
                        let endQuery = Math.max(end, queryEnd)

                        queryRes = await db
                            .collection(golfCourses)
                            .doc(courseDocId)
                            .collection(bookingPath)
                            .where("cartDocId", "==", cartDocId)
                            .where(
                                "startTime",
                                ">=",
                                Timestamp.fromMillis(startQuery)
                            )
                            .where(
                                "startTime",
                                "<",
                                Timestamp.fromMillis(endQuery)
                            )
                            .orderBy("startTime", "desc")
                            .get()
                        currentQuery.queryLimit = Math.max(limit, queryRes.size)
                        currentQuery.queryStart = startQuery
                        currentQuery.start = startQuery
                        currentQuery.queryEnd = endQuery
                        currentQuery.end = endQuery
                        currentQuery.lastUpdated = Date.now()
                        store.dispatch({
                            type: SET_VEHICLE_BOOKINGS,
                            payload: {
                                cartDocId,
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeUpdate()
    }, [cartDocId, courseDocId, queryComplete, lastUpdated, reloadCount])
}
