import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import React from "react"
import { useSelector } from "react-redux"
import {
    DELETE_SEGMENT,
    SELECTSETTINGSFIELD,
    SET_SEGMENT,
} from "../../../constants/actionTypes"
import store from "../../../store"
import { styled } from "@mui/styles"
import { SECONDARY } from "../../../assets/styles/muiTheme"
import RevenueShares from "./priceExplinations"

const StyledTableCell = styled(TableCell)({
    borderBottom: "1px solid rgba(81, 81, 81, 0.2)",
})
const StyledCategoryTextfield = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: SECONDARY,
        },
        "&:hover fieldset": {
            borderColor: "white",
        },
    },
})

const clickToSeeRevenueDistribution = "Click to see the revenue distribution"

const headerNames = (vehicleGroups, course) => {
    const ppmDisabled = course.ppmEnabled !== false
    const nineHolesEnabled = course.nineHolesEnabled
    const colSpan = 1 + Number(ppmDisabled) + Number(nineHolesEnabled)
    return (
        <TableRow key={"header-names"}>
            <StyledTableCell key="vehicle-group-empty-cell" />
            {Object.keys(vehicleGroups).map((vehicleGroupKey) => {
                const vehicleGroup = vehicleGroups[vehicleGroupKey]
                return (
                    <StyledTableCell
                        colSpan={colSpan}
                        key={"vehiclegroup" + vehicleGroup.name}
                    >
                        {vehicleGroup.name}{" "}
                        <span style={{ fontSize: "10pt" }}>
                            <em>(Seats: {vehicleGroup.seats})</em>
                        </span>
                    </StyledTableCell>
                )
            })}
            <StyledTableCell key="emptyDelete" />
        </TableRow>
    )
}
const getHeaderCaptions = (vehicleGroups, course) => (
    <TableRow key={"headerCaptions"}>
        <StyledTableCell color="secondary" key="headerSegmentCaption">
            Category
        </StyledTableCell>
        {Object.keys(vehicleGroups).map((_, index) => {
            return (
                <React.Fragment key={index}>
                    <StyledTableCell
                        key={"headerCaption" + String(0 + index * 3)}
                    >
                        Fixed Price 18 Holes
                    </StyledTableCell>
                    {course.nineHolesEnabled ? (
                        <StyledTableCell
                            key={"headerCaption" + String(1 + index * 3)}
                        >
                            Fixed Price 9 Holes
                        </StyledTableCell>
                    ) : null}
                    {course.ppmEnabled !== false ? (
                        <StyledTableCell
                            key={"headerCaption" + String(2 + index * 3)}
                        >
                            Minute Price
                        </StyledTableCell>
                    ) : null}
                </React.Fragment>
            )
        })}
        <StyledTableCell key="emptyDelete2" />
    </TableRow>
)

const getTableHeader = (vehicleGroups, course) => {
    return (
        <TableHead>
            {headerNames(vehicleGroups, course)}
            {getHeaderCaptions(vehicleGroups, course)}
        </TableHead>
    )
}

function getPPMPriceFromFixed(fixedPrice, currentForm) {
    const parsedPrice = parseFloat(fixedPrice)
    if (parsedPrice === undefined || isNaN(parsedPrice)) {
        return 0
    }
    const { averageTime, startingFee } = currentForm
    const averageTimeTMP = averageTime
    const newPPMPrice = (fixedPrice - startingFee) / averageTimeTMP
    console.log(newPPMPrice)
    if (isNaN(newPPMPrice)) {
        return 0
    }
    return parseFloat(newPPMPrice.toFixed(2))
}

const handlePriceChangePPM = (e, segment, vehicleGroup) => {
    let newPPMPrice = e.target?.value
    if (newPPMPrice !== "") {
        newPPMPrice = parseFloat(newPPMPrice)
    }
    const fixedPrice = segment.vehicleGroups?.[vehicleGroup]?.[0] ?? 0
    const nineHolePrice = segment.vehicleGroups?.[vehicleGroup]?.[2] ?? 0

    const newSegment = {
        ...segment,
        vehicleGroups: {
            ...segment.vehicleGroups,
            [vehicleGroup]: [fixedPrice, newPPMPrice, nineHolePrice],
        },
    }
    store.dispatch({
        type: SET_SEGMENT,
        payload: { key: segment.key, value: newSegment },
    })
}

const handlePriceChange = (e, segment, vehicleGroup, currentForm) => {
    let newFixedPrice = e.target?.value
    let ppm = segment.vehicleGroups?.[vehicleGroup]?.[1] ?? 0
    const nineHolePrice = segment.vehicleGroups[vehicleGroup]?.[2] ?? 0

    if (newFixedPrice !== "") {
        console.log("setting price")
        newFixedPrice = parseFloat(newFixedPrice)
        ppm = getPPMPriceFromFixed(newFixedPrice, currentForm)
    }
    if (ppm <= 0) {
        ppm = 0
    }

    const newSegment = {
        ...segment,
        vehicleGroups: {
            ...segment.vehicleGroups,
            [vehicleGroup]: [newFixedPrice, ppm, nineHolePrice],
        },
    }
    store.dispatch({
        type: SET_SEGMENT,
        payload: { key: segment.key, value: newSegment },
    })
}

const handlePriceChangeNineHoles = (e, segment, vehicleGroup) => {
    let newFixedPrice = e.target?.value
    if (newFixedPrice !== "") {
        console.log("setting price")
        newFixedPrice = parseFloat(newFixedPrice)
    }
    const currentPrice = segment.vehicleGroups[vehicleGroup]
    const newPrice = [currentPrice[0], currentPrice[1], newFixedPrice]

    const newSegment = {
        ...segment,
        vehicleGroups: {
            ...segment.vehicleGroups,
            [vehicleGroup]: newPrice,
        },
    }
    store.dispatch({
        type: SET_SEGMENT,
        payload: { key: segment.key, value: newSegment },
    })
}
const handleQuestionClick = (segment, vehicleGroup, ppm, selected) => {
    const selectedAny = selected?.selected ?? false
    const keyMatch = segment?.key === selected?.segment?.key
    const groupMatch = vehicleGroup === selected?.selectedGroup
    const ppmMatch = ppm === selected?.ppm
    const allMatch = keyMatch && groupMatch && ppmMatch
    if (selectedAny && allMatch) {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "selectedSegment",
                value: {
                    selected: false,
                    selectedGroup: vehicleGroup,
                    ppm,
                    segment,
                },
            },
        })
    } else {
        store.dispatch({
            type: SELECTSETTINGSFIELD,
            payload: {
                key: "selectedSegment",
                value: {
                    selected: true,
                    selectedGroup: vehicleGroup,
                    ppm,
                    segment,
                },
            },
        })
    }
}

const handleNameChange = (e, segment) => {
    const newName = e.target?.value
    const newSegment = {
        ...segment,
        name: newName,
    }
    store.dispatch({
        type: SET_SEGMENT,
        payload: { key: segment.key, value: newSegment },
    })
}
const getLowestIntNotInList = (numberList) => {
    for (let i = 0; i <= numberList.length; i++) {
        if (numberList.indexOf(i) === -1) {
            return i
        }
    }
}

const addNewSegment = (segments, vehicleGroups) => {
    const segmentKeys = segments.map((x) => parseInt(x.key))
    const newKey = getLowestIntNotInList(segmentKeys)
    const newSegment = {
        key: newKey,
        name: "New category",
        vehicleGroups: Object.keys(vehicleGroups).reduce((groups, key) => {
            return {
                ...groups,
                [key]: [0, 0],
            }
        }, {}),
    }
    console.log(newSegment)
    store.dispatch({
        type: SET_SEGMENT,
        payload: { key: newKey, value: newSegment },
    })
}

const deleteSegment = (segmentKey) => {
    store.dispatch({
        type: DELETE_SEGMENT,
        payload: { key: segmentKey },
    })
}

const getSegmentRow = (segment, currentForm, course) => {
    const selected = currentForm?.selectedSegment
    const ppmDisabled = course?.ppmEnabled === false
    const nineHolesEnabled = course.nineHolesEnabled
    const { vehicleGroups } = currentForm
    const rentableGroups = Object.keys(vehicleGroups)
        .filter((x) => vehicleGroups[x].bookable)
        .reduce(
            (current, key) => ({ ...current, [key]: vehicleGroups[key] }),
            {}
        )
    return (
        <TableRow key={segment.key}>
            <StyledTableCell key={"SegmentRows" + segment.key}>
                <StyledCategoryTextfield
                    variant="outlined"
                    size="small"
                    value={segment.name}
                    sx={{ minWidth: 120 }}
                    onChange={(e) => handleNameChange(e, segment)}
                />
            </StyledTableCell>
            {Object.keys(rentableGroups).map(
                (vehicleGroupNumber, groupIndex) => {
                    const isGroupDefined =
                        Object.keys(segment.vehicleGroups).indexOf(
                            vehicleGroupNumber
                        ) > -1
                    const vehicleGroup = isGroupDefined
                        ? segment.vehicleGroups[vehicleGroupNumber]
                        : [0, 0, 0]
                    return (
                        <React.Fragment key={groupIndex}>
                            <StyledTableCell key={1 + groupIndex * 3}>
                                <TextField
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    margin="dense"
                                    sx={{ width: "200px" }}
                                    inputProps={{
                                        min: 1,
                                        max: 10000,
                                        step: 1,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                {currentForm.currency}
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <Tooltip
                                                title={`The fixed price 18 holes is set for the category. ${clickToSeeRevenueDistribution}`}
                                                placement="top"
                                            >
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleQuestionClick(
                                                            segment,
                                                            vehicleGroupNumber,
                                                            0,
                                                            selected
                                                        )
                                                    }
                                                >
                                                    <HelpOutlineIcon />
                                                </IconButton>
                                            </Tooltip>
                                        ),
                                    }}
                                    onChange={(e) =>
                                        handlePriceChange(
                                            e,
                                            segment,
                                            vehicleGroupNumber,
                                            currentForm
                                        )
                                    }
                                    value={vehicleGroup[0]}
                                />
                            </StyledTableCell>
                            {nineHolesEnabled ? (
                                <StyledTableCell key={2 + groupIndex * 3}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        sx={{ width: "200px" }}
                                        inputProps={{
                                            min: 1,
                                            max: 100000,
                                            step: 1,
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {currentForm.currency}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={`The rental price for 9 holes`}
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleQuestionClick(
                                                                    segment,
                                                                    vehicleGroupNumber,
                                                                    1,
                                                                    selected
                                                                )
                                                            }
                                                        >
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => {
                                            handlePriceChangeNineHoles(
                                                e,
                                                segment,
                                                vehicleGroupNumber
                                            )
                                        }}
                                        value={vehicleGroup?.[2] ?? 0}
                                    />
                                </StyledTableCell>
                            ) : null}
                            {!ppmDisabled ? (
                                <StyledTableCell key={3 + groupIndex * 3}>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        inputProps={{
                                            min: 0.01,
                                            max: 8,
                                            step: 0.01,
                                        }}
                                        sx={{ minWidth: "300px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {currentForm.startingFee}{" "}
                                                    {currentForm.currency} +
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography
                                                        display={"inline-block"}
                                                    >
                                                        {currentForm.currency} /
                                                        min
                                                    </Typography>
                                                    <Tooltip
                                                        title={`The minute price is calculated by dividing fixed price minus starting fee over average playing time. If you update the fixed price, the minute price will also be updated. This can also be set manually here. A fixed starting fee of ${currentForm.startingFee} ${currentForm.currency} is added to the price. ${clickToSeeRevenueDistribution}`}
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleQuestionClick(
                                                                    segment,
                                                                    vehicleGroupNumber,
                                                                    2,
                                                                    selected
                                                                )
                                                            }
                                                        >
                                                            <HelpOutlineIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={(e) => {
                                            handlePriceChangePPM(
                                                e,
                                                segment,
                                                vehicleGroupNumber
                                            )
                                        }}
                                        value={vehicleGroup[1]}
                                    />
                                </StyledTableCell>
                            ) : null}
                        </React.Fragment>
                    )
                }
            )}
            <StyledTableCell>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => deleteSegment(segment.key)}
                >
                    Delete
                </Button>
            </StyledTableCell>
        </TableRow>
    )
}

function SegmentTable() {
    const currentForm = useSelector((state) => state.settingsForm)
    const course = useSelector((state) => state.firestore?.data?.field)

    const { vehicleGroups } = course
    if (!vehicleGroups) {
        return null
    }
    const rentableGroups = Object.keys(vehicleGroups)
        .filter((x) => vehicleGroups[x].bookable)
        .reduce(
            (current, key) => ({ ...current, [key]: vehicleGroups[key] }),
            {}
        )
    const segments = currentForm.segments
    if (!vehicleGroups || !segments) {
        return null
    }
    const multipleVehicleGroups = Object.keys(rentableGroups).length > 1
    return (
        <TableContainer component={Paper}>
            <Table size="small" sx={{ minWidth: 650 }}>
                <caption>
                    <Typography>
                        Price table. Set price and price per minute for each
                        user category.{" "}
                        {multipleVehicleGroups
                            ? "Set the price per vehicle group and per category."
                            : null}
                        {Object.keys(segments).length < 6 ? (
                            <Button
                                color="secondary"
                                onClick={() =>
                                    addNewSegment(segments, rentableGroups)
                                }
                            >
                                <AddIcon />
                                Add category
                            </Button>
                        ) : null}
                    </Typography>
                    <Typography fontSize={"12pt"} display={"inline"}>
                        To see the revenue share distribution, press the help
                        icon{" "}
                    </Typography>
                    <HelpOutlineIcon
                        style={{ verticalAlign: "middle" }}
                        size={"12px"}
                    />
                </caption>
                {getTableHeader(rentableGroups, course)}
                <TableBody>
                    {Object.keys(segments)
                        .sort((x, y) => segments[x].key - segments[y].key)
                        .map((segmentId) => {
                            return getSegmentRow(
                                segments[segmentId],
                                currentForm,
                                course
                            )
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default function SegmentsPricing() {
    return (
        <Box sx={{ margin: 1 }}>
            <SegmentTable />
            <RevenueShares />
        </Box>
    )
}
