import React from "react"
import CssBaseline from "@mui/material/CssBaseline"
import { formStyle } from "../../assets/styles/views/formStyle"
import { makeStyles } from "@mui/styles"

const useStyles = (fullHeight) => makeStyles(formStyle(fullHeight))

export default function RegisterContainer(props) {
    const { fullHeight, children } = props
    const styles = useStyles(fullHeight)()

    return (
        <div className={styles.root}>
            <CssBaseline />
            <div className={styles.body}>{children}</div>
        </div>
    )
}
