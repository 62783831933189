import React, { useState } from "react"
import { Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import Button from "@mui/material/Button"
import db, { storage } from "../../rootWrapper/firebase"
import { golfCourses } from "../../constants/dbPaths"
import { getStorage, ref, deleteObject } from "firebase/storage"

const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
}

const img = {
    display: "block",
    width: "auto",
    height: "100%",
}

const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
}

export function ThumbsRow(props) {
    const { items, fileNames } = props
    const [deleted, setDeleted] = useState([])

    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })

    const handleDelete = (fileName) => {
        console.log("delete ", fileName)
        const storage = getStorage()
        const fileRef = ref(storage, fileName)
        deleteObject(fileRef)
            .then((r) => {
                const courseRef = db.collection(golfCourses).doc(courseDocId)
                return db
                    .runTransaction((transaction) =>
                        transaction.get(courseRef).then((courseDoc) => {
                            let { images } = courseDoc.data()
                            images = images.filter((x) => !x.includes(fileName))
                            transaction.update(courseRef, { images })
                        })
                    )
                    .then(() => {
                        setDeleted([...deleted, fileName])
                    })
            })
            .catch((e) => console.error(e.message))
    }
    function Rows() {
        return (
            <>
                {items.map((item, i) => {
                    if (Object.values(deleted).indexOf(fileNames[i]) > -1) {
                        return (
                            <Grid item xs key={i}>
                                <Grid
                                    container
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        <div style={thumb}>
                                            <div style={thumbInner}>
                                                <Typography variant="h6">
                                                    Deleted
                                                </Typography>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    }
                    return (
                        <Grid item xs key={i}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                            >
                                <Grid item xs>
                                    <div style={thumb}>
                                        <div style={thumbInner}>
                                            <img
                                                src={item}
                                                style={img}
                                                alt="Profile picture"
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <Button
                                        color="secondary"
                                        aria-label="remove"
                                        onClick={(e) =>
                                            handleDelete(fileNames[i])
                                        }
                                        variant="outlined"
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </>
        )
    }
    if (items) {
        return (
            <Grid container direction="row">
                <Rows />
            </Grid>
        )
    }
    return <></>
}
