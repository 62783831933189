import React, { useEffect, useState } from "react"
import { useFirestoreConnect } from "react-redux-firebase"
import { users } from "../constants/dbPaths"
import CircleLoader from "../Components/loader"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useSelector } from "react-redux"

function userHook(uid) {
    useFirestoreConnect([
        {
            collection: users,
            doc: uid || null,
            storeAs: "user",
        },
    ])
}

export default function UserWrap(props) {
    const [uid, setUid] = useState()
    const fireAuth = getAuth()

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(fireAuth, (user) => {
            console.log("Uid", user?.uid || "")
            setUid(user?.uid || "")
        })
        return unsubscribe
    }, [fireAuth])

    const user = useSelector((state) => state.firestore?.data?.user)
    userHook(uid)

    if (!user) return <CircleLoader fillScreen />
    return props.children
}
