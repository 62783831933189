import styled from "@emotion/styled"
import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { ReactComponent as KeylessIcon } from "../../assets/img/keylessIcon.svg"
import TimeIcon from "@mui/icons-material/AccessTime"
import LineChartOneDay from "../../Components/statCards/timeOfBooking"
import WeekdayCount from "../../Components/statCards/weekdayCount"
import { HelpQuestion } from "./income"
import theme from "../../assets/styles/muiTheme"

const defaultDot = {
    height: "10px",
    width: "10px",
    borderRadius: "10px",
    margin: "4px",
}

const YellowDot = styled("div")(({ theme }) => ({
    ...defaultDot,
    backgroundColor: theme.palette.secondary.main,
}))

export default function UserRidesSection() {
    const {
        weekdayBookings,
        appVsDash,
        appStarted,
        averageTimeBefore,
        totalRentalTime,
        totalBookings,
    } = useSelector((state) => {
        return state.bookingHistory.stats || {}
    })
    const averageRentalTime = Math.round(totalRentalTime / totalBookings)

    const { bookings } = useSelector((state) => {
        return state.bookingHistory
    })
    const bookingSum = appVsDash.app + appVsDash.dash
    const appPercentage = Math.round((appVsDash.app / bookingSum) * 100)
    const dashPercentage = 100 - appPercentage
    const averageTimeDays = Math.floor(
        averageTimeBefore / (1000 * 60 * 60 * 24)
    )
    const averageTimeHours = Math.floor(
        (averageTimeBefore - averageTimeDays * (1000 * 60 * 60 * 24)) /
            (1000 * 60 * 60)
    )
    const hourFloat = averageRentalTime / (60 * 60 * 1000)
    const minutesFloat = (averageRentalTime % (60 * 60 * 1000)) / (60 * 1000)
    const averageTimeMinutes = Math.floor(
        (averageTimeBefore -
            averageTimeDays * (1000 * 60 * 60 * 24) -
            averageTimeHours * (1000 * 60 * 60)) /
            (1000 * 60)
    )
    const hours = isNaN(hourFloat) ? " " : hourFloat.toFixed()
    const minutes = isNaN(minutesFloat) ? " " : minutesFloat.toFixed()

    return (
        <Box>
            <Typography color="#DCDCDCC9">Your rides</Typography>
            <Box
                sx={{
                    p: 4,
                    pb: 0,
                }}
            >
                <Grid container direction="row" minHeight="400px">
                    <Grid item xs minWidth={"300px"} maxHeight="400px">
                        <Typography>Bookings</Typography>
                        <Grid
                            item
                            xs
                            container
                            direction="column"
                            height="100%"
                            justifyContent="center"
                            alignItems={"center"}
                        >
                            <Grid
                                item
                                xs
                                container
                                alignItems={"center"}
                                diretion="row"
                                style={{ maxHeight: "100px" }}
                            >
                                <Grid item xs={4}>
                                    <Box display={"flex"} alignItems="center">
                                        <YellowDot display="inline" />
                                        <Typography
                                            display={"inline"}
                                            sx={{ pl: 2 }}
                                            fontSize={42}
                                            fontWeight="bold"
                                        >
                                            {bookingSum}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    container
                                    justifyContent="center"
                                    alignItems={"center"}
                                    direction={"column"}
                                >
                                    <Grid item xs container direciton="row">
                                        <Grid item xs={2}>
                                            <Typography fontWeight={"bold"}>
                                                {isNaN(appPercentage)
                                                    ? "- "
                                                    : appPercentage}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="#DCDCDC">
                                                EAGL App
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs container direciton="row">
                                        <Grid item xs={2}>
                                            <Typography fontWeight={"bold"}>
                                                {isNaN(dashPercentage)
                                                    ? "- "
                                                    : dashPercentage}
                                                %
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            <Typography color="#DCDCDC">
                                                EAGL Dashboard
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs
                                container
                                alignItems={"center"}
                                diretion="row"
                                style={{ maxHeight: "100px" }}
                            >
                                <Grid item xs={4}>
                                    <Box display={"flex"} alignItems="center">
                                        <KeylessIcon
                                            fill={theme.palette.secondary.main}
                                            style={{ width: "20px" }}
                                        />
                                        <Typography
                                            display={"inline"}
                                            sx={{ pl: 2 }}
                                            fontSize={42}
                                            fontWeight="bold"
                                        >
                                            {appStarted}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs>
                                    <Typography>
                                        <Typography
                                            display={"inline-block"}
                                            color="secondary"
                                        >
                                            Keyless
                                        </Typography>{" "}
                                        rentals started by customers{" "}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} minWidth={"300px"} maxHeight="400px">
                        <WeekdayCount dayCount={weekdayBookings} />
                    </Grid>
                </Grid>
                <Grid container direction="row" minHeight="400px">
                    <Grid item xs minWidth={"300px"} maxHeight="400px">
                        <LineChartOneDay bookings={bookings} />
                    </Grid>
                    <Grid
                        item
                        xs
                        minWidth={"300px"}
                        maxHeight="400px"
                        minHeight="100px"
                    >
                        <Grid
                            item
                            xs
                            container
                            direction="column"
                            height="100%"
                            justifyContent="space-evenly"
                            alignItems={"center"}
                        >
                            <Grid
                                item
                                xs
                                container
                                alignItems={"center"}
                                diretion="row"
                                style={{ height: "100%" }}
                            >
                                <Grid item xs>
                                    <Typography>
                                        Average Rental Duration{" "}
                                        <HelpQuestion
                                            tooltip={
                                                "Average duration of the rentals."
                                            }
                                        />{" "}
                                    </Typography>
                                    <Box display={"flex"} alignItems="center">
                                        <TimeIcon
                                            color="secondary"
                                            style={{ width: "20px" }}
                                        />{" "}
                                        <Typography
                                            display={"inline"}
                                            sx={{ pl: 2 }}
                                            fontSize={62}
                                            fontWeight="bold"
                                        >
                                            {hours}h {minutes}m
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs
                                container
                                alignItems={"center"}
                                diretion="row"
                                style={{ height: "100%" }}
                            >
                                <Grid item xs>
                                    <Typography>
                                        Average Planning Time{" "}
                                        <HelpQuestion
                                            tooltip={
                                                "Average time before the booking the booking was created."
                                            }
                                        />{" "}
                                    </Typography>
                                    <Box display={"flex"} alignItems="center">
                                        <TimeIcon
                                            color="secondary"
                                            style={{ width: "20px" }}
                                        />
                                        <Typography
                                            display={"inline"}
                                            sx={{ pl: 2 }}
                                            fontSize={62}
                                            fontWeight="bold"
                                        >
                                            {!isNaN(averageTimeDays)
                                                ? averageTimeDays
                                                : " "}
                                            d{" "}
                                            {!isNaN(averageTimeHours)
                                                ? averageTimeHours
                                                : " "}
                                            h{" "}
                                            {!isNaN(averageTimeMinutes)
                                                ? averageTimeMinutes
                                                : " "}
                                            m
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
