import React from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import UsersWithPermission from "../Components/Permissions/PermissionUserList"
import PermissionCheckbox from "../Components/Permissions/permissionCheckbox"
import AddNewUserPermission from "../Components/Permissions/addNewUserPermission"
import CourseLinkingIdDisplay from "../Components/Permissions/courseLinkingId"
import { Box } from "@mui/material"

export default function Permissions(props) {
    return (
        <>
            <Box right={0} sx={{ pr: 2 }} zIndex={1} position="absolute">
                <CourseLinkingIdDisplay />
            </Box>
            <Box m={2}>
                <Grid container direction="column" spacing={2}>
                    <Grid item xs>
                        <AddNewUserPermission />
                    </Grid>
                    <Grid item xs container spacing={2} direction={"row"}>
                        <Grid item xs>
                            <Typography>Existing users</Typography>
                            <UsersWithPermission />
                        </Grid>
                        <Grid item xs>
                            <PermissionCheckbox />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
