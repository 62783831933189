import { statusAdmin, statusMaintenance } from "../../../constants/statusTypes"

const getTlfFromBooking = (booking) => {
    if (booking.status === statusAdmin) {
        return String(booking.tlf)
    }
    if (booking.status === statusMaintenance) {
        return booking.note
    }
    if (!booking.tlf || booking.tlf === "") {
        return "NaN"
    }
    if (booking.bookingType === "admin") {
        return String(booking.tlf)
    }
    if (booking.bookingType === "maintenance") {
        return booking.note
    }
    if (booking.phonePrefix) {
        return String(booking.phonePrefix) + " " + String(booking.tlf)
    }
    return booking.tlf
}

export default getTlfFromBooking
