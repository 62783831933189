import Grid from "@mui/material/Grid"
import makeStyles from "@mui/styles/makeStyles"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
    Checkbox,
    FormControlLabel,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import {
    RIDEPASSFORM_CLEAR,
    RIDEPASS_ADD_SEGMENT,
    RIDEPASS_REMOVE_SEGMENT,
    SET_ACTIVE_RIDEPASS_NOT_LOADED,
    UPDATECREATE_RIDEPASS_FORM,
} from "../../constants/actionTypes"
import store from "../../store"
import TabPanel from "../TabPannels/TabPannel"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { functions } from "../../rootWrapper/firebase"
import theme from "../../assets/styles/muiTheme"
import enLocale from "date-fns/locale/en-GB"
import CreateHeader from "./createHeader"
import { getDateFromTs } from "../RidePassCard"
import { httpsCallable } from "firebase/functions"

const textFieldSize = "small"

const useCouponListStyles = makeStyles((theme) => ({
    root: {
        height: `${100}%`,
        minHeight: `${100}vh`,
        paddingRight: `${20}px`,
        backgroundColor: theme.palette.primary.main,
    },
    formStyle: {
        paddingRight: `${10}px`,
        paddingLeft: `${10}px`,
    },
    yellowTextBox: {
        margin: theme.spacing(1),
        color: theme.palette.secondary,
        "& label.Mui-focused": {
            color: "white",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "white",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.secondary.main,
            },
            "&:hover fieldset": {
                borderColor: theme.palette.secondary.light,
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
        },
    },
}))

const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))

export default function CreateRidepass() {
    const classes = useCouponListStyles(theme)
    const ridepassForm = useSelector((state) => state.ridepass.createPassForm)
    const initStatus = {
        success: false,
        loading: false,
        error: false,
        errorMessage: "",
    }
    const [status, setStatus] = useState(initStatus)
    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (name === "price") {
            if (value == "") {
                store.dispatch({
                    type: UPDATECREATE_RIDEPASS_FORM,
                    payload: { [name]: "" },
                })
            } else {
                store.dispatch({
                    type: UPDATECREATE_RIDEPASS_FORM,
                    payload: { [name]: parseFloat(value) },
                })
            }
        } else {
            store.dispatch({
                type: UPDATECREATE_RIDEPASS_FORM,
                payload: { [name]: value },
            })
        }
    }

    const handleDateChange = (value, name) => {
        if (value === null) {
            store.dispatch({
                type: UPDATECREATE_RIDEPASS_FORM,
                payload: { [name]: "" },
            })
        } else {
            store.dispatch({
                type: UPDATECREATE_RIDEPASS_FORM,
                payload: { [name]: value.getTime() },
            })
        }
    }

    const handleLimitChange = (e) => {
        const { name, value } = e.target
        if (name === "usage-limit") {
            if (ridepassForm.usageLimited) {
                store.dispatch({
                    type: UPDATECREATE_RIDEPASS_FORM,
                    payload: { usageLimit: value },
                })
            }
        }
    }
    const handleUsageLimit = (e) => {
        const { checked } = e.target
        store.dispatch({
            type: UPDATECREATE_RIDEPASS_FORM,
            payload: { usageLimited: !checked },
        })
    }

    const usageLimitLabels = ["Total Usage Limited", "Unlimited Usage"]
    const currency = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("currency") > -1) {
            return field.currency.toUpperCase()
        }
        return "Nan"
    })
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return null
    })
    const segments = useSelector((state) => {
        const { field } = state.firestore.data
        if (Object.keys(field).indexOf("classes") > -1) {
            return Object.keys(field.classes).sort((x, y) => x.localeCompare(y))
        }
        return []
    })

    const handleSegChange = (e) => {
        const { name, checked } = e.target
        if (checked) {
            store.dispatch({
                type: RIDEPASS_ADD_SEGMENT,
                payload: { segment: name },
            })
        } else {
            store.dispatch({
                type: RIDEPASS_REMOVE_SEGMENT,
                payload: { segment: name },
            })
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus({ ...initStatus, loading: true })
        if (ridepassForm.segments.length === 0) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "No categories selected",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (ridepassForm.validFrom > ridepassForm.validTo) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Valid to must be larger than valid from",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }

        if (ridepassForm.validTo < Date.now()) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Valid to must be a date in the future",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }
        if (
            ridepassForm.validTo - ridepassForm.validFrom >
            366 * 24 * 60 * 60 * 1000
        ) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Can't make ridepass lasting longer than a year",
            }))
            wait(2000).then(() => setStatus(initStatus))
            return
        }

        if (ridepassForm.name.length < 5) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "Coupon Name must be at least 5 characters",
            }))
            wait(3000).then(() => setStatus(initStatus))
            return
        }
        if (ridepassForm.usageLimited && !parseInt(ridepassForm.usageLimit)) {
            setStatus((status) => ({
                ...status,
                loading: false,
                error: true,
                errorMessage: "The usage Limit must be >0 if activated",
            }))
            wait(3000).then(() => setStatus(initStatus))
            return
        }

        const createRidepass = httpsCallable(functions, "createRidepass")
        const validToEndOfDay = new Date(ridepassForm.validTo).setHours(
            23,
            59,
            59
        )
        return createRidepass({
            courseDocId,
            ...ridepassForm,
            validTo: validToEndOfDay,
        })
            .then(() => {
                store.dispatch({ type: RIDEPASSFORM_CLEAR })
                store.dispatch({ type: SET_ACTIVE_RIDEPASS_NOT_LOADED })
                setStatus({ ...initStatus, success: true })
                wait(3000).then(() => {
                    setStatus(initStatus)
                })
            })
            .catch((e) => {
                console.error(e.message)
                setStatus({
                    ...initStatus,
                    error: true,
                    errorMessage: e.message,
                })
                wait(4000).then(() => setStatus(initStatus))
            })
    }

    return (
        <div className={classes.root}>
            <CreateHeader />
            <form onSubmit={handleSubmit} className={classes.formStyle}>
                <Grid
                    container
                    alignContent="center"
                    direction="column"
                    spacing={1}
                >
                    <Grid item xs style={{ marginTop: `${24}px` }}>
                        <Grid container direction="column">
                            <Grid item xs>
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Coupon Name"
                                    size={textFieldSize}
                                    value={ridepassForm.name}
                                    autoComplete="off"
                                    name="name"
                                    placeholder=""
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleInputChange}
                                    className={classes.yellowTextBox}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid
                            container
                            direction="row"
                            alignContent="center"
                            alignItems="center"
                        >
                            <Grid item xs>
                                {usageLimitLabels.map((setting, index) => (
                                    <TabPanel
                                        horizontal
                                        key={index}
                                        value={
                                            ridepassForm.usageLimited ? 0 : 1
                                        }
                                        noPad
                                        index={index}
                                    >
                                        <TextField
                                            id="standard-number"
                                            label={setting}
                                            type="number"
                                            name="usage-limit"
                                            InputProps={{
                                                inputProps: {
                                                    min: 0,
                                                    step: 1,
                                                },
                                            }}
                                            size={textFieldSize}
                                            disabled={
                                                !ridepassForm.usageLimited
                                            }
                                            value={
                                                ridepassForm.usageLimited
                                                    ? ridepassForm.usageLimit
                                                    : ""
                                            }
                                            color="secondary"
                                            variant="outlined"
                                            onChange={handleLimitChange}
                                            className={classes.yellowTextBox}
                                        />
                                    </TabPanel>
                                ))}
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isLimitedCheckbox"
                                            color="secondary"
                                            checked={!ridepassForm.usageLimited}
                                            onClick={handleUsageLimit}
                                        />
                                    }
                                    labelPlacement="end"
                                    label="Unlimited Usage"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Grid container direction="row" spacing={2}>
                            <Grid item xs>
                                <LocalizationProvider
                                    adapterLocale={enLocale}
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        autoOk
                                        emptyLabel
                                        size={textFieldSize}
                                        disableToolbar
                                        variant="inline"
                                        label="Valid From"
                                        id="date-picker-inline"
                                        inputFormat="dd/MM/yyyy"
                                        value={ridepassForm.validFrom}
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        onChange={(e) =>
                                            handleDateChange(e, "validFrom")
                                        }
                                        color="secondary"
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={enLocale}
                                >
                                    <DatePicker
                                        autoOk
                                        emptyLabel
                                        size={textFieldSize}
                                        disableToolbar
                                        variant="inline"
                                        label="Valid To"
                                        id="date-picker-inline"
                                        inputFormat="dd/MM/yyyy"
                                        value={ridepassForm.validTo}
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        onChange={(e) =>
                                            handleDateChange(e, "validTo")
                                        }
                                        color="secondary"
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle1">
                            Applies to Category(s):
                        </Typography>
                        <Grid container direction="column">
                            {segments.map((seg, index) => (
                                <Grid key={index} item xs>
                                    <Grid container direction="row">
                                        <Grid item xs>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        color="secondary"
                                                        onClick={
                                                            handleSegChange
                                                        }
                                                        name={seg}
                                                        checked={
                                                            !!(
                                                                ridepassForm.segments.indexOf(
                                                                    seg
                                                                ) > -1
                                                            )
                                                        }
                                                    />
                                                }
                                                label={seg}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs style={{ marginTop: `${24}px` }}>
                        <Grid container direction="column">
                            <Grid item xs>
                                <TextField
                                    required
                                    id="filled-required"
                                    label="Price"
                                    type="number"
                                    size={textFieldSize}
                                    value={ridepassForm.price}
                                    autoComplete="off"
                                    name="price"
                                    placeholder=""
                                    color="secondary"
                                    variant="outlined"
                                    fullWidth
                                    onChange={handleInputChange}
                                    className={classes.yellowTextBox}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs>
                        <SuccessLoader
                            handleSubmit={handleSubmit}
                            error={status.errorMessage}
                            status={status}
                        >
                            {"Create Coupon"}
                        </SuccessLoader>
                    </Grid>
                    <Grid item xs>
                        {ridepassForm.price &&
                        !(
                            ridepassForm.usageLimited &&
                            !ridepassForm.usageLimit
                        ) ? (
                            <Typography fontStyle={"italic"} variant="body2">
                                This will create a coupon costing{" "}
                                {ridepassForm.price.toFixed(2)} {currency} to be
                                purchased in the app. The coupon gives access to{" "}
                                {ridepassForm.usageLimited
                                    ? ridepassForm.usageLimit
                                    : "Unlimited"}{" "}
                                free rides.{" "}
                                {ridepassForm.usageLimited
                                    ? `This is equivalent to an average of ${(
                                          Math.round(
                                              (ridepassForm.price * 100) /
                                                  ridepassForm.usageLimit
                                          ) / 100
                                      ).toFixed(2)} ${currency} per ride.`
                                    : null}{" "}
                                The pass is valid from{" "}
                                {getDateFromTs(ridepassForm.validFrom)} to{" "}
                                {getDateFromTs(ridepassForm.validTo)}. After
                                this the user can no longer use the coupon for
                                free rides. The coupon will be valid for the
                                following user categories:{" "}
                                {ridepassForm.segments.map(
                                    (x) => String(x) + ", "
                                )}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}
