import React, { useEffect, useState } from "react"
import { Box, Button, Grid, LinearProgress, TextField } from "@mui/material"
import { useSelector } from "react-redux"
import { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import { getAvailabilityAccess } from "../../constants/accessPermissionsChecks"
import { SuccessLoader } from "../settingsForm/loader/successLoader"

export default function RentalLimitChange(props) {
    const { vehicle } = props
    const [loading, setLoading] = useState(false)

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )
    const { bookingDailyLimit, cartDocId } = vehicle
    const [newRentalLimit, setNewRentalLimit] = useState(bookingDailyLimit || 0)
    const canChangeAv = getAvailabilityAccess()

    useEffect(() => {
        if (!isNaN(bookingDailyLimit)) {
            setNewRentalLimit(bookingDailyLimit)
        }
    }, [bookingDailyLimit, cartDocId])

    const handleChange = (e) => {
        e.preventDefault
        const { value } = e.target
        if (!canChangeAv) {
            return
        }
        if (!isNaN(value)) {
            setNewRentalLimit(value)
        }
    }

    const changeRentalsOfCart = httpsCallable(functions, "setBookingsPerDay")

    const handleSubmit = async () => {
        setLoading(true)
        if (!isNaN(newRentalLimit) && Number(newRentalLimit) > 0) {
            changeRentalsOfCart({
                courseDocId,
                cartDocId,
                rentalCount: Number(newRentalLimit),
            })
                .then(() => {
                    setLoading(false)
                    console.log("Success", newRentalLimit)
                })
                .catch((e) => {
                    console.error(e.message)
                    setLoading(false)
                    setNewRentalLimit(bookingDailyLimit)
                })
        }
    }
    const shouldOkBeActive = () => {
        if (!canChangeAv) {
            console.log("No permissions to change availability")
            return false
        }
        if (!newRentalLimit) {
            return false
        }
        if (parseInt(newRentalLimit) === parseInt(bookingDailyLimit)) {
            return false
        }
        return true
    }

    return (
        <Box sx={{ margin: 1 }}>
            {loading && <LinearProgress color="secondary" />}
            <Grid
                container
                component="label"
                alignItems="center"
                direction="row"
                justifyContent="center"
                sx={{ width: "140px" }}
            >
                <Grid item xs sx={{ margin: 0 }}>
                    <TextField
                        value={newRentalLimit}
                        onChange={handleChange}
                        label=""
                        size={"small"}
                        type="number"
                        autoComplete="off"
                        name="booking-daily-limit"
                        style={{ marginTop: 0 }}
                        InputProps={{
                            inputProps: {
                                min: 1,
                                step: 1,
                            },
                        }}
                    />
                </Grid>
                <Grid item xs sx={{ margin: 0 }}>
                    <SuccessLoader
                        size="small"
                        disabled={!shouldOkBeActive() || false}
                        status={{
                            success: false,
                            loading: false,
                            error: false,
                        }}
                        noAccess={!canChangeAv}
                        handleSubmit={handleSubmit}
                        variant="contained"
                        sx={{ marginLeft: 0.5, minWidth: "32px" }}
                    >
                        Save
                    </SuccessLoader>
                </Grid>
            </Grid>
        </Box>
    )
}
