import React from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { CssBaseline, Typography } from "@mui/material"
import Login from "./views/loginForm"
import ProtectedRoutes from "./rootWrapper/ProtectedRoutes"
import NoPermissions from "./views/noPermissions"
import ForgotPass from "./views/forgotPass"
import Drawer from "./rootWrapper/drawer"
import theme from "./assets/styles/muiTheme"
import useAppStyle from "./assets/styles/App"
import Register from "./views/Register"
import SubTopbar from "./Components/SubTopbar"
import SigninStatus from "./models/signinStatus"
import GoogleMapWrap from "./Components/GoogleMapWrap"
import SignOutController from "./Components/Signout"
import LogController from "./Components/COIController"
import CookiePopup from "./Components/COIController/CoockiePopup"

function SideBar(props) {
    const { signOut, children } = props
    const { authIsLoaded, authUid, courseDocId } = SigninStatus()
    const hasAccess = authIsLoaded && authUid && courseDocId

    if (hasAccess) {
        return <Drawer signOut={signOut}>{children}</Drawer>
    }
    return children
}

function App() {
    const classes = useAppStyle(theme)
    const { signOut } = SignOutController()
    LogController()

    return (
        <CssBaseline>
            <div className={classes.rootWrap}>
                <GoogleMapWrap>
                    <SideBar signOut={signOut}>
                        <SubTopbar />
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/logout"
                                element={
                                    <Typography
                                        variant="h1"
                                        onClick={signOut}
                                        color="green"
                                    >
                                        Log Out
                                    </Typography>
                                }
                            />
                            <Route path="/register/*" element={<Register />} />
                            <Route
                                path="/forgotpass"
                                element={<ForgotPass />}
                            />
                            <Route
                                path="/nopermission"
                                element={<NoPermissions type="noperm" />}
                            />
                            <Route path="*" element={<ProtectedRoutes />} />
                        </Routes>
                    </SideBar>
                </GoogleMapWrap>
                <CookiePopup />
            </div>
        </CssBaseline>
    )
}

export default App
