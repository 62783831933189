import React from "react"
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { dayMonthYear } from "../../Components/dateTools"

export default function InvoiceTable(props) {
    const { invoices } = props

    return (
        <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ minWidth: "100px" }}>
                            Date
                        </TableCell>
                        <TableCell style={{ minWidth: "80px" }}>
                            Status
                        </TableCell>
                        <TableCell style={{ minWidth: "100px" }}>
                            Amount Due
                        </TableCell>
                        <TableCell style={{ minWidth: "100px" }}>
                            Amount Paid
                        </TableCell>
                        <TableCell>Link</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invoices
                        ? invoices.map((x, index) => {
                              const currency = x.currency
                              return (
                                  <TableRow key={index}>
                                      <TableCell>
                                          {dayMonthYear(
                                              new Date(x.created * 1000)
                                          )}
                                      </TableCell>
                                      <TableCell>{x.status}</TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                          {Number(x.amount_due / 100).toFixed(
                                              2
                                          )}{" "}
                                          {currency.toUpperCase()}
                                      </TableCell>
                                      <TableCell sx={{ textAlign: "right" }}>
                                          {Number(x.amount_paid / 100).toFixed(
                                              2
                                          )}{" "}
                                          {currency.toUpperCase()}
                                      </TableCell>
                                      <TableCell>
                                          <Typography
                                              component="a"
                                              color="secondary"
                                              target="_blank"
                                              href={x.hosted_invoice_url}
                                          >
                                              Link
                                          </Typography>
                                      </TableCell>
                                  </TableRow>
                              )
                          })
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
