import { successColor } from "../general-material-styles"
import theme from "../muiTheme"

const formStyle = (fullHeight) => ({
    root: {
        width: "100vw",
        height: fullHeight ? "100%" : null,
    },
    body: {
        display: "grid",
        margin: "auto",
        padding: 8,
        minHeight: "60vh",
        height: fullHeight ? "100%" : null,
        minWidth: "50%",
        maxWidth: "800px",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
        color: "white",
        overflow: "auto",
    },
    avatar: {
        margin: `${30}px`,
        backgroundColor: "lightblue",
    },
    form: {
        width: "100%",
        marginTop: `${20}px`,
    },
    submit: {},
    error: {
        color: " #8b0000",
        margin: 0,
    },
    errorGrid: {
        height: "30px",
    },
    successMessageStyle: {
        color: successColor,
        margin: `${0}px`,
    },
})

export { formStyle }
