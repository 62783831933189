import React from "react"
import { Box, Button, Grid, Typography } from "@mui/material"
import UpgradePromoTextBox from "./promoTextBox"
import { functions } from "../../rootWrapper/firebase"
import { httpsCallable } from "firebase/functions"
import theme from "../../assets/styles/muiTheme"
import CircleLoader from "../../Components/loader"

const loadIfNoValue = (element, value = null) => {
    if (value === null) {
        if (element) {
            return element
        }
    }
    if (value) {
        return element
    }
    return <CircleLoader />
}
const applyForUpgrade = httpsCallable(functions, "markRequestedUpgrade")

const apply = async (courseDocId) => {
    await applyForUpgrade({ courseDocId }).catch((e) => {
        console.error(e.message)
    })
}

export default function RequestUpgrade(props) {
    const { subData, upgradeModel } = props
    return (
        <Box>
            <Grid container item direction="column" xs alignItems={"center"}>
                <Grid item xs>
                    <Box sx={{ m: 2, p: 4 }}>
                        {loadIfNoValue(
                            <Typography
                                fontWeight={800}
                                fontSize="18pt"
                                textAlign={"center"}
                                p={1}
                            >
                                .EAGL{" "}
                                <Typography
                                    fontWeight={"inherit"}
                                    component="span"
                                    display={"inline-block"}
                                    color="secondary"
                                    fontSize="inherit"
                                >
                                    {upgradeModel?.headerText}
                                </Typography>{" "}
                            </Typography>,
                            upgradeModel?.headerText
                        )}
                    </Box>
                </Grid>
                <Grid item style={{ width: "600px", minWidth: "60%" }} xs>
                    <Box
                        width={"100%"}
                        sx={{
                            borderColor: theme.palette.secondary.main,
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderRadius: "4px",
                            pr: 4,
                            pl: 4,
                            pb: 4,
                            pt: 4,
                            backgroundColor: theme.palette.primary.main,
                            textAlign: "left",
                        }}
                    >
                        <Box maxWidth={"90%"}>
                            {loadIfNoValue(
                                <Typography
                                    color="#DCDCDC"
                                    pb={2}
                                    fontSize={"16pt"}
                                >
                                    You might qualify for{" "}
                                    <Typography
                                        component={"span"}
                                        display={"inline-block"}
                                        fontSize="inherit"
                                        textAlign={"center"}
                                    >
                                        .EAGL{" "}
                                        <Typography
                                            component="span"
                                            display={"inline-block"}
                                            color="secondary"
                                            fontSize="inherit"
                                        >
                                            {upgradeModel?.headerText}
                                        </Typography>
                                        !
                                    </Typography>
                                </Typography>,
                                upgradeModel?.headerText
                            )}

                            <Typography fontSize={"11pt"} color="#DCDCDC">
                                To ensure a quality experience for keyless
                                customers EAGL only allows for approved
                                customers to get keyless access. To apply to
                                check if you are qualified press the button
                                bellow or get in touch with a professional to
                                check if you qualify and if it is the right
                                product for you.
                            </Typography>
                        </Box>

                        <Grid
                            container
                            direction={"row"}
                            justifyContent="center"
                            p={3}
                        >
                            <Grid
                                item
                                xs
                                style={{ display: "table", flex: "none" }}
                            >
                                {subData?.upgrade?.requested ? (
                                    <Typography
                                        textAlign={"center"}
                                        fontWeight={600}
                                    >
                                        Applied. <br /> Awaiting evaluation
                                    </Typography>
                                ) : (
                                    loadIfNoValue(
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{
                                                width: "200px",
                                                fontWeight: "bold",
                                            }}
                                            onClick={() =>
                                                apply(subData.courseDocId)
                                            }
                                        >
                                            Apply Now
                                        </Button>,
                                        subData?.courseDocId
                                    )
                                )}
                            </Grid>
                        </Grid>
                        <UpgradePromoTextBox />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
