import { useSelector } from "react-redux"

function getLockAccess() {
    return useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featureEngine
                ?.access || state.firestore.data?.user?.admin
    )
}

function getAvailabilityAccess() {
    return useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featAvailability
                ?.access || state.firestore.data?.user?.admin
    )
}

function getCreateBookingAccess() {
    return useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featBooking
                ?.access || state.firestore.data?.user?.admin
    )
}
function getEditBookingAccess() {
    return useSelector(
        (state) =>
            state.firestore.data?.user?.accessPermissions?.featRebook?.access ||
            state.firestore.data?.user?.admin
    )
}
export { getAvailabilityAccess, getCreateBookingAccess, getEditBookingAccess }

export default getLockAccess
