import React from "react"
import store from "../../store"
import { SET_COMPLETED_COI, SET_LOADED_COI } from "../../constants/actionTypes"
import { useSelector } from "react-redux"
import { AppBar, Button, Link, Toolbar, Typography } from "@mui/material"

const allSwitcher = (all) => {
    if (all) {
        return {
            statistical: true,
        }
    }
    return {}
}

export default function CookiePopup() {
    const coi = useSelector((state) => state.signin.coi)

    const acceptClick = (all) => {
        store.dispatch({ type: SET_COMPLETED_COI, payload: allSwitcher(all) })
    }
    if (coi.loaded && !coi.completed)
        return (
            <AppBar
                position="fixed"
                color="primary"
                sx={{ top: "auto", bottom: 0, zIndex: 1500 }}
            >
                <Toolbar>
                    <Typography sx={{ flexGrow: 1 }}>
                        By using this website, you agree to our use of cookies.
                        We use cookies to provide you with a great experience.
                        Click accept all to accept statistical and essential
                        cookies.{" "}
                        <Link
                            color="secondary"
                            target={"_blank"}
                            href="https://eagl.app/privacy"
                        >
                            {"Privacy policy"}
                        </Link>
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mr: 2, fontWeight: 600 }}
                        onClick={() => acceptClick(true)}
                    >
                        Accept All
                    </Button>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={() => acceptClick(false)}
                    >
                        Only Essential
                    </Button>
                </Toolbar>
            </AppBar>
        )
    return null
}
