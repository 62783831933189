import makeStyles from "@mui/styles/makeStyles"
import theme from "./muiTheme"

const useAppStyle = makeStyles(() => ({
    rootWrap: {
        maxWidth: `${100}vw`,
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: theme.palette.primary.dark,
    },
    root: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fillScreen: {
        height: `${100}vh`,
        width: `${100}vw`,
        backgroundColor: theme.palette.background.default,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: "#ffc200",
    },
    title: {
        flexGrow: 1,
        color: "#ffc200",
    },
}))

export default useAppStyle
