import { useEffect } from "react"
import { useSelector } from "react-redux"
import store from "../../store"
import db from "../../rootWrapper/firebase"
import { bookingPath, golfCourses } from "../../constants/dbPaths"
import { SET_BOOKINGS } from "../../constants/actionTypes"
import { Timestamp } from "firebase/firestore"

export const updateFrequency = 5 * 60 * 1000

const timeQuery = async (courseDocId, query) => {
    const { start, end, queryStart, queryEnd } = query

    let queryRes = null
    let currentQuery = {}
    if (queryStart && queryEnd && (start !== queryStart || end !== queryEnd)) {
        console.log("startQuery", new Date(queryStart))
        queryRes = await db
            .collection(golfCourses)
            .doc(courseDocId)
            .collection(bookingPath)
            .where("startTime", ">=", Timestamp.fromMillis(queryStart))
            .where("startTime", "<=", Timestamp.fromMillis(queryEnd))
            .orderBy("startTime", "desc")
            .get()
        currentQuery.start = queryStart
        currentQuery.end = queryEnd
        currentQuery.lastUpdated = Date.now()
        return {
            currentQuery,
            queryRes,
            complete: true,
        }
    }
    return {
        complete: false,
    }
}

export default function BookingHistoryLoader() {
    const query = useSelector((state) => {
        return state.bookingHistory.bookingQuery || {}
    })

    const { lastUpdated, reloadCount, queryComplete } = query

    const courseDocId = useSelector(
        (state) => state.firestore.data?.user?.courseDocId || null
    )

    useEffect(() => {
        async function handleTimeQuery() {
            if (courseDocId) {
                if (!queryComplete) {
                    const { complete, currentQuery, queryRes } =
                        await timeQuery(courseDocId, query)

                    if (complete) {
                        store.dispatch({
                            type: SET_BOOKINGS,
                            payload: {
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeQuery()
    }, [courseDocId, queryComplete])

    useEffect(() => {
        async function handleTimeUpdate() {
            if (courseDocId) {
                if (
                    !queryComplete &&
                    lastUpdated + updateFrequency < Date.now()
                ) {
                    const { complete, currentQuery, queryRes } =
                        await timeQuery(courseDocId, query)

                    if (complete) {
                        store.dispatch({
                            type: SET_BOOKINGS,
                            payload: {
                                bookings: queryRes.docs.map((x) => x.data()),
                                query: currentQuery,
                            },
                        })
                    }
                }
            }
        }
        handleTimeUpdate()
    }, [courseDocId, queryComplete, lastUpdated, reloadCount])
}
