const accessPermissionsSchema = {
    featureEngine: {
        key: 0,
        access: false,
    },
    featAvailability: {
        key: 10,
        access: false,
    },
    featBooking: {
        key: 20,
        access: false,
    },
    featRebook: {
        key: 30,
        access: false,
    },
    featCoupons: {
        key: 40,
        access: false,
    },
    featRidepass: {
        key: 45,
        access: false,
    },
    featSettings: {
        key: 50,
        access: false,
    },
    featPrice: {
        key: 55,
        access: false,
    },
    featBilling: {
        key: 60,
        access: false,
    },
    viewMap: {
        key: 110,
        access: false,
    },
    viewBooking: {
        key: 120,
        access: false,
    },
    viewVehicles: {
        key: 130,
        access: false,
    },
    viewStatistics: {
        key: 140,
        access: false,
    },
    viewReviews: {
        key: 145,
        access: false,
    },
    viewCoupons: {
        key: 150,
        access: false,
    },
    viewRidepass: {
        key: 155,
        access: false,
    },
    viewSettings: {
        key: 160,
        access: false,
    },
    viewBilling: {
        key: 165,
        access: false,
    },
    viewInstallerPage: {
        key: 170,
        access: false,
    },
    viewUpgrade: {
        key: 180,
        access: true,
    },
}

export { accessPermissionsSchema }
