import React from "react"
import { TextField, InputAdornment } from "@mui/material"

export const detailSteps = (prop) => {
    const { handleInputChange, handleInputNumber, classes } = prop
    return [
        {
            label: "About",
            description:
                "This text will be presented to the user in the app and allows you to promote it and make it as appealing as possible.  ",
            component: (currentForm) => (
                <TextField
                    id="outlined-multiline-static"
                    label="About the course"
                    onChange={handleInputChange}
                    name="about"
                    placeholder="The golf course is a links-style course, with wide open fairways and large, undulating greens. There are several lakes and streams that come into play, as well as sand traps and bunkers. The course is relatively long, and is a challenge for even the most experienced golfer."
                    multiline
                    className={classes.textField}
                    minRows={4}
                    value={currentForm.about}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                />
            ),
            value: (currentForm) => currentForm.about,
        },
        {
            label: "Address, City and Postal Code",
            description: "Visiting information for golfcourse",
            component: (currentForm) => (
                <>
                    <TextField
                        id="standard-full-width"
                        label="Address"
                        value={currentForm.address}
                        name="address"
                        className={classes.textField}
                        //helperText="Address of the course"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="standard-full-width"
                        label="City and Postal Code"
                        value={currentForm.county}
                        name="county"
                        className={classes.textField}
                        //helperText="County of the field, related to the address"
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </>
            ),
            value: (currentForm) =>
                currentForm.address + ", " + currentForm.county,
        },
        {
            label: "Phone Number",
            description:
                "The phone number of the pro-shop or main contact point. Please include country prefix.",
            component: (currentForm) => (
                <TextField
                    id="standard-full-width"
                    label="Phone number"
                    value={currentForm.phoneNumber}
                    placeholder="+47 111 11 111"
                    name="phoneNumber"
                    className={classes.textField}
                    helperText="Phone number to contact the course"
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ),
            value: (currentForm) => currentForm.phoneNumber,
        },
        {
            label: "Total Holes",
            //description: "MOre h0les = more fun? :D, 8==D (')",
            component: (currentForm) => (
                <TextField
                    id="standard-full-width"
                    label="Total holes"
                    value={currentForm.totalHoles}
                    name="totalHoles"
                    type="number"
                    className={classes.textField}
                    //helperText="Total amount of holes on the course"
                    onChange={handleInputNumber}
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ),
            value: (currentForm) => currentForm.totalHoles,
        },
    ]
}
