import {
    CHANGERAINTIME,
    COLLECTBOOKINGDONE,
    COLLECTBOOKINGLOADING,
    SELECTRAINDAY,
    UNSELECTRAINDAY,
} from "../constants/actionTypes"

const initialState = {
    selected: false,
    startTime: Date.now(),
    endTime: Date.now(),
    bookingIds: [],
    bookingCollectLoading: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SELECTRAINDAY:
            return {
                ...state,
                selected: true,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
            }
        case COLLECTBOOKINGLOADING:
            return {
                ...state,
                bookingCollectLoading: true,
            }
        case COLLECTBOOKINGDONE:
            return {
                ...state,
                bookingCollectLoading: false,
                bookingIds: action.payload.bookingIds,
            }
        case CHANGERAINTIME:
            return {
                ...state,
                startTime: action.payload.startTime,
                endTime: action.payload.endTime,
            }
        case UNSELECTRAINDAY:
            return initialState
        default:
            return state
    }
}
