import makeStyles from "@mui/styles/makeStyles"

export const useSettingsStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        overflowX: "hidden",
        [theme.breakpoints.up("md")]: {
            width: "calc(100vw - 270px)",
        },
    },
    noPad: {
        padding: `${0}%`,
    },
}))
