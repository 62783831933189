import { Box, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import InvoiceTable from "../../Components/InvoiceTable"
import { functions } from "../../rootWrapper/firebase"

const getSubInvoices = httpsCallable(functions, "getSubInvoices")

export default function SubInvoices(props) {
    const [invoices, setInvoices] = useState()
    const { subData } = props
    const courseDocId = subData.courseDocId

    useEffect(() => {
        if (courseDocId) {
            getSubInvoices({ courseDocId })
                .then((r) => {
                    const invoices = r.data
                    console.log("invoices", invoices)
                    setInvoices(invoices)
                })
                .catch((e) => {
                    console.error(e.message)
                    setInvoices(null)
                })
        }
    }, [courseDocId])

    if (invoices === undefined) {
        return null
    }

    return (
        <Box sx={{ p: 2 }}>
            <Typography>Invoices:</Typography>
            <InvoiceTable invoices={invoices} />
        </Box>
    )
}
