import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import Grid from "@mui/material/Grid"
import { useSelector } from "react-redux"
import { Box, Tooltip } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import {
    PERMISSIONSSHOULDUPDATESTATE,
    SETPERMISSIONEMAIL,
} from "../../constants/actionTypes"
import store from "../../store"
import validateEmail from "../firebaseUtils/ValidateEmail"
import { SuccessLoader } from "../settingsForm/loader/successLoader"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../rootWrapper/firebase"

const toolTip =
    "The submit adds the email to your course. You can then edit the users permission afterwards. " +
    "The user will get an invite on email to register with password. Write the exact email address."

export default function AddNewUserPermission(props) {
    const adminUser = useSelector((state) => state.firestore.data.user)
    const { courseDocId } = adminUser

    const initStatus = {
        error: false,
        success: false,
        loading: false,
    }

    const [status, setStatus] = useState(initStatus)
    const [error, setError] = useState("")
    const editing = useSelector((state) => state.permissionEdit)
    const handleChange = (e) => {
        e.preventDefault()
        const { value } = e.target
        store.dispatch({
            type: SETPERMISSIONEMAIL,
            payload: { email: value.toLowerCase() },
        })
    }
    const [invitaiontSent, setInvitationSent] = useState([false, ""])
    const wait = () => new Promise((r) => setTimeout(r, 2000))

    const newUserInvite = httpsCallable(functions, "newUserInvite")
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatus((state) => ({ ...state, loading: true }))
        const { email } = editing
        if (!validateEmail(email)) {
            setError("invalid email")
            setStatus((state) => ({ ...state, error: true, loading: false }))
            wait().then(() => {
                setError("")
                setStatus(initStatus)
            })
            return
        }
        newUserInvite({
            courseDocId,
            email: editing.email,
        })
            .then((r) => {
                setInvitationSent([true, editing.email])
                setStatus({ ...initStatus, success: true })
                setError("")
                store.dispatch({
                    type: SETPERMISSIONEMAIL,
                    payload: { email: "" },
                })
                store.dispatch({ type: PERMISSIONSSHOULDUPDATESTATE })
                wait().then(() => {
                    setStatus(initStatus)
                })
            })
            .catch((e) => {
                setStatus((status) => ({
                    ...status,
                    error: true,
                    loading: false,
                }))
                setError(e.message)
                wait().then(() => {
                    setStatus(initStatus)
                })
            })
    }

    return (
        <Box p={4} maxWidth={"600px"}>
            <Typography fontSize={"16pt"} fontWeight={"500"}>
                Add new User
            </Typography>
            <Typography variant="p">
                Invite a new user to your Eagl Fleet Dashboard
            </Typography>

            <Grid container direction="column" spacing={2}>
                <Grid item xs>
                    <TextField
                        color="secondary"
                        variant="filled"
                        label="New user email"
                        placeholder="test@eagl.app"
                        value={editing.email}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <Tooltip
                                    title={
                                        <Typography fontSize="14pt">
                                            {toolTip}
                                        </Typography>
                                    }
                                    placement="top"
                                >
                                    <IconButton size="small">
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs>
                    <SuccessLoader
                        status={status}
                        error={error}
                        contained
                        sx={{ maxWidth: "200px" }}
                        handleSubmit={handleSubmit}
                    >
                        Send invitation
                    </SuccessLoader>
                </Grid>
                <Grid item xs style={{ maxWidth: "400px" }}>
                    {status.success || invitaiontSent[0] ? (
                        <>
                            <Typography>Invitation sent!</Typography>
                            <Typography color="#DCDCDCDC">
                                An invitation to EAGL Fleet Dashboard is sent to{" "}
                                {invitaiontSent[1]}. Follow the steps in the
                                email to register a new user.
                            </Typography>
                        </>
                    ) : null}
                </Grid>
            </Grid>
        </Box>
    )
}
