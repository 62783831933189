import { Button, Grid, Select, TextField, Typography } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CountryDropdown } from "react-country-region-selector"
import CircleLoader from "../../../Components/loader"
import RegisterContainer from "../../../Components/RegisterContainer"
import { httpsCallable } from "firebase/functions"
import { getAuth } from "firebase/auth"
import theme from "../../../assets/styles/muiTheme"
import CurrencySelector from "../../../Components/CurrencySelector"
import { SuccessLoader } from "../../../Components/settingsForm/loader/successLoader"
import MapPicker from "../../../Components/PositionPicker"
import { functions } from "../../../rootWrapper/firebase"
import store from "../../../store"
import countryCodeToCurrency from "../../../constants/countryCodeTocurrency.json"
import currenciesJson from "../../../Components/CurrencySelector/currencies.json"
import { SET_REGISTER_STEP } from "../../../constants/actionTypes"

const initStatus = {
    success: false,
    loading: false,
    error: false,
    errorMessage: "",
}
const currencies = () => {
    return Object.keys(currenciesJson).reduce((current, key) => {
        return {
            ...current,
            [key]: {
                ...currenciesJson[key],
                key,
            },
        }
    }, {})
}

const checkForm = (data, setStatus) => {
    console.log(data)
    setStatus({
        ...initStatus,
        loading: true,
    })
    const { courseName, country, currency, mapConf } = data
    if (!courseName || courseName.length < 5) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage: "Please enter a valid course name",
        })
        return false
    }
    if (!country || country.length !== 2) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage: "Please enter a valid country",
        })
        return false
    }
    if (!currency || currency.length !== 3) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage: "Please enter a valid currency",
        })
        return false
    }
    if (
        !mapConf ||
        !mapConf.zoom ||
        isNaN(mapConf.zoom) ||
        !mapConf.pos ||
        !(Object.keys(mapConf.pos).length === 2) ||
        isNaN(mapConf.pos.lng) ||
        isNaN(mapConf.pos.lat)
    ) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage: "Invalid map position",
        })
        return false
    }
    if (mapConf.zoom <= 13) {
        setStatus({
            ...initStatus,
            error: true,
            errorMessage:
                "Invalid map position, carefully select where your golf course is",
        })
        return false
    }
    return true
}

const wait = async (time) => new Promise((resolve) => setTimeout(resolve, time))

export default function CreateCourse() {
    const navigate = useNavigate()
    const [details, setDetails] = useState({})
    const [courseDetails, setCourseDetails] = useState({})
    const [courseName, setCourseName] = useState("")
    const [currency, setCurrency] = useState(null)
    const [country, setCountry] = useState("")
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const [status, setStatus] = useState(initStatus)
    const [mapConf, setMapConf] = useState({
        zoom: "",
        pos: {
            lng: 0,
            lat: 0,
        },
    })
    useEffect(() => {
        store.dispatch({ type: SET_REGISTER_STEP, payload: { step: 1 } })
    }, [])

    useEffect(() => {
        if (details) {
            if (details.name) {
                setCourseName(details.name)
            }
            if (details.country) {
                setCountry(details.country)
                if (details.country in countryCodeToCurrency) {
                    const currencyFromCountry =
                        countryCodeToCurrency[details.country]
                    console.log(currencies())
                    const currencyObject = currencies()[currencyFromCountry]
                    console.log(
                        details.country,
                        currencyFromCountry,
                        currencyObject
                    )
                    setCurrency(currencyObject)
                }
            }
            if (details.phone) {
                setCourseDetails((detj) => ({
                    ...detj,
                    phoneNumber: details.phone,
                }))
            }
            if (details.route && details.street_number) {
                setCourseDetails((detj) => ({
                    ...detj,
                    address: details.route + " " + details.street_number,
                }))
            }
            if (details.postal_code && details.postal_town) {
                setCourseDetails((detj) => ({
                    ...detj,
                    county: details.postal_code + " " + details.postal_town,
                }))
            }
        }
    }, [details])

    const handleChangeCourseName = (e) => {
        e.preventDefault()
        const { value } = e.target
        setCourseName(value)
    }
    const handleSetCountry = (val) => {
        setCountry(val)
    }
    useEffect(() => {
        wait(3000).then(() => {
            if (status.error) {
                setStatus(initStatus)
            }
        })
    }, [status.error])

    useEffect(() => {
        wait(2000).then(() => {
            if (status.success) {
                setStatus(initStatus)
                navigate("/register/course/carts")
            }
        })
    }, [status.success])

    const user = useSelector((state) => state.firebase.auth)
    const emailVerified =
        getAuth().currentUser?.emailVerified || user?.emailVerified
    useEffect(() => {
        if (!emailVerified) {
            navigate("/register/course/confirmEmail")
        }
    }, [user?.isLoaded, emailVerified])
    if (!user) {
        return <CircleLoader fillScreen />
    }

    const submitForm = (e) => {
        e.preventDefault()
        setStatus({ ...initStatus, loading: true })
        const success = checkForm(
            {
                courseName,
                country,
                currency: currency?.key,
                mapConf,
            },
            setStatus
        )
        if (!success) {
            return
        }

        const createNewCourseByUser = httpsCallable(
            functions,
            "createNewCourseByUser"
        )
        createNewCourseByUser({
            courseName,
            countryCode: country,
            timeZone,
            currency: currency.key,
            pos: [mapConf.pos.lat, mapConf.pos.lng],
            zoom: mapConf.zoom,
            details: courseDetails,
            mapDetails: details,
        })
            .then(() => {
                setStatus({ ...initStatus, success: true })
            })
            .catch((e) => {
                console.error(e.message)
                setStatus({
                    ...initStatus,
                    error: true,
                    errorMessage: e.message,
                })
            })
    }

    return (
        <RegisterContainer>
            <Typography fontSize={"24pt"} variant="h1">
                Create your Golf Course
            </Typography>
            <Typography fontSize={"12pt"} variant="h2">
                More than one golf course on your Golf Club?{" "}
                <Typography
                    component="a"
                    fontSize={"inherit"}
                    color={"secondary"}
                    href="mailto:contact@eagl.app?subject=[Signup][EAGL Connect] More than one course"
                >
                    Click here for help.
                </Typography>
            </Typography>
            <form onSubmit={submitForm}>
                <Grid
                    justifyContent={"center"}
                    sx={{ mt: 2 }}
                    container
                    alignItems={"center"}
                    spacing={1}
                    direction={"column"}
                >
                    <Grid item xs sx={{ mb: 2 }}>
                        <MapPicker
                            fullWidth
                            heigth={300}
                            setDetails={setDetails}
                            mapConf={mapConf}
                            setMapConf={setMapConf}
                        />
                        <Typography component="p" fontSize="12px">
                            Please move/zoom the map until your entire golf
                            course is inside the frame.
                            <Typography
                                fontSize="inherit"
                                component="a"
                                color={"secondary"}
                                href="mailto:contact@eagl.app?subject=[Signup][EAGL Connect]"
                            >
                                Click here for help.
                            </Typography>
                        </Typography>
                    </Grid>
                    <Grid item xs container direction="row">
                        <Grid item xs>
                            <TextField
                                id="filled-required"
                                label="Course Name"
                                autoComplete="off"
                                name="coures-name"
                                placeholder=""
                                color="secondary"
                                variant="outlined"
                                fullWidth
                                value={courseName}
                                onChange={handleChangeCourseName}
                            />
                        </Grid>
                        <Grid item xs>
                            <CountryDropdown
                                style={{
                                    width: "225px",
                                    height: "40px",
                                    backgroundColor: theme.palette.primary.main,
                                    borderColor: "rgba(255, 255, 255, 0.23)",
                                    borderWidth: "1px",
                                    borderRadius: "5px",
                                    color: "white",
                                    padding: "10px",
                                    cursor: "pointer",
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    marginTop: "8px",
                                    FontFace:
                                        '"Roboto","Helvetica","Arial",sans-serif',
                                }}
                                value={country}
                                valueType="short"
                                onChange={handleSetCountry}
                            />
                        </Grid>
                        <Grid item xs>
                            <CurrencySelector
                                currency={currency}
                                setCurrency={setCurrency}
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs>
                        Timezone: {timeZone}
                    </Grid>
                    <Grid item xs>
                        <SuccessLoader
                            status={status}
                            error={status?.errorMessage}
                            handleSubmit={() => true}
                        />
                    </Grid>
                </Grid>
            </form>
        </RegisterContainer>
    )
}
