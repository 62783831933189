import makeStyles from "@mui/styles/makeStyles"

const drawerWidth = "270"

export const useDrawerStyles = makeStyles((theme) => ({
    root: {
        maxWidth: `${100}vw`,
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        backgroundColor: theme.palette.primary.dark,
    },
    drawer: {
        [theme.breakpoints.up("md")]: {
            width: `${drawerWidth}px`,
            flexShrink: 0,
        },
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.dark,
        padding: 0,
        right: 0,
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            overflowX: "hidden",
        },
    },
    appBar: {
        [theme.breakpoints.up("md")]: {
            width: `calc(100vw - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },

    logowrap: {
        position: "relative",
        display: "flex",
        overflow: "hidden",
        margin: 20,
        alignSelf: "center",
        justifyContent: "center",
    },

    logo: {
        display: "block",
        position: "relative",
        marginLeft: 20,
        marginRight: 20,
    },
    toolbar: {
        [theme.breakpoints.down("lg")]: {
            ...theme.mixins.toolbar,
        },
    },
    drawerPaper: {
        width: `${drawerWidth}px`,
        background: theme.palette.primary.main,
        overflowX: "hidden",
    },
    icon: {
        color: theme.palette.secondary.main,
    },
}))
