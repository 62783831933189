import { Button, Divider, Grid, Tooltip, Typography, Box } from "@mui/material"
import React from "react"
import { Link, useNavigate } from "react-router-dom"
import RegisterContainer from "../../Components/RegisterContainer"

const isDev = process.env.NODE_ENV === "development"

export default function RegisterType() {
    const navigate = useNavigate()

    const handleNewCourse = () => {
        navigate("/register/course")
    }

    const handleJoin = () => {
        navigate("/register/user")
    }

    return (
        <RegisterContainer fullHeight>
            <Grid
                container
                alignItems={"center"}
                direction="column"
                sx={{ m: "auto", "&> div": { margin: 2 }, width: "auto" }}
            >
                <Grid item xs>
                    <Typography fontSize={32}>
                        Welcome to the .EAGL registration
                    </Typography>
                    <Typography fontSize={16}>
                        Are you creating a new course, or joining an existing
                        .EAGL golf course?
                    </Typography>
                </Grid>
                <Divider flexItem />
                <Grid
                    sx={{ mb: 2, button: { height: 100, fontWeight: "bold" } }}
                    container
                    direction="row"
                    spacing={2}
                    item
                    xs
                >
                    <Grid item xs>
                        <Tooltip
                            title={
                                <Typography fontSize="14pt">
                                    {isDev ? null : (
                                        <>
                                            <Typography component={"span"}>
                                                Contact .EAGL at{" "}
                                            </Typography>
                                            <Typography
                                                component={"a"}
                                                color="secondary"
                                                href="mailto:contact@eagl.app?subject=[Register Contact] I want .EAGL"
                                            >
                                                {"contact@eagl.app"}
                                            </Typography>
                                        </>
                                    )}
                                </Typography>
                            }
                            placement="top"
                        >
                            <Box>
                                <Button
                                    variant="contained"
                                    onClick={handleNewCourse}
                                    color="secondary"
                                    fullWidth
                                    disabled={!isDev}
                                >
                                    New Course
                                </Button>
                            </Box>
                        </Tooltip>
                    </Grid>

                    <Grid item xs>
                        <Button
                            variant="contained"
                            onClick={handleJoin}
                            color="secondary"
                            fullWidth
                        >
                            Join course
                        </Button>
                    </Grid>
                </Grid>
                <Divider flexItem />
                <Grid item container>
                    <Grid item xs>
                        <Link
                            to="/login"
                            style={{ color: "#c3c3c3" }}
                            variant="body2"
                        >
                            Login
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link
                            to="/forgotpass"
                            variant="body2"
                            style={{ color: "#c3c3c3" }}
                        >
                            Forgot password?
                        </Link>
                    </Grid>
                </Grid>
            </Grid>
        </RegisterContainer>
    )
}
