import { getAuth } from "firebase/auth"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import RegisterContainer from "../../../Components/RegisterContainer"
import ConfirmEmail from "../../../Components/confirmEmail"
import { useSelector } from "react-redux"

export default function ConfirmEmailView() {
    const navigate = useNavigate()

    const auth = getAuth()
    const user = useSelector((state) => state.firebase.auth)

    useEffect(() => {
        if (user?.isEmpty) {
            navigate("/register/user")
        }
    }, [user])

    useEffect(() => {
        async function checkAuth() {
            await auth.currentUser?.reload()
            if (auth.currentUser?.emailVerified) {
                navigate("/")
            }
        }
        const timer = setInterval(() => {
            checkAuth()
        }, 1000)
        checkAuth()
        return () => clearInterval(timer)
    }, [])

    return (
        <RegisterContainer fullHeight>
            <ConfirmEmail />
        </RegisterContainer>
    )
}
