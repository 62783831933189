import {
    SET_DETAILS_ALL_SETTINGS,
    SELECT_DETAIL_FIELD,
} from "../constants/actionTypes"

const initState = {
    about: "",
    address: "",
    county: "",
    phoneNumber: "",
    totalHoles: 0,
    detailsLoaded: false,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_DETAILS_ALL_SETTINGS:
            return {
                ...action.payload,
                detailsLoaded: true,
            }
        case SELECT_DETAIL_FIELD:
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            }
        default:
            return state
    }
}
