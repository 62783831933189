import React, { useState } from "react"
import Button from "@mui/material/Button"
import { useSelector } from "react-redux"
import MoveDialog from "./bookingMovePopup"

export default function MoveBookingButton(props) {
    const [moveOpen, setMoveOpen] = useState(false)
    const { selected, cartDocId, bookingId, startTime, endTime } = useSelector(
        (state) => state.bookingSelector
    )
    const courseDocId = useSelector((state) => {
        if (
            state.firestore.data &&
            "user" in state.firestore.data &&
            Object.keys(state.firestore.data.user).indexOf("courseDocId") > -1
        ) {
            return state.firestore.data.user.courseDocId
        }
        return false
    })

    const handleClose = () => {
        setMoveOpen(false)
    }
    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                style={{
                    marginTop: "10px",
                    alignSelf: "center",
                    display: "flex",
                    width: "100%",
                    height: "calc(100% - 10px)",
                }}
                onClick={() => setMoveOpen(true)}
                disabled={props.disabled}
            >
                CHANGE TIME
            </Button>
            <MoveDialog
                docRef={bookingId}
                startTime={startTime}
                endTime={endTime}
                courseDocId={courseDocId}
                open={moveOpen}
                handleClose={handleClose}
            />
        </>
    )
}
