import React, { useEffect, useState } from "react"
import { Box, Typography, Icon, Checkbox, LinearProgress } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useDialogStyles } from "../../assets/styles/components/bookingPopup"
import { useSelector } from "react-redux"
import store from "../../store"
import { SET_BOOKING_FIELDS } from "../../constants/actionTypes"

export default function ReoccurSection() {
    const [open, setOpen] = useState(false)
    const [reoccuringInfo, setReoccuringInfo] = useState({
        times: 0,
        loading: false,
        date: new Date(),
    })
    const { startTime, amountOfCarts } = useSelector(
        (state) => state.createBooking
    )

    const reoccuring = useSelector(
        (state) => state.createBooking?.reoccuring ?? false
    )

    const handleChangeOpen = (newOpenValue) => {
        if (newOpenValue) {
            setOpen(true)
        } else {
            store.dispatch({
                type: SET_BOOKING_FIELDS,
                payload: {
                    reoccuring: false,
                },
            })
            setOpen(false)
        }
    }
    const handleReocc = () => {
        store.dispatch({
            type: SET_BOOKING_FIELDS,
            payload: {
                reoccuring: !reoccuring,
            },
        })
    }
    const style = useDialogStyles()

    useEffect(() => {
        if (open) {
            setReoccuringInfo((state) => ({ ...state, loading: true }))
        }
        setReoccuringInfo((state) => ({ ...state, loading: false }))
    }, [open])

    const weekday = new Intl.DateTimeFormat("en-US", {
        weekday: "long",
    }).format(new Date(startTime || 0))

    return (
        <Box>
            <Box className={style.expandHeaderBox}>
                <Typography
                    className={style.expandTypography}
                    display={"inline"}
                    onClick={() => handleChangeOpen(!open)}
                >
                    Recurring
                </Typography>
                <Icon
                    onClick={() => handleChangeOpen(!open)}
                    className={[
                        style.dropdown,
                        open ? style.dropdownClosed : style.dropdownOpen,
                    ]}
                >
                    <ExpandMoreIcon />
                </Icon>
            </Box>
            {open && reoccuringInfo.loading ? (
                <LinearProgress color="secondary" />
            ) : null}
            {open ? (
                <>
                    <Checkbox
                        checked={reoccuring}
                        onChange={handleReocc}
                        name="reoccurring"
                    />
                    {`Copy to every ${weekday} until the end of
                    ${new Date(startTime || 0).getFullYear()}.`}
                    <br />
                    {`* Will create up to ${
                        amountOfCarts || 1
                    } booking('s) each ${weekday} if possible.`}
                </>
            ) : null}
        </Box>
    )
}
