import React, { useEffect, useState } from "react"
import "../TripsList/lastTrips.css"
import { useSelector } from "react-redux"
import LockCart from "../lock/unlocking-cart"
import MapTable from "./mapTable"
import { sortCarts } from "../timeline"

export default function LockTable(props) {
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId || null
    )
    const Carts = useSelector((state) => state.firestore.data.carts)
    const vehicleGroups = useSelector(
        (state) => state.firestore.data.field?.vehicleGroups
    )
    const cartState = useSelector((state) => state.cartReadings)

    const engineValue = (cartDocId) => {
        if (Object.keys(cartState).indexOf(cartDocId) > -1) {
            return cartState[cartDocId].engineComp
        }
        return false
    }
    const [topDisabled, setDisabled] = useState(false)

    useEffect(() => {
        const carts = Object.keys(Carts).reduce((dict, key) => {
            dict.push({
                ...Carts[key],
                cartDocId: key,
                vehicleGroupName: vehicleGroups[Carts[key].vehicleGroup].name,
            })
            return dict
        }, [])
        const newRows = {
            ...rows,
            ...carts.sort(sortCarts),
        }
        Object.keys(newRows).forEach((key) => {
            if (Object.keys(cartState).indexOf(key) > -1) {
                newRows[key] = {
                    ...newRows[key],
                    ...cartState[key],
                    engine: engineValue(newRows[key].engine),
                }
            } else {
                newRows[key] = { engine: false, ...newRows[key] }
            }
        })
        setRows(newRows)
    }, [props])

    const topRow = {
        switchStatus: false,
        ID: "All Carts",
    }
    const [lockAll, setLockAll] = React.useState(topRow)
    const [rows, setRows] = React.useState({})

    const handleChange = (event) => {
        const oldRow = rows[event.target.id]
        if (!(oldRow.engine === event.target.checked)) {
            LockCart(event.target.checked, courseDocId, oldRow.cartDocId)
                .then((r) => {
                    console.log(r)
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
    }
    const handleLockAll = (event) => {
        Promise.all(
            Object.values(rows).map((value, index) => {
                if (!(value.engine === event.target.checked)) {
                    return LockCart(
                        event.target.checked,
                        courseDocId,
                        value.cartDocId
                    )
                }
            })
        )
        setLockAll((topRow) => ({
            ...topRow,
            switchStatus: event.target.checked,
        }))
        setDisabled(true)
        new Promise((res) => setTimeout(res, 10000)).then(() => {
            setDisabled(false)
        })
        if (event.target.checked) {
            console.log("Lock all")
        } else {
            console.log("Unlock all")
        }
    }

    return (
        <MapTable
            rows={rows}
            handleGetVehiclePosition={props.handleGetVehiclePosition}
            handleLockAll={handleLockAll}
            handleChange={handleChange}
            lockAll={lockAll}
            topDisabled={topDisabled}
            lockTable
        />
    )
}
