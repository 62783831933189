import React, { useEffect, useState } from "react"
import { Grid, Box, Typography } from "@mui/material"
import PropTypes from "prop-types"
import makeStyles from "@mui/styles/makeStyles"
import welcomeImage from "../../assets/img/eagl-it-installer.svg"
import theme from "../../assets/styles/muiTheme"
import AddInstallerInput from "./addInstallerInput"

const useStyles = makeStyles((theme) => ({
    imageStyle: {
        maxWidth: "100%",
        height: "auto",
        objectFit: "cover",
    },
}))

export default function addInstallerOnboardingView(props) {
    const { handleComplete } = props
    const [skipPressed, setSkipPressed] = useState(false)
    const introStyle = useStyles(theme)

    useEffect(() => {
        let timeout
        if (skipPressed) {
            new Promise((res) => {
                timeout = setTimeout(res, 5000)
            }).then(() => {
                setSkipPressed(false)
            })
        }
        return () => clearTimeout(timeout)
    }, [skipPressed])

    return (
        <Box sx={{ padding: "40px" }}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={6}>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography variant="h4">
                                Golf Cart Installation
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                In this step we are going to install the
                                SmartBoxes into the golf carts. This will take
                                approximately 5 minutes per golf cart.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                If you want to install the golf carts yourself,
                                scan the QR code with your phone and proceed
                                from there. If you have a designated installer,
                                invite them by email in the add installer field
                                and they will recieve an invitation email to
                                register and installation instructions.
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <AddInstallerInput />
                        </Grid>
                        {/*  */}
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            margin: "auto",
                            maxWidth: 300 + "px",
                        }}
                    >
                        <img
                            src={welcomeImage}
                            className={introStyle.imageStyle}
                            alt="Install golfcart image"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

addInstallerOnboardingView.propTypes = {
    handleComplete: PropTypes.func.isRequired,
}
