import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { Autocomplete, TextField } from "@mui/material"
import { sortCarts } from "../timeline"

export default function BookingCartDropdown(props) {
    const handleChange = (event, values) => {
        if (values) {
            props.setDropdown(values.id)
        } else {
            props.setDropdown("")
        }
    }

    const carts = useSelector((state) => state.firestore.data.carts)
    const vehicleGroups = useSelector(
        (state) => state.firestore.data.field.vehicleGroups || {}
    )

    const getValues = () => {
        if (!props.selectMulti) {
            if (props.dropdown && props.dropdown.length) {
                const cart = carts?.[props.dropdown] || {}
                const vehicleGroup = cart.vehicleGroup || "0"
                const vehicleGroupName = vehicleGroups[vehicleGroup].name

                return {
                    label: `${vehicleGroupName} ${carts[props.dropdown].id}`,
                    id: props.dropdown,
                }
            }
            return ""
        }
        return null
    }
    const options = Object.keys(carts).length
        ? Object.keys(carts)
              .sort((x, y) => sortCarts(carts[x], carts[y]))
              .map((key) => {
                  const vehicleGroup = carts?.[key]?.vehicleGroup || "0"
                  return {
                      label: `${vehicleGroups[String(vehicleGroup)].name} ${
                          carts[key].id
                      }`,
                      id: key,
                  }
              })
        : []
    return (
        <div>
            <Autocomplete
                autoComplete={true}
                options={options}
                value={getValues() || null}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField {...params} label="Vehicle" />
                )}
            />
        </div>
    )
}
