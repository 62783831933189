import React, { useCallback, useState } from "react"
import { useStyles } from "../assets/styles/views/booking"
// make sure you include the timeline stylesheet or the timeline will not be styled
import "../assets/styles/views/booking.css"
import Grid from "@mui/material/Grid"
import Hidden from "@mui/material/Hidden"
import Button from "@mui/material/Button"
import { useSelector } from "react-redux"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import enLocale from "date-fns/locale/en-GB"
import theme from "../assets/styles/muiTheme"
import {
    SELECTRAINDAY,
    UNSELECT_BOOKING,
    UNSELECTRAINDAY,
    SET_BOOKING_VIEW_START,
    UPDATE_BOOKING_VIEW,
} from "../constants/actionTypes"
import store from "../store"
import ControlBooking from "../Components/Booking/bookingController/bookingControlPopup"
import BookingDialog from "../Components/Booking/bookingPopup"
import BookingTable from "../Components/Booking/bookingTable"
import RainPopup from "../Components/Booking/rainPopup"
import BookingTimeline from "../Components/timeline"
import { renderInput } from "../Components/vehicleStatsRow/timeDataPicker"
import { getCreateBookingAccess } from "../constants/accessPermissionsChecks"

export const oneDayInMs = 60 * 60 * 24 * 1000

export default function Booking() {
    const [newBookingOpen, setNewBookingOpen] = useState(false)
    const rainDayOpen = useSelector((state) => state.rainDay.selected)
    const { startTime, endTime } = useSelector((state) => state.bookingView)
    const bookingSelected = useSelector(
        (state) => state.bookingSelector.selected
    )
    const canCreateBooking = getCreateBookingAccess()

    const handleOpenRain = () => {
        store.dispatch({
            type: SELECTRAINDAY,
            payload: {
                startTime: startTime,
                endTime: endTime,
            },
        })
    }
    const handleCloseRain = () => {
        store.dispatch({ type: UNSELECTRAINDAY })
        store.dispatch({
            type: UPDATE_BOOKING_VIEW,
        })
    }
    const handleClickOpenNewBooking = useCallback(() => {
        setNewBookingOpen(true)
    }, [])
    const handleCloseNewBooking = useCallback(() => {
        setNewBookingOpen(false)
        store.dispatch({
            type: UPDATE_BOOKING_VIEW,
        })
    }, [])
    const handleCloseBookingEdit = () => {
        store.dispatch({ type: UNSELECT_BOOKING })
        store.dispatch({
            type: UPDATE_BOOKING_VIEW,
        })
    }
    const classes = useStyles(theme)

    const handleDateChange = (date) => {
        if (date instanceof Date && !isNaN(date)) {
            const newStartTime = date.getTime()
            store.dispatch({
                type: SET_BOOKING_VIEW_START,
                payload: { startTime: newStartTime },
            })
        }
    }

    return (
        <div className={classes.root}>
            <Grid container direction="column" spacing={1}>
                <Hidden lgUp>
                    <Grid item xs>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            alignContent="center"
                            style={{ padding: "8px" }}
                        >
                            <Grid item xs>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                    }}
                                    disabled={!canCreateBooking}
                                    fullWidth
                                    onClick={handleClickOpenNewBooking}
                                >
                                    Make reservation
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    fullWidth
                                    disabled={!canCreateBooking}
                                    style={{
                                        borderRadius: "4px",
                                        fontWeight: "bold",
                                    }}
                                    onClick={handleOpenRain}
                                >
                                    Fleet Override
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item xs className={classes.cal}>
                        <BookingTimeline
                            handleClickOpenNewBooking={
                                handleClickOpenNewBooking
                            }
                            handleOpenRain={handleOpenRain}
                        />
                    </Grid>
                </Hidden>

                <Hidden smUp>
                    <Grid item xs>
                        <Grid container direction="row">
                            <Grid item xs>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ display: "inline-block" }}>
                                        <LocalizationProvider
                                            adapterLocale={enLocale}
                                            dateAdapter={AdapterDateFns}
                                        >
                                            <DatePicker
                                                autoOk
                                                emptyLabel
                                                disableToolbar
                                                variant="inline"
                                                id="date-picker-inline"
                                                inputFormat="dd/MM/yyyy"
                                                value={startTime}
                                                onChange={handleDateChange}
                                                renderInput={renderInput}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid item xs className={classes.cal}>
                    <BookingTable />
                </Grid>
            </Grid>
            <BookingDialog
                handleClose={handleCloseNewBooking}
                open={newBookingOpen}
                startDate={startTime}
            />
            <RainPopup
                handleClose={handleCloseRain}
                handleClickOpen={handleOpenRain}
                open={rainDayOpen}
            />
            <ControlBooking
                handleClose={handleCloseBookingEdit}
                open={bookingSelected}
            />
        </div>
    )
}
