import {
    SET_BOOKING_FIELDS,
    CLEAR_CREATE_BOOKING,
} from "../constants/actionTypes"

const initState = {
    startTime: Date.now(),
    endTime: Date.now() + 5 * 60 * 60 * 1000,
    amountOfCarts: 1,
    price: 0,
    tax: 0,
    paid: false,
    name: "",
    tlf: "",
    note: "",
    userSegment: "",
    couponCode: "",
    cartIdList: [],
    vehicleGroup: "0",
    advancedOptions: false,
    reoccurring: false,
    isNineHoles: false,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SET_BOOKING_FIELDS:
            return {
                ...state,
                ...action.payload,
            }
        case CLEAR_CREATE_BOOKING:
            return {
                ...initState,
                startTime: state.startTime,
                endTime: state.endTime,
                advancedOptions: state.advancedOptions,
            }
        default:
            return state
    }
}
