import { Box, Button, Grid, Link, Typography } from "@mui/material"
import { getAuth, sendEmailVerification } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CircleLoader from "../loader"

export default function ConfirmEmail() {
    const navigate = useNavigate()
    const [countDown, setCountDown] = useState(30)
    const [resend, setResendt] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            let newCountDown = Math.max(countDown - 1, 0)
            setCountDown(newCountDown)
        }, 1000)
        return () => clearTimeout(timeout)
    }, [countDown])

    const user = useSelector((state) => state.firebase.auth)
    const resendVerification = async () => {
        setCountDown(40)
        setResendt(true)
        if (!getAuth().currentUser.emailVerified) {
            await sendEmailVerification(getAuth().currentUser)
        }
    }
    if (!user?.isLoaded) {
        return <CircleLoader />
    }

    return (
        <Box textAlign={"left"}>
            <Typography fontSize={"24pt"} variant="h1">
                .EAGL
            </Typography>
            <Typography
                sx={{ pt: 2, pb: 4 }}
                fontSize={"16pt"}
                fontWeight={500}
                variant="h2"
            >
                Confirm your email to get started.
            </Typography>
            <Typography fontSize={"16pt"} variant="h2">
                Hi {user.displayName}, verify your email so we know it&nbsp;s
                really you.
                <br /> We sent an email to {user.email}.
            </Typography>
            <br />
            <Typography color="#DCDCDC" variant="p">
                {countDown > 0 ? (
                    `Resend in ${countDown} seconds `
                ) : (
                    <Link
                        component="p"
                        sx={{
                            textDecoration: "underline",
                            ":hover": {
                                cursor:
                                    countDown <= 0 ? "pointer" : "not-allowed",
                            },
                            color: countDown <= 0 ? "secondary" : "#DCDCDC",
                        }}
                        onClick={() =>
                            countDown <= 0 ? resendVerification() : null
                        }
                        color="secondary"
                    >
                        Resend
                    </Link>
                )}
                {resend ? (
                    <>
                        <br />
                        <Typography>Re-sent!</Typography>
                        <br />
                    </>
                ) : null}
            </Typography>

            <br />
            <Typography color="#DCDCDC" variant="p">
                If you did not receive an email, please check your spam folder.
            </Typography>
        </Box>
    )
}
