import {
    SET_SERVICE_STATE,
    SET_SERVICE_EXPANDED,
} from "../constants/actionTypes"

const initialState = {
    expanded: false,
    serviceStatus: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVICE_STATE:
            return {
                ...state,
                serviceStatus: {
                    ...state.serviceStatus,
                    ...action.payload,
                },
            }
        case SET_SERVICE_EXPANDED:
            return {
                ...state,
                expanded: action.payload.expanded,
            }
        default:
            return state
    }
}
