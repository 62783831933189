export const vehicleSwitch = {
    RXEL: "EZGO RXV",
    TXEL: "EZGO TXT",
    CCEL: "Club Car EL",
    CCGS: "Club Car Gas",
    YAEL: "Yamaha EL",
    YAGS: "Yamaha Gas",
    ELWE: "Ellwee Scooter",
    EAEL: "Eagle Cart",
    HCEL: "Hansecart",
    FINN: "Finn Scooter",
    OT: "Other",
}

const cartTypeToName = (cartType, cart) => {
    if (cartType.startsWith("OT")) {
        return cart.typeOther
    }
    if (Object.keys(vehicleSwitch).indexOf(cartType) === -1) {
        console.debug("Bad vehicle type", cartType)
        return "NaN"
    }
    return vehicleSwitch[cartType]
}

export default cartTypeToName
