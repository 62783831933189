import { Box, Link, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { SELECTSETTINGSFIELD } from "../../../../constants/actionTypes"
import { functions } from "../../../../rootWrapper/firebase"
import store from "../../../../store"

const title = <>Stripe admin email</>

const desc = (
    <>
        Stripe administrator account email. This can only be changed in the
        stripe dashboard. Go to{" "}
        <Link
            href="https://dashboard.stripe.com/settings/user"
            target="_blank"
            color="secondary"
        >
            Link
        </Link>
    </>
)

function CurrentValue() {
    const [email, setEmail] = useState(null)
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    useEffect(() => {
        let shouldUpdate = true
        if (courseDocId && !email) {
            getBillingAccount({ courseDocId, subscriptionAccount: false })
                .then((r) => {
                    if (shouldUpdate) {
                        const emailTmp = r?.data?.email || ""
                        setEmail(emailTmp)
                        store.dispatch({
                            type: SELECTSETTINGSFIELD,
                            payload: {
                                key: "stripeBillingEmail",
                                value: emailTmp,
                            },
                        })
                    }
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
        return () => {
            shouldUpdate = false
        }
    }, [courseDocId, email])
    return email
}

const getBillingAccount = httpsCallable(functions, "getBillingAccount")

function BillingEmail() {
    const [email, setEmail] = useState(null)
    const courseDocId = useSelector(
        (state) => state.firestore?.data?.user?.courseDocId
    )
    useEffect(() => {
        let shouldUpdate = true
        if (courseDocId && !email) {
            getBillingAccount({ courseDocId, subscriptionAccount: false })
                .then((r) => {
                    if (shouldUpdate) {
                        const emailTmp = r?.data?.email || ""
                        setEmail(emailTmp)
                        store.dispatch({
                            type: SELECTSETTINGSFIELD,
                            payload: {
                                key: "stripeBillingEmail",
                                value: emailTmp,
                            },
                        })
                    }
                })
                .catch((e) => {
                    console.error(e.message)
                })
        }
        return () => {
            shouldUpdate = false
        }
    }, [courseDocId, email])
    const currentForm = useSelector((state) => state.settingsForm)

    if (!email) {
        return null
    }
    const stripeComplete = currentForm.stripeCompleted

    return (
        <Box style={{ overflowWrap: "break-word" }}>
            {stripeComplete ? (
                <Typography id="standard" label="Billing Email">
                    {currentForm?.stripeBillingEmail || ""}
                </Typography>
            ) : (
                <Typography>
                    Register in{" "}
                    <Link color="secondary" href="/setup?page=2">
                        Stripe Setup
                    </Link>
                </Typography>
            )}
        </Box>
    )
}

export default {
    desc,
    title,
    CurrentValue,
    Element: BillingEmail,
}
